import React, { createContext, useCallback, useState } from 'react'

const DocumentContext = createContext()

const DocumentProvider = ({ children }) => {
  const [deletedDocuments, setDeletedDocuments] = useState([])

  const handleDeleteFile = useCallback(
    (value) => {
      setDeletedDocuments([...deletedDocuments, value])
    },
    [deletedDocuments]
  )

  return (
    <DocumentContext.Provider value={{ deletedDocuments, setDeletedDocuments, handleDeleteFile }}>
      {children}
    </DocumentContext.Provider>
  )
}

export { DocumentContext, DocumentProvider }
