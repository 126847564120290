import { Helmet } from 'react-helmet-async'
import { Typography, Button } from 'components'
import styles from './signin.module.scss'
import { createTheme, useMediaQuery } from '@mui/material'
import loginLogo from '../../assets/Login-page-logo.png'

const ConfirmResetPassword = ({ setConfirmed }) => {
  const theme = createTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const heightWidth = isMobile ? 200 : 350
  return (
    <>
      <Helmet title="Check email | RARE" />
      <div className={styles.signin}>
        <div className={styles.logoContainer}>
          <img src={loginLogo} alt="RARE Logo" height={heightWidth} width={heightWidth} />
        </div>
        <div className={styles.centerContent}>
          <Typography.Heading type="2" color="white" style={{ marginTop: 36 }} className={styles.subheading}>
            Confirm Password Reset
          </Typography.Heading>
          <Typography.Body color="white" style={{ marginTop: 8 }} className={styles.subheading}>
            If you have lost your password or wish to reset it, use the link below to get started.
          </Typography.Body>
          <Button type="plain" block onClick={() => setConfirmed(true)} className={styles.passwordResetButton}>
            Set New Password
          </Button>
          <Typography.Body color="white" style={{ marginTop: 40 }} className={styles.subheading}>
            If you did not request a password reset, you can ignore this. Your password will not be changed.
          </Typography.Body>
        </div>
      </div>
    </>
  )
}

export default ConfirmResetPassword
