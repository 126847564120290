const { default: gql } = require('graphql-tag')

const UPDATE_ANNOUNCEMENT = gql`
  mutation updateUserAnnouncement($data: UpdateUserAnnouncementInput!) {
    updateUserAnnouncement(data: $data)
  }
`

const DELETE_ANNOUNCEMENT = gql`
  mutation deleteAnnouncement($id: [String]!) {
    deleteAnnouncement(ids: $id)
  }
`

const CREATE_ANNOUNCEMENT_SENDER = gql`
  mutation createAnnouncementSender($data: AnnouncementSenderInput!) {
    createAnnouncementSender(data: $data)
  }
`

const UPDATE_ANNOUNCEMENT_SENDER = gql`
  mutation updateAnnouncementSender($data: UpdateAnnouncementSenderInput!) {
    updateAnnouncementSender(data: $data)
  }
`

const DELETE_ANNOUNCEMENT_SENDER = gql`
  mutation deleteAnnouncementSender($id: Int!) {
    deleteAnnouncementSender(id: $id)
  }
`
const RENAME_FILE = gql`
  mutation performFileAction($data: S3ActionInput!) {
    performFileAction(data: $data)
  }
`
const UPDATE_USER_PROFILE = gql`
  mutation updateUserByAdminOrSA($data: UpdateProfileInput) {
    updateUserByAdminOrSA(data: $data)
  }
`
export {
  UPDATE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  CREATE_ANNOUNCEMENT_SENDER,
  UPDATE_ANNOUNCEMENT_SENDER,
  DELETE_ANNOUNCEMENT_SENDER,
  RENAME_FILE,
  UPDATE_USER_PROFILE
}
