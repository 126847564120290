const STATUS_COLORS = {
  attention: '#FF7777',
  draft: '#FFB672',
  submitted: '#94FFB5',
  review: '#FFE766',
  refused: '#FF7777',
  approved: '#C5DFCD',
  closed: '#DADADA',
  settled: '#4a505280'
}
const AGENT_COLORS = {
  primary: '#CBFFA9',
  secondary: '#F7D59C'
}

const STATUS = {
  attention: 'Needs Attention',
  draft: 'Draft',
  submitted: 'Submitted',
  review: 'In Review',
  approved: 'Approved',
  closed: 'Closed',
  settled: 'Settled'
}

const STATUS_TYPES = {
  review: 'review',
  draft: 'draft',
  attention: 'attention',
  submitted: 'submitted',
  approved: 'approved',
  closed: 'closed',
  settled: 'settled'
}
const ANNONCEMENT_TYPES = {
  INBOX: 'inbox',
  STARRED: 'starred',
  DRAFT: 'draft',
  SCHEDULED: 'scheduled'
}

const ANNONCEMENT_STATUS = {
  INBOX: 'Inbox',
  STARRED: 'Starred',
  DRAFT: 'Draft',
  SCHEDULED: 'Scheduled'
}

const DEAL_TYPES = {
  sale: 'Sale',
  'pre-con': 'Pre-con',
  lease: 'Lease'
}

const REPRESENTATIVE_TYPE = {
  BUYER: 'buyer',
  SELLER: 'seller'
}

const DEAL_BROKERAGE_SIDES = {
  listing: 'Listing',
  coop: 'Co-op',
  both: 'Both'
}

const FORM_DEAL_TYPES = {
  SALE: 'sale',
  PRE_CON: 'pre-con',
  LEASE: 'lease'
}

const FORM_BROKERAGE_SIDES = {
  LISTING: 'listing',
  CO_OP: 'co-op',
  BOTH: 'both'
}

const HEADERS = {
  deals: 'Deals',
  listings: 'Listings',
  sale: 'Deal Checklist - Sale',
  'pre-con': 'Deal Checklist - Pre Construction',
  lease: 'Deal Checklist - Lease',
  create: 'Listing Checklist',
  mls: 'Listing Checklist',
  exclusive: 'Listing Checklist',
  announcements: 'Announcements',
  library: 'Library',
  revenue_share: 'Revenue Share',
  announcement: 'Announcement',
  'activity-log': 'Admin Panel',
  'user-management': 'Admin Panel',
  supermode: 'Admin Panel',
  admin: 'Admin Panel'
}

const LISTING_TYPES = {
  lease_listing: 'Lease',
  sale_listing: 'Sale'
}

const LISTING_OPTIONS = {
  exclusive: 'Exclusive',
  mls: 'MLS'
}
const LIBRARY_OPTIONS = {
  CREATE_FOLDER: 'create_folder',
  UPLOAD_FILE: 'upload_file',
  ATTACH_FILE: 'attach_file'
}

const CONTENT_TYPE = {
  FILE: 'File',
  FOLDER: 'Folder'
}

const LIBRARY_TABS = {
  MY_FILES: 'my_files',
  DOWNLOADS: 'downloads',
  ALL_FILES: 'all_files'
}

const LIBRARY_ACTIONS = {
  DELETE: 'delete',
  DOWNLOAD: 'download',
  PREVIEW: 'preview',
  RENAME: 'rename'
}

const PREVIEWABLE_TYPES = ['gif', 'jpg', 'jpeg', 'png', 'svg', 'webp']
const PREVIEWABLE_DOC_TYPES = ['pdf']
const PREVIEWABLE_VIDEO_TYPES = ['mp4', 'mov', 'mp3', 'wav']

const ACTION_OPTIONS = {
  view: 'View',
  edit: 'Edit',
  delete: 'Delete',
  settled: 'Settled'
}

const USER_ROLES = {
  super_admin: 'super_admin',
  admin: 'admin',
  moderator: 'moderator',
  staff: 'staff',
  agent: 'agent',
  deal_admin: 'deal_admin'
}

// temporary hack to clamp monthly revshare earnings on frontend
const MONTHLY_EARNING_CAPS = [5400, 4500, 3600, 2700, 1800]

const LISTING_DOCUMENTS_TYPES = {
  listing_all_document: 'All Listing Documents',
  listing_agreement: 'Listing Agreement',
  listing_data_sheet: 'Data Sheet',
  listing_working_with_realtor: 'Working with a Realtor',
  listing_showing_instructions: 'Showing Instructions',
  listing_additional_document: 'Additional Documents',
  sale_fintrac_form_630: 'Fintrac Form 630  - Individual Identification Information Record',
  sale_fintrac_form_634: 'Fintrac Form 634 - Politically Exposed Person/Head of International Organization Checklist'
}

const DEAL_DOCUMENTS_TYPES = {
  sale: {
    sale_all_document: 'All Sale Documents',
    sale_agreement_purchase_sale: 'Agreement of Purchase and Sale',
    sale_confirmation_cooperating_representative: 'Confirmation of Cooperation',
    sale_listing_agreement: 'Listing Agreement',
    sale_working_with_realtor: 'Working with a Realtor',
    sale_buyer_rep_agreement: 'Buyer Representation Agreement',
    sale_fintrac_form_630: 'Fintrac Form 630  – Individual Identification Information Record',
    sale_fintrac_form_634: 'Fintrac Form 634 – Politically Exposed Person/Head of International Organization Checklist',
    sale_copy_deposit_receipt: 'Copy of Deposit Receipt',
    sale_additional_document: 'Additional Documents',
    sale_form_127: 'Form 127 – Condition(s) In Offer – Buyer Acknowledgment',
    sale_fintrac_form_635: 'Fintrac Form 635 – Receipt of Funds',
    sale_data_sheet: 'Data Sheet'
  },
  'pre-con': {
    new_sale_all_document: 'All New Sale Documents',
    new_sale_first_page_agreement_purchase: '1st page of Agreement of Purchase and Sale and all amendments',
    new_sale_broker_coop_letter: 'Broker Co-op Letter',
    new_sale_first_page_tarion: '1st page of Tarion',
    new_sale_working_with_realtor: 'Working with a Realtor',
    new_sale_buyer_rep_agreement: 'Buyer Representation Agreement',
    mortgage_preapproval: 'Mortgage Pre-Approval',
    new_sale_copy_deposit_receipt: 'Copy of Deposits',
    new_sale_additional_document: 'Additional Documents'
  },
  lease: {
    lease_all_document: 'All Lease Documents',
    lease_agreement_lease: 'Agreement to Lease',
    lease_ontario_standard_lease: 'Ontario Standard Lease Agreement',
    lease_confirmation_cooperating_representative: 'Confirmation of Cooperation',
    lease_listing_agreement: 'Listing Agreement',
    lease_working_with_realtor: 'Working with a Realtor',
    lease_buyer_rep_agreement: 'Buyer Representation Agreement',
    lease_copy_deposit_receipt: 'Copy of Deposit Receipt',
    lease_closing_confirmation: 'Lease Closing Confirmation',
    lease_additional_document: 'Additional Documents',
    lease_data_sheet: 'Data Sheet'
  }
}

const LISTING_COMMON_DOCUMENTS = {
  sale_all_document: 'listing_all_document',
  sale_data_sheet: 'listing_data_sheet',
  sale_listing_agreement: 'listing_agreement',
  sale_working_with_realtor: 'listing_working_with_realtor',
  sale_additional_documen: 'listing_additional_document',
  sale_fintrac_form_630: 'sale_fintrac_form_630',
  sale_fintrac_form_634: 'sale_fintrac_form_634',
  lease_all_document: 'listing_all_document',
  lease_data_sheet: 'listing_data_sheet',
  lease_listing_agreement: 'listing_agreement',
  lease_working_with_realtor: 'listing_working_with_realtor',
  lease_additional_document: 'listing_additional_document'
}

const USER_MANAGEMENT_ACTIONS = {
  ACTIVATE: 'activate',
  DEACTIVATE: 'deactivate',
  EDIT: 'edit'
}

module.exports = {
  STATUS,
  STATUS_COLORS,
  AGENT_COLORS,
  LISTING_TYPES,
  LIBRARY_OPTIONS,
  LISTING_OPTIONS,
  ACTION_OPTIONS,
  MONTHLY_EARNING_CAPS,
  LISTING_DOCUMENTS_TYPES,
  DEAL_TYPES,
  DEAL_DOCUMENTS_TYPES,
  DEAL_BROKERAGE_SIDES,
  HEADERS,
  STATUS_TYPES,
  USER_ROLES,
  FORM_DEAL_TYPES,
  FORM_BROKERAGE_SIDES,
  LISTING_COMMON_DOCUMENTS,
  REPRESENTATIVE_TYPE,
  ANNONCEMENT_STATUS,
  ANNONCEMENT_TYPES,
  LIBRARY_ACTIONS,
  LIBRARY_TABS,
  CONTENT_TYPE,
  PREVIEWABLE_TYPES,
  PREVIEWABLE_DOC_TYPES,
  PREVIEWABLE_VIDEO_TYPES,
  USER_MANAGEMENT_ACTIONS
}
