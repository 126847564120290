import { Routes as Switch, Route } from 'react-router-dom'
import AppLayout from 'layouts/AppLayout'
import RequireAuth from 'components/RequireAuth'
import {
  Home,
  Chat,
  Library,
  Listings,
  Login,
  Deals,
  RevShare,
  Settings,
  Forgot,
  Test,
  ViewListing,
  ViewDeal,
  Reset,
  Referral,
  Toolbox,
  // Forum,
  Admin,
  Conversation,
  AddChannel,
  NoChatSelected,
  Channels,
  DirectMessages,
  RevShareCalc,
  EditListing,
  EditDeal,
  CreateDeal,
  CreateListing,
  CreateAnnouncement,
  EditAnnouncement,
  ActivityLog,
  AnnouncementPage,
  ViewAnnouncement
} from 'pages'
import { useContext } from 'react'
import { UserContext } from 'context'
import { createTheme } from '@mui/system'
import { useMediaQuery } from '@mui/material'

const Routes = () => {
  const isAdmin = { super_admin: 'super_admin', admin: 'admin' }
  const { user } = useContext(UserContext)
  const theme = createTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Switch>
      <Route path="/referral" element={<Referral />} />
      <Route path="/calculator" element={<RevShareCalc />} />
      <Route element={<RequireAuth required={false} />}>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/reset/:token" element={<Reset />} />
      </Route>
      <Route element={<RequireAuth required={true} />}>
        <Route element={<AppLayout />}>
          {/* <Route path="/chat" element={<Chat />}>
            <Route path="channels/add" element={<AddChannel />} />
            <Route path="channels/:gid" element={<Conversation />} />
            <Route path="channels" element={<Channels />} />
            <Route path="users/:uid" element={<Conversation />} />
            <Route path="users" element={<DirectMessages />} />
            <Route index element={<NoChatSelected />} />
          </Route> */}
          {/* <Route path="/forum" element={<Forum />} /> */}
          <Route path="library/*" element={<Library />} />
          {/* <Route path="/toolbox">
            <Route index element={<Toolbox />} />
          </Route> */}

          <Route path="/announcements/">
            <Route index element={<AnnouncementPage />} />
            <Route path=":id" element={<ViewAnnouncement />} />
            <Route element={<RequireAuth role={isAdmin[user?.role]} />}>
              {!isMobile && <Route path="create" element={<CreateAnnouncement />} />}
              <Route path="edit/:id" element={<EditAnnouncement />} />
            </Route>
          </Route>
          <Route path="/listings/">
            <Route index element={<Listings />} />
            <Route path="view/:type/:id" element={<ViewListing />} />
            <Route path="edit/:type/:id" element={<EditListing />} />
            <Route element={<RequireAuth required={true} role="agent" />}>
              <Route path="create" element={<CreateListing />} />
            </Route>
          </Route>
          <Route path="/deals/">
            <Route index element={<Deals />} />
            <Route path="view/:type/:id" element={<ViewDeal />} />
            <Route element={<RequireAuth required={true} role="agent" />}>
              <Route path="create/:type" element={<CreateDeal />} />
            </Route>
            <Route path="edit/:type/:id" element={<EditDeal />} />
          </Route>

          <Route path="/revenue_share/">
            <Route index element={<RevShare />} />
            <Route path=":agentId" element={<RevShare />} />
          </Route>
          <Route element={<RequireAuth required={true} role="super_admin" />}>
            <Route path="/admin/">
              <Route index element={<Admin />} />
              <Route path=":section" element={<Admin />} />
            </Route>
          </Route>

          <Route path="/settings/">
            <Route index element={<Settings />} />
            <Route path=":section" element={<Settings />} />
          </Route>

          <Route path="/activity-log/">
            <Route index element={<ActivityLog />} />
          </Route>

          {process.env.NODE_ENV === 'development' && <Route path="/test" element={<Test />} />}
          <Route path="*" element={<Home />} />
        </Route>
      </Route>
    </Switch>
  )
}

export default Routes
