import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useForm, Controller } from 'react-hook-form'
import { Typography, Button, Input, Loading, Row, Col } from 'components'
import { passwordStrength } from 'check-password-strength'
import ConfirmResetPassword from './ConfirmResetPassword'
import styles from './signin.module.scss'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate, useParams } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { createTheme, useMediaQuery } from '@mui/material'
import loginLogo from '../../assets/Login-page-logo.png'

const Reset = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { token } = params
  const [error, setError] = useState(null)
  const [confirmed, setConfirmed] = useState(false)
  const [newPassword, setNewpassword] = useState()
  const [confirmPassword, setconfirmpassword] = useState()
  const theme = createTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const heightWidth = isMobile ? 200 : 350

  const { loading: checkingToken } = useQuery(GET_TOKEN_VALID, {
    variables: {
      token
    },
    onError: () => {
      toast.dismiss()
      toast.error('Invalid or expired link')
      navigate('/')
    }
  })

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
    onError: (err) => {
      toast.dismiss()
      setError(err)
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('Password reset')
      navigate('/')
    }
  })

  const [passwordValidation, setPasswordValidation] = useState({
    passwordsMatch: false,
    eightChars: false,
    lowerCase: false,
    upperCase: false,
    number: false,
    specialChars: false
  })

  const { handleSubmit, control, watch } = useForm()

  const watchFields = watch(['password'])

  const onSubmit = (data) => {
    resetPassword({ variables: { token, password: data.password } })
  }

  const checkValidation = (value) => {
    const rule = passwordStrength(value)
    const eightChars = rule.length >= 8
    const lowerCase = rule.contains.indexOf('lowercase') !== -1
    const upperCase = rule.contains.indexOf('uppercase') !== -1
    const number = rule.contains.indexOf('number') !== -1
    const specialChars = rule.contains.indexOf('symbol') !== -1

    setPasswordValidation({
      ...passwordValidation,
      eightChars,
      lowerCase,
      upperCase,
      number,
      specialChars
    })
  }

  const disabled =
    !passwordValidation.eightChars ||
    !passwordValidation.lowerCase ||
    !passwordValidation.upperCase ||
    !passwordValidation.number ||
    !passwordValidation.specialChars ||
    newPassword !== confirmPassword

  if (checkingToken) return <Loading fullpage={true} />
  if (!confirmed) return <ConfirmResetPassword setConfirmed={setConfirmed} />

  const ValidationIcon = ({ valid = false }) => {
    return valid ? <CheckIcon className={styles.checkIcon} /> : <CloseIcon className={styles.closeIcon} />
  }

  return (
    <>
      <Helmet title="Reset Password | RARE" />
      <section id="reset" className={styles.signin}>
        <div className={styles.logoContainer}>
          <img src={loginLogo} alt="RARE Logo" height={heightWidth} width={heightWidth} />
        </div>

        <div className={styles.centerContent}>
          <Typography.Heading type="2" color="white" style={{ marginTop: 100 }}>
            Reset Password
          </Typography.Heading>

          <div style={{ marginTop: 24 }}>
            <Col>
              <Typography.Subheading type="3" color="white">
                New password must contain:
              </Typography.Subheading>
              <ul className={styles.passwordRequirements}>
                <li>
                  <Row>
                    <Typography.Body type="3" color="white">
                      At least 8 characters
                    </Typography.Body>
                    <ValidationIcon valid={passwordValidation.eightChars} />
                  </Row>
                </li>
                <li>
                  <Row>
                    <Typography.Body type="3" color="white">
                      At least 1 lowercase character
                    </Typography.Body>
                    <ValidationIcon valid={passwordValidation.lowerCase} />
                  </Row>
                </li>
                <li>
                  <Row>
                    <Typography.Body type="3" color="white">
                      At least 1 uppercase character
                    </Typography.Body>
                    <ValidationIcon valid={passwordValidation.upperCase} />
                  </Row>
                </li>
                <li>
                  <Row>
                    <Typography.Body type="3" color="white">
                      At least 1 number
                    </Typography.Body>
                    <ValidationIcon valid={passwordValidation.number} />
                  </Row>
                </li>
                <li>
                  <Row>
                    <Typography.Body type="3" color="white">
                      At least 1 special character
                    </Typography.Body>
                    <ValidationIcon valid={passwordValidation.specialChars} />
                  </Row>
                </li>
                <li>
                  <Row>
                    <Typography.Body type="3" color="white">
                      Confirm password should be same as new password
                    </Typography.Body>
                    <ValidationIcon
                      valid={newPassword === confirmPassword && newPassword != null && confirmPassword != null}
                    />
                  </Row>
                </li>
              </ul>
            </Col>

            <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 40 }}>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: 'New password is required'
                }}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    type="password"
                    placeholder="NEW PASSWORD"
                    error={error?.message}
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value)
                      checkValidation(e.target.value)
                      setNewpassword(e.target.value)
                    }}
                    isWhiteLine={true}
                    required
                    name="password"
                    className={styles.whitePlaceholder}
                    color="dark"
                    size="medium"
                  />
                )}
              />

              <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  required: 'Confirm password is required',
                  validate: (value) => value === watchFields[0] || 'Passwords do not match'
                }}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    type="password"
                    placeholder="CONFIRM PASSWORD"
                    error={error?.message}
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value)
                      checkValidation(e.target.value)
                      setconfirmpassword(e.target.value)
                    }}
                    isWhiteLine={true}
                    required
                    name="confirmPassword"
                    color="dark"
                    size="medium"
                  />
                )}
              />

              <Button
                type="plain"
                form
                block
                loading={loading}
                style={{ marginTop: 40 }}
                disabled={disabled}
                className={styles.passwordResetButton}>
                Update Password
              </Button>

              {error && (
                <Typography.Body type="3" color="error" style={{ marginTop: 8 }}>
                  {error.message}
                </Typography.Body>
              )}
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

const GET_TOKEN_VALID = gql`
  query getTokenValid($token: String!) {
    getTokenValid(token: $token)
  }
`

const RESET_PASSWORD = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password)
  }
`

export default Reset
