import React from 'react'
import { Grid } from '@mui/material'
import { Input, DatePicker, YearPicker } from 'components'
import { Controller, useFormContext } from 'react-hook-form'
import { useContext } from 'react'
import { DealContext } from 'context'
import { STATUS_TYPES } from 'helpers/constants'

const PreConOfferDetails = () => {
  const { control } = useFormContext()
  const { deal, disabled = false } = useContext(DealContext)

  return (
    <>
      <Grid container columnSpacing={7} sx={{ mt: 1 }}>
        <Grid xs={12} md={3} item>
          <Controller
            name="acceptance_date"
            control={control}
            defaultValue={deal?.acceptance_date || null}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <DatePicker
                type={5}
                error={error?.message}
                value={value}
                onChange={onChange}
                label="Firm Date"
                required
                name="acceptance_date"
                disabled={disabled}
                style={{ width: '100%' }}
                maxDate={new Date()}
              />
            )}
          />
        </Grid>
        <Grid xs={12} md={3} item mt={{ xs: 3, md: 0 }}>
          <Controller
            name="close_date"
            control={control}
            defaultValue={deal?.close_date || null}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <YearPicker
                type={5}
                error={error?.message}
                value={value}
                placeholder="(Only Year)"
                onChange={onChange}
                label="Approx. Close Year"
                name="close_date"
                required
                disabled={disabled}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={7} sx={{ mt: 2 }}>
        <Grid xs={12} md={3} item>
          <Controller
            name="sale_price"
            control={control}
            defaultValue={deal?.sale_price || 0}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type="number"
                error={error?.message}
                value={value}
                onChange={onChange}
                label="Sale Price ($ Net of HST)"
                required
                name="sale_price"
                placeholder="$"
                style={{ width: '100%' }}
                disabled={disabled}
              />
            )}
          />
        </Grid>
        {deal?.status === STATUS_TYPES.settled && (
          <Grid xs={12} md={3} item mt={{ xs: 3, md: 0 }}>
            <Controller
              name="settled_date"
              control={control}
              defaultValue={deal?.settled_date}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <DatePicker
                  type={5}
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Settle Date"
                  required
                  disabled={disabled}
                  name="settled_date"
                  style={{ width: '100%' }}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default PreConOfferDetails
