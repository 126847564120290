import React, { useContext, useEffect, useState } from 'react'
import { Nav } from 'components'
import Header from 'components/nav/Header'
import { Outlet, useLocation } from 'react-router-dom'
import RecoLicensePopup from '../pages/settings/RecoLicensePopup'
import { UserContext } from 'context'
import moment from 'moment'

const AppLayout = () => {
  const location = useLocation()
  const [shouldOpenPopup, setShouldOpenPopup] = useState(false)
  const { user } = useContext(UserContext)

  useEffect(() => {
    if (user.reco_expiry && moment(user.reco_expiry).isBefore(moment())) {
      setShouldOpenPopup(true)
    }
  }, [])

  return (
    <>
      <section id="app-layout">
        <Nav />
        <section id="app-content" style={location.pathname.includes('chat') ? { padding: 0 } : {}}>
          <Header />
          <Outlet />
        </section>
      </section>

      {shouldOpenPopup && (
        <RecoLicensePopup
          user={user}
          onClose={() => setShouldOpenPopup(false)}
          onSave={() => setShouldOpenPopup(false)}
        />
      )}
    </>
  )
}

export default AppLayout
