import { useQuery } from '@apollo/client'
import { Loading } from 'components'
import { GET_DEAL } from 'helpers/gql'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { DocumentProvider } from 'context'
import ViewDealForm from './ViewDealForm'
import { toast } from 'react-toastify'
import { useEffect } from 'react'

const ViewDeal = () => {
  const params = useParams()
  const { id } = params

  const {
    data: { getDeal: deal } = [],
    loading,
    refetch
  } = useQuery(GET_DEAL, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    variables: {
      id
    }
  })

  useEffect(() => {
    refetch()
  })

  return (
    <>
      <Helmet title="Deal | RARE" />
      <section id="deal">
        {loading || !deal ? (
          <Loading fullpage />
        ) : (
          <>
            <DocumentProvider>
              <ViewDealForm deal={deal} />
            </DocumentProvider>
          </>
        )}
      </section>
    </>
  )
}

export default ViewDeal
