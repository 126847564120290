import { Modal, Row, Col, Button, Typography, Select, Agent } from 'components'
import { useQuery, useMutation, gql } from '@apollo/client'
import { toast } from 'react-toastify'
import DeleteIcon from '@mui/icons-material/Delete'
import styles from './admin.module.scss'

const EditRevShare = ({ open, setOpen, agents, allUsers }) => {
  const agent = open

  const onCancel = () => {
    setOpen(null)
  }

  const {
    data: { getMyRelationships: relationships } = [],
    loading,
    refetch
  } = useQuery(
    GET_MY_RELATIONSHIPS,
    {
      variables: { id: agent?.id },
      skip: !agent,
      fetchPolicy: 'no-cache'
    },
    {
      onError: (err) => {
        toast.dismiss()
        if (!err?.message?.includes('Session timeout')) {
          toast.error(err.message)
        }
      }
    }
  )

  const [addReferree] = useMutation(ADD_REFERREE, {
    onError: (err) => {
      toast.dismiss()
      console.error(err)
      toast.error('Error adding referree')
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('User successfully added')
      refetch()
    }
    // refetchQueries: [{ query: GET_MY_RELATIONSHIPS, variables: { id: agent?.id } }]
  })

  const [deleteReferree] = useMutation(DELETE_REFERREE, {
    onError: (err) => {
      toast.dismiss()
      console.error(err)
      toast.error('Error deleting referree')
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('User successfully deleted')
      refetch()
    }
    // refetchQueries: [{ query: GET_MY_RELATIONSHIPS, variables: { id: agent?.id } }]
  })

  if (!agent || loading) return null

  const referrer = agents.find((agent) => agent.id === relationships?.referredBy)
  const referrees = relationships.referred
    ? relationships.referred.map((id) => agents.find((agent) => agent.id === id))
    : []

  const handleAddReferree = (a) => {
    addReferree({ variables: { referringAgentId: agent.id, referredAgentId: a.value } })
  }

  const handleDelete = (a) => {
    deleteReferree({ variables: { referringAgentId: agent.id, referredAgentId: a.id } })
  }

  return (
    <Modal
      id="editRevShare"
      title="Edit Rev Share"
      open={open}
      handleClose={onCancel}
      backdropClickClose={false}
      maxWidth={800}
      buttons={
        <Row justifyContent="space-between" style={{ width: '100%' }}>
          <Button onClick={onCancel} type="tertiary">
            Cancel
          </Button>
        </Row>
      }>
      <form id="editRevShare">
        <Row>
          <Col style={{ width: '33%' }}>
            <Typography.Subheading type="2" style={{ marginBottom: 12 }}>
              Influencer
            </Typography.Subheading>
            {referrer ? <Agent agent={referrer} /> : <Typography.Body type="3">None</Typography.Body>}
          </Col>
          <Col style={{ width: '33%' }}>
            <Typography.Subheading type="2" style={{ marginBottom: 12 }}>
              Agent
            </Typography.Subheading>
            <Agent agent={agent} />
          </Col>
          <Col style={{ width: '33%' }}>
            <Typography.Subheading type="2" style={{ marginBottom: 12 }}>
              Agent’s Downstream
            </Typography.Subheading>
            <div style={{ marginBottom: 12 }}>
              <Select
                placeholder="Add referree"
                value={null}
                onChange={handleAddReferree}
                options={allUsers
                  .filter((a) => a.id !== agent.id)
                  .filter((agent) => !referrees.find((a) => a.id === agent.id))
                  .map((agent) => {
                    return {
                      value: agent.id,
                      label: agent.name
                    }
                  })}
              />
            </div>
            {referrees.map((agent, index) => (
              <Agent
                key={agent.id}
                agent={agent}
                style={{ marginBottom: index === referrees.length - 1 ? 0 : 12 }}
                action={<DeleteIcon className={styles.deleteIcon} onClick={() => handleDelete(agent)} />}
              />
            ))}
          </Col>
        </Row>
      </form>
    </Modal>
  )
}

export default EditRevShare

const GET_MY_RELATIONSHIPS = gql`
  query getMyRelationships($id: Int!) {
    getMyRelationships(id: $id) {
      id
      referredBy
      referred
    }
  }
`

const ADD_REFERREE = gql`
  mutation addReferree($referringAgentId: Int!, $referredAgentId: Int!) {
    addReferree(referringAgentId: $referringAgentId, referredAgentId: $referredAgentId)
  }
`

const DELETE_REFERREE = gql`
  mutation deleteReferree($referringAgentId: Int!, $referredAgentId: Int!) {
    deleteReferree(referringAgentId: $referringAgentId, referredAgentId: $referredAgentId)
  }
`
