import { Col, Row } from 'components'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts'
import { Card } from 'components'
import { CardHeader } from './CardHeader'
import React, { useCallback, useEffect, useState } from 'react'
import { RevshareEarnings } from './RevshareEarnings'
import moment from 'moment'
import { gql, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

const RevshareOverview = () => {
  const date = new Date()
  date.setDate(date.getDate() - 60)

  const [selectedYear, setSelectedYear] = useState(moment().year())
  const [yearOptions, setYearOptions] = useState([])

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value)
  }

  const {
    data,
    error,
    loading,
    refetch: dashBoardRevshareDetailsRefetch
  } = useQuery(GET_DASHBOARD_REVSHARE_DETAILS, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    variables: {
      year: selectedYear
    }
  })

  useEffect(() => {
    if (!loading && !error) {
    }
  }, [data, loading, error])

  useEffect(() => {
    const fetchYears = async () => {
      try {
        const currentYear = new Date().getFullYear()
        const startingYear = 2023
        const lastYears = Array.from({ length: currentYear - startingYear + 1 }, (_, index) => currentYear - index)
        setYearOptions(lastYears)
      } catch (error) {
        console.error('Error fetching years:', error)
      }
    }

    fetchYears()
  }, [])

  useEffect(() => {
    dashBoardRevshareDetailsRefetch()
  }, [selectedYear, dashBoardRevshareDetailsRefetch])

  return (
    <>
      <Card
        style={{
          mt: 3
        }}
        title={
          <CardHeader
            title="Revenue Share Overview"
            showYearDropdown
            selectedYear={selectedYear}
            handleYearChange={handleYearChange}
            yearOptions={yearOptions}
          />
        }>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <Col style={{ flex: 1 }}>
            <RevshareEarnings revshareOverview={data?.getDashBoardRevshareDetails} />

            <span style={{ marginBottom: 10, marginTop: 10 }}>Review your revenue share return with RARE to date.</span>
            <Row>
              <ResponsiveContainer width="100%" height={150}>
                <LineChart
                  data={data?.getDashBoardRevshareDetails?.allData}
                  margin={{ top: 0, right: 40, bottom: 0, left: 10 }}>
                  <CartesianGrid vertical={false} stroke="#000000" strokeDasharray="3 3" />
                  <XAxis dataKey="month" axisLine={{ stroke: '#000000' }} />
                  <YAxis axisLine={{ stroke: '#000000' }} />
                  <Line dataKey="monthTotalEarning" stroke="#DEDEDE" dot={false} strokeWidth={2} />
                </LineChart>
              </ResponsiveContainer>
            </Row>
          </Col>
        )}
      </Card>
    </>
  )
}

const GET_DASHBOARD_REVSHARE_DETAILS = gql`
  query getDashBoardRevshareDetails($year: Int!) {
    getDashBoardRevshareDetails(year: $year)
  }
`

export default RevshareOverview
