import { Input, PhoneInput, Typography } from 'components'
import { Controller, useFormContext } from 'react-hook-form'
import { Grid } from '@mui/material'
import styles from '../deals.module.scss'
import { useContext } from 'react'
import { DealContext } from 'context'

const Referrals = () => {
  const { control } = useFormContext()
  const { deal, disabled = false } = useContext(DealContext)

  return (
    <>
      <Typography.Heading type={5} style={{ marginTop: 22 }} gap={50} className="dealStepTitle">
        Referral Information
      </Typography.Heading>

      <Typography.Body type={1} style={{ marginTop: 22, marginLeft: 2 }} gap={50} className="dealStepSubTitle">
        Agent Full Name
      </Typography.Body>
      <Grid container columns={12}>
        <Grid container columnSpacing={7} sx={{ marginTop: 1 }}>
          <Grid xs={6} md={3} item>
            <Controller
              name="referral_first_name"
              control={control}
              defaultValue={deal?.referral?.first_name || undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  placeholder="First Name"
                  name="referral_first_name"
                  style={{ width: '100%' }}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
          <Grid xs={6} md={3} item>
            <Controller
              name="referral_last_name"
              control={control}
              defaultValue={deal?.referral?.last_name || undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  placeholder="Last Name"
                  name="referral_last_name"
                  style={{ width: '100%' }}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
        </Grid>
        <Typography.Body
          type={1}
          style={{ marginTop: 22, fontFamily: 'Source Sans Pro' }}
          gap={50}
          className="dealStepSubTitle">
          Referral Commission
        </Typography.Body>
        <Grid container sx={{ mt: 1 }} columnSpacing={2}>
          <Grid xs={12} md={2.75} item>
            <Controller
              name="referral_amount"
              control={control}
              defaultValue={deal?.referral?.referral_amount || undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="currency"
                  error={error?.message}
                  value={value}
                  onChange={(e) => {
                    onChange(parseFloat(e.target.value))
                  }}
                  label="Referral Amount"
                  placeholder="$"
                  name="referral_amount"
                  disabled={disabled}
                  style={{ width: '100%' }}
                />
              )}
            />
          </Grid>
          <Grid xs={12} md={0.25} item>
            <Typography.Body type={5} className={styles.referral_amount}>
              OR
            </Typography.Body>
          </Grid>
          <Grid xs={12} md={2.75} item ml={{ xs: 0, md: 1 }}>
            <Controller
              name="referral_percentage"
              control={control}
              defaultValue={deal?.referral?.referral_percentage || undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="percentage"
                  placeholder="%"
                  error={error?.message}
                  value={value}
                  onChange={(e) => {
                    onChange(parseFloat(e.target.value))
                  }}
                  label="Referral Percentage"
                  name="referral_percentage"
                  style={{ width: '100%' }}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={7} sx={{ marginTop: 2 }}>
          <Grid xs={12} md={6} item>
            <Controller
              name="referral_office_name"
              control={control}
              defaultValue={deal?.referral?.office_name || undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Brokerage Name"
                  name="referral_office_name"
                  style={{ width: '100%' }}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={7} sx={{ marginTop: 2 }}>
          <Grid xs={12} md={6} item>
            <Controller
              name="referral_office_address"
              control={control}
              defaultValue={deal?.referral?.office_address || undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Brokerage Address"
                  name="referral_office_address"
                  style={{ width: '100%' }}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={7} sx={{ marginTop: 2 }}>
          <Grid xs={12} md={3} item>
            <Controller
              name="referral_office_phone"
              control={control}
              defaultValue={deal?.referral?.office_phone || undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <PhoneInput
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Brokerage Phone Number"
                  name="referral_office_phone"
                  style={{ width: '100%' }}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
          <Grid xs={12} md={3} item sx={{ mt: { xs: 2, sm: 0, md: 0 } }}>
            <Controller
              name="referral_office_email"
              control={control}
              defaultValue={deal?.referral?.office_email || undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Brokerage Email Address"
                  name="referral_office_email"
                  style={{ width: '100%' }}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Referrals
