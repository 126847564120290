import { NavLink } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { GET_USER } from 'context/UserProvider'
import { toast } from 'react-toastify'

const NavItem = ({ icon, to, title, type, onClick }) => {
  const [logout] = useMutation(LOGOUT, {
    onError: (err) => {
      toast.dismiss()
      console.error(err)
      toast.error('Error logging out')
    },
    onCompleted: () => {
      localStorage.setItem('sessionTimeout', JSON.stringify(false))
      window.location.reload()
    },
    refetchQueries: [{ query: GET_USER }]
  })

  const [exitGodMode] = useMutation(EXIT_GOD_MODE, {
    onError: (err) => {
      toast.dismiss()
      console.error(err)
      toast.error('Error exiting god mode')
    },
    onCompleted: () => {
      window.location.reload()
    },
    refetchQueries: [{ query: GET_USER }]
  })

  const handleItemClick = () => {
    if (onClick) {
      onClick()
    } else if (type === 'logout') {
      logout()
    } else if (type === 'exit_god_mode') {
      exitGodMode()
    }
  }

  return (
    <li className="nav-item" onClick={handleItemClick}>
      {type === 'logout' || type === 'exit_god_mode' ? (
        <div className="nav-item-logout">
          {icon}
          <span>{title}</span>
        </div>
      ) : (
        <NavLink to={to}>
          {icon}
          <span>{title}</span>
        </NavLink>
      )}
    </li>
  )
}

const LOGOUT = gql`
  mutation logout {
    logout
  }
`

const EXIT_GOD_MODE = gql`
  mutation exitGodMode {
    exitGodMode
  }
`

export default NavItem
