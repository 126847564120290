import { gql, useMutation, useQuery } from '@apollo/client'
import { Avatar, Button, Col, Input, Row, Typography, Upload, DatePicker, Loading, PhoneInput } from 'components'
import { GET_USER_PROFILE } from 'helpers/gql'
import { getFileUrl } from 'helpers/utils'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

const ProfilePic = ({ user, value, onChange }) => {
  const fileKey = value?.key === null ? null : value?.key || value

  const handleRemove = () => {
    onChange({ key: null })
  }

  return (
    <Row alignItems="center" gap={24} style={{ width: '100%' }}>
      <Avatar size={80} name={user.name} src={getFileUrl(fileKey)} />
      <Col>
        <Typography.Subheading type="2">Change profile picture</Typography.Subheading>
        <Typography.Body type="3">Max file size is 10Mb.</Typography.Body>
        <Typography.Caption type="2" style={{ color: '#E90B0B', cursor: 'pointer' }} onClick={handleRemove}>
          Remove
        </Typography.Caption>
      </Col>
      <Upload onChange={onChange} showFile={false} />
    </Row>
  )
}

const Profile = () => {
  const phoneRegex = /^(1-)?\d{3}-\d{3}-\d{4}$/
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    date_of_birth: Yup.string().required('Date of birth is required'),
    title: Yup.string().required('Title is required'),
    sin_number: Yup.string().required('SIN is required'),
    treb_number: Yup.string().required('TREB is required'),
    email: Yup.string()
      .matches(emailRegex, 'Email address must be a valid email')
      .required('Email Address is required')
      .transform((value, defaultValue) => (defaultValue === '' ? undefined : value)),
    phone_number: Yup.string()
      .required('Phone number is required')
      .transform((value, defaultValue) => (defaultValue === '' ? undefined : value))
      .matches(phoneRegex, 'Please enter a valid phone number'),
    address: Yup.string().required('Address is required'),
    emergency_contact_phone: Yup.string()
      .nullable()
      .transform((value, defaultValue) => (defaultValue === '' ? undefined : value))
      .matches(phoneRegex, 'Please enter a valid phone number'),
    alternative_email: Yup.string()
      .nullable()
      .matches(emailRegex, 'Email address must be a valid email')
      .transform((value, defaultValue) => (defaultValue === '' ? undefined : value))
  })

  const { handleSubmit, control } = useForm({ resolver: yupResolver(validationSchema) })
  const { data: { getUser: user } = {}, loading: userProfileLoading } = useQuery(GET_USER_PROFILE, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    }
  })

  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE, {
    onError: (err) => {
      toast.dismiss()
      console.error(err)
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('Profile updated.')
    },
    refetchQueries: [{ query: GET_USER_PROFILE }]
  })

  const onSubmit = (data) => {
    const picture = (data?.picture?.key !== null ? data?.picture?.key : '') ?? data.picture
    updateProfile({
      variables: { data: { ...data, picture: picture } }
    })
  }

  return userProfileLoading ? (
    <Loading fullpage />
  ) : (
    <form>
      <Col style={{ width: '100%' }} gap={24}>
        <Typography.Heading type="5">Profile</Typography.Heading>
        <Controller
          name="picture"
          control={control}
          defaultValue={user.picture || undefined}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <ProfilePic user={user} value={value} onChange={onChange} />
          )}
        />
        <Typography.Heading type="6">Personal information</Typography.Heading>
        <Row>
          <Col style={{ width: 560 }}>
            <Controller
              name="first_name"
              control={control}
              defaultValue={user.first_name || undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="First name"
                  required
                  name="first_name"
                  style={{ width: '100%' }}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 560 }}>
            <Controller
              name="last_name"
              control={control}
              defaultValue={user.last_name || undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Last name"
                  required
                  name="last_name"
                  style={{ width: '100%' }}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 560 }}>
            <Controller
              name="date_of_birth"
              control={control}
              defaultValue={user.date_of_birth || undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <DatePicker
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Date of birth"
                  required
                  name="date_of_birth"
                  style={{ width: '100%' }}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 560 }}>
            <Controller
              name="title"
              control={control}
              defaultValue={user.title || undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Title"
                  required
                  name="title"
                  style={{ width: '100%' }}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 560 }}>
            <Controller
              name="sin_number"
              control={control}
              defaultValue={user.sin_number || undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="SIN Number"
                  required
                  name="sin_number"
                  style={{ width: '100%' }}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 560 }}>
            <Controller
              name="treb_number"
              control={control}
              defaultValue={user.treb_number || undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="TREB Number"
                  required
                  name="treb_number"
                  style={{ width: '100%' }}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 560 }}>
            <Controller
              name="hst_number"
              control={control}
              defaultValue={user.hst_number || undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="HST Number (optional)"
                  name="hst_number"
                  style={{ width: '100%' }}
                />
              )}
            />
          </Col>
        </Row>
        <Typography.Heading type="6">Contact information</Typography.Heading>
        <Row>
          <Col style={{ width: 560 }}>
            <Controller
              name="email"
              control={control}
              defaultValue={user.email || undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Primary email address"
                  required
                  name="email"
                  style={{ width: '100%' }}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 560 }}>
            <Controller
              name="alternative_email"
              control={control}
              defaultValue={user.alternative_email || undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Alternative email address"
                  name="alternative_email"
                  style={{ width: '100%' }}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 560 }}>
            <Controller
              name="phone_number"
              control={control}
              defaultValue={user.phone_number || undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <PhoneInput
                  name="phone_number"
                  label="Phone number"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  style={{ width: '100%' }}
                  required
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 560 }}>
            <Controller
              name="address"
              control={control}
              defaultValue={user.address || undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Address"
                  required
                  name="address"
                  style={{ width: '100%' }}
                />
              )}
            />
          </Col>
        </Row>
        <Typography.Heading type="6">Emergency contact</Typography.Heading>
        <Row>
          <Col style={{ width: 560 }}>
            <Controller
              name="emergency_contact_name"
              control={control}
              defaultValue={user.emergency_contact_name || undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Name (optional)"
                  name="emergency_contact_name"
                  style={{ width: '100%' }}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 560 }}>
            <Controller
              name="emergency_contact_phone"
              control={control}
              defaultValue={user.emergency_contact_phone || undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <PhoneInput
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Phone number (optional)"
                  name="emergency_contact_phone"
                  style={{ width: '100%' }}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 560 }}>
            <Controller
              name="emergency_contact_relationship"
              control={control}
              defaultValue={user.emergency_contact_relationship || undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Relationship (optional)"
                  name="emergency_contact_relationship"
                  style={{ width: '100%' }}
                />
              )}
            />
          </Col>
        </Row>
        <Button loading={loading} onClick={handleSubmit(onSubmit)} style={{ width: 136 }}>
          Save changes
        </Button>
      </Col>
    </form>
  )
}

const UPDATE_PROFILE = gql`
  mutation updateProfile($data: UpdateProfileInput!) {
    updateProfile(data: $data)
  }
`

export default Profile
