import { Avatar, Typography } from 'components'
import { getFileUrl } from 'helpers/utils'
import vars from '../../vars.scss'

const Agent = ({ agent, style, action = null }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 12, ...style }}>
      <Avatar name={agent.name} src={getFileUrl(agent.thumbnail)} />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography.Body type={3} style={{ color: vars['font-color-dark'], whiteSpace: 'nowrap' }}>
          {agent.name}
        </Typography.Body>
        <Typography.Body type={3} style={{ color: vars['font-color-secondary'], whiteSpace: 'nowrap' }}>
          Rare Agent #{agent.id}
        </Typography.Body>
      </div>
      {action}
    </div>
  )
}

export default Agent
