import { HomeOutlined, ExpandCircleDownOutlined } from '@mui/icons-material'
import { Card } from 'components'
import { Box, Grid, IconButton, Typography, createTheme, useMediaQuery } from '@mui/material'
import { CardHeader } from './CardHeader'
import { useNavigate } from 'react-router-dom'
import { useCallback, useMemo } from 'react'
import moment from 'moment'
import vars from '../../vars.scss'
import { STATUS_TYPES } from 'helpers/constants'

const UpcomingDeals = ({ upcomingDeals = [] }) => {
  const navigate = useNavigate()
  const theme = createTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const deals = useMemo(() => {
    return upcomingDeals?.filter((d) => d.status !== STATUS_TYPES.draft)
  }, [upcomingDeals])

  const handleClick = useCallback(() => {
    navigate('/deals')
  }, [navigate])

  return (
    <>
      <Card
        style={{
          mt: 3,
          '& .MuiCardHeader-root': {
            pb: 1
          }
        }}
        title={<CardHeader title="Upcoming Deals" buttonText="VIEW ALL DEALS" onClick={handleClick} />}
        subHeader={'Only deals that are closing within the next 30 days will be displayed here.'}>
        <div style={{ height: '95px' }}>
          {deals?.length > 0 ? (
            deals?.map((deal, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    backgroundColor: vars['font-color-white'],
                    padding: 1,
                    mt: 1
                  }}>
                  <Grid container columns={12}>
                    <Grid xs={1} item>
                      <HomeOutlined fontSize={isMobile ? 'small' : 'medium'} sx={{ color: '#C1C1C1', mt: 1 }} />
                    </Grid>
                    <Grid xs={5} md={6} item>
                      <Typography sx={{ fontWeight: 400, fontSize: '16px', mt: 1 }}>
                        {isMobile
                          ? deal.address.length > 12
                            ? `${deal.address.slice(0, 12)}...`
                            : deal.address
                          : deal.address}
                      </Typography>
                    </Grid>
                    <Grid xs={5} md={4} item>
                      <Typography
                        sx={{ fontWeight: 300, fontSize: '14px', mt: 1, color: vars.stepperIconDefaultColor }}>
                        Closing on {moment(deal.close_date).format(isMobile ? 'MMM DD' : 'MMM DD, YYYY')}
                      </Typography>
                    </Grid>
                    <Grid xs={1} item>
                      <IconButton
                        onClick={() => {
                          navigate(`/deals/view/${deal.type}/${deal.public_id}`)
                        }}>
                        <ExpandCircleDownOutlined fontSize={isMobile ? 'small' : 'medium'} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              )
            })
          ) : (
            <Typography sx={{ fontWeight: 400, fontSize: '14px', mt: 1, color: vars.stepperIconDefaultColor }}>
              No Upcoming Deals
            </Typography>
          )}
        </div>
      </Card>
    </>
  )
}

export default UpcomingDeals
