import { UserContext } from 'context'
import { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import Announcements from './Announcements'
import RevshareOverview from './RevshareOverview'
import UpcomingDeals from './UpcomingDeals'
import styles from './home.module.scss'
import { Grid } from '@mui/material'
import { gql, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { GET_ANNOUNCEMENTS, GET_REVSHARE_OVERVIEW_DETAILS } from 'helpers/gql'
import { Loading } from 'components'
import { ANNONCEMENT_TYPES } from 'helpers/constants'
import GCIEarnings from './GCIEarnings'

const Home = () => {
  const {
    data: { getUpcomingDeals: upcomingDeals } = [],
    loading: upcomingDealsLoading,
    refetch: upcomingDealsRefetch
  } = useQuery(GET_UPCOMING_DEALS, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    }
  })

  const {
    data: { getAnnouncements: announcements } = [],
    loading: announcementsLoading,
    refetch
  } = useQuery(GET_ANNOUNCEMENTS, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    variables: {
      filters: { status: ANNONCEMENT_TYPES.INBOX }
    }
  })

  useEffect(() => {
    refetch()
    upcomingDealsRefetch()
  }, [refetch, upcomingDealsRefetch])

  const loading = announcementsLoading || upcomingDealsLoading

  return (
    <>
      <Helmet title="Home | RARE" />
      <section id="home" className={styles.homeWrapper}>
        {loading ? (
          <Loading fullpage />
        ) : (
          <div>
            <Grid container spacing={{ md: 5, xs: 1 }} columns={12}>
              <Grid item xs={12} md={8}>
                <Grid>
                  <GCIEarnings />
                </Grid>
                <Grid>
                  <RevshareOverview />
                </Grid>
                <Grid>
                  <UpcomingDeals upcomingDeals={upcomingDeals} />
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} sx={{ mt: { xs: 2, md: 0 } }}>
                <Announcements announcements={announcements} refetch={refetch} />
              </Grid>
            </Grid>
          </div>
        )}
      </section>
    </>
  )
}

const GET_UPCOMING_DEALS = gql`
  query getUpcomingDeals {
    getUpcomingDeals {
      id
      public_id
      type
      address
      sale_price
      close_date
      creator_id
      status
      listing {
        id
        type
        option
        address
      }
      created
    }
  }
`

export default Home
