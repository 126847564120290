import React from 'react'
import { Typography, MenuItem, Select, IconButton, FormControl } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Row } from 'components'

export const CardHeader = ({
  title,
  buttonText,
  onClick,
  showYearDropdown,
  selectedYear,
  handleYearChange,
  yearOptions
}) => {
  return (
    <Row justifyContent="space-between" marginTop={'-10px'}>
      <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>{title}</Typography>
      {showYearDropdown && (
        <>
          <Row>
            <Typography
              className="cardBtnText"
              sx={{ fontSize: '14px', fontWeight: 400, marginRight: '5px', marginTop: '5px', color: '#898989' }}>
              Select Year
            </Typography>
            <FormControl variant="standard" sx={{ minWidth: 75 }}>
              <Select
                label="Select Year"
                value={selectedYear}
                onChange={handleYearChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      overflowY: 'auto'
                    }
                  }
                }}>
                {yearOptions.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Row>
        </>
      )}
      {buttonText && (
        <Row gap={20}>
          <IconButton
            onClick={onClick}
            sx={{
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}>
            <Typography className="cardBtnText" sx={{ fontSize: '12px', fontWeight: 400, color: '#898989' }}>
              {buttonText}
            </Typography>
            <KeyboardArrowRightIcon fontSize="medium" />
          </IconButton>
        </Row>
      )}
    </Row>
  )
}
