import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Button } from 'components'

const PopUp = (props) => {
  const {
    title,
    open,
    children,
    loading = false,
    confirmBtnLabel = 'Confirm',
    cancelBtnLabel = 'Close',
    maxWidth = 'md',
    fullScreen,
    width,
    height = 'auto',
    fullWidth,
    canShowConfirmButton = true,
    canShowCancelButton = true,
    onCancel,
    onConfirm,
    style
  } = props

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: width,
              height: height,
              ...(style || {})
            }
          }
        }}
        open={open}
        maxWidth={maxWidth}>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: '10px',
            ml: '14px'
          }}>
          <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>{title}</Typography>
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        {(canShowCancelButton || canShowConfirmButton) && (
          <DialogActions>
            {canShowCancelButton && (
              <Button onClick={onCancel} type="tertiary">
                {cancelBtnLabel}
              </Button>
            )}
            {canShowConfirmButton && (
              <Button loading={loading} style={{ minWidth: 100 }} onClick={onConfirm}>
                {confirmBtnLabel}
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    </div>
  )
}

export default PopUp
