import { Typography } from 'components'
import styles from './divider.module.scss'

const Divider = ({ style, margin, children, direction = 'horizontal', className }) => {
  return (
    <div className={`${children ? '' : styles.divider} ${direction === 'vertical' && styles.vertical} ${className}`} style={style} >
      {children && (
        <Typography.Body type="3" color="secondary" className={styles.text} style={{ margin }}>
          {children}
        </Typography.Body>
      )}
    </div>
  )
}

export default Divider
