import { gql, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { useContext, useState, useEffect } from 'react'
import { Col, PopUp, Row, Typography, WarningPopup } from 'components'
import { UserContext } from 'context'
import moment from 'moment'
import { usePopup } from 'hooks/usePopup'
import { USER_ROLES } from 'helpers/constants'
import { GET_ANNOUNCEMENTS } from 'helpers/gql'
import vars from '../../vars.scss'
import { DownloadForOffline, InsertDriveFile } from '@mui/icons-material'
import { IconButton, Tooltip, createTheme, useMediaQuery } from '@mui/material'
import styles from '../announcements/announcements.module.scss'
import { downloadAnnouncementAttachment, generateSignedUrl } from 'helpers/utils'
import { Visibility } from '@mui/icons-material'
import AttachmentViewer from 'pages/announcements/AttachmentViewer'
import { UPDATE_ANNOUNCEMENT } from 'helpers/mutations'

const AnnouncementPopup = ({ announcement, viewAnnouncement, onCancel, refetch }) => {
  const [confirmDelete, showConfirmDelete, hideConfirmDelete] = usePopup()
  const [attachmentPreview, showAttachmentPreview, hideAttachmentPreview] = usePopup()
  const { user } = useContext(UserContext)
  const [selectedAttachment, setSelectedAttachment] = useState(null)
  const theme = createTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const nameLength = isMobile ? 15 : 20

  const [deleteAnnouncement, { loading: deleteLoading }] = useMutation(DELETE_ANNOUNCEMENT, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('Announcement deleted.')
      refetch()
    },
    refetchQueries: [{ query: GET_ANNOUNCEMENTS }]
  })

  const [updateAnnouncement] = useMutation(UPDATE_ANNOUNCEMENT, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      refetch()
    }
  })

  useEffect(() => {
    if (announcement?.public_id && !announcement?.is_read) {
      updateAnnouncement({
        variables: {
          data: {
            announcementId: announcement?.public_id,
            read: true
          }
        }
      })
    }
  }, [announcement, refetch, updateAnnouncement])

  const handleDeleteAnnouncement = async () => {
    await deleteAnnouncement({ variables: { id: [announcement.public_id] } })
    hideConfirmDelete()
    onCancel()
  }

  const isViewableAttachment = (attachment) => {
    const viewableTypes = ['pdf', 'png', 'jpg', 'jpeg', 'gif', 'bmp', 'mp4']
    const fileType = attachment?.file_name.split('.').pop()
    return viewableTypes.includes(fileType.toLowerCase())
  }

  const openViewModal = async (attachment) => {
    try {
      const signedUrl = await generateSignedUrl(attachment.path)
      setSelectedAttachment({ ...attachment, signedUrl })
    } catch (error) {
      console.error('Error generating signed URL:', error)
    }
    showAttachmentPreview()
  }

  return (
    <>
      <PopUp
        title={
          <>
            <Typography.Heading type={6}>{announcement?.title}</Typography.Heading>
            {announcement?.sender && (
              <Typography.Body type={1} style={{ color: vars.stepperBorderColor }}>
                From {announcement?.sender}
              </Typography.Body>
            )}
          </>
        }
        open={viewAnnouncement}
        width="100%"
        canShowConfirmButton={user.role === USER_ROLES.super_admin && announcement?.id ? true : false}
        canShowCancelButton={false}
        confirmBtnLabel={'Delete Announcement'}
        onCancel={onCancel}
        onConfirm={showConfirmDelete}>
        <div>
          {announcement?.id && (
            <Typography.Body type="2" color="secondary" style={{ marginBottom: 16 }}>
              Posted:{' '}
              {announcement?.start_date
                ? moment(announcement?.start_date).format('LL')
                : moment(announcement?.created).format('LL')}
            </Typography.Body>
          )}
          <div dangerouslySetInnerHTML={{ __html: announcement?.content }} />
          <div className={styles.announcementPreviewBox}>
            <div>
              {announcement?.attachments?.length > 0 && (
                <>
                  <Typography.Body type={2} className={styles.created} style={{ marginBottom: 5 }}>
                    Attachments
                  </Typography.Body>
                  <div className={styles.container}>
                    {announcement?.attachments?.map((attachment, i) => {
                      const isViewable = isViewableAttachment(attachment)
                      return (
                        <div className={styles.attachment} key={i}>
                          <Row className={styles.row}>
                            <Col style={{ paddingTop: 7 }}>
                              <Row gap={4} style={{ alignItems: 'center' }}>
                                <InsertDriveFile fontSize="small" sx={{ color: vars['nav-divider-color'] }} />
                                <Tooltip
                                  placement="bottom-start"
                                  title={attachment?.file_name.length > nameLength ? attachment?.file_name : ''}>
                                  <div>
                                    {attachment?.file_name.length > nameLength
                                      ? `${attachment?.file_name.slice(0, nameLength)}...`
                                      : attachment?.file_name}
                                  </div>
                                </Tooltip>
                              </Row>
                            </Col>
                            <Col>
                              <Row>
                                {isViewable && (
                                  <IconButton
                                    onClick={() => openViewModal(attachment)}
                                    sx={{ color: vars['nav-divider-color'] }}>
                                    <Visibility fontSize="small" sx={{ color: vars['nav-divider-color'] }} />
                                  </IconButton>
                                )}
                                <IconButton
                                  onClick={() => {
                                    downloadAnnouncementAttachment(attachment?.path, attachment.file_name)
                                  }}
                                  sx={{ color: vars['nav-divider-color'] }}>
                                  <DownloadForOffline fontSize="small" />
                                </IconButton>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      )
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </PopUp>

      {selectedAttachment && (
        <AttachmentViewer open={attachmentPreview} onCancel={hideAttachmentPreview} attachment={selectedAttachment} />
      )}

      <WarningPopup
        openModel={confirmDelete}
        loading={deleteLoading}
        confirmBtnLabel={'Delete'}
        onCancle={hideConfirmDelete}
        onConfirm={handleDeleteAnnouncement}>
        <Typography.Body type={1}>Do you really want to delete this announcement?</Typography.Body>
      </WarningPopup>
    </>
  )
}

const DELETE_ANNOUNCEMENT = gql`
  mutation deleteAnnouncement($id: [String]!) {
    deleteAnnouncement(ids: $id)
  }
`
export default AnnouncementPopup
