import React, { useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Fade from '@mui/material/Fade'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { Button, Checkbox } from 'components'
import styles from './ActivityLogFilter.module.scss'
import vars from '../../vars.scss'

export const ActivityLogFilter = (props) => {
  const { label, options, children, selectedActivityType, onFilterHandler } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (event, value) => {
    onFilterHandler(value)
  }

  return (
    <div className="customMenuButton">
      {label ? (
        <Button
          prefixIcon={label && <FilterAltOutlinedIcon />}
          onClick={handleClick}
          className={'menuButton'}
          style={{ width: 200, fontsize: '18px' }}>
          {label}
        </Button>
      ) : (
        <div onClick={handleClick}>{children}</div>
      )}
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        getcontentanchorel={null}
        PaperProps={{
          style: {
            width: 200,
            border: `0.5px solid ${vars['menuButton-border-color']}`,
            borderRadius: '0px'
          }
        }}
        elevation={3}>
        {options.map((menuItem, index) => (
          <MenuItem
            key={index}
            onClick={(event) => handleMenuItemClick(event, menuItem.text)}
            className={`${styles.customMenuItem} ${index !== options.length - 1 ? styles.customMenuItemBorder : ''}`}>
            <Checkbox checked={selectedActivityType.includes(menuItem.text)} /> {menuItem.text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default ActivityLogFilter
