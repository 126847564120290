import { forwardRef } from 'react'
import styles from './radio.module.scss'
import { Row } from 'components'

const Radio = forwardRef(
  ({ onChange = () => {}, onBlur, name, label, value, checked, lableStyle, disabled = false }, ref) => (
    <Row alignItems="center">
      <input
        id={name}
        className={styles.radio}
        type="radio"
        name={name}
        ref={ref}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        checked={checked}
        disabled={disabled}
      />
      {label && (
        <label className={styles.radio_label} style={lableStyle} htmlFor={name}>
          {label}
        </label>
      )}
    </Row>
  )
)

export default Radio
