import Divider from 'components/divider/Divider'
import styles from './dashboard.module.scss'
import DashboardChart from './DashboardChart'
import DashboardItem from './DashboardItem'

const Dashboard = () => {
  return (
    <div className={styles.dashboard}>
      <div className={styles.dashboardCol}>
        <div className={styles.dashboardRow}>
          <DashboardItem />
          <DashboardItem />
          <DashboardItem />
        </div>
        <Divider />
        <div className={styles.dashboardRow}>
          <DashboardItem />
          <DashboardItem />
          <DashboardItem />
        </div>
      </div>
      <div className={styles.dashboardCol}>
        <DashboardChart />
      </div>
    </div>
  )
}

Dashboard.Item = DashboardItem

export default Dashboard
