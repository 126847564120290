import React from 'react'
import { Card as MUICard, CardActions, CardContent, CardHeader } from '@mui/material'
import vars from '../../vars.scss'

const Card = ({ style, title, subHeader, children, actions }) => {
  return (
    <MUICard
      sx={style}
      style={{ borderRadius: '7px', backgroundColor: vars.cardBgColor, color: vars.warningPopupContentColor }}>
      <CardHeader
        titleTypographyProps={{ fontSize: '16px', fontWeight: 600, color: vars.warningPopupContentColor }}
        subheaderTypographyProps={{ fontSize: '12px', fontWeight: 500, marginTop: '-3px' }}
        title={title}
        subheader={subHeader}
      />
      <CardContent sx={{ px: 2, py: 0, overflowY: 'auto' }}>{children}</CardContent>
      {actions && <CardActions>{actions}</CardActions>}
    </MUICard>
  )
}

export default Card
