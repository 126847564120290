import { gql, useMutation, useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet-async'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Add } from '@mui/icons-material'
import { Divider, Loading, Table, Typography, Button, Row, Col } from 'components'
import { GET_FORUM_TOPICS } from 'helpers/gql'
import { toast } from 'react-toastify'

const columns = [
  {
    key: 'topic',
    title: 'Topic',
    width: 760,
    render: (object) => (
      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        <Typography.Body type={2}>{object.topic}</Typography.Body>
      </div>
    )
  },
  {
    key: 'author_name',
    title: 'Author',
    width: 180,
    render: (object) => (
      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        <Typography.Body type={2}>{object.author_name}</Typography.Body>
      </div>
    )
  }
]

const Forum = () => {
  const navigate = useNavigate()
  const [showNewForumTopic, setShowNewForumTopic] = useState('hidden')

  const [createForumTopic] = useMutation(CREATE_FORUM_TOPIC, {})
  const {
    data: { getForumTopics: forumTopics } = [],
    loading,
    refetch
  } = useQuery(GET_FORUM_TOPICS, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    }
  })

  const handleNewTopic = () => {
    const display = showNewForumTopic === 'hidden' ? '' : 'hidden'
    setShowNewForumTopic(display)
  }

  const submitNewTopic = async () => {
    const val = document.getElementById('newTopic').value

    await createForumTopic({ variables: { topic: val } })
    refetch()
    document.getElementById('newTopic').value = ''
    setShowNewForumTopic('hidden')
  }
  const handleRowClick = async (topic) => {
    // navigate('/forum/' + topic.public_id)
    console.log('navigating')
  }

  if (loading) return <Loading />
  return (
    <>
      <Helmet title="Forum | RARE" />
      <section id="forum">
        <Row justifyContent="space-between" alignItems="center">
          <Col>
            <Typography.Heading type="3">Forum</Typography.Heading>
          </Col>
          <Col>
            <Button prefixIcon={<Add />} onClick={handleNewTopic}>
              New Topic
            </Button>
          </Col>
        </Row>
        <Divider />
        <input id="newTopic" type={showNewForumTopic} />
        <Button display={showNewForumTopic} onClick={submitNewTopic}>
          Create
        </Button>
        <Table data={forumTopics} columns={columns} rowOnClick={handleRowClick}></Table>
      </section>
    </>
  )
}

const CREATE_FORUM_TOPIC = gql`
  mutation createForumTopic($topic: String!) {
    createForumTopic(topic: $topic)
  }
`
export default Forum
