import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Error, Warning, EditOutlined } from '@mui/icons-material'
import Typography from 'components/typography/Typography'
import Row from 'components/layout/Row'
import { IconButton } from '@mui/material'

const RecoExpiry = ({ expiryDate, onEditClick, showLabel, isNav }) => {
  const today = moment()
  const expiryMoment = moment(expiryDate)
  const daysUntilExpiry = expiryMoment.diff(today, 'days')
  const [localExpiryDate, setLocalExpiryDate] = useState(expiryDate)

  useEffect(() => {
    setLocalExpiryDate(expiryDate)
  }, [expiryDate])

  const isExpired = daysUntilExpiry < 0

  const labelStyle = isNav ? { color: 'white', fontSize: 20, fontWeight: 100 } : { color: 'white', fontSize: 22 }

  const updatedLabelStyle = isNav
    ? { color: 'white', background: '#262626' }
    : { color: 'white', background: '#DD0000', fontSize: 22 }

  return (
    <Row
      style={{
        border: isExpired && !isNav ? '1px solid #DD0000' : 'none',
        ...updatedLabelStyle
      }}>
      {!isNav && (
        <>
          {isExpired ? (
            <Error style={{ color: 'white', ...updatedLabelStyle, margin: 20 }} />
          ) : (
            <Warning style={{ color: 'white', ...updatedLabelStyle, margin: 20 }} />
          )}
        </>
      )}
      <div style={{ margin: 10 }}>
        <Typography.Subheading type="5" style={{ ...labelStyle }}>
          Your RECO license {isExpired ? 'expired' : 'expires '} on{' '}
          <span style={{ ...labelStyle }}>{moment(expiryDate).format('MMM D, YYYY')}.</span>
        </Typography.Subheading>

        {showLabel && (
          <Typography.Subheading type="4" style={{ color: 'white', ...updatedLabelStyle }}>
            You MUST renew your license to continue trading in real estate.
          </Typography.Subheading>
        )}
      </div>
      {!isNav && daysUntilExpiry <= 120 && (
        <IconButton onClick={onEditClick} style={{ color: 'white', ...updatedLabelStyle, marginTop: 20 }}>
          <EditOutlined />
        </IconButton>
      )}
    </Row>
  )
}

export default RecoExpiry
