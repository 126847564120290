import React, { useEffect } from 'react'
import AnnouncementForm from './AnnouncementForm'
import { useNavigate, useParams } from 'react-router-dom'
import { GET_ANNOUNCEMENT, GET_ANNOUNCEMENTS } from 'helpers/gql'
import { toast } from 'react-toastify'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet-async'
import { Loading, Row, Typography } from 'components'
import { ANNONCEMENT_TYPES } from 'helpers/constants'
import { useState } from 'react'

const EditAnnouncement = () => {
  const params = useParams()
  const { id } = params
  const navigate = useNavigate()
  const [loadingSection, setLoadingSection] = useState()

  const {
    data: { getAnnouncement: announcement } = [],
    loading,
    refetch
  } = useQuery(GET_ANNOUNCEMENT, {
    variables: {
      filters: { announcementId: id }
    },
    onError: (err) => {
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    }
  })

  useEffect(() => {
    refetch()
  }, [refetch])

  const [updateAnnouncement, { loading: updateLoading }] = useMutation(UPDATE_ANNOUNCEMENT, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
      setLoadingSection(null)
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('Announcement updated.')
      navigate('/announcements')
      setLoadingSection(null)
    },
    refetchQueries: [{ query: GET_ANNOUNCEMENTS, variables: { filters: { status: ANNONCEMENT_TYPES.INBOX } } }]
  })

  const handleSaveDraft = async (values) => {
    setLoadingSection(ANNONCEMENT_TYPES.DRAFT)
    await updateAnnouncement({
      variables: {
        data: {
          ...values,
          status: ANNONCEMENT_TYPES.DRAFT,
          id: announcement?.public_id,
          sender_id: values.sender_id?.value
        }
      }
    })
  }

  const handleSave = async (data) => {
    setLoadingSection('post')
    await updateAnnouncement({
      variables: {
        data: {
          ...data,
          status: ANNONCEMENT_TYPES.INBOX,
          id: announcement?.public_id,
          sender_id: data.sender_id?.value
        }
      }
    })
  }

  const handleSchedulePost = async (data) => {
    setLoadingSection(ANNONCEMENT_TYPES.SCHEDULED)
    await updateAnnouncement({
      variables: {
        data: {
          ...data,
          status: ANNONCEMENT_TYPES.SCHEDULED,
          id: announcement?.public_id,
          sender_id: data.sender_id?.value
        }
      }
    })
  }

  return (
    <>
      <Helmet title="Announcement | RARE" />
      <section id="announcement">
        <Row marginBottom={10}>
          <Typography.Heading type={5}>Edit Announcement</Typography.Heading>
        </Row>
        {loading || !announcement ? (
          <Loading fullpage />
        ) : (
          <AnnouncementForm
            isEdit
            announcement={announcement}
            onSave={handleSave}
            onDraftSave={handleSaveDraft}
            loading={updateLoading}
            loadingSection={loadingSection}
            onSchedulePost={handleSchedulePost}
          />
        )}
      </section>
    </>
  )
}

const UPDATE_ANNOUNCEMENT = gql`
  mutation updateAnnouncement($data: UpdateAnnouncementInput!) {
    updateAnnouncement(data: $data)
  }
`

export default EditAnnouncement
