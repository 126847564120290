import { Grid } from '@mui/material'
import { Tag, Typography } from 'components'
import { AgentSelect } from 'components'
import { UserContext } from 'context'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styles from '../deals/deals.module.scss'
import { STATUS_TYPES, USER_ROLES } from 'helpers/constants'

const ListingFormHeader = ({ listing, isView = false, isEdit = false }) => {
  const { user } = useContext(UserContext)
  const { control, setValue } = useFormContext()
  const [selectedAgents, setSelectedAgents] = useState(listing?.agents ?? [])
  const isAdmin = user.role === USER_ROLES.super_admin || user.role === USER_ROLES.admin
  const isClosedAndAgent = user.role === USER_ROLES.agent && listing?.status === STATUS_TYPES.closed

  useEffect(() => {
    if (selectedAgents) {
      setValue(
        'agents',
        selectedAgents.map((agent) => agent.id)
      )
    }
  }, [selectedAgents, setValue])

  const primaryAgent = useMemo(() => listing?.agents?.find((agent) => agent.id === listing?.creator_id), [listing])

  const filteredAgents = useMemo(() => {
    return selectedAgents?.filter((agent) => agent.id !== primaryAgent?.id)
  }, [selectedAgents, primaryAgent])

  return (
    <Grid
      container
      item
      rowSpacing={3}
      columnSpacing={7}
      columns={12}
      className={`${styles.dealFormHeaderMain} dealFormHeaderResolutionMain`}>
      <Grid item xs={12} md={6}>
        <Typography.Body type={1} className={`${styles.mandatoryFieldText} resolutionMandatoryText`}>
          <span className={styles.requiredIcon}>*</span>
          Mandatory Fields
        </Typography.Body>
        <div className={styles.agentNameLable}>
          <Typography.Heading type={5} style={{ fontFamily: 'Source Sans Pro' }} className="agentNameResolutionLable">
            RARE Agent # {isView || isEdit ? primaryAgent.id : user.id} :{' '}
            {isView || isEdit ? primaryAgent.name : user.name}
          </Typography.Heading>
          <Tag text="Primary Agent" type={1} className={`${styles.primaryAgentLabel} resolutionPrimaryAgentLabel`} />
        </div>
        <div style={{ marginTop: 16, marginBottom: 30 }}>
          {filteredAgents?.map((agent, index) => {
            return (
              <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginTop: 8 }}>
                <Typography.Body type={5}>
                  RARE Agent # {agent.id} : {agent.name}
                </Typography.Body>
              </div>
            )
          })}
        </div>
      </Grid>
      {!isView && ((isEdit && (user.id === primaryAgent?.id || isAdmin)) || (!isEdit && true)) && (
        <Grid className={`${styles.addAgentDropdown} resolutionaddAgentDropdown`} item xs={12} md={6}>
          <Controller
            name="agents"
            control={control}
            defaultValue={[user.id]}
            render={({ field: { onChange } }) => (
              <AgentSelect
                onChange={(values) => {
                  setSelectedAgents(values)
                  onChange(values.map((agent) => agent.id))
                }}
                selectedAgents={selectedAgents}
                isClosedAndAgent={isClosedAndAgent}
                creatorId={listing?.creator_id}
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default ListingFormHeader
