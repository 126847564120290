import moment from 'moment'
import styles from './revshare.module.scss'
import RevShareTable from './RevShareTable'

const RevshareProgress = ({ data }) => {
  return (
    <div style={{ width: '100%' }}>
      {data?.revShareDetails && (
        <div className={styles.potentialEligiblityWrapper}>
          <span className={styles.potentialEligiblity}></span>
          <span>Potential Earnings</span>
        </div>
      )}
      {data?.revShareDetails?.map((revshare, index) => {
        return (
          <div key={index}>
            <RevShareTable
              data={revshare?.tier}
              tierNum={index + 1}
              isTierLock={revshare?.isLock}
              length={revshare?.tier?.length}
              message={revshare?.message}
              month={moment()
                .month(data?.month - 1)
                .format('MMMM')}
              totalMEE={revshare?.total?.totalMEE}
              totalMPE={revshare?.total?.totalMPE}
              totalYEE={revshare?.total?.totalYEE}
              totalYPE={revshare?.total?.totalYPE}
              activeAgentsMessage={revshare?.activeAgentsMessage}
            />
          </div>
        )
      })}
    </div>
  )
}

export default RevshareProgress
