import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useForm, Controller } from 'react-hook-form'
import { useMutation, gql } from '@apollo/client'
import { GET_USER } from 'context/UserProvider'
import { Typography, Button, Input } from 'components'
import { toast } from 'react-toastify'
import loginLogo from '../../assets/Login-page-logo.png'
import styles from './signin.module.scss'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import { useLocation, useNavigate } from 'react-router-dom'
import { createTheme, useMediaQuery } from '@mui/material'

const Login = () => {
  const isSessionTimeout = JSON.parse(localStorage.getItem('sessionTimeout'))
  const location = useLocation()
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const theme = createTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const heightWidth = isMobile ? 200 : 350

  if (isSessionTimeout) {
    toast.error('Session timeout. Please login again')
    localStorage.setItem('sessionTimeout', JSON.stringify(false))
  }

  const [authenticateUser, { loading }] = useMutation(AUTHENTICATE_USER, {
    onError: (err) => {
      toast.dismiss()
      console.error(err)
      setError(err)
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      if (location?.state?.from?.pathname) {
        navigate(location.state.from.pathname)
      } else {
        navigate('/')
      }
    },
    refetchQueries: [{ query: GET_USER }]
  })

  const { handleSubmit, control } = useForm()

  const onSubmit = (data) => authenticateUser({ variables: { ...data } })

  return (
    <>
      <Helmet title="Login | RARE" />

      <section id="login" className={styles.signin}>
        <div className={styles.logoContainer}>
          <img src={loginLogo} alt="RARE Logo" height={heightWidth} width={heightWidth} />
        </div>
        <div className={styles.centerContent}>
          <LockRoundedIcon sx={{ fontSize: '50px', color: 'white' }} />
          <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 40 }}>
            <div>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: 'Email is required'
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    type="text"
                    placeholder="EMAIL"
                    error={error?.message}
                    value={value}
                    onChange={onChange}
                    required
                    isWhiteLine={true}
                    name="email"
                    color="dark"
                    size="medium"
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                rules={{
                  required: 'Password is required'
                }}
                style={{ marginTop: 24 }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    type="password"
                    placeholder="PASSWORD"
                    error={error?.message}
                    value={value}
                    onChange={onChange}
                    isWhiteLine={true}
                    required
                    name="password"
                    color="dark"
                    size="medium"
                  />
                )}
              />
            </div>
            <Typography.Body color="white" style={{ marginTop: 18 }}>
              <Typography.Link to="/forgot" style={{ marginLeft: 8, color: 'white' }}>
                Forgot your password?
              </Typography.Link>
            </Typography.Body>
            <Button form type="plain" block loading={loading} className={styles.passwordResetButton}>
              Login
            </Button>

            {error && (
              <Typography.Body type="3" color="error" style={{ marginTop: 8 }}>
                {error.message}
              </Typography.Body>
            )}
          </form>
        </div>
      </section>
    </>
  )
}

export default Login

const AUTHENTICATE_USER = gql`
  mutation authenticateUser($email: String!, $password: String!) {
    authenticateUser(email: $email, password: $password)
  }
`
