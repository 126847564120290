import { Typography } from 'components'
import styles from './input.module.scss'

const SIZES = ['small', 'medium', 'large']
const COLORS = ['light', 'dark']

const Input = ({
  type,
  placeholder,
  name,
  error,
  value,
  defaultValue,
  onChange,
  onKeyPress = () => {},
  maxLength,
  style,
  label,
  labelStyle,
  sublabel,
  disabled,
  color = 'light',
  size = 'medium',
  required,
  isWhiteLine = false,
  labelType = 3
}) => {
  const TColor = (() => {
    if (color && COLORS.includes(color)) return color
    return 'light'
  })()

  const TSize = (() => {
    if (size && SIZES.includes(size)) return size
    return 'medium'
  })()

  let min, max, step, pattern
  if (type === 'percentage') {
    min = 1
    max = 100
    step = 0.01
    type = 'number'
  }

  if (type === 'currency') {
    min = 0
    step = 0.01
    type = 'number'
  }

  if (type === 'tel') {
    pattern = '[0-9]{3}-[0-9]{3}-[0-9]{4}'
  }

  const handleWheel = (e) => {
    if (type === 'number') {
      e.preventDefault()
      e.target.blur()
    }
  }

  const handleKeyDown = (e) => {
    if (type === 'number' && (e.key === 'ArrowUp' || e.key === 'ArrowDown')) {
      e.preventDefault()
      e.target.blur()
    }
  }

  const handleKeyPress = (e) => {
    if (e?.key === 'Enter') {
      e.preventDefault()
    }
    onKeyPress(e)
  }

  return (
    <>
      {label && (
        <Typography.Body
          type={2}
          color={color === 'light' ? 'primary' : 'white'}
          htmlFor={name}
          className={`${styles.label} `}
          style={labelStyle}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </Typography.Body>
      )}

      {sublabel && (
        <Typography.Body type="3" color="secondary" htmlFor={name} className={`${styles.sublabel} `}>
          {sublabel}
        </Typography.Body>
      )}

      <input
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onKeyPress={handleKeyPress}
        maxLength={maxLength}
        style={style}
        disabled={disabled}
        min={min}
        max={max}
        step={step}
        onWheel={handleWheel}
        onKeyDown={handleKeyDown}
        pattern={pattern}
        className={`customInput ${styles.input} ${styles[TColor]} ${styles[TSize]}
       ${isWhiteLine ? styles.whiteLineInput : ''}
        `}
      />

      {error && (
        <Typography.Body type="3" color="error" className={styles.error}>
          {error}
        </Typography.Body>
      )}
    </>
  )
}

export default Input
