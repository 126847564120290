import { Tab, Tabs, Typography, ThemeProvider, createTheme } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import styles from './CustomTabs.module.scss'
import vars from '../../vars.scss'

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: vars['font-color-primary'],
            color: `${vars['font-color-white']} !important`
          },                 
        }
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          flexDirection: "row", 
          justifyContent: "space-between"
        },
      },
    },
  }
})

const CustomTabs = ({ options = [], onChange, children }) => {
  const [value, setValue] = useState(0)

  if (options.length === 0) return null

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleOptionClick = (option) => {
    if (onChange) onChange(option.value)
  }
  return (
    <Box className={styles.customTabsMain}>
      <ThemeProvider theme={theme}>
        <Tabs
          className={`${styles.tabHeaderRoot} ${styles.tabsContainer}`}
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={false}
          aria-label="Custom Tabs"
          TabIndicatorProps={{
            style: { display: 'none' }
          }}>     
          {options.map((option, index) => {
            return (
              <Tab
                label={option.label}
                key={index}
                className={styles.customTabHead}
                onClick={() => {
                  handleOptionClick(option)
                }}
              />
            )
          })}
        </Tabs>
      </ThemeProvider>
      <Box>
        <Typography component="span">{children}</Typography>
      </Box>
    </Box>
  )
}
export default CustomTabs
