import { useNavigate } from 'react-router-dom'
import { Typography } from 'components'
import styles from './sidebar.module.scss'

function SidebarOption({ Icon, title, id, user, online }) {
  const navigate = useNavigate()

  const selectChannel = () => {
    if (user) {
      navigate(`/chat/users/${id}`)
    } else {
      navigate(`/chat/channels/${id}`)
    }
  }

  return (
    <div className={styles.sidebarOption} onClick={selectChannel}>
      {Icon ? (
        <>
          <Icon className={online ? styles.onlineIcon : styles.icon} />
          <Typography.Body type="2">{title}</Typography.Body>
        </>
      ) : (
        <Typography.Body type="2"># {title}</Typography.Body>
      )}
    </div>
  )
}

export default SidebarOption
