import { Input, PopUp } from 'components'
import React, { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import styles from './library.module.scss'
import { useMutation } from '@apollo/client'
import { RENAME_FILE } from 'helpers/mutations'
import { toast } from 'react-toastify'
import { CONTENT_TYPE } from 'helpers/constants'

const RenameFilePopup = (props) => {
  const { refetch, rename, option, folderName, hideRename } = props
  const fileExtension = option?.key?.split('.').pop()?.toLowerCase()
  const inValidFileText = `${option?.type} name can't contain any of the following characters:  / : * ?  < >| `

  const validationSchema = Yup.object().shape({
    folderName: Yup.string()
      .required(`${option?.type} Name is required`)
      .matches(/^[\w\s!@#$%^&()_+={}\[\];',.~-]+$/, inValidFileText)
  })

  const [renameFile, { loading }] = useMutation(RENAME_FILE, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      refetch()
      toast.success(`${option.type} renamed successfully`)
      resetField('folderName')
      hideRename()
    }
  })

  const { handleSubmit, control, resetField } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      folderName: folderName.split('.')?.[0]
    }
  })

  const handleRename = useCallback(
    async (values) => {
      const fileName = values?.folderName.trim()
      if (fileName?.length > 0) {
        const filePath = option.key.substr(0, option.key.lastIndexOf('/') + 1)
        const folderPath = option.key.substring(
          0,
          option.key.substring(0, option.key.lastIndexOf('/')).lastIndexOf('/') + 1
        )
        const destinationKey =
          option.type === CONTENT_TYPE.FILE ? `${filePath}${fileName}.${fileExtension}` : `${folderPath}${fileName}/`

        await renameFile({
          variables: {
            data: {
              action: option.type === CONTENT_TYPE.FILE ? 'renameFile' : 'renameFolder',
              sourceKey: option.key,
              destinationKey
            }
          }
        })
      }
    },
    [fileExtension, option, renameFile]
  )

  const handleCancel = () => {
    resetField('folderName')
    hideRename()
  }

  return (
    <div>
      <PopUp
        title={`Rename ${option?.type}`}
        open={rename}
        loading={loading}
        confirmBtnLabel={'Rename'}
        canShowCancelButton={false}
        onCancel={handleCancel}
        onConfirm={handleSubmit(handleRename)}>
        <div className={styles.createFolderPopup}>
          <form>
            <Controller
              name="folderName"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  name="folderName"
                  value={value}
                  label={`Rename ${option?.type}`}
                  required
                  onChange={onChange}
                  error={error?.message}
                  style={{ width: '100%' }}
                />
              )}
            />
          </form>
        </div>
      </PopUp>
    </div>
  )
}

export default RenameFilePopup
