import { Loading } from 'components'
import styles from './button.module.scss'

// type = primary, secondary, tertiary, plain
const Button = ({
  type = 'primary',
  loading = false,
  onClick = () => {},
  prefixIcon,
  suffixIcon,
  block = false,
  style,
  form = false,
  children,
  display,
  disabled,
  className
}) => {
  const TType = `button_${type}`
  const TBlock = block ? { width: '100%', display: 'flex', justifyContent: 'center' } : {}

  if (display === 'hidden') return null

  const TChildren = (() => {
    if (prefixIcon && suffixIcon) {
      return (
        <>
          <span className={styles.prefix_icon}>{prefixIcon}</span>
          {children}
          <span className={styles.suffix_icon}>{suffixIcon}</span>
        </>
      )
    }

    if (suffixIcon) {
      return (
        <>
          {children}
          <span className={styles.suffix_icon} style={children ? {} : { margin: 0 }}>
            {suffixIcon}
          </span>
        </>
      )
    }

    if (prefixIcon) {
      return (
        <>
          <span className={styles.prefix_icon} style={children ? {} : { margin: 0 }}>
            {prefixIcon}
          </span>
          {children}
        </>
      )
    }

    return children
  })()

  return (
    <button
      type={form ? 'submit' : 'button'}
      className={`${styles[TType]} ${styles.button} ${styles[className]} ${className}`}
      onClick={onClick}
      style={{ ...TBlock, ...style }}
      disabled={disabled || loading}>
      {loading ? (
        <Loading size={16} className={styles.loading} type={type === 'primary' ? 'secondary' : 'primary'} />
      ) : (
        TChildren
      )}
    </button>
  )
}

export default Button
