import { useEffect, useRef, useState } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import Button from '../button/Button'
import { Delete, FileUpload } from '@mui/icons-material'
import { Typography, AlertPopup } from 'components'
import { getFileUrl, openNewTab } from 'helpers/utils'
import styles from './upload.module.scss'
import { usePopup } from 'hooks/usePopup'
import { toast } from 'react-toastify'

const Upload = ({
  multiple = false,
  onChange = console.log,
  showFile = true,
  defaultValue,
  handleDeleteFile = console.log
}) => {
  const fileSelector = useRef(null)
  const client = useApolloClient()
  const [value, setValue] = useState(defaultValue)
  const [loading, setLoading] = useState(false)
  const [confirmDelete, showConfirmDelete, hideConfirmDelete] = usePopup()
  const supportedFileTypes = ['image/jpg', 'image/jpeg', 'image/png']
  useEffect(() => {
    if (!fileSelector.current) return

    const uploadFile = async (file) => {
      toast.dismiss()
      const { type, size } = file
      if (!supportedFileTypes.includes(file.type)) {
        toast.error('Unsupported file type. Please upload a JPEG, JPG, or PNG.')
        return
      }
      setLoading(true)
      return new Promise(async (resolve, reject) => {
        const { data: { getSignedUrl: { signedUrl, key, fileName, newFilename } } = {} } = await client.query({
          query: GET_SIGNED_URL,
          variables: { fileName: file.name, fileType: file.type }
        })

        fetch(signedUrl, {
          method: 'PUT',
          body: file
        })
          .then(() => {
            setLoading(false)
            resolve({ key, fileName, newFilename })
          })
          .catch((e) => {
            setLoading(false)
            reject(e)
          })
      })
    }

    const fileInput = fileSelector.current

    const listener = fileInput.addEventListener('change', async () => {
      const uploadedFiles = await Promise.all(Array.from(fileInput.files).map(uploadFile))
      setValue(multiple ? uploadedFiles : uploadedFiles[0])
      onChange(multiple ? uploadedFiles : uploadedFiles[0])
    })
    return () => {
      fileInput.removeEventListener('change', listener)
    }
  }, [multiple, client, fileSelector, onChange])

  const handleUpload = () => {
    fileSelector.current.click()
  }

  const handleRemove = async () => {
    handleDeleteFile(value)
    fileSelector.current.value = null
    setValue(null)
    onChange(null)
    hideConfirmDelete()
  }
  return (
    <>
      <input type="file" ref={fileSelector} hidden multiple={multiple} />
      {value && showFile ? (
        <div style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          {multiple ? (
            <>
              <Typography.Body type="3">TODO: Multifile state</Typography.Body>
              <Button type="tertiary" onClick={showConfirmDelete} className={styles.deleteButton}>
                <Delete fontSize="small" />
              </Button>
            </>
          ) : (
            <>
              <Typography.Link
                onClick={() => {
                  openNewTab(getFileUrl(value.key))
                }}
                style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: 200 }}>
                {value.fileName}
              </Typography.Link>
              <Button type="tertiary" onClick={showConfirmDelete} className={styles.deleteButton}>
                <Delete fontSize="small" />
              </Button>
            </>
          )}
        </div>
      ) : (
        <Button prefixIcon={<FileUpload />} type="tertiary" onClick={handleUpload} loading={loading}>
          Upload
        </Button>
      )}
      <AlertPopup
        openModel={confirmDelete}
        handleOnCloseBtn={hideConfirmDelete}
        HeaderTitle={<Typography.Subheading>Delete Document</Typography.Subheading>}
        handleRightBtnClick={handleRemove}
        handleLeftBtnClick={hideConfirmDelete}>
        Are you sure you want to delete this document?
      </AlertPopup>
    </>
  )
}

const GET_SIGNED_URL = gql`
  query getSignedUrl($fileName: String, $fileType: String) {
    getSignedUrl(fileName: $fileName, fileType: $fileType) {
      signedUrl
      fileName
      key
      newFilename
    }
  }
`
export default Upload
