import { Grid } from '@mui/material'
import { Input, DatePicker } from 'components'
import { Controller, useFormContext } from 'react-hook-form'
import React, { useEffect } from 'react'
import PreConOfferDetails from './pre_con/PreConOfferDetails'
import { useContext } from 'react'
import { DealContext } from 'context'
import { FORM_DEAL_TYPES, STATUS_TYPES } from 'helpers/constants'

const OfferDetails = ({ setPrice, isView, setPriceFlag }) => {
  const { control, getValues } = useFormContext()
  const { deal, disabled = false } = useContext(DealContext)
  const type = getValues().type || deal?.type

  useEffect(() => {
    if (!isView && deal?.sale_price) {
      setPrice(deal?.sale_price)
    }
    if (!isView && deal?.lease_price) {
      setPrice(deal?.lease_price)
    }
  }, [deal?.lease_price, deal?.sale_price, isView, setPrice])
  return (
    <>
      {type === FORM_DEAL_TYPES.PRE_CON ? (
        <PreConOfferDetails />
      ) : (
        <>
          <Grid container columnSpacing={7} sx={{ mt: 3 }}>
            <Grid xs={12} md={3} item>
              <Controller
                name="offer_date"
                control={control}
                defaultValue={deal?.offer_date || null}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <DatePicker
                    type={5}
                    error={error?.message}
                    value={value}
                    onChange={onChange}
                    label="Offer Date"
                    required
                    disabled={disabled}
                    name="offer_date"
                    style={{ width: '100%' }}
                    maxDate={new Date()}
                  />
                )}
              />
            </Grid>
            <Grid xs={12} md={3} item mt={{ xs: 3, md: 0 }}>
              <Controller
                name="acceptance_date"
                control={control}
                defaultValue={deal?.acceptance_date || null}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <DatePicker
                    type={5}
                    error={error?.message}
                    value={value}
                    onChange={onChange}
                    label="Firm Date"
                    required
                    disabled={disabled}
                    name="acceptance_date"
                    style={{ width: '100%' }}
                    maxDate={new Date()}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={7} sx={{ mt: 3 }}>
            <Grid xs={12} md={3} item>
              <Controller
                name="close_date"
                control={control}
                defaultValue={deal?.close_date || null}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <DatePicker
                    type={5}
                    error={error?.message}
                    value={value}
                    onChange={onChange}
                    label="Close Date"
                    required
                    disabled={disabled}
                    name="close_date"
                    style={{ width: '100%' }}
                  />
                )}
              />
            </Grid>
            {deal?.status === STATUS_TYPES.settled && (
              <Grid xs={12} md={3} item mt={{ xs: 3, md: 0 }}>
                <Controller
                  name="settled_date"
                  control={control}
                  defaultValue={deal?.settled_date}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <DatePicker
                      type={5}
                      error={error?.message}
                      value={value}
                      onChange={onChange}
                      label="Settle Date"
                      required
                      disabled={disabled}
                      name="settled_date"
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
          <Grid container columnSpacing={7} sx={{ mt: 3 }}>
            <Grid xs={12} md={3} item>
              {type === FORM_DEAL_TYPES.SALE ? (
                <Controller
                  name="sale_price"
                  control={control}
                  defaultValue={deal?.sale_price || 0}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      type="number"
                      error={error?.message}
                      value={value}
                      onChange={(e) => {
                        setPriceFlag(true)
                        setPrice(e?.target.value)
                        onChange(e)
                      }}
                      label="Sale Price"
                      required
                      disabled={disabled}
                      name="sale_price"
                      style={{ width: '100%' }}
                      placeholder="$"
                    />
                  )}
                />
              ) : (
                <Controller
                  name="lease_price"
                  control={control}
                  defaultValue={deal?.lease_price || 0}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      type="number"
                      error={error?.message}
                      value={value}
                      onChange={(e) => {
                        setPriceFlag(true)
                        setPrice(e?.target.value)
                        onChange(e)
                      }}
                      label="Lease Price"
                      required
                      disabled={disabled}
                      name="lease_price"
                      placeholder="$"
                      style={{ width: '100%' }}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid xs={12} md={3} item mt={{ xs: 3, md: 0 }}>
              <Controller
                name="deposit_amount"
                control={control}
                defaultValue={deal?.deposit_amount}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    type="number"
                    error={error?.message}
                    value={value}
                    onChange={(e) => {
                      onChange(e || '')
                    }}
                    label="Deposit Amount 1"
                    disabled={disabled}
                    name="deposit_amount"
                    required
                    style={{ width: '100%' }}
                    placeholder="$"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={7} sx={{ mt: 3 }}>
            <Grid xs={12} md={3} item>
              <Controller
                name="second_deposit_amount"
                control={control}
                defaultValue={deal?.second_deposit_amount || 0}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    type="number"
                    error={error?.message}
                    value={value}
                    onChange={(e) => {
                      onChange(e || '')
                    }}
                    label="Deposit Amount 2"
                    name="second_deposit_amount"
                    style={{ width: '100%' }}
                    placeholder="$"
                    disabled={disabled}
                  />
                )}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default OfferDetails
