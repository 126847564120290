import { createTheme, useMediaQuery } from '@mui/material'
import React from 'react'
import Announcements from './Announcements'
import MobileAnnouncements from './mobileAnnouncements/MobileAnnouncements'

const AnnouncementPage = () => {
  const theme = createTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return <>{isMobile ? <MobileAnnouncements /> : <Announcements />}</>
}

export default AnnouncementPage
