import { Helmet } from 'react-helmet-async'
import styles from './referral.module.scss'
import { Widget } from '@typeform/embed-react'

const Referral = () => {
  return (
    <>
      <Helmet title="Agent Referral | RARE" />
      <section id="referral" className={styles.referral}>
        <Widget id="LL5asQyX" className={styles.form} />
      </section>
    </>
  )
}

export default Referral
