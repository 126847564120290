import React, { useContext } from 'react'
import { Typography } from 'components'
import styles from '../../deals.module.scss'
import DocumentBase from '../../DocumentBase'
import AdditionalDocuments from 'components/upload/AdditionalDocuments'
import { DealContext } from 'context'
import { useFormContext } from 'react-hook-form'

const PreConDocuments = ({ isAgent = false, user, dealStatus }) => {
  const { deal, isView, isClosedAndAgent } = useContext(DealContext)
  const { control } = useFormContext()

  return (
    <>
      <DocumentBase title="All New Sale Documents" name="new_sale_all_document" required={isAgent} />
      <Typography.Subheading type={1} className={`${styles.orDivider} documentsOrDivider`}>
        OR
      </Typography.Subheading>
      <DocumentBase
        title="1st page of Agreement of Purchase and Sale and all amendments"
        name="new_sale_first_page_agreement_purchase"
        required={isAgent}
      />
      <DocumentBase title="Broker Co-op Letter" name="new_sale_broker_coop_letter" required={isAgent} />
      <DocumentBase title="1st page of Tarion" name="new_sale_first_page_tarion" required={isAgent} />
      {deal?.documents?.map((doc) => {
        if (doc?.checklist_document_type === 'new_sale_working_with_realtor ') {
          return <DocumentBase title="Working with a Realtor" name="lease_working_with_realtor" required={isAgent} />
        }
      })}
      <DocumentBase title="Buyer Representation Agreement" name="new_sale_buyer_rep_agreement" required={isAgent} />
      <DocumentBase title="Mortgage Pre-Approval" name="mortgage_preapproval" required={isAgent} />
      <DocumentBase title="Copy of Deposits" name="new_sale_copy_deposit_receipt" required={isAgent} />
      <AdditionalDocuments isView={isView} control={control} isClosedAndAgent={isClosedAndAgent} />
    </>
  )
}

export default PreConDocuments
