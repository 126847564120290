import { Grid } from '@mui/material'
import { Input, Typography } from 'components'
import { Controller, useFormContext } from 'react-hook-form'

const ViewCommission = ({ agents, deal }) => {
  const { control } = useFormContext()
  const primaryCommission = deal.agents_commission_breakdown.primary_agent_commission
  const commission = deal.agents_commission_breakdown.secondary_agents_commission
  return (
    <>
      <Grid container items spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={4}>
          <Typography.Heading type={6} style={{ fontWight: 700 }}>
            {primaryCommission?.name}
          </Typography.Heading>
        </Grid>
        <Grid item xs={4}>
          <Controller
            name={'primary_Agent_Percentage'}
            control={control}
            defaultValue={primaryCommission?.commission_percentage}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type="text"
                error={error?.message}
                value={primaryCommission?.commission_percentage}
                onChange={onChange}
                placeholder={'Percentage'}
                name={'primary_Agent_Percentage'}
                disabled={true}
                style={{ width: '100%' }}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name={'primary_Agent_Total'}
            control={control}
            defaultValue={primaryCommission?.commission_amount}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type="number"
                error={error?.message}
                value={primaryCommission?.commission_amount}
                onChange={onChange}
                placeholder={'Total'}
                disabled={true}
                style={{ width: '100%' }}
              />
            )}
          />
        </Grid>
      </Grid>
      {commission.map((agent, index) => {
        return (
          <div key={index}>
            <Grid container items spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={4}>
                <Typography.Body type={1} style={{ marginTop: 10 }}>
                  {agent.name}
                </Typography.Body>
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name={'secondaryAgent_percentage_' + agent.id}
                  control={control}
                  defaultValue={commission[index].commission_percentage}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      type="number"
                      error={error?.message}
                      value={value}
                      onChange={onChange}
                      placeholder={'Percentage'}
                      name={agent.name}
                      disabled={true}
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name={'secondaryAgent_total_' + agent.id}
                  control={control}
                  defaultValue={commission[index].commission_amount}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      type="number"
                      error={error?.message}
                      value={value}
                      onChange={onChange}
                      placeholder={'Total'}
                      disabled={true}
                      name="totalCommission"
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
        )
      })}
    </>
  )
}

export default ViewCommission
