import { useContext } from 'react'
import { UserContext } from 'context'
import { useLocation, Outlet, Navigate } from 'react-router-dom'

const RequireAuth = ({ required, role }) => {
  const { user } = useContext(UserContext)
  const location = useLocation()

  if (role) {
    return user.role === role ? <Outlet /> : <Navigate to="/" replace />
  }

  if (required) {
    return user ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />
  }

  return user ? <Navigate to={location.state?.from || '/'} replace /> : <Outlet />
}

export default RequireAuth
