import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { gql, useMutation } from '@apollo/client'
import { Comments, Row } from 'components'
import { GET_DEAL } from 'helpers/gql'

const DealComments = ({ deal }) => {
  const { handleSubmit, control, reset } = useForm()
  const [isCreatingComment, setIsCreatingComment] = useState(false)
  const [createDealComment, { loading }] = useMutation(CREATE_DEAL_COMMENT, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      reset()
    },
    refetchQueries: [{ query: GET_DEAL, variables: { id: deal.public_id } }]
  })

  const handleCancel = () => {
    setIsCreatingComment(false)
    reset()
  }

  const handleSubmitComment = async (values) => {
    if (values.comment.length === 0) return
    if (!values.comment) return

    createDealComment({ variables: { dealId: deal.public_id, message: values.comment } })
  }

  return (
    <Row gap={10} marginBottom={10}>
      <Comments
        comments={deal.comments}
        control={control}
        handleSubmit={handleSubmit(handleSubmitComment)}
        createLoading={loading}
        onCancel={handleCancel}
      />
    </Row>
  )
}

const CREATE_DEAL_COMMENT = gql`
  mutation createDealComment($dealId: String, $message: String) {
    createDealComment(dealId: $dealId, message: $message)
  }
`

export default DealComments
