import { gql, useMutation } from '@apollo/client'
import { GET_AGENTS, GET_ALL_USERS } from 'helpers/gql'
import { toast } from 'react-toastify'
import UserForm from './UserForm'

const CreateUser = ({ create, hideCreate, roles }) => {
  const [createUser] = useMutation(CREATE_USER, {
    onError: (err) => {
      toast.dismiss()
      console.error(err)
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    refetchQueries: [{ query: GET_AGENTS }, { query: GET_ALL_USERS, variables: {
      excludeDeleted: false
    } }]
  })

  const onSubmit = async (data, resetForm, setSelectedValue) => {
    try {
      await createUser({
        variables: {
          data: { ...data, role: data.role.value }
        },
        onCompleted: () => {
          toast.dismiss()
          toast.success('User created.')
          hideCreate()
          resetForm()
          setSelectedValue(null)
        }
      })
    } catch (error) {
      console.error(error)
      toast.dismiss()
      toast.error(error.message)
    }
  }

  return <UserForm open={create} hidePopup={hideCreate} onSubmit={onSubmit} roles={roles} />
}

const CREATE_USER = gql`
  mutation createUser($data: UpdateProfileInput!) {
    createUser(data: $data)
  }
`
export default CreateUser
