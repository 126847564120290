import React, { useCallback, useState } from 'react'
import agentPinnedIcon from '../../assets/images/agentPinnedIcon.png'
import adminPinnedIcon from '../../assets/images/adminPinnedIcon.png'
import starredIcon from '../../assets/images/starredIcon.png'
import unPinnedIcon from '../../assets/images/unPinnedIcon.png'
import dropdownOption from 'helpers/dropdownOptions'
import { MUIMenuButton } from 'components'
import { IconButton, createTheme, useMediaQuery } from '@mui/material'
import { DeleteOutlined, EditOutlined, StarOutline } from '@mui/icons-material'
import styles from './announcements.module.scss'
import { useNavigate } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { GET_ANNOUNCEMENT } from 'helpers/gql'
import { UPDATE_ANNOUNCEMENT } from 'helpers/mutations'

const AnnouncementActions = ({ refetch, isAdmin, preview, onConfirmDelete }) => {
  const navigate = useNavigate()
  const [successMessage, setSuccessMessage] = useState('')
  const [adminSuccessMessage, setAdminSuccessMessage] = useState('')
  const theme = createTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [updateAnnouncement] = useMutation(UPDATE_ANNOUNCEMENT, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      if (successMessage) {
        toast.success(successMessage)
      }
      refetch()
    },
    refetchQueries: [{ query: GET_ANNOUNCEMENT }]
  })

  const [adminPinned] = useMutation(ADMIN_PIN, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      if (adminSuccessMessage) {
        toast.success(adminSuccessMessage)
      }
      refetch()
    },
    refetchQueries: [{ query: GET_ANNOUNCEMENT }]
  })

  const handleEdit = useCallback(() => {
    if (preview.public_id) {
      navigate('/announcements/edit/' + preview.public_id)
    }
  }, [navigate, preview?.public_id])

  const handleStarred = () => {
    setSuccessMessage('Announcement updated.')
    updateAnnouncement({
      variables: {
        data: {
          announcementId: preview?.public_id,
          starred: preview?.is_starred ? false : true
        }
      }
    })
  }

  const handlePinned = () => {
    setSuccessMessage('Announcement updated.')
    updateAnnouncement({
      variables: {
        data: {
          announcementId: preview.public_id,
          pinned: preview?.is_pinned ? false : true
        }
      }
    })
  }

  const handlePin = (value) => {
    setSuccessMessage('Announcement updated.')
    updateAnnouncement({
      variables: {
        data: {
          announcementId: preview.public_id,
          pinned: value
        }
      }
    })
  }

  const handleAdminPin = (value = false) => {
    value ? setAdminSuccessMessage('Announcement updated.') : setAdminSuccessMessage(null)
    adminPinned({
      variables: {
        data: {
          announcementId: preview?.public_id,
          admin_pinned: value
        }
      }
    })
  }

  const handlePinMenu = (value) => {
    value ? setAdminSuccessMessage('Announcement updated.') : setAdminSuccessMessage(null)
    if (value === 'all') {
      preview?.admin_pinned ? handleAdminPin(false) : handleAdminPin(true)
      handlePin(false)
    } else {
      handleAdminPin(false)
      preview?.is_pinned ? handlePin(false) : handlePin(true)
    }
  }

  return (
    <div className={styles.announcementActions}>
      <IconButton onClick={handleStarred}>
        {preview?.is_starred ? <img src={starredIcon} height={23} width={23} alt="starredIcon" /> : <StarOutline />}
      </IconButton>
      {isAdmin ? (
        <>
          <MUIMenuButton onClick={handlePinMenu} options={dropdownOption.PIN_OPTIONS}>
            <IconButton>
              {preview?.admin_pinned || preview?.is_pinned ? (
                preview?.admin_pinned ? (
                  <img src={adminPinnedIcon} height={23} width={23} alt="adminPinnedIcon" />
                ) : (
                  <img src={agentPinnedIcon} height={23} width={23} alt="agentPinnedIcon" />
                )
              ) : (
                <img src={unPinnedIcon} height={23} width={23} alt="unPinnedIcon" />
              )}
            </IconButton>
          </MUIMenuButton>
        </>
      ) : (
        <IconButton onClick={handlePinned}>
          {preview?.is_pinned ? (
            <img src={agentPinnedIcon} height={23} width={23} alt="agentPinnedIcon" />
          ) : (
            <img src={unPinnedIcon} height={23} width={23} alt="unPinnedIcon" />
          )}
        </IconButton>
      )}
      {isAdmin && !isMobile && (
        <>
          <IconButton onClick={handleEdit}>
            <EditOutlined />
          </IconButton>
          <IconButton onClick={onConfirmDelete}>
            <DeleteOutlined />
          </IconButton>
        </>
      )}
    </div>
  )
}

const ADMIN_PIN = gql`
  mutation adminPin($data: AdminPinned!) {
    adminPin(data: $data)
  }
`

export default AnnouncementActions
