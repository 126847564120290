import { ApolloClient, InMemoryCache, split, ApolloLink } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { onError } from '@apollo/client/link/error'

// Create a GraphQLWs link
const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_API_URL_WS, // Your WebSocket URL
    options: {
      reconnect: true
    }
  })
)

// Create an HTTP link with file upload capability
const httpUploadLink = createUploadLink({
  uri: process.env.REACT_ENV === 'prod' ? 'https://api.rarerealestate.ca/graphql' : process.env.REACT_APP_API_URL,
  credentials: 'include'
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    if (graphQLErrors[0].statusCode === 440) {
      localStorage.setItem('sessionTimeout', JSON.stringify(true))
      window.location.reload()
    }
  }
})

// Use the split function to split between HTTP and WebSocket
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  httpUploadLink
)

const client = new ApolloClient({
  cache: new InMemoryCache({ addTypename: false }),
  link: ApolloLink.from([errorLink, link])
})

export default client
