import { useContext, useMemo, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import { GET_LISTING, GET_LISTINGS } from 'helpers/gql'
import { useParams } from 'react-router-dom'
import ListingComments from '../ListingComments'
import ListingAdmin from '../ListingAdmin'
import { toast } from 'react-toastify'
import { DocumentContext } from 'context'
import { FormProvider, useForm } from 'react-hook-form'
import styles from '../listings.module.scss'
import EditListingDetails from '../ListingDetails'
import { UserContext } from 'context'
import { useNavigate } from 'react-router'
import { LISTING_DOCUMENTS_TYPES, STATUS_TYPES } from 'helpers/constants'
import { ListingHelper } from 'helpers/listing/ListingHelper'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useCallback } from 'react'
import PropertyInfo from '../PropertyInfo'
import ListingDocuments from '../ListingDocuments'
import ListingFooter from '../ListingFooter'
import ListingFormHeader from '../ListingFormHeader'
import { convertLocalToUTC, convertUTCToLocal } from 'helpers/utils'

const EditListingForm = ({ listing }) => {
  const navigate = useNavigate()
  const params = useParams()
  const { id } = params
  const { user } = useContext(UserContext)
  const isAdmin = ['admin', 'super_admin'].includes(user.role)
  const { deletedDocuments } = useContext(DocumentContext)
  const [listingType, setListingType] = useState(listing?.type)
  const [listingOption, setListingOption] = useState(listing?.option)
  const [listingStatus, setListingStatus] = useState('')

  const [updateListing, { loading: updateListingLoading }] = useMutation(UPDATE_LISTING, {
    onError: (err) => {
      toast.dismiss()
      console.error(err)
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('Listing updated.')
      formRef.reset()
      navigate('/listings')
    },
    refetchQueries: [
      { query: GET_LISTING, variables: { id } },
      { query: GET_LISTINGS, variables: { filters: {} } }
    ]
  })

  const validationSchema = useMemo(() => ListingHelper.ListingValidationSchema(true), [])

  const formRef = useForm({
    defaultValues: {
      type: listing?.type,
      option: listing?.option,
      address: listing?.address,
      mls_pictures_link: listing?.mls_pictures_link,
      conditions: listing.conditions.length ? listing.conditions : [{ conditional_upon: '', conditional_until: null }],
      additionalDocuments: listing?.additionalDocuments?.length
        ? listing?.additionalDocuments
        : [{ fileName: '', key: null, size: null, type: '' }],
      checkedDocuments: listing?.checkedDocuments ?? [],
      sign_installation_date: convertUTCToLocal(listing?.sign_installation_date)
    },
    resolver: yupResolver(validationSchema)
  })

  const formatData = (data) => {
    const documents = {}
    Object.keys(LISTING_DOCUMENTS_TYPES).forEach((typeKey) => {
      if (data[typeKey]) {
        const { fileName, newFilename } = data[typeKey]
        documents[typeKey] = { fileName, newFilename }
      }
      delete data[typeKey]
    })

    return {
      ...data,
      id,
      documents,
      deletedDocuments,
      sign_installation_date: convertLocalToUTC(data?.sign_installation_date),
      need_sale_sign: data.need_sale_sign === 'need_sale_sign_yes',
      conditions: data.option === 'mls' ? data.conditions : null,
      conditional: data.option === 'mls' ? data.conditional === 'yes' : null,
      upload_conditional: data.option === 'mls' ? data.upload_conditional === 'yes' : null
    }
  }

  const handleSubmit = async (values) => {
    setListingStatus(STATUS_TYPES.draft)
    const data = await formatData(values)
    updateListing({
      variables: {
        data: {
          ...data,
          submitted: !isAdmin && listing?.status === STATUS_TYPES.attention ? true : undefined
        }
      }
    })
  }

  const handleSubmitDraft = async (values) => {
    setListingStatus(STATUS_TYPES.submitted)
    const data = await formatData(values)
    updateListing({
      variables: {
        data: {
          ...data,
          submitted: true
        }
      }
    })
  }

  const handleChangeListingType = useCallback((type) => {
    setListingType(type)
  }, [])

  const handleChangeListingOption = useCallback((option) => {
    setListingOption(option)
  }, [])

  useEffect(() => {
    const isSubmitting = formRef.formState.isSubmitting
    const fetchValid = async () => {
      const isValid = await formRef.trigger()
      if (isValid === false) {
        if (isSubmitting === true) {
          toast.dismiss()
          toast.error('Oops! Something went wrong. Please check the error/validation messages.')
        }
      }
    }
    fetchValid()
  }, [formRef.formState.isSubmitting])

  return (
    <>
      <FormProvider {...formRef}>
        <div className={styles.flexContainer}>
          <div className={styles.leftSide}>
            <div className={styles.form}>
              <ListingFormHeader listing={listing} isEdit />
              <form>
                <PropertyInfo
                  isEdit
                  onChangeListingType={handleChangeListingType}
                  onChangeListingOption={handleChangeListingOption}
                />
                <ListingDocuments listing={listing} isEdit isView={!isAdmin && Boolean(listing?.isListingAttached)} />
                <EditListingDetails listing={listing} listingType={listingType} listingOption={listingOption} isEdit />
                <div className={styles.mobileListingComment}>
                  <ListingComments listing={listing} />
                </div>
                {isAdmin && (
                  <ListingAdmin
                    isEdit
                    listingStatus={listingStatus}
                    listing={listing}
                    isAdmin={isAdmin}
                    loading={updateListingLoading}
                    onListingSave={formRef.handleSubmit(handleSubmit)}
                  />
                )}
                {!isAdmin && (
                  <ListingFooter
                    status={listing?.status}
                    isEdit
                    listingStatus={listingStatus}
                    loading={updateListingLoading}
                    onListingSave={formRef.handleSubmit(handleSubmit)}
                    onSubmitDraft={formRef.handleSubmit(handleSubmitDraft)}
                  />
                )}
                <div className={styles.rightSideEdit}>
                  <ListingComments listing={listing} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </FormProvider>
    </>
  )
}

const UPDATE_LISTING = gql`
  mutation updateListing($data: UpdateListingInput!) {
    updateListing(data: $data)
  }
`

export default EditListingForm
