import * as Yup from 'yup'

export class ListingHelper {
  static ListingValidationSchema = (isEdit = false) => {
    return Yup.object().shape({
      type: Yup.string().required('Listing type is required'),
      option: Yup.string().required('Listing option is required'),
      address: Yup.string()
        .max(190, 'Property Address  must be atmost 190 Characters')
        .required('Property Address is required'),
      sign_installation_date: Yup.date()
        .nullable()
        .when('need_sale_sign', {
          is: (need_sale_sign) =>
            need_sale_sign === 'yes' || need_sale_sign === true || need_sale_sign === 'need_sale_sign_yes',
          then: () => Yup.date().required('Date is required')
        }),
      conditional: Yup.string().when('option', {
        is: (option) => option === 'mls' && isEdit,
        then: () => Yup.string().required('Conditional is required')
      }),
      upload_conditional: Yup.string().when('option', {
        is: (option) => option === 'mls' && isEdit,
        then: () => Yup.string().required('Upload conditional is required')
      }),
      conditions: Yup.array().when(['option', 'conditional'], {
        is: (option, conditional) => option === 'mls' && conditional === 'yes',
        then: () =>
          Yup.array().of(
            Yup.object().shape({
              conditional_upon: Yup.string().required('Condition is required'),
              conditional_until: Yup.date().nullable().required('Expiry date is required')
            })
          )
      })
    })
  }
  //Might need in future
  // static validationSchemaAgentListing = () => {
  //   return Yup.object().shape({
  //     type: Yup.string().required('Listing type is required'),
  //     option: Yup.string().required('Listing option is required'),
  //     address: Yup.string().required('Property Address is required'),
  //     listing_all_document: Yup.mixed().nullable(),
  //     listing_agreement: Yup.mixed()
  //       .nullable()
  //       .when('listing_all_document', {
  //         is: (value) => value === null,
  //         then: () => Yup.mixed().required('Listing Agreement is required')
  //       }),
  //     listing_data_sheet: Yup.mixed()
  //       .nullable()
  //       .when('listing_all_document', {
  //         is: (value) => value === null,
  //         then: () => Yup.mixed().required('Data Sheet is required')
  //       }),
  //     listing_working_with_realtor: Yup.mixed()
  //       .nullable()
  //       .when('listing_all_document', {
  //         is: (value) => value === null,
  //         then: () => Yup.mixed().required('Working with a Realtor is required')
  //       }),
  //     listing_showing_instructions: Yup.mixed()
  //       .nullable()
  //       .when(['listing_all_document', 'type'], {
  //         is: (listing_all_document, type) => listing_all_document === null && type === 'lease_listing',
  //         then: () => Yup.mixed().required('Showing Instructions is required')
  //       }),
  //     sale_fintrac_form_630: Yup.mixed()
  //       .nullable()
  //       .when(['listing_all_document', 'type'], {
  //         is: (listing_all_document, type) => listing_all_document === null && type === 'sale_listing',
  //         then: () =>
  //           Yup.mixed().required('Fintrac Form 630  - Individual Identification Information Record is required')
  //       }),
  //     sale_fintrac_form_634: Yup.mixed()
  //       .nullable()
  //       .when(['listing_all_document', 'type'], {
  //         is: (listing_all_document, type) => listing_all_document === null && type === 'sale_listing',
  //         then: () =>
  //           Yup.mixed().required(
  //             'Fintrac Form 634 - Politically Exposed Person/Head of International Organization Checklist is required'
  //           )
  //       }),
  //     sign_installation_date: Yup.date()
  //       .nullable()
  //       .when('need_sale_sign', {
  //         is: (need_sale_sign) => need_sale_sign === 'yes',
  //         then: () => Yup.date().required('Date is required')
  //       }),
  //     conditions: Yup.array().when(['option', 'conditional'], {
  //       is: (option, conditional) => option === 'mls' && conditional === 'yes',
  //       then: () =>
  //         Yup.array().of(
  //           Yup.object().shape({
  //             conditional_upon: Yup.string().required('Condition is required'),
  //             conditional_until: Yup.date().nullable().required('Expiry date is required')
  //           })
  //         )
  //     })
  //   })
  // }
}
