import { gql, useMutation } from '@apollo/client'
import { Comments, Row } from 'components'
import { GET_LISTING } from 'helpers/gql'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

const ListingComments = ({ listing }) => {
  const { handleSubmit, control, reset } = useForm()
  const [isCreatingComment, setIsCreatingComment] = useState(false)

  const [createListingComment, { loading: createCommentLoading }] = useMutation(CREATE_LISTING_COMMENT, {
    onError: (err) => {
      toast.dismiss()
      console.error(err)
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      reset()
      toast.success('Comment created.')
    },
    refetchQueries: [{ query: GET_LISTING, variables: { id: listing.public_id } }]
  })

  const handleSubmitComment = async (values) => {
    if (values.comment.length === 0) return
    if (!values.comment) return

    createListingComment({ variables: { listingId: listing.public_id, message: values.comment } })
  }

  const handleCancel = () => {
    setIsCreatingComment(false)
    reset()
  }

  return (
    <Row gap={10} marginBottom={10}>
      <Comments
        comments={listing.comments}
        control={control}
        handleSubmit={handleSubmit(handleSubmitComment)}
        createLoading={createCommentLoading}
        onCancel={handleCancel}
      />
    </Row>
  )
}

const CREATE_LISTING_COMMENT = gql`
  mutation createListingComment($listingId: String, $message: String) {
    createListingComment(listingId: $listingId, message: $message)
  }
`

export default ListingComments
