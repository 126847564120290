import React from 'react'
import { Button, Card, Grid, Tooltip } from '@mui/material'
import { Loading, Row, Typography } from 'components'
import styles from '../announcements.module.scss'
import { AttachFile } from '@mui/icons-material'
import agentPinnedIcon from '../../../assets/images/agentPinnedIcon.png'
import adminPinnedIcon from '../../../assets/images/adminPinnedIcon.png'
import starredIcon from '../../../assets/images/starredIcon.png'
import { useNavigate } from 'react-router-dom'

const AnnouncementsList = ({ data, announcements, loading, searchText, onShowMore }) => {
  const navigate = useNavigate()

  const handlePreviewChange = async (id) => {
    navigate('/announcements/' + id)
  }

  const getHighlightedText = (text) => {
    let parts = text?.split(new RegExp(`(${searchText})`, 'gi'))
    return parts?.map((part, index) => (
      <React.Fragment key={index}>
        {part.toLowerCase() === searchText?.toLowerCase() ? <b style={{ backgroundColor: '#eed8a2' }}>{part}</b> : part}
      </React.Fragment>
    ))
  }

  return (
    <div className={styles.list}>
      {announcements?.length > 0 ? (
        announcements?.map((value, index) => {
          return (
            <React.Fragment key={index}>
              <Card variant="outlined" style={{ marginBottom: 10, backgroundColor: value?.is_read ? '#f9f9f9' : '#e8f1fc' }}>
                <div
                  className={value?.is_read ? styles.readBox : styles.unReadBox}
                  onClick={() => handlePreviewChange(value.public_id)}>
                  <Grid container columns={12} style={{ padding: 4 }}>
                    <Grid item xs={9}>
                      <Typography.Heading
                        style={{
                          fontSize: 17,
                          marginLeft: 10,
                          fontWeight: value?.is_read ? 500 : 600,
                          color: value?.is_read ? '#36454F' : '#000000'
                        }}
                        type={6}>
                        {getHighlightedText(value.title)}
                      </Typography.Heading>
                      {value?.sender && (
                        <Typography.Body
                          style={{
                            fontSize: 15,
                            marginLeft: 10,
                            fontWeight: value?.is_read ? 500 : 600,
                            color: '#898989'
                          }}
                          className={styles.preview}>
                          From {getHighlightedText(value?.sender)}
                        </Typography.Body>
                      )}
                    </Grid>
                    <Grid item xs={3} style={{ display: 'flex', alignItems: 'center',justifyContent:'flex-end' }}>
                      {value?.is_pinned && (
                        <Tooltip title={'Pinned By You'}>
                          <img src={agentPinnedIcon} height={18} width={18} alt="agentPinnedIcon" />
                        </Tooltip>
                      )}
                      {value?.admin_pinned && (
                        <Tooltip title={'Pinned By Admin'}>
                          <img
                            src={adminPinnedIcon}
                            height={18}
                            width={18}
                            style={{ marginLeft: 1 }}
                            alt="adminPinnedIcon"
                          />
                        </Tooltip>
                      )}
                      {value?.is_starred && (
                        <Tooltip title={'Important'}>
                          <img
                            src={starredIcon}
                            height={18}
                            width={18}
                            style={{ marginLeft: 1 }}
                            alt="adminPinnedIcon"
                          />
                        </Tooltip>
                      )}
                      {value?.attachments?.length > 0 && (
                        <Tooltip title={'Has Attachements'}>
                          <AttachFile sx={{ fontSize: '18px', marginLeft: 0.5, color: '#4d4d4d' }} />
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </Card>
              {announcements?.length - 1 === index && data?.totalCount > announcements?.length && (
                <Row justifyContent="center">
                  {loading ? (
                    <Loading />
                  ) : (
                    <Button
                      className={styles.loadMore}
                      variant="contained"
                      sx={{
                        mt: 1,
                        padding: '4px 16px',
                        fontSize: '12px',
                        backgroundColor: '#616161',
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: 'black !important'
                        }
                      }}
                      onClick={onShowMore}>
                      Load More...
                    </Button>
                  )}
                </Row>
              )}
            </React.Fragment>
          )
        })
      ) : (
        <Typography.Body type={6} className={styles.noAnnouncements}>
          No Announcements
        </Typography.Body>
      )}
    </div>
  )
}

export default AnnouncementsList
