import { DealHelper } from './DealHelper'
import { FORM_DEAL_TYPES, FORM_BROKERAGE_SIDES, REPRESENTATIVE_TYPE } from '../constants'

const getValidationSchema = (type, brokerageSide, activeStep) => {
  switch (type) {
    case FORM_DEAL_TYPES.SALE:
      if (brokerageSide === FORM_BROKERAGE_SIDES.BOTH) {
        switch (activeStep) {
          case 0:
            return DealHelper.validationSchemaStep1()
          case 1:
            return null
          case 2:
            return DealHelper.validationSchemaStep4()
          case 3:
            return DealHelper.validationSchemaStep5()
          default:
            return null
        }
      } else {
        switch (activeStep) {
          case 0:
            return DealHelper.validationSchemaStep1()
          case 1:
            return DealHelper.validationSchemaStep2()
          case 2:
            return null
          case 3:
            return DealHelper.validationSchemaStep4()
          case 4:
            return DealHelper.validationSchemaStep5()
          default:
            return null
        }
      }
    case FORM_DEAL_TYPES.LEASE:
      if (brokerageSide === FORM_BROKERAGE_SIDES.BOTH) {
        switch (activeStep) {
          case 0:
            return DealHelper.validationSchemaStep1()
          case 1:
            return null
          case 2:
            return DealHelper.validationSchemaStep5()
          default:
            return null
        }
      } else {
        switch (activeStep) {
          case 0:
            return DealHelper.validationSchemaStep1()
          case 1:
            return DealHelper.validationSchemaStep2()
          case 2:
            return null
          case 3:
            return DealHelper.validationSchemaStep5()
          default:
            return null
        }
      }
    case FORM_DEAL_TYPES.PRE_CON:
      switch (activeStep) {
        case 0:
          return DealHelper.validationSchemaPreConStep1()
        case 1:
          return null
        case 2:
          return DealHelper.validationSchemaStep4()
        case 3:
          return DealHelper.validationSchemaStep5()
        default:
          return null
      }
    default:
      return null
  }
}

const isListingBrokerage = (brokerageSide, doubleEnded, representativeType) => {
  return (
    brokerageSide === FORM_BROKERAGE_SIDES.LISTING ||
    representativeType === REPRESENTATIVE_TYPE.SELLER ||
    doubleEnded === 'yes'
  )
}

const isCoopBrokerage = (brokerageSide, doubleEnded, representativeType) => {
  return (
    brokerageSide === FORM_BROKERAGE_SIDES.CO_OP ||
    representativeType === REPRESENTATIVE_TYPE.BUYER ||
    doubleEnded === 'yes'
  )
}

export const DealValidationHelper = {
  getValidationSchema,
  isListingBrokerage,
  isCoopBrokerage
}
