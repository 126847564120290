import React, { useState } from 'react'
import { Row, Typography } from 'components'
import styles from './announcements.module.scss'
import { downloadAnnouncementAttachment, generateSignedUrl } from 'helpers/utils'
import vars from '../../vars.scss'
import { IconButton, Tooltip, createTheme, useMediaQuery } from '@mui/material'
import { DownloadForOffline, InsertDriveFile, Visibility } from '@mui/icons-material'
import AttachmentViewer from './AttachmentViewer'
import { usePopup } from 'hooks/usePopup'
import { useParams } from 'react-router-dom'

const AnnouncementAttachments = ({ preview }) => {
  const { id } = useParams()
  const [attachmentPreview, showAttachmentPreview, hideAttachmentPreview] = usePopup()
  const [selectedAttachment, setSelectedAttachment] = useState(null)
  const theme = createTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const nameLength = isMobile ? 10 : 25

  const isViewableAttachment = (attachment) => {
    const viewableTypes = ['pdf', 'png', 'jpg', 'jpeg', 'gif', 'bmp', 'mp4']
    const fileType = attachment?.file_name.split('.').pop()
    return viewableTypes.includes(fileType.toLowerCase())
  }

  const handleAttachmentPreview = async (attachment) => {
    try {
      const signedUrl = await generateSignedUrl(attachment.path)
      setSelectedAttachment({ ...attachment, signedUrl })
    } catch (error) {
      console.error('Error generating signed URL:', error)
    }
    showAttachmentPreview()
  }

  return (
    <>
      {preview?.attachments?.length > 0 && (
        <>
          <Typography.Body type={2} className={styles.created} style={{ marginBottom: 5 }}>
            Attachments
          </Typography.Body>
          <div className={styles.container}>
            {preview?.attachments?.map((attachment, i) => {
              const isViewable = isViewableAttachment(attachment)
              return (
                <div className={id ? styles.attachmentView : styles.attachment} key={i}>
                  <Row className={styles.row}>
                    <Row gap={4} style={{ alignItems: 'center' }}>
                      <InsertDriveFile fontSize="small" sx={{ color: vars['nav-divider-color'] }} />
                      <Tooltip
                        placement="bottom-start"
                        title={attachment?.file_name.length > nameLength ? attachment?.file_name : ''}>
                        <div>
                          {attachment?.file_name.length > nameLength
                            ? `${attachment?.file_name.slice(0, nameLength)}...`
                            : attachment?.file_name}
                        </div>
                      </Tooltip>
                    </Row>
                    <Row gap={2}>
                      {isViewable && (
                        <IconButton
                          onClick={() => handleAttachmentPreview(attachment)}
                          sx={{ color: vars['nav-divider-color'] }}>
                          <Visibility fontSize="small" sx={{ color: vars['nav-divider-color'] }} />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => {
                          downloadAnnouncementAttachment(attachment?.path, attachment.file_name)
                        }}
                        sx={{ color: vars['nav-divider-color'] }}>
                        <DownloadForOffline fontSize="small" />
                      </IconButton>
                    </Row>
                  </Row>
                </div>
              )
            })}
          </div>
        </>
      )}
      <AttachmentViewer open={attachmentPreview} onCancel={hideAttachmentPreview} attachment={selectedAttachment} />
    </>
  )
}

export default AnnouncementAttachments
