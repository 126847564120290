import { createPortal } from 'react-dom'
import { useRef, useEffect } from 'react'
import usePortal from 'hooks/usePortal'
import styles from './modal.module.scss'
import CloseIcon from '@mui/icons-material/Close'
import { Divider, Typography } from 'components'
import IconButton from '@mui/material/IconButton';

const Modal = ({
  id,
  handleClose = () => {},
  open,
  title,
  subtitle,
  step,
  numSteps,
  buttons,
  children,
  maxWidth,
  backdropClickClose = true
}) => {
  const target = usePortal(id, open)
  const nodeRef = useRef(null)

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
    // eslint-disable-next-line
  }, [])

  const handleClick = (e) => {
    if (backdropClickClose && !nodeRef?.current?.contains(e.target)) {
      handleClose()
    }
  }

  if (!open) return null

  return createPortal(
    <div className={styles.modal}>
      <div ref={nodeRef} className={styles.body} style={{ maxWidth }}>
           <IconButton sx={{marginLeft: "auto" }} onClick={handleClose}>
           <CloseIcon className={styles.closeIcon}  />
          </IconButton>
        <div className={styles.title}>
          {step && (
            <Typography.Overline color="secondary" className={styles.subtitle}>
              Step {step} {numSteps ? `of ${numSteps}` : ''}
            </Typography.Overline>
          )}
          <Typography.Heading type="6" className={styles.titleText}>
            {title}
          </Typography.Heading>
          {subtitle && (
            <Typography.Body type="3" color="secondary" className={styles.titleText}>
              {subtitle}
            </Typography.Body>
          )}
        </div>
        <Divider style={{ margin: '16px 0px 0px 0px' }} />
        <div className={styles.content}>{children}</div>
        <Divider style={{ margin: '0px 0px 16px 0px' }} />
        <div className={styles.buttons}>{buttons}</div>
      </div>
    </div>,
    target
  )
}

export default Modal
