import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { LISTING_DOCUMENTS_TYPES, STATUS } from 'helpers/constants'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import ListingFormHeader from '../ListingFormHeader'
import PropertyInfo from '../PropertyInfo'
import ListingDocuments from '../ListingDocuments'
import ListingDetails from './ListingDetails'
import { ListingHelper } from '../../../helpers/listing/ListingHelper'
import { DocumentProvider } from 'context'
import { useNavigate } from 'react-router'
import AgentFooter from 'pages/deals/create_deal/AgentFooter'
import { convertLocalToUTC } from 'helpers/utils'

const formatData = (values, submitted) => {
  let data = values
  const documents = {}
  Object.keys(LISTING_DOCUMENTS_TYPES).forEach((typeKey) => {
    if (data[typeKey]) {
      const { fileName, newFilename } = data[typeKey]
      documents[typeKey] = { fileName, newFilename }
    }
    delete data[typeKey]
  })
  data = {
    ...data,
    documents,
    need_feature_sheet_and_social_media_post: data.need_feature_sheet_and_social_media_post === 'yes',
    need_sale_sign: data.need_sale_sign === 'yes',
    submitted,
    sign_installation_date: convertLocalToUTC(data.sign_installation_date)
  }
  return data
}

const CreateListing = () => {
  const navigate = useNavigate()
  const [listingStatus, setListingStatus] = useState('')
  const [listingType, setListingType] = useState()

  const [createListing, { loading }] = useMutation(CREATE_LISTING, {
    onError: (err) => {
      toast.dismiss()
      console.error(err)
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      navigate('/listings')
      formRef.reset()
      if (listingStatus === STATUS.draft) {
        toast.success('Listing saved as Draft.')
      } else {
        toast.success('Listing created.')
      }
    }
  })

  const onSubmit = async (values) => {
    const isValid = await formRef.trigger()
    if (isValid) {
      setListingStatus(STATUS.submitted)
      const data = formatData(values, true)
      createListing({ variables: { data } })
    }
  }

  const handleSaveDraft = (values) => {
    setListingStatus(STATUS.draft)
    const data = formatData(values, false)
    createListing({ variables: { data } })
  }

  const handleCancel = () => {
    formRef.reset({
      type: ''
    })
    navigate('/listings')
  }

  const validationSchema = useMemo(() => ListingHelper.ListingValidationSchema(), [])

  const formRef = useForm({
    defaultValues: {
      additionalDocuments: [{ fileName: null, key: null, size: null, type: null }],
      checkedDocuments: [],
      listing_all_document: null
    },
    resolver: yupResolver(validationSchema)
  })

  const handleChangeListingType = useCallback((type) => {
    setListingType(type)
  }, [])

  useEffect(() => {
    const isSubmitting = formRef.formState.isSubmitting
    const fetchValid = async () => {
      if (isSubmitting === true) {
        const isValid = await formRef.trigger()
        toast.dismiss()
        if (isValid === false) {
          toast.error('Oops! Something went wrong. Please check the error/validation messages.')
        }
      }
    }
    fetchValid()
  }, [formRef.formState.isSubmitting])

  return (
    <form id="createDeal">
      <DocumentProvider>
        <FormProvider {...formRef}>
          <ListingFormHeader />
          <PropertyInfo onChangeListingType={handleChangeListingType} />
          <ListingDocuments listingType={listingType} />
          <ListingDetails />
          <AgentFooter
            loading={loading}
            dealStatus={listingStatus}
            onCancel={handleCancel}
            onSubmit={formRef.handleSubmit(onSubmit)}
            onSaveDraft={formRef.handleSubmit(handleSaveDraft)}
          />
        </FormProvider>
      </DocumentProvider>
    </form>
  )
}

const CREATE_LISTING = gql`
  mutation createListing($data: CreateListingInput!) {
    createListing(data: $data)
  }
`

export default CreateListing
