import { Input, Row, Typography } from 'components'
import { Grid, IconButton } from '@mui/material'
import styles from './listings.module.scss'
import { Controller, useFormContext } from 'react-hook-form'
import ListingDocumentBase from './ListingDocumentBase'
import AdditionalDocuments from 'components/upload/AdditionalDocuments'
import { STATUS_TYPES, USER_ROLES } from 'helpers/constants'
import { useContext, useState } from 'react'
import { UserContext } from 'context'
import { downloadLibraryZip } from 'helpers/utils'
import vars from '../../vars.scss'
import downloadAllIcon from '../../assets/images/downloadAllIcon.png'
import downloadingGif from '../../assets/images/downloading.gif'
import { gql, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

const ListingDocuments = ({ listing, isView = false, isEdit = false, listingType }) => {
  const { control } = useFormContext()
  const type = listingType ?? listing?.type
  const { user } = useContext(UserContext)
  const isAgent = user.role === USER_ROLES.agent
  const [downloading, setDownloading] = useState(false)
  const isClosedAndAgent = user.role === USER_ROLES.agent && listing?.status === STATUS_TYPES.closed

  const [generatePreSignedUrls] = useMutation(GENERATE_PRESIGNED_URLS, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
      setDownloading(false)
    },
    onCompleted: async (data) => {
      const filesUrl = []
      data?.generatePreSignedUrlsForDealsAndListing?.map((doc) => filesUrl.push(doc))
      await downloadLibraryZip(filesUrl, `${listing?.address}.zip`)
      setDownloading(false)
    }
  })

  const handleDownloadAll = () => {
    setDownloading(true)
    const listingDocUrls = []
    listing?.documents?.map((doc) => listingDocUrls.push(doc.path))
    listing?.additionalDocuments?.map((doc) => listingDocUrls.push(doc.key))
    generatePreSignedUrls({
      variables: {
        data: {
          s3ObjectKeys: listingDocUrls,
          type: 'listings'
        }
      }
    })
  }

  return (
    <>
      <Typography.Heading type={5} className={styles.headerLabel} style={{ marginTop: 20 }}>
        Agreement and Supporting Documents
      </Typography.Heading>
      <Typography.Label type={1} className={styles.documentsRequiredInfo}>
        The following documents are required to process this Listing
      </Typography.Label>
      {isAgent && (isEdit || isView) && (
        <Row style={{ alignItems: 'center' }}>
          {downloading ? (
            <IconButton sx={{ color: vars['doc-font-color'], mr: 1 }} size="large">
              <img src={downloadingGif} alt="downloadAllIcon" height={40} width={40} />
            </IconButton>
          ) : (
            <IconButton onClick={handleDownloadAll} sx={{ color: vars['doc-font-color'], mr: 1 }} size="large">
              <img src={downloadAllIcon} alt="downloadAllIcon" height={40} width={40} />
            </IconButton>
          )}
          <Typography.Body type={2}>Download All</Typography.Body>
        </Row>
      )}
      <Typography.Heading type={6} className={`${styles.headerLabel} `}>
        Attachments
      </Typography.Heading>
      <Grid container columnSpacing={7} mt="20px">
        <Grid xs={12} md={6} item>
          <ListingDocumentBase
            title="All Listing Documents"
            name="listing_all_document"
            required={isAgent}
            disabled={isView}
            listing={listing}
          />

          <Typography.Subheading type={1} className={`${styles.orDivider} `} style={{ marginLeft: '10%' }}>
            OR
          </Typography.Subheading>
          <ListingDocumentBase
            title="Listing Agreement"
            name="listing_agreement"
            required={isAgent}
            listing={listing}
            disabled={isView}
          />
          <ListingDocumentBase
            title="Data Sheet"
            name="listing_data_sheet"
            required={isAgent}
            listing={listing}
            disabled={isView}
          />
          {listing?.documents?.map((doc) => {
            if (doc?.checklist_document_type === 'listing_working_with_realtor') {
              return (
                <ListingDocumentBase
                  title="Working with a Realtor"
                  name="listing_working_with_realtor"
                  required={isAgent}
                  listing={listing}
                  disabled={isView}
                />
              )
            }
          })}
          {type === 'lease_listing' && (
            <ListingDocumentBase
              title="Showing Instructions"
              name="listing_showing_instructions"
              required={isAgent}
              listing={listing}
              disabled={isView}
            />
          )}
          {type === 'sale_listing' && (
            <ListingDocumentBase
              title="Fintrac Form 630  - Individual Identification Information Record"
              name="sale_fintrac_form_630"
              required={isAgent}
              listing={listing}
              disabled={isView}
            />
          )}
          {type === 'sale_listing' && (
            <ListingDocumentBase
              title="Fintrac Form 634 - Politically Exposed Person/Head of International Organization Checklist"
              name="sale_fintrac_form_634"
              required={isAgent}
              listing={listing}
              disabled={isView}
            />
          )}
          <AdditionalDocuments isView={isView} control={control} isClosedAndAgent={isClosedAndAgent} tresa={true} />
        </Grid>
      </Grid>
      <Grid container columnSpacing={7} mt="20px">
        <Grid xs={12} md={6} item>
          <Controller
            name="mls_pictures_link"
            control={control}
            defaultValue={listing?.mls_pictures_link ?? ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type="text"
                error={error?.message}
                value={value}
                onChange={onChange}
                label="Link to MLS Pictures"
                name="mls_pictures_link"
                placeholder="Please add the picture link here..."
                style={{ width: '100%' }}
                disabled={isView || isClosedAndAgent}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  )
}

const GENERATE_PRESIGNED_URLS = gql`
  mutation generatePreSignedUrlsForDealsAndListing($data: PreSignedInput) {
    generatePreSignedUrlsForDealsAndListing(data: $data) {
      fileName
      preSignedUrl
    }
  }
`

export default ListingDocuments
