import { useState, useContext, useRef, useLayoutEffect, useEffect } from 'react'
import { useQuery, gql, useMutation } from '@apollo/client'
import { Typography, Row, WarningPopup, InputSearch, MUIMenuButton, Loading, Button } from 'components'
import { GET_DOWNLOADS_LOGS, GET_LIBRARY, GET_ROOT_FOLDERS } from 'helpers/gql'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { UserContext } from 'context'
import { CONTENT_TYPE, LIBRARY_OPTIONS, LIBRARY_TABS, USER_ROLES } from 'helpers/constants'
import { Grid, IconButton, createTheme, useMediaQuery } from '@mui/material'
import dropdownOption from 'helpers/dropdownOptions'
import CreateFolderPopup from './CreateFolderPopup'
import { usePopup } from 'hooks/usePopup'
import MyFileLogs from './MyFileLogs '
import vars from '../../vars.scss'
import styles from './library.module.scss'
import LibraryList from './LibraryList'
import React from 'react'
import MobileLibraryList from './MobileLibraryList'
import LibraryTabs from './LibraryTabs'
import { ArrowCircleLeft } from '@mui/icons-material'
import { useCallback } from 'react'

const Library = () => {
  const { user } = useContext(UserContext)
  const params = useParams()
  const navigate = useNavigate()
  const isAdmin = user.role === USER_ROLES.super_admin || user.role === USER_ROLES.admin
  const [value, setValue] = useState(0)
  const [selectedTab, setSelectedTab] = useState()
  const [selectedToDelete, setSelectedToDelete] = useState([])
  const [createFolder, showCreateFolder, hideCreateFolder] = usePopup()
  const [confirmDelete, showConfirmDelete, hideConfirmDelete] = usePopup()
  const [showCheckbox, setSetshowCheckbox] = useState()
  const [librarySearch, setLibrarySearch] = useState('')
  const themes = createTheme()
  const isMobile = useMediaQuery(themes.breakpoints.down('sm'))

  const {
    data: { getRootFolders: libraryOptions } = [],
    loading: mainTabLoading,
    refetch: refetchFolders
  } = useQuery(
    GET_ROOT_FOLDERS,
    {
      variables: {
        filters: { prefix: '' }
      }
    },
    {
      onError: (err) => {
        toast.dismiss()
        if (!err?.message?.includes('Session timeout')) {
          toast.error(err.message)
        }
      }
    }
  )

  const { data: { getDownloadLog: downloadLog } = [] } = useQuery(GET_DOWNLOADS_LOGS, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    skip: !isAdmin
  })

  const {
    data: { getLibrary: libraryList } = [],
    loading,
    refetch
  } = useQuery(
    GET_LIBRARY,
    {
      variables: {
        filters: { type: librarySearch, prefix: librarySearch ? '' : params['*'] }
      }
    },
    {
      onError: (err) => {
        toast.dismiss()
        if (!err?.message?.includes('Session timeout')) {
          toast.error(err.message)
        }
      }
    }
  )

  const handleConfirmDelete = () => {
    deleteLibraryItem({ variables: { data: selectedToDelete } })
  }

  useLayoutEffect(() => {
    refetch()
  }, [navigate, refetch])

  useEffect(() => {
    refetch()
  }, [librarySearch, refetch])

  const [deleteLibraryItem, { loading: deleteLoading }] = useMutation(DELETE_LIBRARY_ITEM, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('Deleted successfully.')
      setSelectedToDelete([])
      hideConfirmDelete()
      setTimeout(function () {
        refetchFolders()
        refetch()
      }, 2000)
    },
    refetchQueries: [{ query: GET_DOWNLOADS_LOGS }, { query: GET_ROOT_FOLDERS }]
  })

  const [uploadLibraryFile, { loading: uploadFileLoading }] = useMutation(UPLOAD_LIBRARY_FILE, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('File uploaded.')
      setTimeout(function () {
        refetch()
      }, 1000)
    },
    refetchQueries: [{ query: GET_DOWNLOADS_LOGS }],
    awaitRefetchQueries: true
  })

  const hiddenFileInput = useRef(null)

  const handleUploadFile = () => {
    hiddenFileInput.current.click()
  }

  const handleFileChange = (event) => {
    uploadLibraryFile({ variables: { prefix: params['*'], files: event.target.files } })
    const fileInput = document.querySelector('input[type="file"]')
    fileInput.value = null
  }

  const handleTabChange = (option) => {
    setSelectedToDelete([])
    setSelectedTab(option)
    navigate('/library/' + option + '/')
  }

  const handleInnerTabChange = (option, e) => {
    setLibrarySearch(undefined)
    if (option.type === CONTENT_TYPE.FOLDER) {
      setSetshowCheckbox(false)
      navigate('/library/' + option.key)
    }
  }

  const handleChange = (event, newValue) => {
    setSetshowCheckbox(false)
    setLibrarySearch(undefined)
    setValue(newValue)
  }

  const handleMyDownloads = async () => {
    setSelectedTab(LIBRARY_TABS.DOWNLOADS)
    navigate('/library/Downloads')
  }

  const handleAllFiles = async () => {
    navigate('/library')
    setSelectedTab(LIBRARY_TABS.ALL_FILES)
  }

  const handleMyFiles = async () => {
    setSelectedTab(LIBRARY_TABS.MY_FILES)
    navigate('/library/my_files')
  }

  const handleAddNewButton = (value) => {
    if (showCheckbox) {
      setSetshowCheckbox(false)
      setSelectedToDelete([])
    }
    if (value === LIBRARY_OPTIONS.CREATE_FOLDER) {
      showCreateFolder()
    } else {
      handleUploadFile()
    }
  }

  const handleBack = useCallback(async () => {
    const splitedPath = params['*']?.split('/')
    if (splitedPath?.length === 2) {
      navigate('/library/')
      setValue(0)
    } else {
      splitedPath?.splice(-2, 1)
      const path = splitedPath?.join('/')
      navigate('/library/' + path)
    }
    await refetch()
  }, [navigate, params, refetch])

  const handleBulkDelete = (option) => {
    const existingObjectIndex = selectedToDelete?.findIndex((doc) => doc.key === option.key)
    if (existingObjectIndex !== -1) {
      const filter = selectedToDelete.filter((val) => val.key !== option.key)
      setSelectedToDelete(filter)
    } else {
      setSelectedToDelete([...selectedToDelete, option])
    }
  }

  const handleCancel = () => {
    setSelectedToDelete([])
    hideConfirmDelete()
  }

  return (
    <>
      <Helmet title="Library | RARE" />
      <Grid container className={styles.headerContent}>
        <Grid item xs={12} sm={6} md={2.5}>
          <InputSearch name="search" value={librarySearch} onChange={(e) => setLibrarySearch(e.target.value)} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={9.5}
          sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' }, mt: { xs: 2, sm: 0, md: 0 } }}>
          {selectedTab !== LIBRARY_TABS.MY_FILES && (
            <Row gap={20}>
              {(isAdmin || selectedTab === LIBRARY_TABS.DOWNLOADS) && (
                <Button
                  style={{ width: 120 }}
                  disabled={selectedToDelete?.length > 0 ? false : true}
                  onClick={showConfirmDelete}>
                  Delete
                </Button>
              )}
              {isAdmin && selectedTab !== LIBRARY_TABS.DOWNLOADS && (
                <MUIMenuButton
                  loading={uploadFileLoading}
                  onClick={handleAddNewButton}
                  style={{ width: 140 }}
                  width={140}
                  label={'Add New'}
                  options={dropdownOption.libraryOptions}
                />
              )}
              <input
                type="file"
                multiple
                ref={hiddenFileInput}
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
            </Row>
          )}
        </Grid>
      </Grid>
      <Grid container>
        {isMobile && (
          <Grid item xs={1}>
            <MobileLibraryList
              libraryOptions={libraryOptions}
              onAllFiles={handleAllFiles}
              onMyFiles={handleMyFiles}
              onMyDownloads={handleMyDownloads}
              onTabChange={handleTabChange}
            />
          </Grid>
        )}
        <Grid item xs={1} md={2.5}>
          <Row style={{ alignItems: 'center' }}>
            <IconButton onClick={handleBack} disabled={!params['*']}>
              <ArrowCircleLeft />
            </IconButton>
            Back
          </Row>
        </Grid>
      </Grid>
      <Grid container columns={12} spacing={2}>
        {!isMobile && (
          <Grid item xs={4} md={2.5} pt={0}>
            <LibraryTabs
              isAdmin={isAdmin}
              value={value}
              isMobile={isMobile}
              libraryOptions={libraryOptions}
              onAllFiles={handleAllFiles}
              onMyFiles={handleMyFiles}
              onMyDownloads={handleMyDownloads}
              onTabChange={handleTabChange}
              onChange={handleChange}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={8} md={9.5}>
          <div className={styles.content}>
            {loading || mainTabLoading ? (
              <Loading fullpage />
            ) : (
              <div style={{ padding: 16 }} className={selectedTab !== LIBRARY_TABS.MY_FILES ? styles.contentList : ''}>
                {selectedTab === LIBRARY_TABS.MY_FILES ? (
                  <MyFileLogs downloadLog={downloadLog} />
                ) : libraryList?.length > 0 ? (
                  libraryList?.map((option, index) => {
                    return (
                      <div key={index} style={{ width: isMobile ? 100 : 170 }}>
                        <LibraryList
                          isAdmin={isAdmin}
                          option={option}
                          selectedTab={selectedTab}
                          handleInnerTabChange={handleInnerTabChange}
                          selectedToDelete={selectedToDelete}
                          handleBulkDelete={handleBulkDelete}
                          showCheckbox={showCheckbox}
                          setSetshowCheckbox={setSetshowCheckbox}
                          setSelectedToDelete={setSelectedToDelete}
                          showConfirmDelete={showConfirmDelete}
                          refetch={refetch}
                        />
                      </div>
                    )
                  })
                ) : (
                  <div className={styles.emptyFolder}>
                    <Typography.Body style={{ color: vars['table-cell-color'] }} type={1}>
                      This folder is empty
                    </Typography.Body>
                  </div>
                )}
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      <WarningPopup
        openModel={confirmDelete}
        loading={deleteLoading}
        confirmBtnLabel={'Delete'}
        onCancle={handleCancel}
        onConfirm={handleConfirmDelete}>
        <Typography.Body type="2">
          {selectedToDelete?.length === 1
            ? selectedToDelete[0]?.type === 'File'
              ? 'Are you sure you want to delete this file?'
              : 'Are you sure you want to delete this folder and all its contents?'
            : 'Are you sure you want to delete these file(s)/folder(s) and all its contents?'}
        </Typography.Body>
      </WarningPopup>
      <CreateFolderPopup createFolder={createFolder} hideCreateFolder={hideCreateFolder} refetch={refetch} />
    </>
  )
}

export default Library

const DELETE_LIBRARY_ITEM = gql`
  mutation deleteLibraryItem($data: [LibraryFileInput!]!) {
    deleteLibraryItem(data: $data)
  }
`

const UPLOAD_LIBRARY_FILE = gql`
  mutation uploadLibraryFile($prefix: String!, $files: [Upload!]!) {
    uploadLibraryFile(prefix: $prefix, files: $files)
  }
`
