import styles from './loading.module.scss'
import vars from '../../vars.scss'
import { Typography } from 'components'

const Loading = ({ size = 30, fullpage, type = 'primary', text }) => {
  return (
    <>
      <div className={`${styles.loading} ${fullpage ? styles.fullpage : ''}`}>
        <div
          className={styles.spinner}
          style={{
            height: size,
            width: size,
            border: `2px solid ${
              type === 'primary' ? vars['loading-outer-color'] : vars['loading-outer-color-secondary']
            }`,
            borderTop: `2px solid ${
              type === 'primary' ? vars['loading-inner-color'] : vars['loading-inner-color-secondary']
            }`
          }}
        />
        {text && (
          <Typography.Heading style={{ marginTop: 20, fontWeight: 500 }} type={6}>
            {text}
          </Typography.Heading>
        )}
      </div>
    </>
  )
}

export default Loading
