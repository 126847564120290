import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Grid, Tab, Tabs, ThemeProvider, createTheme } from '@mui/material'
import { Button, Typography, WarningPopup, Loading } from 'components'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import dropdownOption from 'helpers/dropdownOptions'
import { usePopup } from 'hooks/usePopup'
import AnnouncementPreview from './AnnouncementPreview'
import styles from './announcements.module.scss'
import { GET_ANNOUNCEMENTS } from 'helpers/gql'
import AnnouncementsList from './AnnouncementsList'
import { ANNONCEMENT_TYPES, USER_ROLES } from 'helpers/constants'
import { UserContext } from 'context'
import AnnouncementActions from './AnnouncementActions'
import { Helmet } from 'react-helmet-async'
import { DELETE_ANNOUNCEMENT } from 'helpers/mutations'
import debounce from 'lodash.debounce'

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 18,
          fontWeight: 400,
          alignItems: 'baseline',
          '&.Mui-selected': {
            color: '#C12121 !important',
            fontSize: 18,
            fontWeight: 600
          }
        }
      }
    }
  }
})

const Announcements = () => {
  const navigate = useNavigate()
  const { user } = useContext(UserContext)
  const [confirmDelete, showConfirmDelete, hideConfirmDelete] = usePopup()
  const [announcements, setAnnouncements] = useState([])
  const [selectedTab, setSelectedTab] = useState(ANNONCEMENT_TYPES.INBOX)
  const [value, setValue] = useState(0)
  const [preview, setPreview] = useState()
  const [selectedToDelete, setSelectedToDelete] = useState([])
  const [searchText, setSearchText] = useState(null)
  const [page, setPage] = useState(10)
  const isAdmin = user.role === USER_ROLES.super_admin || user.role === USER_ROLES.admin

  const {
    data: { getAnnouncements: data } = [],
    loading,
    refetch
  } = useQuery(GET_ANNOUNCEMENTS, {
    variables: {
      filters: { status: selectedTab, perPage: page, searchString: searchText }
    },
    onError: (err) => {
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: (data) => {
      setAnnouncements(data?.getAnnouncements.data)
      if (preview?.public_id) {
        setPreview(data?.getAnnouncements.data?.find((ann) => ann.public_id === preview.public_id))
      } else {
        setPreview(data?.getAnnouncements.data[0])
      }
    }
  })

  const [deleteAnnouncement, { loading: deleteLoading }] = useMutation(DELETE_ANNOUNCEMENT, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('Announcement(s) deleted.')
      setSelectedToDelete([])
      setPreview(null)
      refetch()
    }
  })

  const handleTabChange = async (option) => {
    await setPage(10)
    setSelectedToDelete([])
    await setSelectedTab(option.value)
    setSearchText(null)
    refetch()
  }

  const handleChange = (event, newValue) => {
    setPage(10)
    setPreview(null)
    setValue(newValue)
  }

  const handlePreviewChange = useCallback((value) => {
    setPreview(value)
  }, [])

  const handleBulkDelete = (e) => {
    const id = e.target.id
    if (selectedToDelete.includes(id)) {
      const filteredVslue = selectedToDelete.filter((annId) => annId !== id)
      setSelectedToDelete(filteredVslue)
    } else {
      setSelectedToDelete([...selectedToDelete, id])
    }
  }

  useEffect(() => {
    refetch()
  }, [refetch, searchText])

  const handleDelete = async () => {
    await deleteAnnouncement({
      variables: { id: selectedToDelete.length > 0 ? selectedToDelete : [preview.public_id] }
    })
    hideConfirmDelete()
    setSelectedToDelete([])
  }

  const tabOptions = useMemo(() => {
    return user.role === USER_ROLES.admin || user.role === USER_ROLES.super_admin
      ? dropdownOption.ANNOUNCEMENT_OPTIONS
      : dropdownOption.ANNOUNCEMENT_OPTIONS.filter(
          (opt) => opt.value !== ANNONCEMENT_TYPES.DRAFT && opt.value !== ANNONCEMENT_TYPES.SCHEDULED
        )
  }, [user.role])

  const handleShowMore = useCallback(async () => {
    await setPage(page + 10)
    refetch()
  }, [page, refetch])

  const handleSearch = useCallback(
    debounce((e) => {
      const text = e.target.value?.trimStart()
      if (text?.length > 2) {
        setSearchText(text)
      } else {
        setSearchText(null)
      }
    }, 300),
    []
  )
  return (
    <>
      <Helmet title="Announcements | RARE" />
      {preview && (
        <AnnouncementActions
          isAdmin={isAdmin}
          refetch={refetch}
          preview={preview}
          onConfirmDelete={showConfirmDelete}
        />
      )}
      <Grid container columns={12} mt={!preview ? 5 : 0}>
        <Grid item xs={1.5}>
          {isAdmin && (
            <Button className={styles.compose} onClick={() => navigate('/announcements/create')}>
              Compose
            </Button>
          )}
          <ThemeProvider theme={theme}>
            <Tabs
              value={value}
              onChange={handleChange}
              orientation="vertical"
              variant="scrollable"
              TabIndicatorProps={{
                style: { display: 'none' }
              }}
              style={{ alignSelf: 'left', marginBottom: '10px' }}>
              {tabOptions.map((option, index) => {
                return (
                  <Tab
                    label={
                      <div style={{ display: 'flex' }}>
                        {option.label}
                        <span style={{ marginLeft: 10 }}>
                          {loading && option.value === selectedTab && <Loading key={index} size={20} />}
                        </span>
                      </div>
                    }
                    key={index}
                    sx={{ textTransform: 'none' }}
                    onClick={() => handleTabChange(option)}
                  />
                )
              })}
            </Tabs>
          </ThemeProvider>
        </Grid>
        <>
          <Grid item xs={3} className={styles.announcementList}>
            <AnnouncementsList
              isAdmin={isAdmin}
              data={data}
              loading={loading}
              refetch={refetch}
              preview={preview}
              searchText={searchText}
              announcements={announcements}
              selectedToDelete={selectedToDelete}
              onPreviewChange={handlePreviewChange}
              handleBulkDelete={handleBulkDelete}
              onShowMore={handleShowMore}
              onSearch={handleSearch}
            />
          </Grid>
          <Grid item xs={7.5} className={styles.announcementPreview}>
            {preview && <AnnouncementPreview isAdmin={isAdmin} preview={preview} />}
          </Grid>
        </>
      </Grid>
      <WarningPopup
        openModel={confirmDelete}
        loading={deleteLoading}
        confirmBtnLabel="Delete"
        onCancle={hideConfirmDelete}
        onConfirm={handleDelete}>
        <Typography.Body type={1}>Do you really want to delete this Annoncement(s) ?</Typography.Body>
        {selectedToDelete.length > 0 && (
          <Typography.Body type={1} style={{ textAlign: 'center' }}>
            <b>{selectedToDelete.length}</b> Announcement(s) selected.
          </Typography.Body>
        )}
      </WarningPopup>
    </>
  )
}

export default Announcements
