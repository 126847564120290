import { Col, DateTimePicker, PopUp } from 'components'
import { ANNONCEMENT_TYPES } from 'helpers/constants'
import React from 'react'
import { Controller } from 'react-hook-form'
import styles from './announcements.module.scss'
import moment from 'moment'

const SchedulePostPopup = (props) => {
  const { schedule, control, hideSchedule, onSchedulePost, loading, loadingSection } = props

  return (
    <PopUp
      title={'Schedule Post'}
      loading={loading && loadingSection === ANNONCEMENT_TYPES.SCHEDULED}
      open={schedule}
      confirmBtnLabel={'Schedule'}
      cancelBtnLabel={'Cancel'}
      onCancel={hideSchedule}
      onConfirm={onSchedulePost}>
      <Col gap={20} className={styles.schedule}>
        <Controller
          name={'start_date'}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <DateTimePicker
              name={'start_date'}
              type={1}
              value={value}
              label={'Start Date'}
              error={error?.message}
              minDateTime={moment()}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name={'end_date'}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <DateTimePicker
              name={'end_date'}
              type={1}
              value={value}
              label={'End Date'}
              error={error?.message}
              style={{ marginTop: 10 }}
              minDateTime={moment()}
              onChange={onChange}
            />
          )}
        />
      </Col>
    </PopUp>
  )
}

export default SchedulePostPopup
