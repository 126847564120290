import Typography from 'components/typography/Typography'

const DashboardChart = () => {
  return (
    <div
      style={{
        background: 'white',
        border: '1px solid grey',
        display: 'flex',
        height: 260,
        width: 510
      }}>
      <div style={{ margin: 'auto' }}>
        <Typography.Heading type={2}>Chart 📈</Typography.Heading>
      </div>
    </div>
  )
}

export default DashboardChart
