import React from 'react'
import { BasicUpload } from 'components'

const ListingAdditionalDocumentsInDeal = ({
  isView = false,
  isAdminSection = false,
  isClosedAndAgent = false,
  tresa = false,
  additionalDoc,
}) => {

  return (
    <>
      {additionalDoc?.map((value, index) => (
            <BasicUpload
              title={tresa ? `Additional Documents/TRESA Documents ${index + 1}` : `Additional Document ${index + 1}`}
              defaultValue={value}
              index={index}
              disabled={isView}
              canShowAdditionalDoc
              isAllDocument={isAdminSection}
              isClosedAndAgent={isClosedAndAgent}
            />
      ))}
    </>
  )
}

export default ListingAdditionalDocumentsInDeal
