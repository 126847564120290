import { useQuery } from '@apollo/client'
import { Grid } from '@mui/material'
import { Loading } from 'components'
import { DocumentProvider } from 'context'
import { GET_LISTING } from 'helpers/gql'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ViewListingForm from './ViewListingForm'

const ViewListing = () => {
  const params = useParams()
  const { id } = params

  const { data: { getListing: listing } = [], loading } = useQuery(GET_LISTING, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    variables: {
      id
    }
  })

  return (
    <>
      <Helmet title="Listing | RARE" />
      <Grid>
        <section id="listing">
          {loading || !listing ? (
            <Loading fullpage />
          ) : (
            <DocumentProvider>
              <ViewListingForm listing={listing} />
            </DocumentProvider>
          )}
        </section>
      </Grid>
    </>
  )
}

export default ViewListing
