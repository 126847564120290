import React, { useCallback, useMemo, useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import { components } from 'react-select'
import { Grid, IconButton } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import { Button, Input, Row, Typography, WarningPopup } from 'components'
import { usePopup } from 'hooks/usePopup'
import { toast } from 'react-toastify'
import { Get_Announcement_Senders } from 'helpers/gql'
import { useMutation, useQuery } from '@apollo/client'
import { CREATE_ANNOUNCEMENT_SENDER, DELETE_ANNOUNCEMENT_SENDER, UPDATE_ANNOUNCEMENT_SENDER } from 'helpers/mutations'
import styles from '../../components/select/select.module.scss'
import vars from '../../vars.scss'

const AnnouncementSender = ({ onChange, value, error }) => {
  const [editedText, setEditedText] = useState()
  const [edit, showEdit, hideEdit] = usePopup()
  const [confirmDelete, showConfirmDelete, hideConfirmDelete] = usePopup()
  const [senderId, setSenderId] = useState()
  const [isDisable, setIsDisable] = useState()
  const [isCreate, setIsCreate] = useState()

  const {
    data: { getAnnouncementSenders: senders } = [],
    loading,
    refetch
  } = useQuery(Get_Announcement_Senders, {
    onError: (err) => {
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    }
  })

  const [createAnnouncementSender] = useMutation(CREATE_ANNOUNCEMENT_SENDER, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('Announcement(s) Sender created.')
      setTimeout(function () {
        refetch()
      }, 1000)
    },
    refetchQueries: [{ query: Get_Announcement_Senders }]
  })

  const [deleteAnnouncementSender, { loading: deleteLoading }] = useMutation(DELETE_ANNOUNCEMENT_SENDER, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('Announcement(s) Sender deleted.')
      setTimeout(function () {
        refetch()
      }, 1000)
    },
    refetchQueries: [{ query: Get_Announcement_Senders }]
  })

  const [updateAnnouncementSender, { loading: updateLoading }] = useMutation(UPDATE_ANNOUNCEMENT_SENDER, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('Announcement(s) Sender updated.')
      setTimeout(function () {
        refetch()
      }, 1000)
    },
    refetchQueries: [{ query: Get_Announcement_Senders }]
  })

  const dropdownOptions = useMemo(() => {
    const options = []
    senders?.map((sender) => options.push({ label: sender?.sender, value: sender?.id }))
    return options
  }, [senders])

  const selectedOption = useMemo(() => {
    const option = dropdownOptions?.find((val) => val.value === value?.value)
    return option ? option : value
  }, [dropdownOptions, value])

  const handleCreate = useCallback(
    async (label) => {
      await createAnnouncementSender({
        variables: {
          data: { sender: label }
        }
      })
    },
    [createAnnouncementSender]
  )

  const handleInput = useCallback((e) => {
    setIsCreate(true)
    if (e === '') {
      setIsCreate(false)
    }
  }, [])

  const handleEdit = useCallback(async () => {
    await updateAnnouncementSender({
      variables: {
        data: {
          id: senderId,
          sender: editedText
        }
      }
    })
    hideEdit()
    setEditedText(null)
    setIsDisable(false)
  }, [editedText, hideEdit, senderId, updateAnnouncementSender])

  const handleDelete = useCallback(async () => {
    await deleteAnnouncementSender({
      variables: { id: senderId }
    })
    setEditedText(null)
    hideConfirmDelete()
    onChange(null)
  }, [deleteAnnouncementSender, hideConfirmDelete, onChange, senderId])

  const handleEditClick = useCallback(
    (value) => {
      setSenderId(value.value)
      setEditedText(value.label)
      showEdit()
      setIsDisable(true)
    },
    [showEdit]
  )

  const handleCancel = useCallback(() => {
    hideEdit()
    setIsDisable(false)
    onChange(null)
  }, [hideEdit, onChange])

  const handleDeleteClick = useCallback(
    (value) => {
      setSenderId(value.value)
      setEditedText(value.label)
      showConfirmDelete()
    },
    [showConfirmDelete]
  )

  const Option = (props) => {
    const iconColor = props.data?.value === value?.value ? 'white' : 'black'
    return (
      <>
        <components.Option {...props}>
          <Row justifyContent="space-between">
            <div style={{ width: '85%' }}>{props.children}</div>
            {!isCreate && (
              <div style={{ width: '15%' }}>
                <IconButton onClick={() => handleEditClick(props.data)}>
                  <Edit fontSize="small" style={{ color: iconColor }} />
                </IconButton>
                <IconButton onClick={() => handleDeleteClick(props.data)}>
                  <Delete fontSize="small" style={{ color: iconColor }} />
                </IconButton>
              </div>
            )}
          </Row>
        </components.Option>
      </>
    )
  }

  return (
    <Grid container columnSpacing={6}>
      <Grid item xs={12} md={6} style={{ marginTop: 14, zIndex: 100 }}>
        <CreatableSelect
          isClearable
          value={selectedOption}
          options={dropdownOptions}
          placeholder="Sender Name with designation"
          isDisabled={isDisable}
          components={{ Option }}
          isLoading={loading || deleteLoading || updateLoading}
          onChange={onChange}
          onCreateOption={handleCreate}
          onInputChange={(e) => handleInput(e)}
          classNamePrefix={'customSelectPlaceholder'}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: vars['select-hover-color'],
              primary: vars['select-primary-color']
            }
          })}
        />
        {error && (
          <Typography.Body type="3" color="error" className={styles.error}>
            {error?.message}
          </Typography.Body>
        )}
      </Grid>
      {edit && (
        <Grid item xs={12} md={6}>
          <Grid container columnSpacing={3}>
            <Grid item xs={8} mt={'10px'}>
              <Input
                name="title"
                type="text"
                value={editedText}
                placeholder="Enter Title"
                onChange={(e) => setEditedText(e.target.value)}
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={4} style={{ marginTop: 14 }}>
              <Grid container columnSpacing={3}>
                <Grid item>
                  <Button onClick={handleEdit}>Save</Button>
                </Grid>
                <Grid item>
                  <Button onClick={handleCancel}>Cancel</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <WarningPopup
        openModel={confirmDelete}
        confirmBtnLabel={'Delete'}
        onCancle={hideConfirmDelete}
        onConfirm={handleDelete}>
        <Typography.Body type={1}>Do you really want to delete this sender?</Typography.Body>
      </WarningPopup>
    </Grid>
  )
}

export default AnnouncementSender
