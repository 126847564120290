import React, { useState, useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { Card } from '@mui/material'
import { Typography, Loading, BasicTable, Row, Col, Button, Avatar, InputSearch, Tag } from 'components'
import search from 'helpers/search'
import { GET_ACTIVITY_LOG } from 'helpers/gql'
import { convertUTCToLocal, getFileUrl } from 'helpers/utils'
import ActivityLogFilter from './ActivityLogFilter'
import styles from './Activitylog.module.scss'
import vars from '../../vars.scss'

const ActivityLog = () => {
  const [filters, setFilters] = useState([])
  const [selectedActivityType, setSelectedActivityType] = useState([])
  const [rows, setRows] = useState([])
  const [filteredRows, setFilteredRows] = useState([])

  const { data: { getActivityLogs: activitylogs } = [], loading } = useQuery(GET_ACTIVITY_LOG, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data?.getActivityLogs) {
        setRows(data.getActivityLogs)
        let filterData = []
        data.getActivityLogs.map((value, index) => {
          if (!filterData.includes(value.activity)) {
            filterData.push(value.activity)
            filters.push({ id: index, text: value.activity })
          }
        })
        setFilters([...filters])
      }
    }
  })

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        display: false
      }
    },
    {
      name: 'public_id',
      label: '',
      options: {
        display: false
      }
    },
    {
      name: 'creator_id',
      label: '',
      options: {
        display: false
      }
    },
    {
      name: 'user',
      label: 'User',
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div className={styles.agentColumn}>
              {value && (
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar name={value.name} src={getFileUrl(value.thumbnail)} />
                    <div style={{ marginLeft: '10px' }}>
                      <Typography.Body className={styles.agentName}>{value.name}</Typography.Body>
                      <Typography.Body style={{ color: vars['loading-inner-color-secondary'] }}>
                        <Tag text={`Rare Agent #${value.id}`} />
                      </Typography.Body>
                    </div>
                  </div>
                </>
              )}
            </div>
          )
        }
      }
    },
    {
      name: 'activity_time',
      label: 'Date & Time',
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography.Body style={{ color: vars['table-cell-color'] }}>
              {convertUTCToLocal(value, 'MMM DD, yyyy hh:mm A')}
            </Typography.Body>
          )
        }
      }
    },
    {
      name: 'activity',
      label: 'Action',
      options: {
        sort: false,
        customBodyRender: (value) => {
          return <Typography.Body style={{ color: vars['table-cell-color'] }}>{value}</Typography.Body>
        }
      }
    },
    {
      name: 'description',
      label: 'Activity on',
      options: {
        sort: false,
        customBodyRender: (value) => {
          return <Typography.Body style={{ color: vars['table-cell-color'] }}>{value}</Typography.Body>
        }
      }
    }
  ]
  const handleSearch = useCallback(
    (searchedVal) => {
      const filteredRows = search.ActivityLogTableFilterRowsBySearch(activitylogs, searchedVal)
      setRows(filteredRows)
    },
    [activitylogs]
  )

  const onFilterData = (arr) => {
    let filteredRowsData = []
    arr.map((data) => {
      const filteredData = search.ActivityLogTableFilterRowsBySearch(activitylogs, data)
      filteredRowsData.push(...filteredData)
    })
    setFilteredRows(filteredRowsData)
  }

  const onFilterHandler = (value) => {
    if (!selectedActivityType.includes(value)) {
      let data = [...selectedActivityType, value]
      setSelectedActivityType(data)
      setFilteredRows([])
      onFilterData(data)
    } else {
      let data = selectedActivityType.filter((data) => data !== value)
      setSelectedActivityType(data)
      setFilteredRows([])
      onFilterData(data)
    }
  }

  const removeFilter = useCallback(
    (value) => {
      let data = selectedActivityType.filter((data) => data !== value)
      setSelectedActivityType(data)
      setFilteredRows([])
      onFilterData(data)
    },
    [selectedActivityType]
  )

  return (
    <Col gap={24}>
      <Typography.Heading type="5">Activity Log</Typography.Heading>

      {loading ? (
        <Loading fullpage />
      ) : rows.length === 0 ? (
        <div>No data available</div>
      ) : (
        <div className={styles.activitylogTableMain}>
          <section id="activitylog">
            <Row
              alignItems="center"
              marginBottom={selectedActivityType.length > 0 ? '0px' : '10px'}
              justifyContent="space-between"
              className={styles.activitylogDivMain}>
              <Col className={styles.activitylogInnerDiv}>
                <InputSearch
                  name="search"
                  onChange={(e) => {
                    handleSearch(e.target.value.trimStart())
                  }}
                />
              </Col>
              <Col>
                <ActivityLogFilter
                  label={'Filter'}
                  options={filters}
                  selectedActivityType={selectedActivityType}
                  onFilterHandler={onFilterHandler}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '40px' }}>
              <Col>
                <div style={{ display: 'flex', marginTop: '7px' }}>
                  <Card
                    style={{
                      display: 'inline-flex',
                      borderRadius: '16px',
                      paddingLeft: '5px',
                      paddingRight: '10px'
                    }}>
                    {selectedActivityType.length > 0 && (
                      <Typography.Body
                        style={{
                          color: vars['table-cell-color'],
                          marginTop: '7px',
                          padding: '4px 8px'
                        }}>
                        Filter:
                      </Typography.Body>
                    )}
                    {selectedActivityType.map((data, index) => {
                      return (
                        <div style={{ display: 'inline-flex' }} key={index}>
                          <Typography.Body
                            style={{
                              color: vars['table-cell-color'],
                              marginTop: '7px',
                              padding: '4px 5px',
                              borderRadius: '16px'
                            }}>
                            {`${data}`}
                          </Typography.Body>
                          <Button
                            onClick={() => removeFilter(data)}
                            style={{
                              color: 'black',
                              backgroundColor: 'white',
                              border: 'none',
                              fontSize: '18px',
                              padding: '5px',
                              marginTop: '3px',
                              fontWeight: '600'
                            }}>
                            x
                            {index !== selectedActivityType.length - 1 && (
                              <div style={{ marginLeft: '5px', color: '#cfcfcf' }}>|</div>
                            )}
                          </Button>
                        </div>
                      )
                    })}
                  </Card>
                </div>
              </Col>
            </Row>
            {loading ? (
              <Loading fullpage />
            ) : (
              <BasicTable tableData={filteredRows.length > 0 ? filteredRows : rows} tableColumns={columns} />
            )}
          </section>
        </div>
      )}
    </Col>
  )
}

export default ActivityLog
