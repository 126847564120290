import Typography from 'components/typography/Typography'
import styles from './tag.module.scss'

const Tag = ({ type = 3, text, icon = null, background = 'rgba(0, 0, 0, 0.1)', style = {}, className = '' }) => {
  const tagSyle = {
    ...style,
    background: background
  }

  return (
    <div className={`${styles.tag} ${className}`} style={tagSyle}>
      {icon}
      <Typography.Body type={type}>{text}</Typography.Body>
    </div>
  )
}

export default Tag
