import { useQuery, gql } from '@apollo/client'
import { Loading } from 'components'
import { Helmet } from 'react-helmet-async'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from 'context'
import { toast } from 'react-toastify'
import RevenueShare from './RevShareHeader'
import { CardHeader } from '../home/CardHeader'
import { Grid } from '@mui/material'
import RevshareProgress from './RevshareProgress'
import moment from 'moment'

const RevShare = () => {
  const [selectedYear, setSelectedYear] = useState(moment().year())
  const [yearOptions, setYearOptions] = useState([])

  const {
    data: { getRevshareAgent: revShareAgent } = {},
    loading,
    refetch: getRevshareAgent
  } = useQuery(GET_REV_SHARE_AGENT, {
    variables: { year: selectedYear },
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    }
  })

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value)
  }

  useEffect(() => {
    const fetchYears = async () => {
      try {
        const currentYear = new Date().getFullYear()
        const startingYear = 2023
        const lastYears = Array.from({ length: currentYear - startingYear + 1 }, (_, index) => currentYear - index)
        setYearOptions(lastYears)
      } catch (error) {
        console.error('Error fetching years:', error)
      }
    }

    fetchYears()
  }, [])

  useEffect(() => {
    getRevshareAgent()
  }, [selectedYear, getRevshareAgent])

  const yearFilter = () => {
    return (
      <>
        <CardHeader
          showYearDropdown
          selectedYear={selectedYear}
          handleYearChange={handleYearChange}
          yearOptions={yearOptions}
        />
      </>
    )
  }

  return (
    <>
      <Helmet title="Revenue Share | RARE" />
      {loading || !revShareAgent ? (
        <Loading size={40} fullpage text={'Calculating Revenue Share'} />
      ) : (
        <section id="rev-share">
          <Grid container>
            <Grid item xs={12} lg={10} sm={12} md={12} xl={8}>
              <RevenueShare revShareAgent={revShareAgent} yearFilter={yearFilter()} />
              <RevshareProgress data={revShareAgent} />
            </Grid>
          </Grid>
        </section>
      )}
    </>
  )
}

export default RevShare

const GET_REV_SHARE_AGENT = gql`
  query getRevshareAgent($year: Int) {
    getRevshareAgent(year: $year)
  }
`
