import React, { useEffect, useMemo } from 'react'
import { gql, useMutation } from '@apollo/client'
import { GET_DEAL, GET_DEALS } from 'helpers/gql'
import { FormProvider, useForm } from 'react-hook-form'
import Commission from '../create_deal/Commission'
import { toast } from 'react-toastify'
import { DealContext, DocumentContext, UserContext } from 'context'
import { useContext } from 'react'
import DealFormHeader from './../create_deal/DealFormHeader'
import DealAdmin from '../DealAdmin'
import DealType from '../create_deal/DealType'
import OutsideBrokerage from '../create_deal/OutsideBrokerage'
import LawyerInfo from '../create_deal/LawyerInfo'
import DealDocuments from '../create_deal/DealDocuments'
import { useNavigate, useParams } from 'react-router-dom'
import { DEAL_DOCUMENTS_TYPES, FORM_DEAL_TYPES, STATUS_TYPES } from 'helpers/constants'
import DealFooter from '../DealFooter'
import { DealHelper } from './../../../helpers/deal/DealHelper'
import { yupResolver } from '@hookform/resolvers/yup'
import DealComments from '../DealComments'
import styles from '../deals.module.scss'
import { convertLocalToUTC, convertUTCToLocal } from 'helpers/utils'
import { useState } from 'react'
import ListingDocumentsInDeal from '../ListingDocumentsInDeal'
import { Row, Typography } from 'components'


const EditDealForm = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { id } = params
  const { deletedDocuments } = useContext(DocumentContext)
  const { deal } = useContext(DealContext)
  const { user } = useContext(UserContext)
  const isAdmin = ['admin', 'super_admin'].includes(user.role)
  const [dealStatus, setDealStatus] = useState()
  const [primaryAgent, setPrimaryAgent] = useState()
  const [secondaryAgent, setSecondaryAgent] = useState()
  const [price, setPrice] = useState()
  const [secondaryCommission, setSecondaryCommission] = useState()
  const [agentCommissions, setAgentCommissions] = useState([])
  const [totalCommissions, setTotalCommissions] = useState([])
  const [agent, setAgents] = useState([deal.agent])
  const [priceFlag, setPriceFlag] = useState(false)

  const validationSchema = useMemo(() => {
    if (isAdmin) {
      return DealHelper.editDealValidationSchema(deal?.type, deal?.brokerage_side, deal?.status)
    } else {
      return DealHelper.validationSchemaAgentDeal()
    }
  }, [deal?.brokerage_side, deal?.type, deal?.status, isAdmin])

  const formRef = useForm({
    defaultValues: {
      type: deal?.type,
      brokerage_side: deal?.brokerage_side,
      double_ended: deal?.double_ended,
      representative_type: deal?.representative_type,
      property_address: deal?.listing ? undefined : deal?.address,
      listing_id: deal?.listing ? { id: deal?.listing?.id, label: deal?.listing?.address } : undefined,
      offer_date: convertUTCToLocal(deal?.offer_date),
      acceptance_date: convertUTCToLocal(deal?.acceptance_date),
      close_date: convertUTCToLocal(deal?.close_date),
      sale_price: deal?.sale_price,
      lease_price: deal?.lease_price,
      deposit_amount: deal?.deposit_amount,
      second_deposit_amount: deal?.second_deposit_amount,
      brokerage: deal?.brokerage,
      brokerage_phone_number: deal?.brokerage_phone_number,
      brokerage_email: deal?.brokerage_email,
      deal_agents: [
        {
          name: deal?.external_agents?.[0]?.name,
          email: deal?.external_agents?.[0]?.email,
          phone: deal?.external_agents?.[0]?.phone
        }
      ],
      seller_lawyer: [
        {
          id: deal?.seller_lawyer?.id,
          name: deal?.seller_lawyer?.name,
          address: deal?.seller_lawyer?.address,
          phone: deal?.seller_lawyer?.phone_number,
          email: deal?.seller_lawyer?.email
        }
      ],
      buyer_lawyer: [
        {
          id: deal?.buyer_lawyer?.id,
          name: deal?.buyer_lawyer?.name,
          address: deal?.buyer_lawyer?.address,
          phone: deal?.buyer_lawyer?.phone_number,
          email: deal?.buyer_lawyer?.email
        }
      ],
      annual_personal_deal_checkbox: deal?.annual_personal_deal_checkbox,
      first_installment: deal?.first_installment,
      second_installment: deal?.second_installment,
      third_installment: deal?.third_installment,
      final_installment: deal?.final_installment,
      total_commission: deal?.total_commission,
      listing_brokerage_commission: deal?.listing_brokerage_commission,
      cooperating_brokerage_commission: deal?.cooperating_brokerage_commission,
      total: deal?.total,
      notes: deal?.notes,
      status: deal?.status,
      referral: [
        {
          referral_first_name: deal?.referral?.first_name,
          referral_last_name: deal?.referral?.last_name,
          referral_amount: deal?.referral?.referral_amount,
          referral_percentage: deal?.referral?.referral_percentage,
          referral_office_name: deal?.referral?.office_name,
          referral_office_address: deal?.referral?.office_address,
          referral_office_phone: deal?.referral?.office_phone,
          referral_office_email: deal?.referral?.office_email
        }
      ],
      additionalDocuments: deal?.additionalDocuments?.length
        ? deal?.additionalDocuments
        : [{ fileName: '', key: null, size: null, type: '' }],
      checkedDocuments: deal?.checkedDocuments ?? [],
      primary_Agent_Total: deal?.agents_commission_breakdown?.primary_agent_commission?.commission_amount
    },
    resolver: yupResolver(validationSchema)
  })

  const canShowBrokerage = formRef.getValues().brokerage_side !== 'both' && deal?.type !== FORM_DEAL_TYPES.PRE_CON
  const canShowLawyer = deal?.type !== FORM_DEAL_TYPES.LEASE
  const [updateDeal, { loading: updateDealLoading }] = useMutation(UPDATE_DEAL, {
    onError: (err) => {
      console.error(err)
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('Deal updated.')
      navigate('/deals')
    },
    refetchQueries: [
      { query: GET_DEAL, variables: { id: id } },
      {
        query: GET_DEALS,
        variables: {
          filters: {}
        }
      }
    ]
  })

  const formatData = (data) => {
    data = { ...data, deletedDocuments }
    const documents = {}
    Object.keys(DEAL_DOCUMENTS_TYPES[deal.type]).forEach((typeKey) => {
      if (data[typeKey]) {
        const { fileName, newFilename } = data[typeKey]
        documents[typeKey] = { fileName, newFilename }
      }
      delete data[typeKey]
    })
    let buyer_lawyer = null
    buyer_lawyer = {
      name: data?.buyer_lawyer_name ?? '',
      address: data?.buyer_lawyer_address ?? '',
      phone_number: data?.buyer_lawyer_phone ?? '',
      email: data?.buyer_lawyer_email ?? ''
    }
    const buyerLawyerKeys = ['buyer_lawyer_name', 'buyer_lawyer_address', 'buyer_lawyer_phone', 'buyer_lawyer_email']
    buyerLawyerKeys.forEach((e) => delete data[e])

    let seller_lawyer = null
    seller_lawyer = {
      name: data?.seller_lawyer_name ?? '',
      address: data?.seller_lawyer_address ?? '',
      phone_number: data?.seller_lawyer_phone ?? '',
      email: data?.seller_lawyer_email ?? ''
    }
    const sellerLawyerKeys = [
      'seller_lawyer_name',
      'seller_lawyer_address',
      'seller_lawyer_phone',
      'seller_lawyer_email'
    ]
    sellerLawyerKeys.forEach((e) => delete data[e])

    let agents_commission_breakdown = null
    agents_commission_breakdown = {
      primary_agent_commission: {
        id: primaryAgent.id,
        name: primaryAgent.name,
        commission_percentage: parseFloat(data?.primary_Agent_Percentage) ?? '',
        commission_amount: parseFloat(secondaryCommission) ?? ''
      },
      secondary_agents_commission: secondaryAgent.map((agent, index) => {
        return {
          id: agent.id,
          name: agent.name,
          commission_amount: agentCommissions[index] === undefined ? 0 : parseFloat(agentCommissions[index]) ?? '',
          commission_percentage:
            data[`secondaryAgent_percentage_${agent.id}`] === undefined
              ? 0
              : parseFloat(data[`secondaryAgent_percentage_${agent.id}`]) ?? ''
        }
      })
    }

    const agentsCommissionKeys = secondaryAgent.map((agent) => {
      const agentList = 'secondaryAgent_percentage_' + agent.id
      return { agentList }
    })
    agentsCommissionKeys.forEach((e) => {
      delete data[e.agentList]
    })

    const primaryAgentKeys = ['primary_Agent_Percentage', 'primary_Agent_Total']
    primaryAgentKeys.forEach((e) => delete data[e])

    const agentsCommissionTotalKeys = secondaryAgent.map((agent) => {
      const agentListTotal = 'secondaryAgent_total_' + agent.id
      return { agentListTotal }
    })
    agentsCommissionTotalKeys.forEach((e) => {
      delete data[e.agentListTotal]
    })

    const secondaryAgentKeys = agent.map((agent) => {
      const agentList = 'secondaryAgent_percentage_' + agent.id
      return { agentList }
    })
    secondaryAgentKeys.forEach((e) => {
      delete data[e.agentList]
    })

    const secondaryAgentTotalKeys = agent.map((agent) => {
      const agentList = 'secondaryAgent_total_' + agent.id
      return { agentList }
    })
    secondaryAgentTotalKeys.forEach((e) => {
      delete data[e.agentList]
    })

    let referral = null
    referral = {
      id: deal?.referral?.id ?? null,
      first_name: data.referral_first_name,
      last_name: data.referral_last_name,
      referral_amount: data.referral_amount,
      referral_percentage: data.referral_percentage,
      office_name: data.referral_office_name,
      office_email: data.referral_office_email,
      office_address: data.referral_office_address,
      office_phone: data.referral_office_phone
    }
    const referralKeys = [
      'have_referral',
      'referral_first_name',
      'referral_last_name',
      'referral_office_name',
      'referral_office_email',
      'referral_office_address',
      'referral_office_phone',
      'referral_amount',
      'referral_percentage',
      'referral_amount_type'
    ]
    referralKeys.forEach((e) => delete data[e])
    return {
      id: deal.public_id,
      ...data,
      documents,
      buyer_lawyer,
      seller_lawyer,
      agents_commission_breakdown,
      referral,
      type: undefined,
      listing_id: data?.listing_id?.value,
      deposit_amount: data.deposit_amount ? data.deposit_amount : null,
      second_deposit_amount: data.second_deposit_amount ? data.second_deposit_amount : 0,
      final_installment: data.final_installment ? Number(data.final_installment) : 0,
      first_installment: data.first_installment ? Number(data.first_installment) : 0,
      third_installment: data.third_installment ? Number(data.third_installment) : 0,
      second_installment: data.second_installment ? Number(data.second_installment) : 0,
      conditional: data.conditional === 'yes',
      paperwork_to_lawyer: data.paperwork_to_lawyer === 'yes',
      annual_personal_deal: data.annual_personal_deal === 'yes',
      acceptance_date: convertLocalToUTC(data.acceptance_date),
      offer_date: convertLocalToUTC(data.offer_date),
      close_date: convertLocalToUTC(data.close_date),
      settled_date: deal.status === STATUS_TYPES.settled ? convertLocalToUTC(data.settled_date) : null,
      deal_commission_amount: parseFloat(totalCommissions || 0),
      deal_commission_percentage: parseFloat(data?.deal_commission_percentage || 0)
    }
  }

  const handleSubmit = async (values) => {
    setDealStatus(STATUS_TYPES.draft)
    const data = await formatData(values)
    updateDeal({
      variables: {
        data: {
          ...data,
          status: isAdmin && deal?.status === STATUS_TYPES.submitted ? STATUS_TYPES.review : deal?.status,
          submitted: !isAdmin && deal?.status === STATUS_TYPES.attention ? true : undefined
        }
      }
    })
  }

  const handleSubmitDraft = async (values) => {
    setDealStatus(STATUS_TYPES.submitted)
    const data = await formatData(values)
    updateDeal({
      variables: {
        data: {
          ...data,
          submitted: true
        }
      }
    })
  }

  useEffect(() => {
    setAgents(deal?.agents)
    const isSubmitting = formRef.formState.isSubmitting
    const fetchValid = async () => {
      const isValid = await formRef.trigger()
      if (isValid === false) {
        if (isSubmitting === true) {
          toast.dismiss()
          toast.error('Oops! Something went wrong. Please check the error/validation messages.')
        }
      }
    }
    fetchValid()
  }, [formRef.formState.isSubmitting])

  return (
    <FormProvider {...formRef}>
      <form>
        <div className={styles.flexContainer}>
          <div className={styles.leftSide}>
            <div className={styles.form}>
              <DealFormHeader setSecondaryAgent={setSecondaryAgent} setPrimaryAgent={setPrimaryAgent} />
              <DealType isAgent={!isAdmin} setPrice={setPrice} setPriceFlag={setPriceFlag} />
              {isAdmin && canShowBrokerage && <OutsideBrokerage />}
              <DealDocuments />
              {!deal?.listing && (<Typography.Heading type={6} className={styles.headerLabel} style={{ marginTop: 20 }}>
                No linked listing
              </Typography.Heading>)}
              {(deal?.listing && (deal?.listing?.documents?.length !== 0 || deal?.listing?.additionalDocuments?.length !== 0) && (<ListingDocumentsInDeal listing={deal?.listing} isView={true} listingType={deal?.listing?.type} />))}
              {isAdmin && canShowLawyer && <LawyerInfo />}
              <Commission
                isAgent={!isAdmin}
                secondaryAgent={secondaryAgent}
                primaryAgent={primaryAgent}
                price={price}
                setAgentCommissions={setAgentCommissions}
                setSecondaryCommission={setSecondaryCommission}
                setTotalCommissions={setTotalCommissions}
                priceFlag={priceFlag}
              />
              <div className={styles.mobileDealComment}>
                <DealComments deal={deal} />
              </div>
              {isAdmin && deal.status !== STATUS_TYPES.submitted ? (
                <DealAdmin
                  isAdmin={isAdmin}
                  onEditDeal={formRef.handleSubmit(handleSubmit)}
                  loading={updateDealLoading}
                />
              ) : (
                <DealFooter
                  dealStatus={dealStatus}
                  onEditDeal={formRef.handleSubmit(handleSubmit)}
                  loading={updateDealLoading}
                  onSubmitDraft={formRef.handleSubmit(handleSubmitDraft)}
                />
              )}
            </div>
          </div>
          <div className={styles.rightSideEdit}>
            <DealComments deal={deal} />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}
const UPDATE_DEAL = gql`
  mutation updateDeal($data: UpdateDealInput!) {
    updateDeal(data: $data)
  }
`

export default EditDealForm
