import {
  LISTING_OPTIONS,
  DEAL_TYPES,
  STATUS,
  STATUS_TYPES,
  ANNONCEMENT_TYPES,
  ANNONCEMENT_STATUS,
  LIBRARY_OPTIONS,
  LIBRARY_ACTIONS,
  USER_MANAGEMENT_ACTIONS
} from 'helpers/constants'

const PluralizeLabel = (label) => {
  return label + 's'
}

const tableFilterOptions = [
  {
    value: 'all',
    label: 'All'
  },
  {
    value: STATUS_TYPES.submitted,
    label: STATUS.submitted
  },
  {
    value: STATUS_TYPES.draft,
    label: PluralizeLabel(STATUS.draft)
  },
  {
    value: STATUS_TYPES.review,
    label: STATUS.review
  },
  {
    value: STATUS_TYPES.attention,
    label: STATUS.attention
  },
  {
    value: STATUS_TYPES.approved,
    label: STATUS.approved
  },
  {
    value: STATUS_TYPES.closed,
    label: STATUS.closed
  },
  {
    value: STATUS_TYPES.settled,
    label: STATUS.settled
  }
]
const listingFilterOptions = [{ ...tableFilterOptions[0], label: 'All Listings' }, ...tableFilterOptions.slice(1)]
const dealsFilterOptions = [{ ...tableFilterOptions[0], label: 'All Deals' }, ...tableFilterOptions.slice(1)]

const listingOptions = [
  { id: 'exclusive', text: LISTING_OPTIONS.exclusive },
  { id: 'mls', text: LISTING_OPTIONS.mls }
]

const dealOptions = [
  { id: 'sale', text: DEAL_TYPES.sale },
  { id: 'lease', text: DEAL_TYPES.lease },
  { id: 'pre-con', text: DEAL_TYPES['pre-con'] }
]

const libraryOptions = [
  { id: LIBRARY_OPTIONS.CREATE_FOLDER, text: 'Create Folder' },
  { id: LIBRARY_OPTIONS.UPLOAD_FILE, text: 'Upload File' }
]

const ATTACHMENT_UPLOAD_OPTIONS = [
  { id: LIBRARY_OPTIONS.UPLOAD_FILE, text: 'Upload File' },
  { id: LIBRARY_OPTIONS.ATTACH_FILE, text: 'Attach From Library' }
]

const PIN_OPTIONS = [
  { id: 'all', text: 'All' },
  { id: 'self', text: 'Self' }
]

const ADMIN_STATUS_OPTIONS = [
  { value: STATUS_TYPES.review, label: STATUS.review },
  // todo add back later
  // { value: 'draft', label: 'Draft' },
  { value: STATUS_TYPES.attention, label: STATUS.attention },
  { value: STATUS_TYPES.approved, label: STATUS.approved },
  { value: STATUS_TYPES.closed, label: STATUS.closed },
  { value: STATUS_TYPES.settled, label: STATUS.settled }
]

const ANNOUNCEMENT_OPTIONS = [
  {
    value: ANNONCEMENT_TYPES.INBOX,
    label: ANNONCEMENT_STATUS.INBOX
  },
  {
    value: ANNONCEMENT_TYPES.STARRED,
    label: ANNONCEMENT_STATUS.STARRED
  },
  {
    value: ANNONCEMENT_TYPES.DRAFT,
    label: ANNONCEMENT_STATUS.DRAFT
  },
  {
    value: ANNONCEMENT_TYPES.SCHEDULED,
    label: ANNONCEMENT_STATUS.SCHEDULED
  }
]

const LIBRARY_HOVER_OPTIONS = [
  { id: LIBRARY_ACTIONS.DOWNLOAD, text: 'Download' },
  { id: LIBRARY_ACTIONS.PREVIEW, text: 'Preview' },
  { id: LIBRARY_ACTIONS.RENAME, text: 'Rename' },
  { id: LIBRARY_ACTIONS.DELETE, text: 'Delete' }
]

const USER_MANAGEMENT_OPTIONS = [
  { id: USER_MANAGEMENT_ACTIONS.ACTIVATE, text: 'Activate' },
  { id: USER_MANAGEMENT_ACTIONS.DEACTIVATE, text: 'Deactivate' },
  { id: USER_MANAGEMENT_ACTIONS.EDIT, text: 'Edit' }
]

const dropdownOption = {
  listingFilterOptions,
  dealsFilterOptions,
  listingOptions,
  dealOptions,
  libraryOptions,
  ADMIN_STATUS_OPTIONS,
  ANNOUNCEMENT_OPTIONS,
  PIN_OPTIONS,
  ATTACHMENT_UPLOAD_OPTIONS,
  LIBRARY_HOVER_OPTIONS,
  USER_MANAGEMENT_OPTIONS
}
export default dropdownOption
