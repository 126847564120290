import { useMemo, useState, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import { DEAL_DOCUMENTS_TYPES, STATUS, FORM_DEAL_TYPES, FORM_BROKERAGE_SIDES } from 'helpers/constants'
import { toast } from 'react-toastify'
import DealType from './DealType'
import DealDocuments from './DealDocuments'
import { useParams, useNavigate } from 'react-router-dom'
import DealFormHeader from './DealFormHeader'
import Commission from './Commission'
import { yupResolver } from '@hookform/resolvers/yup'
import { DealProvider, DocumentProvider } from 'context'
import { useForm, FormProvider } from 'react-hook-form'
import AgentFooter from './AgentFooter'
import { DealHelper } from 'helpers/deal/DealHelper'
import { convertLocalToUTC } from 'helpers/utils'

export const formatData = (values, submitted) => {
  let data = values
  const documents = {}
  Object.keys(DEAL_DOCUMENTS_TYPES[data.type]).forEach((typeKey) => {
    if (data[typeKey]) {
      const { fileName, size, type, key: path, newFilename } = data[typeKey]
      documents[typeKey] = { fileName, size, type, path, newFilename }
    }
    delete data[typeKey]
  })

  let buyer_lawyer = null
  if (data.buyer_lawyer_name) {
    buyer_lawyer = {
      name: data.buyer_lawyer_name,
      address: data.buyer_lawyer_address,
      phone_number: data.buyer_lawyer_phone,
      email: data.buyer_lawyer_email
    }
  }
  const buyerLawyerKeys = ['buyer_lawyer_name', 'buyer_lawyer_address', 'buyer_lawyer_phone', 'buyer_lawyer_email']
  buyerLawyerKeys.forEach((e) => delete data[e])

  let seller_lawyer = null
  if (data.seller_lawyer_name) {
    seller_lawyer = {
      name: data.seller_lawyer_name,
      address: data.seller_lawyer_address,
      phone_number: data.seller_lawyer_phone,
      email: data.seller_lawyer_email
    }
  }
  const sellerLawyerKeys = ['seller_lawyer_name', 'seller_lawyer_address', 'seller_lawyer_phone', 'seller_lawyer_email']
  sellerLawyerKeys.forEach((e) => delete data[e])

  let referral = null

  referral = {
    first_name: data.referral_first_name,
    last_name: data.referral_last_name,
    referral_amount: data.referral_amount,
    referral_percentage: data.referral_percentage,
    office_name: data.referral_office_name,
    office_email: data.referral_office_email,
    office_address: data.referral_office_address,
    office_phone: data.referral_office_phone
  }

  const referralKeys = [
    'have_referral',
    'referral_first_name',
    'referral_last_name',
    'referral_office_name',
    'referral_office_email',
    'referral_office_address',
    'referral_office_phone',
    'referral_amount',
    'referral_percentage',
    'referral_amount_type'
  ]
  referralKeys.forEach((e) => delete data[e])

  data = {
    ...data,
    listing_id: data.listing_id?.value,
    documents,
    buyer_lawyer,
    seller_lawyer,
    conditional: data.conditional === 'yes',
    paperwork_to_lawyer: data.paperwork_to_lawyer === 'yes',
    annual_personal_deal: data.annual_personal_deal === 'yes',
    referral,
    submitted,
    acceptance_date: convertLocalToUTC(data.acceptance_date),
    offer_date: convertLocalToUTC(data.offer_date),
    close_date: convertLocalToUTC(data.close_date)
  }

  return data
}

const CreateDeal = () => {
  const params = useParams()
  const { type } = params
  const navigate = useNavigate()
  const [dealStatus, setDealStatus] = useState('')
  const [brokerageSide, setBrokerageSide] = useState(FORM_BROKERAGE_SIDES.LISTING)

  const validationSchema = useMemo(
    () => DealHelper.validationSchemaAgentDeal(type, brokerageSide),
    [type, brokerageSide]
  )

  const formRef = useForm({
    defaultValues: {
      type: type,
      brokerage_side: type === FORM_DEAL_TYPES.PRE_CON ? '' : FORM_BROKERAGE_SIDES.LISTING,
      listing_id: undefined,
      additionalDocuments: [{ fileName: null, key: null, size: null, type: null }],
      checkedDocuments: [],
      listing_brokerage_commission: '',
      cooperating_brokerage_commission: '',
      total: ''
    },
    resolver: yupResolver(validationSchema)
  })

  const handleChangeBrokerageSide = (brokerageType) => {
    setBrokerageSide(brokerageType)
  }

  const [createDeal, { loading }] = useMutation(CREATE_DEAL, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
      toast.error(err.message)
    },
    onCompleted: () => {
      toast.dismiss()
      navigate('/deals')
      formRef.reset()
      if (dealStatus === STATUS.draft) {
        toast.success('Deal saved as Draft.')
      } else {
        toast.success('Deal created.')
      }
    }
  })

  const handleCancel = () => {
    formRef.reset({
      type: ''
    })
    navigate('/deals')
  }

  const onSubmit = async (values) => {
    const isValid = await formRef.trigger()
    if (isValid) {
      setDealStatus(STATUS.submitted)
      const data = formatData(values, true)
      createDeal({ variables: { data } })
    }
  }

  const handleSaveDraft = (values) => {
    setDealStatus(STATUS.draft)
    const data = formatData(values, false)
    createDeal({ variables: { data } })
  }

  useEffect(() => {
    const isSubmitting = formRef.formState.isSubmitting
    const fetchValid = async () => {
      if (isSubmitting === true) {
        const isValid = await formRef.trigger()
        toast.dismiss()
        if (isValid === false) {
          toast.error('Oops! Something went wrong. Please check the error/validation messages.')
        }
      }
    }
    fetchValid()
  }, [formRef.formState.isSubmitting])

  return (
    <DocumentProvider>
      <DealProvider disabled={false}>
        <FormProvider {...formRef}>
          <form id="createDeal">
            <DealFormHeader />
            <DealType isAgent handleChangeBrokerageSide={handleChangeBrokerageSide} />
            <DealDocuments />
            <Commission isAgent />
            <AgentFooter
              loading={loading}
              dealStatus={dealStatus}
              onCancel={handleCancel}
              onSubmit={formRef.handleSubmit(onSubmit)}
              onSaveDraft={formRef.handleSubmit(handleSaveDraft)}
            />
          </form>
        </FormProvider>
      </DealProvider>
    </DocumentProvider>
  )
}

const CREATE_DEAL = gql`
  mutation createDeal($data: CreateDealInput!) {
    createDeal(data: $data)
  }
`

export default CreateDeal
