import React, { createContext, useCallback, useContext, useState } from 'react'
import { UserContext } from './UserProvider'
import { STATUS_TYPES, USER_ROLES } from 'helpers/constants'

const DealContext = createContext()

const DealProvider = ({ deal, disabled = false, isView = false, isEdit = false, children }) => {
  const [agentsByListing, setAgentByListing] = useState([])
  const { user } = useContext(UserContext)
  const [documentsByListing, setDocumentsByListing] = useState([])
  const [documentsByDeals, setDocumentsByDeals] = useState(deal?.documents)
  const isClosedAndAgent =
    user.role === USER_ROLES.agent && (deal?.status === STATUS_TYPES.closed || deal?.status === STATUS_TYPES.settled)
  const isApprovedAndAgent = user.role === USER_ROLES.agent && deal?.status === STATUS_TYPES.approved
  const handleAgentByListing = useCallback((value) => {
    setAgentByListing(value)
  }, [])

  const handleDocumentsByListing = useCallback((value) => {
    setDocumentsByListing(value)
  }, [])

  const handleDeleteDefaultListingDocuments = useCallback(
    (id) => {
      const filteredDocs = documentsByListing?.filter((document) => document.id !== id)
      setDocumentsByListing(filteredDocs)
    },
    [documentsByListing]
  )
  return (
    <DealContext.Provider
      value={{
        deal,
        disabled,
        isView,
        isEdit,
        agentsByListing,
        handleAgentByListing,
        documentsByListing,
        handleDocumentsByListing,
        handleDeleteDefaultListingDocuments,
        isApprovedAndAgent,
        documentsByDeals,
        isClosedAndAgent
      }}>
      {children}
    </DealContext.Provider>
  )
}

export { DealContext, DealProvider }
