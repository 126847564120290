import { useCallback, useState } from 'react'

export const usePopup = () => {
  const [visible, setVisible] = useState(false)

  const showPopup = useCallback(() => {
    setVisible(true)
  }, [])

  const hidePopup = useCallback(() => {
    setVisible(false)
  }, [])

  return [visible, showPopup, hidePopup]
}
