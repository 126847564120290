import { Typography, Radio, RadioGroup } from 'components'
import { Controller, useFormContext } from 'react-hook-form'
import PropertyInformation from './PropertyInformation'
import OfferDetails from './OfferDetails'
import { useState } from 'react'
import { Grid } from '@mui/material'
import { FORM_DEAL_TYPES, FORM_BROKERAGE_SIDES } from 'helpers/constants'
import styles from '../deals.module.scss'
import { DealContext } from 'context'
import { useContext } from 'react'

const DealType = ({ isAgent = false, setPrice,setPriceFlag, handleChangeBrokerageSide = console.log }) => {
  const { control, getValues, resetField, reset, setValue } = useFormContext()
  const { handleAgentByListing, handleDocumentsByListing, deal, isEdit } = useContext(DealContext)
  const { type, double_ended, brokerage_side } = getValues()
  const [dealType, setDealType] = useState(brokerage_side ?? deal?.brokerage_side)
  const [isDoubleEnded, setIsDoubleEnded] = useState(double_ended ?? deal?.double_ended)
  const [bothDealType, setBothDealType] = useState('')
  const mainDealType = type ?? deal?.type

  const resetFormExceptSpecificFields = () => {
    const valuesToPreserve = {
      brokerage_side1: getValues('brokerage_side'),
      double_ended1: getValues('double_ended'),
      representative_type1: getValues('representative_type')
    }
    reset()
    // Reset specific fields to their previous values
    setValue('brokerage_side', valuesToPreserve.brokerage_side1)
    setValue('double_ended', valuesToPreserve.double_ended1)
    setValue('representative_type', valuesToPreserve.representative_type1)
  }

  const handleChangeRepresentative_type = (value) => {
    setBothDealType(value)
    handleAgentByListing([])
    handleDocumentsByListing('')
    resetFormExceptSpecificFields()
  }

  const handleChangeBrokerageType = (brokerageType) => {
    setDealType(brokerageType)
    handleChangeBrokerageSide(brokerageType)
    handleAgentByListing([])
    handleDocumentsByListing('')
    if (brokerageType === FORM_BROKERAGE_SIDES.CO_OP || brokerageType === FORM_BROKERAGE_SIDES.LISTING) {
      resetField('double_ended')
      resetField('representative_type')
      setIsDoubleEnded('')
      setBothDealType('')
    }
    resetFormExceptSpecificFields()
  }

  const handleChangeDoubleEnded = (value) => {
    setIsDoubleEnded(value)
    resetField('representative_type')
    handleAgentByListing([])
    handleDocumentsByListing('')
    resetFormExceptSpecificFields()
  }

  return (
    <div className={`${styles.dealsStepOne} resolutionDealsStepOne`}>
      {mainDealType !== FORM_DEAL_TYPES.PRE_CON && (
        <Typography.Heading type={5} className={`${styles.dealTypeTitle} resolutionStepOneTitle`}>
          RARE is the
        </Typography.Heading>
      )}
      <Grid container columns={12}>
        {mainDealType !== FORM_DEAL_TYPES.PRE_CON && (
          <>
            <Grid container columnSpacing={7} sx={{ marginTop: 2 }}>
              <Grid xs={12} md={6} item>
                <Controller
                  name="brokerage_side"
                  defaultValue={deal?.brokerage_side}
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <RadioGroup
                      onChange={(e) => {
                        onChange(e)
                        handleChangeBrokerageType(e.target.value)
                      }}
                      value={value}
                      error={error?.message}
                      gap={16}>
                      <Radio
                        name="Listing Brokerage"
                        label="Listing Brokerage"
                        value={FORM_BROKERAGE_SIDES.LISTING}
                        checked={value === FORM_BROKERAGE_SIDES.LISTING}
                        disabled={isEdit}
                      />
                      <Radio
                        name="Cooperating Brokerage"
                        label="Cooperating Brokerage"
                        value={FORM_BROKERAGE_SIDES.CO_OP}
                        checked={value === FORM_BROKERAGE_SIDES.CO_OP}
                        disabled={isEdit}
                      />
                      <Radio
                        name="Both"
                        label="Both"
                        value={FORM_BROKERAGE_SIDES.BOTH}
                        checked={value === FORM_BROKERAGE_SIDES.BOTH}
                        disabled={isEdit}
                      />
                    </RadioGroup>
                  )}
                />
              </Grid>
            </Grid>
            {dealType === FORM_BROKERAGE_SIDES.BOTH && (
              <>
                <Grid container columnSpacing={7} sx={{ marginTop: 2, ml: '0px' }}>
                  <Grid xs={12} md={6} item>
                    <Controller
                      name="double_ended"
                      control={control}
                      defaultValue={deal?.double_ended ?? ''}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <RadioGroup
                          type={5}
                          label="Did you double ended the Deal ?"
                          required
                          onChange={(e) => {
                            onChange(e)
                            handleChangeDoubleEnded(e.target.value)
                          }}
                          value={value}
                          error={error?.message}>
                          <Radio
                            name="double_ended_yes"
                            label="Yes"
                            value="yes"
                            checked={value === 'yes'}
                            lableStyle={{ marginRight: 70 }}
                            disabled={isEdit}
                          />
                          <Radio
                            name="double_ended_no"
                            label="No"
                            value="no"
                            checked={value === 'no'}
                            disabled={isEdit}
                          />
                        </RadioGroup>
                      )}
                    />
                  </Grid>
                </Grid>
                {isDoubleEnded === 'no' && (
                  <Grid container columnSpacing={7} sx={{ marginTop: 2, ml: '0px' }}>
                    <Grid xs={12} md={6} item>
                      <Controller
                        name="representative_type"
                        control={control}
                        defaultValue={deal?.representative_type ?? ''}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <RadioGroup
                            type={5}
                            label={
                              mainDealType === FORM_DEAL_TYPES.LEASE
                                ? 'Did you represent Landlord/Tenant ?'
                                : 'Did you represent Seller/Buyer ?'
                            }
                            required
                            onChange={(e) => {
                              onChange(e)
                              handleChangeRepresentative_type(e.target.value)
                            }}
                            value={value}
                            error={error?.message}>
                            <Radio
                              lableStyle={{ marginRight: mainDealType === FORM_DEAL_TYPES.LEASE ? 33 : 53 }}
                              name="seller"
                              label={mainDealType === FORM_DEAL_TYPES.LEASE ? 'Landlord' : 'Seller'}
                              value="seller"
                              checked={value === 'seller'}
                              disabled={isEdit}
                            />
                            <Radio
                              name="buyer"
                              label={mainDealType === FORM_DEAL_TYPES.LEASE ? 'Tenant' : 'Buyer'}
                              value="buyer"
                              checked={value === 'buyer'}
                              disabled={isEdit}
                            />
                          </RadioGroup>
                        )}
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </>
        )}
        <Grid container columnSpacing={7} mt="20px" className="propertyInformation">
          <Grid xs={12} md={6} item>
            <PropertyInformation isAgent={isAgent} isDoubleEnded={isDoubleEnded} bothDealType={bothDealType} />
          </Grid>
        </Grid>
        {!isAgent && <OfferDetails setPrice={setPrice} setPriceFlag={setPriceFlag}/>}
      </Grid>
    </div>
  )
}

export default DealType
