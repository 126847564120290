import { Card, Col, Row } from 'components'
import { Box, IconButton, createTheme, useMediaQuery } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { Typography } from 'components'
import AnnouncementPopup from './AnnouncementPopup'
import { usePopup } from 'hooks/usePopup'
import { CardHeader } from './CardHeader'
import { useNavigate } from 'react-router-dom'
import agentPinnedIcon from '../../assets/images/agentPinnedIcon.png'
import adminPinnedIcon from '../../assets/images/adminPinnedIcon.png'
import starredIcon from '../../assets/images/starredIcon.png'
import { AttachFile } from '@mui/icons-material'

const Announcements = ({ announcements, refetch }) => {
  const navigate = useNavigate()
  const [viewAnnouncement, showAnnouncement, hideAnnouncement] = usePopup()
  const [announcement, setAnnouncement] = useState()
  const theme = createTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleAnnouncements = (value) => {
    isMobile && navigate('/announcements/' + value.public_id)
    setAnnouncement(value)
    showAnnouncement()
  }

  const handleClick = useCallback(() => {
    navigate('/announcements')
  }, [navigate])

  const filteredAnnouncements = useMemo(() => {
    return announcements?.data?.slice(0, 20) ?? []
  }, [announcements])

  return (
    <Card title={<CardHeader title="Announcements" buttonText="VIEW ALL" onClick={handleClick} />}>
      <div style={{ height: '710px' }}>
        {filteredAnnouncements?.length > 0 ? (
          filteredAnnouncements?.slice(0, 20)?.map((announcement, i) => (
            <Box
              onClick={() => handleAnnouncements(announcement)}
              key={i}
              sx={{
                backgroundColor: !announcement?.is_read ? '#e8f1fc' : 'white',
                padding: 2,
                mt: 2,
                position: 'relative',
                cursor: 'pointer'
              }}>
              <Row style={{ justifyContent: 'space-between' }}>
                <Col>
                  <Typography.Subheading type="2" key={announcement?.id}>
                    {announcement?.title}
                  </Typography.Subheading>
                </Col>
                <Col style={{ flexDirection: 'row' }}>
                  {announcement?.is_starred && <img src={starredIcon} height={18} width={18} alt="starredIcon" />}
                  {announcement?.is_pinned && <img src={agentPinnedIcon} height={18} width={18} alt="unPinIcon" />}
                  {announcement?.admin_pinned && <img src={adminPinnedIcon} height={18} width={18} alt="unPinIcon" />}
                  {announcement?.attachments && announcement.attachments.length > 0 && (
                    <AttachFile sx={{ fontSize: '18px', color: '#4d4d4d' }} />
                  )}
                </Col>
              </Row>
              {announcement?.sender && <Typography.Body type="3">From {announcement?.sender}</Typography.Body>}
              <IconButton
                sx={{ position: 'absolute', right: 0, bottom: -18 }}
                onClick={() => handleAnnouncements(announcement)}></IconButton>
            </Box>
          ))
        ) : (
          <Typography.Body>No Announcements </Typography.Body>
        )}
      </div>
      <AnnouncementPopup
        viewAnnouncement={viewAnnouncement}
        announcement={announcement}
        onCancel={hideAnnouncement}
        refetch={refetch}
      />
    </Card>
  )
}

export default Announcements
