import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Fade from '@mui/material/Fade'
import styles from './MUIMenuButton.module.scss'
import vars from '../../vars.scss'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

const MUIActionButton = (props) => {
  const { options, handleOnClickActionButton, actionIcon } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (event, value) => {
    handleOnClickActionButton(value)
    handleClose()
  }

  return (
    <div className="customMenuButton">
      <IconButton onClick={handleClick}>{actionIcon ? actionIcon : <MoreHorizIcon />}</IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        getcontentanchorel={null}
        PaperProps={{
          style: {
            border: `0.5px solid ${vars['menuButton-border-color']}`,
            borderRadius: '0px'
          }
        }}
        elevation={3}>
        {options.map((menuItem, index) => (
          <MenuItem
            key={index}
            onClick={(event) => handleMenuItemClick(event, menuItem.id)}
            className={`${styles.customActionMenuItem} ${
              index !== options.length - 1 ? styles.customMenuItemBorder : ''
            }`}>
            {menuItem.text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
export default MUIActionButton
