import { useRef, useEffect } from 'react'
import NavItems from './NavItems'

const MobileDrawer = ({ open, setOpen }) => {
  const ref = useRef()

  let drawerClasses = 'mobile-nav-drawer'

  if (open) {
    drawerClasses = 'mobile-nav-drawer mobile-nav-drawer-open'
  }

  // Click outside of drawer to close
  const handleClick = (e) => {
    if (e.type === 'mousedown' && !ref.current.contains(e.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div ref={ref} className={drawerClasses}>
      <NavItems mobile={true} />
    </div>
  )
}

export default MobileDrawer
