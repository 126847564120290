import { getFileUrl } from 'helpers/utils'
import vars from '../../vars.scss'
import { Col, Typography, Table, Avatar, Loading, BasicTable, Agent, InputSearch, Row } from 'components'
import { gql, useMutation, useQuery } from '@apollo/client'
import { GET_AGENTS, GET_ALL_USERS } from 'helpers/gql'
import { GET_USER } from 'context/UserProvider'
import { toast } from 'react-toastify'
import { useCallback, useState } from 'react'
import debounce from 'lodash.debounce'
import search from 'helpers/search'

const Godmode = () => {
  const [rows, setRows] = useState()
  const [rowsPerPage, setrowsPerPage] = useState(10)

  const { data: { getUsersV2: allUsers } = [], loading } = useQuery(GET_ALL_USERS, {
    variables: {
      excludeDeleted: false
    },
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: (data) => {
      setRows(data.getUsersV2)
    }
  })

  const handleSearch = useCallback(
    debounce(async (searchedVal) => {
      const filteredRows = await search.AdminPanelFilterRowsBySearch(allUsers, searchedVal)
      setRows(filteredRows ?? [])
    }, 400),
    [allUsers]
  )

  const [enterGodMode] = useMutation(ENTER_GOD_MODE, {
    onError: (err) => {
      toast.dismiss()
      console.error(err)
      toast.error('Error entering god mode')
    },
    onCompleted: () => {
      window.location.reload()
    },
    refetchQueries: [{ query: GET_USER }]
  })

  const handleRowClick = (agent) => {
    enterGodMode({ variables: { userId: agent.id } })
  }
  const handleChangeRows = (perPageRows) => {
    setrowsPerPage(perPageRows)
  }
  if (!allUsers) return null

  const columns = [
    {
      name: 'public_id',
      label: '',
      options: {
        display: false
      }
    },
    {
      name: 'id',
      label: '',
      options: {
        display: false
      }
    },
    {
      name: 'thumbnail',
      label: '',
      options: {
        display: false
      }
    },
    {
      name: 'name',
      label: 'Agents',
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const [public_id, id, thumbnail] = tableMeta.rowData
          return (
            <div style={{ cursor: 'pointer' }}>
              <Agent agent={{ name: value, id, thumbnail }} />
            </div>
          )
        }
      }
    },
    {
      name: 'deleted_at',
      label: 'Status',
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography.Body style={{ color: vars['table-cell-color'] , fontStyle: "italic"}}>
              {value ? 'Terminated' : 'Active'}
            </Typography.Body>
          )
        }
      }
    },
  ]

  return (
    <Col gap={24}>
      <Row alignItems="center" justifyContent="space-between">
        <Col>
          <Typography.Heading type="5">Super Mode</Typography.Heading>
        </Col>
      </Row>
      <Row>
        <Col>
          <InputSearch
            name="search"
            onChange={(e) => {
              handleSearch(e.target.value.trimStart())
            }}
          />
        </Col>
      </Row>
      {loading ? (
        <Loading fullpage />
      ) : (
        <BasicTable
          tableData={rows}
          tableColumns={columns}
          handleRowClick={handleRowClick}
          handleChangeRowsPerPage={handleChangeRows}
          rowsPerPage={rowsPerPage}
        />
      )}
    </Col>
  )
}

const ENTER_GOD_MODE = gql`
  mutation enterGodMode($userId: Int!) {
    enterGodMode(userId: $userId)
  }
`

export default Godmode
