import React, { useContext, useMemo } from 'react'
import { Typography } from 'components'
import styles from '../../deals.module.scss'
import DocumentBase from '../../DocumentBase'
import { useFormContext } from 'react-hook-form'
import { DealContext } from 'context'
import { DealValidationHelper } from 'helpers/deal/DealValidationHelper'
import AdditionalDocuments from 'components/upload/AdditionalDocuments'

const SaleDocuments = ({ isAgent = false }) => {
  const { getValues, control } = useFormContext()
  const { deal, isView, isClosedAndAgent } = useContext(DealContext)
  const { brokerage_side, double_ended, representative_type } = getValues()
  const brokerageSide = brokerage_side ?? deal?.brokerage_side
  const doubleEnded = double_ended ?? deal?.double_ended
  const representativeType = representative_type ?? deal?.representative_type

  const isListingBrokerage = useMemo(() => {
    return DealValidationHelper.isListingBrokerage(brokerageSide, doubleEnded, representativeType)
  }, [brokerageSide, doubleEnded, representativeType])

  const isCoopBrokerage = useMemo(() => {
    return DealValidationHelper.isCoopBrokerage(brokerageSide, doubleEnded, representativeType)
  }, [brokerageSide, doubleEnded, representativeType])

  return (
    <>
      <DocumentBase title="All Sale Documents" name="sale_all_document" required={isAgent} />
      <Typography.Subheading type={1} className={`${styles.orDivider} documentsOrDivider`}>
        OR
      </Typography.Subheading>
      <DocumentBase title="Agreement of Purchase and Sale" name="sale_agreement_purchase_sale" required={isAgent} />
      <DocumentBase
        title="Confirmation of Cooperation"
        name="sale_confirmation_cooperating_representative"
        required={isAgent}
      />
      {deal?.documents?.map((doc) => {
        if (doc?.checklist_document_type === 'sale_working_with_realtor') {
          return <DocumentBase title="Working with a Realtor" name="sale_working_with_realtor" required={isAgent} />
        }
      })}

      {isCoopBrokerage && (
        <DocumentBase title="Buyer Representation Agreement" name="sale_buyer_rep_agreement" required={isAgent} />
      )}
      <DocumentBase
        title="Fintrac Form 630  - Individual Identification Information Record"
        name="sale_fintrac_form_630"
        required={isAgent}
      />
      <DocumentBase
        title="Fintrac Form 634 - Politically Exposed Person/Head of International Organization Checklist"
        name="sale_fintrac_form_634"
        required={isAgent}
      />
      {isCoopBrokerage && (
        <DocumentBase title="Fintrac Form 635 - Receipt of Funds" name="sale_fintrac_form_635" required={isAgent} />
      )}
      <DocumentBase title="Copy of Deposit Receipt" name="sale_copy_deposit_receipt" required={isAgent} />
      {isCoopBrokerage && (
        <DocumentBase
          title="Form 127 - Condition(s) In Offer - Buyer Acknowledgment"
          name="sale_form_127"
          required={isAgent}
        />
      )}
      {isListingBrokerage && (
        <DocumentBase title="Listing Agreement" name="sale_listing_agreement" required={isAgent} />
      )}
      {isListingBrokerage && <DocumentBase title="Data Sheet" name="sale_data_sheet" required={isAgent} />}
      <AdditionalDocuments isView={isView} control={control} isClosedAndAgent={isClosedAndAgent} tresa={true} />
    </>
  )
}

export default SaleDocuments
