import React, { useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Fade from '@mui/material/Fade'
import { Add } from '@mui/icons-material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Button } from 'components'
import styles from './MUIMenuButton.module.scss'
import vars from '../../vars.scss'

export const MUIMenuButton = (props) => {
  const { onClick, label, options, children, style, width, loading = false } = props
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (event, value) => {
    onClick(value)
    handleClose()
  }

  return (
    <div className="customMenuButton">
      {label ? (
        <Button
          prefixIcon={!open ? <Add /> : <KeyboardArrowDownIcon />}
          style={style}
          onClick={handleClick}
          loading={loading}
          className={'menuButton'}>
          {label}
        </Button>
      ) : (
        <div onClick={handleClick}>{children}</div>
      )}
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        getcontentanchorel={null}
        PaperProps={{
          style: {
            width: width ?? 115,
            border: `0.5px solid ${vars['menuButton-border-color']}`,
            borderRadius: '0px'
          }
        }}
        elevation={3}>
        {options.map((menuItem, index) => (
          <MenuItem
            key={index}
            onClick={(event) => handleMenuItemClick(event, menuItem.id)}
            className={`${styles.customMenuItem} ${index !== options.length - 1 ? styles.customMenuItemBorder : ''}`}>
            {menuItem.text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default MUIMenuButton
