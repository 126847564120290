import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useForm, Controller } from 'react-hook-form'
import { Typography, Button, Input } from 'components'
import SuccessRequestPassword from './SuccessRequestPassword'
import styles from './signin.module.scss'
import { gql, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import loginLogo from '../../assets/Login-page-logo.png'
import { createTheme, useMediaQuery } from '@mui/material'
const Forgot = () => {
  const [error, setError] = useState(null)
  const [email, setEmail] = useState(false)
  const { handleSubmit, control, getValues } = useForm()
  const theme = createTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const heightWidth = isMobile ? 200 : 350

  const [forgotPassword] = useMutation(FORGOT_PASSWORD, {
    onError: (err) => {
      toast.dismiss()
      setError(err)
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      const email = getValues().email
      setEmail(email)

      toast.success(`Password reset link has been sent to ${email}`)
    }
  })

  const onSubmit = (variables) => {
    forgotPassword({ variables })
  }

  const loading = false

  if (email) return <SuccessRequestPassword email={email} setEmail={setEmail} />

  return (
    <>
      <Helmet title="Forgot | RARE" />
      <div className={styles.logoContainer}>
        <img src={loginLogo} alt="RARE Logo" height={heightWidth} width={heightWidth} />
      </div>
      <section id="forgot" className={styles.signin}>
        <div className={styles.centerContent}>
          <Typography.Heading type="2" color="white" style={{ marginTop: 60 }}>
            Reset Password
          </Typography.Heading>

          <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 30 }}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: 'Email is required'
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  placeholder="Please enter the email you use to sign in to RARE."
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  required
                  isWhiteLine={true}
                  name="email"
                  color="dark"
                  size="large"
                />
              )}
            />

            <Button form type="plain" block loading={loading} className={styles.passwordResetButton}>
              Password Reset
            </Button>

            {error && (
              <Typography.Body type="3" color="error" style={{ marginTop: 8 }}>
                {error.message}
              </Typography.Body>
            )}
          </form>

          <Typography.Body color="white" style={{ marginTop: 20 }} className={styles.subheading}>
            Forgot which email address you used?
            <Typography.Link
              href="mailto:fatema@rarerealestate.ca"
              style={{ marginLeft: 8 }}
              className={styles.subheading}>
              Contact admin
            </Typography.Link>
          </Typography.Body>

          <Typography.Body color="white" style={{ marginTop: 16 }} className={styles.subheading}>
            No longer need to reset password?
            <Typography.Link to="/login" style={{ marginLeft: 8 }} className={styles.subheading}>
              Sign in instead
            </Typography.Link>
          </Typography.Body>
        </div>
      </section>
    </>
  )
}

const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`
export default Forgot
