import React, { useCallback } from 'react'
import UserForm from './UserForm'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'
import { UPDATE_USER_PROFILE } from 'helpers/mutations'

const EditUser = ({ users, open, hidePopup, refetch, roles }) => {
  const [updateProfileInput] = useMutation(UPDATE_USER_PROFILE, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('User updated.')
      refetch()
      hidePopup()
    }
  })

  const handleSubmit = useCallback(
    async (values) => {
      await updateProfileInput({
        variables: {
          data: {
            ...values,
            user_id: users?.id,
            role: values.role.value
          }
        }
      })
    },
    [updateProfileInput, users?.id]
  )

  return <UserForm open={open} isEdit users={users} hidePopup={hidePopup} onSubmit={handleSubmit} roles={roles} />
}

export default EditUser
