import { useState, useEffect } from 'react'
import { formatCurrency } from 'helpers/utils'
import './revsharecalc.scss'
import { Row } from 'components'
import { IconButton } from '@mui/material'
import { ArrowCircleLeft } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import Logo from '../../assets/rare-logo-white.svg'

// Copy/pasted from old code
function Calculator(props) {
  const navigate = useNavigate()
  const calculatorBaseClass = 'calculator'
  const calculatorLeftBoxClass = calculatorBaseClass + '__left-box'
  const calculatorDividerClass = calculatorBaseClass + '__divider'
  const calculatorRightBoxClass = calculatorBaseClass + '__right-box'
  const calculatorTitleClass = calculatorBaseClass + '__title'
  const calculatorSectionClass = calculatorBaseClass + '__section'
  const calculatorFieldRowClass = calculatorBaseClass + '__field-row'
  const calculatorFieldClass = calculatorBaseClass + '__field'
  const calculatorControlClass = calculatorBaseClass + '__control'
  const calculatorSocialClass = calculatorBaseClass + '__social'

  const [propertiesSold, setPropertiesSold] = useState(0)
  const [averagePrice, setAveragePrice] = useState(null)
  const [commPercent, setCommPercent] = useState(null)

  const [levelOneAgent, setLevelOneAgent] = useState(0)
  const [levelTwoAgent, setLevelTwoAgent] = useState(0)
  const [levelThreeAgent, setLevelThreeAgent] = useState(0)
  const [levelFourAgent, setLevelFourAgent] = useState(0)
  const [levelFiveAgent, setLevelFiveAgent] = useState(0)

  const [levelOneComm, setLevelOneComm] = useState('')
  const [levelTwoComm, setLevelTwoComm] = useState('')
  const [levelThreeComm, setLevelThreeComm] = useState('')
  const [levelFourComm, setLevelFourComm] = useState('')
  const [levelFiveComm, setLevelFiveComm] = useState('')

  const [totalAnnual, setTotalAnnual] = useState('')
  const [totalMonthly, setTotalMonthly] = useState('')
  const [totalAgents, setTotalAgents] = useState(0)

  const [calculated, setCalculated] = useState(false)

  // Calculate Constants
  const [levelOneActiveFrontLine, setLevelOneActiveFrontLine] = useState(1)
  const [levelOneRevSharePercent, setLevelOneRevSharePercent] = useState(0.3)
  const [levelTwoActiveFrontLine, setLevelTwoActiveFrontLine] = useState(5)
  const [levelTwoRevSharePercent, setLevelTwoRevSharePercent] = useState(0.25)
  const [levelThreeActiveFrontLine, setLevelThreeActiveFrontLine] = useState(10)
  const [levelThreeRevSharePercent, setLevelThreeRevSharePercent] = useState(0.2)
  const [levelFourActiveFrontLine, setLevelFourActiveFrontLine] = useState(15)
  const [levelFourRevSharePercent, setLevelFourRevSharePercent] = useState(0.15)
  const [levelFiveActiveFrontLine, setLevelFiveActiveFrontLine] = useState(20)
  const [levelFiveRevSharePercent, setLevelFiveRevSharePercent] = useState(0.1)
  const [avgAnnualCompanyDollar, setAvgAnnualCompanyDollar] = useState(18000)

  const [revShareConstants, setRevShareConstants] = useState([]) // Same as the constants above...

  useEffect(() => {
    let localRevShareConstant = []
    const topLevelRevSharePercent = 30

    for (let i = 1; i <= 5; i++) {
      let revSharePercent = topLevelRevSharePercent - (i - 1) * 5

      const localRevShareObj = {}
      localRevShareObj.level = i
      localRevShareObj.active_front_line = i > 1 ? i * 5 - 5 : 1
      localRevShareObj.rev_share_percent = revSharePercent
      localRevShareObj.maximum_revenue = formatCurrency((revSharePercent / 100) * avgAnnualCompanyDollar, true)

      localRevShareConstant.push(localRevShareObj)
    }

    setRevShareConstants(localRevShareConstant)
  }, [])

  // Handle the calculation of the commission
  const calculate = () => {
    let levelOneTotal = 0,
      levelTwoTotal = 0,
      levelThreeTotal = 0,
      levelFourTotal = 0,
      levelFiveTotal = 0

    let lvlOneAgentVal = 0,
      lvlTwoAgentVal = 0,
      lvlThreeAgentVal = 0,
      lvlFourAgentVal = 0,
      lvlFiveAgentVal = 0

    if (!isNaN(levelOneAgent)) {
      lvlOneAgentVal = levelOneAgent
    } else {
      setLevelOneAgent(0)
    }
    if (!isNaN(levelTwoAgent)) {
      lvlTwoAgentVal = levelTwoAgent
    } else {
      setLevelTwoAgent(0)
    }
    if (!isNaN(levelThreeAgent)) {
      lvlThreeAgentVal = levelThreeAgent
    } else {
      setLevelThreeAgent(0)
    }
    if (!isNaN(levelFourAgent)) {
      lvlFourAgentVal = levelFourAgent
    } else {
      setLevelFourAgent(0)
    }
    if (!isNaN(levelFiveAgent)) {
      lvlFiveAgentVal = levelFiveAgent
    } else {
      setLevelFiveAgent(0)
    }

    if (lvlOneAgentVal >= levelOneActiveFrontLine) {
      levelOneTotal = lvlOneAgentVal * (levelOneRevSharePercent * avgAnnualCompanyDollar)
    }

    if (lvlOneAgentVal >= levelTwoActiveFrontLine) {
      levelTwoTotal = lvlTwoAgentVal * (levelTwoRevSharePercent * avgAnnualCompanyDollar)
    }

    if (lvlOneAgentVal >= levelThreeActiveFrontLine) {
      levelThreeTotal = lvlThreeAgentVal * (levelThreeRevSharePercent * avgAnnualCompanyDollar)
    }

    if (lvlOneAgentVal >= levelFourActiveFrontLine) {
      levelFourTotal = lvlFourAgentVal * (levelFourRevSharePercent * avgAnnualCompanyDollar)
    }

    if (lvlOneAgentVal >= levelFiveActiveFrontLine) {
      levelFiveTotal = lvlFiveAgentVal * (levelFiveRevSharePercent * avgAnnualCompanyDollar)
    }

    const totalAgentsLocal = lvlOneAgentVal + lvlTwoAgentVal + lvlThreeAgentVal + lvlFourAgentVal + lvlFiveAgentVal
    const total = levelOneTotal + levelTwoTotal + levelThreeTotal + levelFourTotal + levelFiveTotal
    const monthly = total / 12

    setTotalAgents(totalAgentsLocal)
    setTotalAnnual(formatCurrency(total, true))
    setTotalMonthly(formatCurrency(monthly, true))

    setLevelOneComm(formatCurrency(levelOneTotal, true))
    setLevelTwoComm(formatCurrency(levelTwoTotal, true))
    setLevelThreeComm(formatCurrency(levelThreeTotal, true))
    setLevelFourComm(formatCurrency(levelFourTotal, true))
    setLevelFiveComm(formatCurrency(levelFiveTotal, true))

    setCalculated(true)
  }

  // Handle the refresh button clicked
  const refresh = () => {
    setPropertiesSold(0)
    setAveragePrice(null)
    setCommPercent(null)

    setLevelOneAgent(0)
    setLevelTwoAgent(0)
    setLevelThreeAgent(0)
    setLevelFourAgent(0)
    setLevelFiveAgent(0)

    setLevelOneComm(0)
    setLevelTwoComm(0)
    setLevelThreeComm(0)
    setLevelFourComm(0)
    setLevelFiveComm(0)

    setTotalAgents(0)
    setTotalAnnual(0)
    setTotalMonthly(0)

    setCalculated(false)
  }

  // Handle inputting the number
  const inputNumber = (value, setField) => {
    const numValue = parseInt(value)

    if (!isNaN(numValue)) {
      setField(numValue)
    } else {
      setField(0)
    }
  }

  return (
    <div className={`${calculatorBaseClass}__container`}>
      <img src={Logo} alt="RARE-LOGO" />

      {/* Container wrapping both boxes*/}
      <Row className={'backButton'}>
        <IconButton onClick={() => navigate('/revenue_share')}>
          <ArrowCircleLeft sx={{ color: 'white' }} />
        </IconButton>
        Back
      </Row>
      <div className={`${calculatorBaseClass}__box-container`}>
        {/* Revenue Sharing Plan box*/}
        <div className={`${calculatorLeftBoxClass} _container`}>
          <div className={`${calculatorTitleClass} _container`}>
            <div className={`${calculatorTitleClass} _title-name form-title-font`}>Revenue Sharing Plan</div>
            <hr />
          </div>

          <div className={`${calculatorSectionClass} _container`}>
            {/* Header*/}
            <div className={`${calculatorFieldRowClass} _container`}>
              <div className={`${calculatorFieldClass} _label sm-table-col field-section-label-font`}>Tier</div>
              <div className={`${calculatorFieldClass} _label wide-table-col field-section-label-font`}>
                Min <span>#</span> of influenced agents
                <div className={`${calculatorFieldClass} _caption`}>(to unlock respective tier)</div>
              </div>
              <div className={`${calculatorFieldClass} _label wide-table-col field-section-label-font`}>
                Payout <span>(%)</span>
              </div>
              <div className={`${calculatorFieldClass} _label wide-table-col field-section-label-font`}>
                Max Payout<span>/</span>Agent
              </div>
            </div>

            {Object.entries(revShareConstants).map(([key, revShareEntry]) => {
              return (
                <div className={`${calculatorFieldRowClass} _container`}>
                  <div className={`${calculatorFieldClass} _label sm-table-col field-section-label-font`}>
                    {revShareEntry.level}
                  </div>
                  <input
                    type={'number'}
                    className={'wide-input'}
                    value={revShareEntry.active_front_line}
                    readOnly={true}
                  />
                  <input
                    type={'number'}
                    className={'wide-input'}
                    value={revShareEntry.rev_share_percent}
                    readOnly={true}
                  />
                  <input
                    className={'wide-input transparent-background'}
                    value={revShareEntry.maximum_revenue}
                    readOnly={true}
                  />
                </div>
              )
            })}
          </div>
        </div>

        {calculated ? (
          <div className={`${calculatorDividerClass} _longer`} />
        ) : (
          <div className={`${calculatorDividerClass}`} />
        )}

        {/* Calculator box*/}
        <div className={`${calculatorRightBoxClass} _container`}>
          {/* Title*/}
          <div className={`${calculatorTitleClass} _container`}>
            <div className={`${calculatorTitleClass} _title-name form-title-font`}>Calculator</div>
            <hr />
          </div>

          <div className={`${calculatorSectionClass} _container`}>
            {/* Header*/}
            <div className={`${calculatorFieldRowClass} _container spaced`}>
              <div className={`${calculatorFieldClass} _label wide-table-col-mobile field-section-label-font`}>
                Tier
              </div>
              <div className={`${calculatorFieldClass} _label lg-wide-table-col field-section-label-font`}>
                <div className={`${calculatorFieldClass} _label sub-title`}>Payout</div>
                <hr />
                Influenced Agents
              </div>
              {calculated ? (
                <div className={`${calculatorFieldClass} _label lg-wide-table-col field-section-label-font`}>
                  Rev Share <span>$</span>
                </div>
              ) : null}
            </div>

            {/* Level 1 */}
            <div className={`${calculatorFieldRowClass} _container spaced`}>
              <div className={`${calculatorFieldClass} _label wide-table-col-mobile field-section-label-font`}>1</div>
              <input
                type={'number'}
                className={'lg-wide-input'}
                onChange={(e) => setLevelOneAgent(parseInt(e.target.value))}
                value={levelOneAgent}
                placeholder={''}
              />
              {calculated ? <input className={'lg-wide-input'} value={levelOneComm} readOnly={true} /> : null}
            </div>

            {/* Level 2 */}
            <div className={`${calculatorFieldRowClass} _container spaced`}>
              <div className={`${calculatorFieldClass} _label wide-table-col-mobile field-section-label-font`}>2</div>
              <input
                type={'number'}
                className={'lg-wide-input'}
                onChange={(e) => setLevelTwoAgent(parseInt(e.target.value))}
                value={levelTwoAgent}
                placeholder={''}
              />
              {calculated ? <input className={'lg-wide-input'} value={levelTwoComm} readOnly={true} /> : null}
            </div>

            {/* Level 3 */}
            <div className={`${calculatorFieldRowClass} _container spaced`}>
              <div className={`${calculatorFieldClass} _label wide-table-col-mobile field-section-label-font`}>3</div>
              <input
                type={'number'}
                className={'lg-wide-input'}
                onChange={(e) => setLevelThreeAgent(parseInt(e.target.value))}
                value={levelThreeAgent}
                placeholder={''}
              />
              {calculated ? <input className={'lg-wide-input'} value={levelThreeComm} readOnly={true} /> : null}
            </div>

            {/* Level 4 */}
            <div className={`${calculatorFieldRowClass} _container spaced`}>
              <div className={`${calculatorFieldClass} _label wide-table-col-mobile field-section-label-font`}>4</div>
              <input
                type={'number'}
                className={'lg-wide-input'}
                onChange={(e) => setLevelFourAgent(parseInt(e.target.value))}
                value={levelFourAgent}
                placeholder={''}
              />
              {calculated ? <input className={'lg-wide-input'} value={levelFourComm} readOnly={true} /> : null}
            </div>

            {/* Level 5 */}
            <div className={`${calculatorFieldRowClass} _container spaced`}>
              <div className={`${calculatorFieldClass} _label wide-table-col-mobile field-section-label-font`}>5</div>
              <input
                type={'number'}
                className={'lg-wide-input'}
                onChange={(e) => setLevelFiveAgent(parseInt(e.target.value))}
                value={levelFiveAgent}
                placeholder={''}
              />
              {calculated ? <input className={'lg-wide-input'} value={levelFiveComm} readOnly={true} /> : null}
            </div>
          </div>

          {/* Summary */}
          {calculated ? (
            <div className={`${calculatorSectionClass} _container`}>
              <div className={`${calculatorFieldRowClass} _container`}>
                <div className={`${calculatorFieldClass} _label wide-table-col`} />
                <div className={`${calculatorSectionClass} _title sub-title`}>
                  Potential Revenue Shares
                  <hr />
                </div>
              </div>

              <div className={`${calculatorFieldRowClass} _container`}>
                <div className={`${calculatorFieldClass} _label wide-table-col field-section-label-font`}>
                  Total Annual<span>:</span>
                </div>
                <input className={'xl-wide-input'} value={totalAnnual} readOnly={true} />
              </div>

              <div className={`${calculatorFieldRowClass} _container`}>
                <div className={`${calculatorFieldClass} _label wide-table-col field-section-label-font`}>
                  Monthly<span>:</span>
                </div>
                <input className={'xl-wide-input'} value={totalMonthly} readOnly={true} />
              </div>

              <div className={`${calculatorFieldRowClass} _container`}>
                <div className={`${calculatorFieldClass} _label wide-table-col field-section-label-font`}>
                  Total Agents in Revenue Sharing Plan<span>:</span>
                </div>
                <input type={'number'} className={'xl-wide-input'} value={totalAgents} readOnly={true} />
              </div>
            </div>
          ) : null}

          <hr />

          <div className={`${calculatorSectionClass} _container`}>
            <div className={`${calculatorControlClass} _container`}>
              <div className={`${calculatorControlClass} _item`} onClick={() => refresh()}>
                {/*<svg className={""}>*/}
                {/*    <use xlinkHref={'/img/sprite.svg#icon-rare-save-draft'}></use>*/}
                {/*</svg>*/}
                <div className={`${calculatorControlClass} _text`}>Refresh</div>
              </div>

              <div className={`${calculatorControlClass} _item`} onClick={() => calculate()}>
                {/*<svg className={""}>*/}
                {/*    <use xlinkHref={'/img/sprite.svg#icon-rare-submit'}></use>*/}
                {/*</svg>*/}
                <div className={`${calculatorControlClass} _text`}>Calculate</div>
              </div>
            </div>

            <br />
          </div>
        </div>
      </div>

      <div className={`${calculatorBaseClass}__box-container no-border`} style={{ margin: '32px 0px' }}>
        <div className={`${calculatorRightBoxClass} _container`}>
          <div className={`${calculatorFieldClass} _caption-italic`}>
            This calculator is for illustration purposes only and should not be construed otherwise. Payouts from the
            Revenue Sharing Plan may vary based on influenced agents’ annual commission production. Given the complexity
            of Revenue Sharing Plan, all agents who join Rare Real Estate Inc. will be subject to individualized
            agreements outlining complete terms of the Revenue Sharing Plan as well as their overall terms as
            Independent Contractors with Rare Real Estate inc. Please speak to a Rare Real Estate inc. employee for
            additional details.
          </div>
        </div>
      </div>
    </div>
  )
}

export default Calculator
