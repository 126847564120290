import Typography from 'components/typography/Typography'
import styles from './avatar.module.scss'

const getInitials = (name) => {
  const names = name.trim().split(' ')
  const firstName = names.shift()
  const lastName = names.pop()
  const firstNameInitial = (firstName && firstName[0]) || ''
  const lastNameInitial = (lastName && lastName[0]) || ''
  return (firstNameInitial + lastNameInitial).toUpperCase()
}

const Avatar = ({ size = 40, name, src, style, className }) => {
  const avatarStyle = {
    width: size,
    height: size,
    ...style
  }

  return (
    <div style={avatarStyle} className={`${styles.avatar} ${className}`}>
      {src ? (
        <img className={`${styles.avatarImage}`} src={src} alt={getInitials(name)} />
      ) : (
        <Typography.Body type={2}>{getInitials(name)}</Typography.Body>
      )}
    </div>
  )
}

export default Avatar
