import React from 'react'
import { Dialog, DialogActions, IconButton, Tooltip, Typography } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Divider } from 'components'
import vars from '../../vars.scss'
import styles from './AlertPopup.module.scss'

const AlertPopup = ({
  openModel,
  HeaderTitle,
  handleOnCloseBtn,
  handleLeftBtnClick,
  handleRightBtnClick,
  children,
  leftBtnLabel,
  rightBtnLabel
}) => {
  const defaultModelCloseIcon = (
    <Tooltip title="Close">
      <IconButton
        aria-label="Cancel"
        onClick={handleOnCloseBtn}
        size="small"
        sx={{ color: vars['font-color-primary'] }}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  )

  return (
    <Dialog
      open={openModel}
      maxWidth={'lg'}
      fullWidth={false}
      className={styles.alertPopUpMain}
      PaperProps={{
        style: { borderRadius: 0 }
      }}>
      <DialogTitle className={styles.alertPopUPTitle}>
        <Typography>{HeaderTitle}</Typography>
        <Typography className={styles.alertPopUPCloseBtn} onClick={handleOnCloseBtn}>
          {defaultModelCloseIcon}
        </Typography>
      </DialogTitle>
      <DialogContent className={styles.alertPopUPContent}>{children}</DialogContent>
      <Divider style={{ margin: '16px 0px 0px 0px' }} />
      <DialogActions className={styles.alertPopUPActions}>
        <Button onClick={handleLeftBtnClick}>{leftBtnLabel ? leftBtnLabel : 'Cancel'}</Button>
        <Button onClick={handleRightBtnClick}>{rightBtnLabel ? rightBtnLabel : 'Confirm'}</Button>
      </DialogActions>
    </Dialog>
  )
}
export default AlertPopup
