import React from 'react'
import { Dialog, DialogActions } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import { Button, Typography } from 'components'
import styles from './AlertPopup.module.scss'
import deleteIcon from '../../assets/images/deleteIcon.png'

const WarningPopup = ({
  openModel,
  loading = false,
  cancleBtnLabel = 'Cancel',
  confirmBtnLabel = 'Confirm',
  onCancle,
  onConfirm,
  children
}) => {
  return (
    <Dialog open={openModel} maxWidth={'lg'} fullWidth={false} className={styles.WarningPopupMain}>
      <div className={styles.deleteIconDiv}>
        <img src={deleteIcon} alt="deleteIcon" height={45} width={45} />
        <Typography.Heading type={3} className={styles.warningTitle}>
          Are you sure ?
        </Typography.Heading>
      </div>
      <DialogContent className={styles.warningPopupContent}>{children}</DialogContent>

      <DialogActions className={styles.warningPopupActions}>
        <Button disabled={loading} onClick={onCancle} type="gray">
          {cancleBtnLabel}
        </Button>
        <Button loading={loading} onClick={onConfirm} style={{ minWidth: 80 }} type="lightBlack">
          {confirmBtnLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default WarningPopup
