import React from 'react'
import { Typography } from 'components'
import styles from '../../deals.module.scss'
import DocumentBase from '../../DocumentBase'
import { useFormContext } from 'react-hook-form'
import { useContext } from 'react'
import { DealContext } from 'context'
import { useMemo } from 'react'
import { DealValidationHelper } from 'helpers/deal/DealValidationHelper'
import AdditionalDocuments from 'components/upload/AdditionalDocuments'

const LeaseDocuments = ({ isAgent = false }) => {
  const { deal, isView, isClosedAndAgent } = useContext(DealContext)
  const { getValues, control } = useFormContext()
  const { brokerage_side, double_ended, representative_type } = getValues()
  const brokerageSide = brokerage_side ?? deal?.brokerage_side
  const doubleEnded = double_ended ?? deal?.double_ended
  const representativeType = representative_type ?? deal?.representative_type

  const isListingBrokerage = useMemo(() => {
    return DealValidationHelper.isListingBrokerage(brokerageSide, doubleEnded, representativeType)
  }, [brokerageSide, doubleEnded, representativeType])

  const isCoopBrokerage = useMemo(() => {
    return DealValidationHelper.isCoopBrokerage(brokerageSide, doubleEnded, representativeType)
  }, [brokerageSide, doubleEnded, representativeType])

  return (
    <>
      <DocumentBase title="All Lease Documents" name="lease_all_document" required={isAgent} />
      <Typography.Subheading type={1} getValues={getValues} className={`${styles.orDivider} documentsOrDivider`}>
        OR
      </Typography.Subheading>
      <DocumentBase title="Agreement to Lease" name="lease_agreement_lease" />
      <DocumentBase title="Ontario Standard Lease Agreement" name="lease_ontario_standard_lease" required={isAgent} />
      <DocumentBase
        title="Confirmation of Cooperation"
        name="lease_confirmation_cooperating_representative"
        required={isAgent}
      />
      {deal?.documents?.map((doc) => {
        if (doc?.checklist_document_type === 'lease_working_with_realtor') {
          return <DocumentBase title="Working with a Realtor" name="lease_working_with_realtor" required={isAgent} />
        }
      })}
      {isCoopBrokerage && (
        <DocumentBase
          title="Buyer Representation Agreement/Tenant Representation Agreement"
          name="lease_buyer_rep_agreement"
          required={isAgent}
        />
      )}
      <DocumentBase title="Copy of Deposit Receipt" name="lease_copy_deposit_receipt" required={isAgent} />
      {isListingBrokerage && (
        <>
          <DocumentBase title="Listing Agreement" name="lease_listing_agreement" required={isAgent} />
          <DocumentBase title="Data Sheet" name="lease_data_sheet" required={isAgent} />
        </>
      )}
      <AdditionalDocuments isView={isView} control={control} isClosedAndAgent={isClosedAndAgent} tresa={true} />
    </>
  )
}

export default LeaseDocuments
