import React, { useCallback } from 'react'
import { Button, Checkbox, IconButton, Tooltip } from '@mui/material'
import { Col, InputSearch, Row, Typography, Divider, Loading } from 'components'
import moment from 'moment'
import styles from './announcements.module.scss'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { AttachFile, OpenInFull } from '@mui/icons-material'
import agentPinnedIcon from '../../assets/images/agentPinnedIcon.png'
import adminPinnedIcon from '../../assets/images/adminPinnedIcon.png'
import starredIcon from '../../assets/images/starredIcon.png'
import { UPDATE_ANNOUNCEMENT } from 'helpers/mutations'
import { useNavigate } from 'react-router-dom'

const AnnouncementsList = (props) => {
  const {
    isAdmin = false,
    data,
    loading,
    searchText,
    announcements,
    selectedToDelete,
    refetch,
    onPreviewChange,
    handleBulkDelete,
    onShowMore,
    onSearch
  } = props
  const navigate = useNavigate()

  const [updateAnnouncement] = useMutation(UPDATE_ANNOUNCEMENT, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    }
  })

  const handlePreviewChange = async (value) => {
    if (!value?.is_read) {
      await updateAnnouncement({
        variables: {
          data: {
            announcementId: value.public_id,
            read: true
          }
        }
      })
      refetch()
    }
    onPreviewChange(value)
  }

  const getHighlightedText = (text) => {
    let parts = text?.split(new RegExp(`(${searchText})`, 'gi'))
    return parts?.map((part, index) => (
      <React.Fragment key={index}>
        {part.toLowerCase() === searchText?.toLowerCase() ? <b style={{ backgroundColor: '#eed8a2' }}>{part}</b> : part}
      </React.Fragment>
    ))
  }

  const handleView = useCallback(
    (id) => {
      if (id) {
        navigate('/announcements/' + id)
      }
    },
    [navigate]
  )

  return (
    <>
      <div style={{ margin: 16 }}>
        <InputSearch name="search" onChange={onSearch} />
      </div>
      <div className={styles.list}>
        {announcements?.length > 0 ? (
          announcements?.map((value, index) => {
            return (
              <>
                <div
                  id={value.public_id}
                  key={index}
                  className={value?.is_read ? styles.readBox : styles.unReadBox}
                  onClick={() => handlePreviewChange(value)}>
                  <div>
                    <Row className={styles.row}>
                      <IconButton className={styles.expand} onClick={() => handleView(value?.public_id)}>
                        <OpenInFull sx={{ fontSize: '18px' }} />
                      </IconButton>
                      <Col>
                        {isAdmin && (
                          <Checkbox
                            id={value.public_id}
                            sx={{
                              padding: '4px 4px 0px 0px ',
                              '& .MuiSvgIcon-root': { fontSize: 20 },
                              '&.Mui-checked': {
                                color: '#1f1616'
                              }
                            }}
                            checked={selectedToDelete.includes(value.public_id)}
                            onChange={handleBulkDelete}
                          />
                        )}
                      </Col>
                      <Col>
                        <Typography.Heading
                          style={{
                            fontSize: 17,
                            fontWeight: value?.is_read ? 500 : 600,
                            color: value?.is_read ? '#36454F' : '#000000'
                          }}
                          type={6}>
                          {getHighlightedText(value.title)}
                        </Typography.Heading>
                        <Typography.Body style={{ fontSize: 10 }} className={styles.created}>
                          {isAdmin
                            ? getHighlightedText(moment(value.created).format('LL'))
                            : value?.start_date
                              ? getHighlightedText(moment(value?.start_date).format('LL'))
                              : getHighlightedText(moment(value.created).format('LL'))}
                        </Typography.Body>
                        {value.sender && (
                          <Typography.Body
                            style={{ fontSize: 13, fontWeight: value?.is_read ? 500 : 600 }}
                            className={styles.preview}>
                            From {getHighlightedText(value.sender)}
                          </Typography.Body>
                        )}
                      </Col>
                    </Row>
                    <Row justifyContent="flex-end" style={{ marginRight: 12, paddingBottom: 3 }}>
                      {value?.attachments?.length > 0 && (
                        <Tooltip title={'Has Attachements'}>
                          <AttachFile sx={{ fontSize: '18px', color: '#4d4d4d' }} />
                        </Tooltip>
                      )}
                      {value?.is_starred && (
                        <Tooltip title={'Important'}>
                          <img src={starredIcon} height={18} width={18} alt="adminPinnedIcon" />
                        </Tooltip>
                      )}
                      {value?.is_pinned && (
                        <Tooltip title={'Pinned By You'}>
                          <img src={agentPinnedIcon} height={18} width={18} alt="agentPinnedIcon" />
                        </Tooltip>
                      )}
                      {value?.admin_pinned && (
                        <Tooltip title={'Pinned By Admin'}>
                          <img src={adminPinnedIcon} height={18} width={18} alt="adminPinnedIcon" />
                        </Tooltip>
                      )}
                    </Row>
                  </div>
                  <Divider style={{ margin: '0px 16px' }} />
                </div>
                {announcements?.length - 1 === index && data?.totalCount > announcements?.length && (
                  <Row justifyContent="center">
                    {loading ? (
                      <Loading />
                    ) : (
                      <Button
                        className={styles.loadMore}
                        variant="contained"
                        sx={{
                          mt: 1,
                          padding: '4px 16px',
                          fontSize: '12px',
                          backgroundColor: '#616161',
                          textTransform: 'none',
                          '&:hover': {
                            backgroundColor: 'black !important'
                          }
                        }}
                        onClick={onShowMore}>
                        Load More...
                      </Button>
                    )}
                  </Row>
                )}
              </>
            )
          })
        ) : (
          <Typography.Body type={6} className={styles.noAnnouncements}>
            No Announcements
          </Typography.Body>
        )}
      </div>
    </>
  )
}

export default AnnouncementsList
