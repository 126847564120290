import React from 'react'
import { BasicUpload } from 'components'
import { Controller, useFieldArray } from 'react-hook-form'
import { useContext } from 'react'
import { DocumentContext } from 'context'

const AdditionalDocuments = ({
  isView = false,
  isAdminSection = false,
  control,
  isClosedAndAgent = false,
  tresa = false
}) => {
  const { handleDeleteFile } = useContext(DocumentContext)

  const { fields, append, remove } = useFieldArray({
    control,
    name: isAdminSection ? 'adminDocuments' : 'additionalDocuments'
  })

  return (
    <>
      {fields?.map(({ id }, index) => (
        <Controller
          key={id}
          name={isAdminSection ? `adminDocuments.${index}` : `additionalDocuments.${index}`}
          control={control}
          defaultValue={null}
          render={({ field: { onChange, value } }) => (
            <BasicUpload
              onChange={onChange}
              title={tresa ? `Additional Documents/TRESA Documents ${index + 1}` : `Additional Document ${index + 1}`}
              append={append}
              remove={remove}
              fields={fields}
              defaultValue={value}
              index={index}
              disabled={isView}
              canShowAdditionalDoc
              isAllDocument={isAdminSection}
              handleDeleteFile={handleDeleteFile}
              isClosedAndAgent={isClosedAndAgent}
            />
          )}
        />
      ))}
    </>
  )
}

export default AdditionalDocuments
