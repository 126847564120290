import { useState } from 'react'
import vars from '../../vars.scss'
import styles from './filter.module.scss'
import { Dropdown, Typography } from 'components'

const Filter = ({ options = [], defaultValue, onChange = console.log }) => {
  if (!defaultValue) defaultValue = options[0].value
  const [selected, setSelected] = useState(defaultValue)

  if (options.length === 0) return null

  const handleOptionClick = (option) => {
    setSelected(option.value)
    if (onChange) onChange(option.value)
  }

  return (
    <div className={styles.filter}>
      {options.map((option, i) => {
        const active = option.value === selected
        const color = active ? vars['font-color-dark'] : vars['font-color-secondary']
        const cursor = active ? 'auto' : 'pointer'
        return (
          <div
            className={`${styles.option} ${active ? styles.selected : ''}`}
            onClick={() => {
              handleOptionClick(option)
            }}
            key={i}>
            <option.icon fontSize="16px" style={{ color, cursor }} />
            <Typography.Label type="2" style={{ color, cursor }}>
              {option.label}
            </Typography.Label>
          </div>
        )
      })}
    </div>
  )
}

const Tabs = ({ options = [], defaultValue, onChange = console.log }) => {
  if (!defaultValue) defaultValue = options[0].value
  const [selected, setSelected] = useState(defaultValue)

  if (options.length === 0) return null

  const handleOptionClick = (option) => {
    setSelected(option.value)
    if (onChange) onChange(option.value)
  }

  return (
    <div className={styles.filterTabs}>
      {options.map((option, i) => {
        const active = option.value === selected
        const color = active ? vars['font-color-dark'] : vars['font-color-secondary']
        const cursor = active ? 'auto' : 'pointer'
        return (
          <div
            className={`${styles.option} ${active ? styles.selected : ''}`}
            onClick={() => {
              handleOptionClick(option)
            }}
            key={i}>
            <option.icon fontSize="20px" style={{ color, cursor }} />
            <Typography.Label type="1" style={{ color, cursor }}>
              {option.label}
            </Typography.Label>
          </div>
        )
      })}
    </div>
  )
}

Filter.Tabs = Tabs

export default Filter
