import React from 'react'
import styles from './library.module.scss'
import { Tab, Tabs, ThemeProvider, Tooltip, createTheme } from '@mui/material'

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 400,
          alignItems: 'baseline',
          '&.Mui-selected': {
            color: '#C12121 !important',
            fontSize: 16,
            fontWeight: 600
          }
        }
      }
    }
  }
})

const LibraryTabs = (props) => {
  const { isAdmin, isMobile, value, libraryOptions, onMyDownloads, onMyFiles, onAllFiles, onTabChange, onChange } =
    props
  return (
    <ThemeProvider theme={theme}>
      <Tabs
        value={value}
        onChange={onChange}
        orientation="vertical"
        variant="scrollable"
        scrollButtons={false}
        TabIndicatorProps={{
          style: { display: 'none' }
        }}
        className={styles.tabs}>
        <Tab label={'All Files'} sx={{ textTransform: 'none' }} onClick={onAllFiles} />
        {libraryOptions?.map((option, index) => {
          return (
            <Tab
              key={index}
              label={
                isMobile ? (
                  <div>{option.length > 5 ? `${option.slice(0, 5)}...` : option}</div>
                ) : (
                  <Tooltip placement="right-start" title={option.length > 20 ? option : ''}>
                    <div>{option.length > 20 ? `${option.slice(0, 20)}...` : option}</div>
                  </Tooltip>
                )
              }
              sx={{ textTransform: 'none', marginLeft: 2 }}
              onClick={() => onTabChange(option)}
            />
          )
        })}
        {isAdmin && <Tab label={'My Files'} sx={{ textTransform: 'none' }} onClick={onMyFiles} />}
        <Tab label={'Downloads'} sx={{ textTransform: 'none' }} onClick={onMyDownloads} />
      </Tabs>
    </ThemeProvider>
  )
}

export default LibraryTabs
