import { gql, useMutation } from '@apollo/client'
import { Input, PopUp } from 'components'
import { GET_ROOT_FOLDERS } from 'helpers/gql'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import styles from './library.module.scss'

const CreateFolderPopup = (props) => {
  const { hideCreateFolder, createFolder, refetch } = props
  const params = useParams()
  const inValidFileText = "A folder name can't contain any of the following characters:  / : * ?  < >| "

  const validationSchema = Yup.object().shape({
    folderName: Yup.string()
      .required('Folder Name is required')
      .matches(/^[\w\s!@#$%^&()_+={}\[\];',.~-]+$/, inValidFileText)
  })

  const { handleSubmit, control, resetField } = useForm({ resolver: yupResolver(validationSchema) })

  const [createLibraryFolder, { loading }] = useMutation(CREATE_LIBRARY_FOLDER, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('Folder created.')
      handleCancel()
      refetch()
    },
    refetchQueries: [{ query: GET_ROOT_FOLDERS }]
  })

  const handleCreateFolder = (values) => {
    createLibraryFolder({ variables: { prefix: params['*'] + values.folderName + '/' } })
  }

  const handleCancel = () => {
    resetField('folderName')
    hideCreateFolder()
  }

  return (
    <div>
      <PopUp
        title={'Create Folder'}
        open={createFolder}
        confirmBtnLabel={'Create'}
        canShowCancelButton={false}
        onCancel={handleCancel}
        loading={loading}
        onConfirm={handleSubmit(handleCreateFolder)}>
        <div className={styles.createFolderPopup}>
          <form>
            <Controller
              name="folderName"
              control={control}
              defaultValue={undefined}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Folder Name"
                  required
                  name="folderName"
                  style={{ width: '100%' }}
                />
              )}
            />
          </form>
        </div>
      </PopUp>
    </div>
  )
}

const CREATE_LIBRARY_FOLDER = gql`
  mutation createLibraryFolder($prefix: String!) {
    createLibraryFolder(prefix: $prefix)
  }
`

export default CreateFolderPopup
