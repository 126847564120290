import styles from './table.module.scss'
import Typography from 'components/typography/Typography'
import Pagination from 'components/pagination/Pagination'
import { useState } from 'react'
import { paginate } from 'helpers/utils'

const Row = ({ row, columns, onClick }) => {
  const handleRowClick = () => {
    if (onClick) onClick(row)
  }

  return (
    <tr className={styles.tableRow}>
      {/* <th>
        <input type="checkbox" />
      </th> */}
      {columns.map((column) => {
        const value = row[column.key]
        return (
          <td key={column.key} onClick={column.key === 'action' ? () => {} : handleRowClick}>
            {column.render ? column.render(row) : <Typography.Body type={2}>{value}</Typography.Body>}
          </td>
        )
      })}
    </tr>
  )
}

// TODO: Update Typography.Label to use subheading when Fardeen creates those

const Table = ({ data = [], columns, pagination = true, pageSize = 10, rowOnClick }) => {
  const [page, setPage] = useState(1)
  pagination = pagination && data.length >= pageSize

  if (!columns && data.length > 0) {
    columns = Object.keys(data[0]).map((key) => {
      return {
        key,
        title: key,
        render: (row) => <Typography.Body type={2}>{row[key]}</Typography.Body>
      }
    })
  }

  const currentPageData = pagination ? paginate(data, pageSize, page) : data

  if (!columns) return null

  return (
    <div className={styles.tableWrapper}>
      <div style={{ overflowX: 'auto' }}>
        <table className={styles.table}>
          <thead className={styles.tableHeader}>
            <tr>
              {columns.map((column) => {
                return (
                  <th key={column.key} style={{ width: column.width || 'auto' }}>
                    <Typography.Subheading type={2}>{column.title}</Typography.Subheading>
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {currentPageData.map((row, index) => (
              <Row key={index} columns={columns} row={row} onClick={rowOnClick} />
            ))}
          </tbody>
        </table>
      </div>
      {pagination && (
        <div className={styles.paginationWrapper}>
          <Pagination total={data.length} pageSize={pageSize} onChange={setPage} />
        </div>
      )}
    </div>
  )
}

export default Table
