import { useState, useEffect } from 'react'
import { LogoMobile } from './Logo'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import MobileDrawer from './MobileDrawer'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { useLocation, useNavigate } from 'react-router-dom'

const MobileNav = () => {
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  const openWhatsApp = () => {
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    if (isMobileDevice) {
      window.location.href = `${process.env.REACT_APP_CHAT_WHATSAPP_LINK}`
      navigate('/')
    } else {
      window.open(`${process.env.REACT_APP_CHAT_WEB_WHATSAPP_LINK}`, '_blank')
      navigate('/')
    }
  }

  useEffect(() => {
    handleClose()
  }, [location])

  return (
    <section id="mobile-nav">
      <LogoMobile />
      <WhatsAppIcon style={{ marginLeft: 177 }} onClick={openWhatsApp} />
      {open ? <CloseIcon onClick={handleClose} /> : <MenuIcon onClick={handleOpen} />}
      <MobileDrawer open={open} setOpen={setOpen} />
    </section>
  )
}

export default MobileNav
