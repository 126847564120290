import { gql, useMutation } from '@apollo/client'
import { Modal, Col, Row, Button, Input, PhoneInput } from 'components'
import { GET_AGENTS, GET_ALL_USERS } from 'helpers/gql'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { isValidPhoneNumber } from 'react-phone-number-input'

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
const phoneRegex = /^(1-)?\d{3}-\d{3}-\d{4}$/

const CreateAdmin = ({ open, setOpen }) => {
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    phone_number: Yup.string()
      .required('Phone number is required')
      .transform((value, defaultValue) => (defaultValue === '' ? undefined : value))
      .matches(phoneRegex, 'Please enter a valid phone number'),
    email: Yup.string().matches(emailRegex, 'Email address must be a valid email').required('Email Address is required')
  })

  const { handleSubmit, control, reset } = useForm({ resolver: yupResolver(validationSchema) })

  const [createUser, { loading }] = useMutation(CREATE_USER, {
    onError: (err) => {
      toast.dismiss()
      console.error(err)
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('User created.')
      reset()
      setOpen(false)
    },
    refetchQueries: [{ query: GET_AGENTS }, { query: GET_ALL_USERS, variables: {
      excludeDeleted: false
    } }]
  })

  const onCancel = () => {
    reset()
    setOpen(false)
  }

  const onSubmit = async (data) => {
    createUser({
      variables: {
        data: {
          ...data,
          role: 2
        }
      }
    })
  }

  return (
    <Modal
      id="createAdmin"
      title="Create Admin"
      open={open}
      backdropClickClose={false}
      buttons={
        <Row justifyContent="space-between" style={{ width: '100%' }}>
          <Button onClick={onCancel} type="tertiary">
            Cancel
          </Button>
          <Button onClick={handleSubmit(onSubmit)} loading={loading}>
            Create
          </Button>
        </Row>
      }
      handleClose={() => setOpen(false)}>
      <form id="createUser">
        <Col>
          <Controller
            name="first_name"
            control={control}
            defaultValue={undefined}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type="text"
                error={error?.message}
                value={value}
                onChange={onChange}
                label="First name"
                required
                name="first_name"
                style={{ width: '100%' }}
              />
            )}
          />

          <Controller
            name="last_name"
            control={control}
            defaultValue={undefined}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type="text"
                error={error?.message}
                value={value}
                onChange={onChange}
                label="Last name"
                required
                name="last_name"
                style={{ width: '100%' }}
              />
            )}
          />
        </Col>

        <Controller
          name="phone_number"
          control={control}
          defaultValue={undefined}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <PhoneInput
              name="phone_number"
              label="Phone number"
              error={error?.message}
              required
              value={value}
              onChange={onChange}
              style={{ width: '100%' }}
            />
          )}
        />

        <Controller
          name="email"
          control={control}
          defaultValue={undefined}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type="text"
              error={error?.message}
              value={value}
              onChange={onChange}
              label="Email address"
              required
              name="email"
              style={{ width: '100%' }}
            />
          )}
        />
      </form>
    </Modal>
  )
}

const CREATE_USER = gql`
  mutation createUser($data: UpdateProfileInput!) {
    createUser(data: $data)
  }
`
export default CreateAdmin
