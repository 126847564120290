import React from 'react'
import { STATUS } from 'helpers/constants'
import { Button, Row } from 'components'
import saveIcon from '../../../assets/images/saveIcon.png'
import saveDraftIcon from '../../../assets/images/saveDraftIcon.png'
import cancelIcon from '../../../assets/images/cancelIcon.png'
import styles from '../deals.module.scss'

const AgentFooter = ({ loading = false, dealStatus, onSaveDraft, onCancel, onSubmit }) => {
  return (
    <Row className={styles.agentDealFooter}>
      <Button
        style={{ width: 120 }}
        prefixIcon={<img src={cancelIcon} alt="cancelIcon" height={12} width={12} />}
        onClick={onCancel}>
        Cancel
      </Button>
      <Button
        style={{ width: 120 }}
        prefixIcon={<img src={saveDraftIcon} alt="saveDraftIcon" height={12} width={12} />}
        disabled={dealStatus === STATUS.submitted && loading}
        loading={dealStatus === STATUS.draft && loading}
        onClick={onSaveDraft}>
        Save Draft
      </Button>
      <Button
        style={{ width: 120 }}
        prefixIcon={<img src={saveIcon} alt="saveIcon" height={12} width={12} />}
        loading={dealStatus === STATUS.submitted && loading}
        disabled={dealStatus === STATUS.draft && loading}
        onClick={onSubmit}>
        Submit
      </Button>
    </Row>
  )
}

export default AgentFooter
