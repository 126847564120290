import NavItems from './NavItems'
import MobileNav from './MobileNav'
import { LogoDesktop } from './Logo'
import './nav.scss'
import { Avatar } from '@mui/material'
import { useContext } from 'react'
import { UserContext } from 'context'
import { Typography } from 'components'
import { getFileUrl } from 'helpers/utils'
import vars from '../../vars.scss'
import { USER_ROLES } from 'helpers/constants'
import RecoExpiry from './RecoExpiry'
import moment from 'moment'

const Nav = (expiryDate) => {
  const { user } = useContext(UserContext)
  const agent = user.role === USER_ROLES.agent
  const recoExpiry = user.reco_expiry && moment(user.reco_expiry)

  const onSave = () => {}

  return (
    <>
      <section id="desktop-nav">
        <div style={{ margin: '40px 45px 15px 45px' }}>
          <Avatar
            className="desktop-logo"
            alt="userImag"
            src={getFileUrl(user.thumbnail)}
            sx={{
              width: 150,
              height: 150,
              backgroundColor: vars['font-color-white'],
              '& .MuiAvatar-fallback': {
                fill: 'black'
              }
            }}
          />
          <Typography.Heading type={5} style={{ color: vars['font-color-white'], marginTop: 15 }}>
            {user.name}
          </Typography.Heading>
        </div>

        {recoExpiry && agent && <RecoExpiry expiryDate={user.reco_expiry} showLabel={false} isNav={true} />}

        <NavItems />
        <LogoDesktop className="desktop-logo" style={{ margin: 15, flexShrink: 0 }} />
      </section>
      <MobileNav />
    </>
  )
}

export default Nav
