import { Grid } from '@mui/material'
import { Input, PhoneInput, Typography } from 'components'
import { DealContext } from 'context'
import { useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const OutsideBrokerage = () => {
  const { control } = useFormContext()
  const { deal, disabled = false } = useContext(DealContext)

  return (
    <>
      <Typography.Heading
        type={5}
        style={{ marginTop: 22, fontFamily: 'Source Sans Pro' }}
        gap={50}
        className="dealStepTitle">
        Outside Brokerage & Agent Details
      </Typography.Heading>
      <Grid container columns={12}>
        <Grid container columnSpacing={7} mt={2}>
          <Grid xs={12} md={6} item>
            <Controller
              name="brokerage"
              control={control}
              defaultValue={deal?.brokerage || ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Brokerage Name"
                  required
                  name="brokerage"
                  disabled={disabled}
                  style={{ width: '100%' }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={7} mt={3}>
          <Grid xs={12} md={3} item>
            <Controller
              name="brokerage_email"
              control={control}
              defaultValue={deal?.brokerage_email || ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Brokerage Email Address"
                  name="brokerage_email"
                  disabled={disabled}
                  style={{ width: '100%' }}
                />
              )}
            />
          </Grid>
          <Grid xs={12} md={3} item sx={{ mt: { xs: 2, sm: 0, md: 0 } }}>
            <Controller
              name="brokerage_phone_number"
              control={control}
              defaultValue={deal?.brokerage_phone_number || ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <PhoneInput
                  label="Brokerage Phone Number"
                  value={value}
                  required
                  onChange={onChange}
                  error={error?.message}
                  disabled={disabled}
                  style={{ width: '100%' }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={7} mt={2}>
          <Grid xs={12} md={6} item>
            <Controller
              name="deal_agents[0].name"
              control={control}
              defaultValue={deal?.external_agents?.[0]?.name || ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Agent's Full Name"
                  required
                  disabled={disabled}
                  name="deal_agents[0].name"
                  style={{ width: '100%' }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={7} mt={2}>
          <Grid xs={12} md={3} item>
            <Controller
              name="deal_agents[0].email"
              control={control}
              defaultValue={deal?.external_agents?.[0]?.email || ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Agent's Email Address"
                  name="deal_agents[0].email"
                  style={{ width: '100%' }}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
          <Grid xs={12} md={3} item sx={{ mt: { xs: 2, sm: 0, md: 0 } }}>
            <Controller
              name="deal_agents[0].phone"
              control={control}
              defaultValue={deal?.external_agents?.[0]?.phone || ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <PhoneInput
                  name="deal_agents[0].phone"
                  label="Agent's Phone Number"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  disabled={disabled}
                  style={{ width: '100%' }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default OutsideBrokerage
