import { useMutation, useQuery } from '@apollo/client'
import { Loading, Row, Typography, WarningPopup } from 'components'
import { GET_ANNOUNCEMENT } from 'helpers/gql'
import React, { useCallback, useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import AnnouncementPreview from './AnnouncementPreview'
import styles from './announcements.module.scss'
import { UserContext } from 'context'
import { USER_ROLES } from 'helpers/constants'
import AnnouncementActions from './AnnouncementActions'
import { DELETE_ANNOUNCEMENT } from 'helpers/mutations'
import { usePopup } from 'hooks/usePopup'
import { IconButton, createTheme, useMediaQuery } from '@mui/material'
import { ArrowCircleLeft } from '@mui/icons-material'

const ViewAnnouncement = () => {
  const params = useParams()
  const { id } = params
  const navigate = useNavigate()
  const { user } = useContext(UserContext)
  const [confirmDelete, showConfirmDelete, hideConfirmDelete] = usePopup()
  const isAdmin = user.role === USER_ROLES.super_admin || user.role === USER_ROLES.admin
  const theme = createTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const {
    data: { getAnnouncement: announcement } = [],
    loading,
    refetch
  } = useQuery(GET_ANNOUNCEMENT, {
    variables: {
      filters: { announcementId: id }
    },
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    }
  })

  const [deleteAnnouncement] = useMutation(DELETE_ANNOUNCEMENT, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('Announcement deleted.')
      handleBack()
    }
  })

  const handleDelete = useCallback(async () => {
    await deleteAnnouncement({
      variables: { id: [announcement?.public_id] }
    })
  }, [announcement?.public_id, deleteAnnouncement])

  const handleBack = useCallback(async () => {
    navigate('/announcements')
  }, [navigate])

  return (
    <>
      <Helmet title="Announcement | RARE" />
      <section id="announcement">
        {loading || !announcement ? (
          <Loading fullpage />
        ) : (
          <div className={styles.viewAnouncement}>
            <Row justifyContent={isMobile ? 'flex-end' : 'space-between'}>
              {!isMobile && (
                <div>
                  <IconButton onClick={handleBack}>
                    <ArrowCircleLeft />
                  </IconButton>
                  Back
                </div>
              )}
              <AnnouncementActions
                isAdmin={isAdmin}
                refetch={refetch}
                preview={announcement}
                onConfirmDelete={showConfirmDelete}
              />
            </Row>
            <div className={styles.announcementPreview}>
              <AnnouncementPreview isAdmin={isAdmin} preview={announcement} isMobile={isMobile} />
            </div>
            <WarningPopup
              openModel={confirmDelete}
              confirmBtnLabel="Delete"
              onCancle={hideConfirmDelete}
              onConfirm={handleDelete}>
              <Typography.Body type={1}>Do you really want to delete this Annoncement ?</Typography.Body>
            </WarningPopup>
          </div>
        )}
      </section>
    </>
  )
}

export default ViewAnnouncement
