import React from 'react'
import { Col, Input, Typography } from 'components'
import { Controller, useFormContext } from 'react-hook-form'
import style from '../../deals.module.scss'
import { useContext } from 'react'
import { DealContext, UserContext } from 'context'
import { USER_ROLES } from 'helpers/constants'

const PreConCommission = ({ canDisabledCommission = false }) => {
  const { user } = useContext(UserContext)
  const { control, watch } = useFormContext()
  const { deal, disabled = false } = useContext(DealContext)
  const watchFields = watch(['first_installment', 'second_installment', 'third_installment', 'final_installment'])

  const installmentTotal = watchFields.reduce((sum, installment) => {
    if (!installment) return sum
    return sum + parseFloat(installment)
  }, 0)

  return (
    <div className={style.responsive}>
      <Col style={{ width: '100%', marginTop: 20 }}>
        <Typography.Heading
          type={5}
          style={{ marginTop: 22, fontFamily: 'Source Sans Pro' }}
          gap={50}
          className="dealStepTitle">
          Commission Breakdown
        </Typography.Heading>
      </Col>
      <Col style={{ width: '100%', marginTop: 10 }}>
        <Controller
          name="annual_personal_deal"
          control={control}
          defaultValue={deal ? (deal.annual_personal_deal ? 'yes' : 'no') : undefined}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div className={style.annual_personal_deal_checkbox}>
              <label htmlFor="annual_personal_deal_checkbox" className="annualDealSublabel">
                This deal is for my personal property:
              </label>
              <div>
                <input
                  id="annual_personal_deal_checkbox"
                  type="checkbox"
                  value="yes"
                  disabled={disabled || canDisabledCommission}
                  checked={value === 'yes'}
                  onChange={(e) => {
                    onChange(e.target.checked ? 'yes' : 'no')
                  }}
                />
                {error && <span>{error.message}</span>}
              </div>
            </div>
          )}
        />
      </Col>
      <Controller
        name="first_installment"
        control={control}
        defaultValue={deal?.first_installment || 0}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            type="number"
            disabled={disabled || canDisabledCommission}
            error={error?.message}
            value={value}
            required
            onChange={(e) => {
              onChange(parseFloat(e.target.value))
            }}
            label="First Installment(Amount)"
            name="first_installment"
            style={{ width: '100%', marginBottom: 10 }}
          />
        )}
      />
      {user.role !== USER_ROLES.agent && (
        <Controller
          name="second_installment"
          control={control}
          defaultValue={deal?.second_installment || 0}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type="number"
              error={error?.message}
              value={value}
              onChange={(e) => {
                onChange(parseFloat(e.target.value))
              }}
              label="Second Installment(Amount)"
              name="second_installment"
              style={{ width: '100%', marginBottom: 10 }}
              disabled={disabled || canDisabledCommission}
            />
          )}
        />
      )}
      {user.role !== USER_ROLES.agent && (
        <Controller
          name="third_installment"
          control={control}
          defaultValue={deal?.third_installment || 0}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type="number"
              error={error?.message}
              value={value}
              onChange={(e) => {
                onChange(parseFloat(e.target.value))
              }}
              label="Third Installment(Amount)"
              name="third_installment"
              style={{ width: '100%', marginBottom: 10 }}
              disabled={disabled || canDisabledCommission}
            />
          )}
        />
      )}
      <Controller
        name="final_installment"
        control={control}
        defaultValue={deal?.final_installment || 0}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            type="number"
            error={error?.message}
            value={value}
            onChange={(e) => {
              onChange(parseFloat(e.target.value))
            }}
            label="Final Installment(Amount)"
            name="final_installment"
            style={{ width: '100%', marginBottom: 10 }}
            disabled={disabled || canDisabledCommission}
          />
        )}
      />
      <Input
        type="number"
        value={installmentTotal.toFixed(2)}
        label="Total(Amount)"
        name="total_commission"
        style={{ width: '100%' }}
        disabled={disabled || canDisabledCommission}
      />
    </div>
  )
}

export default PreConCommission
