const Row = ({
  gap = null,
  className,
  justifyContent = 'flex-start',
  alignItems = 'stretch',
  marginBottom,
  marginTop,
  style,
  onChange,
  children,
  onClick
}) => {
  return (
    <div
      onChange={onChange}
      onClick={onClick}
      className={className}
      style={{ display: 'flex', gap, justifyContent, alignItems, marginBottom, marginTop, ...style }}>
      {children}
    </div>
  )
}

export default Row
