import { Col, Typography, Table, Loading, Row, Input } from 'components'
import { gql, useMutation, useQuery } from '@apollo/client'
import { formatCurrency } from 'helpers/utils'
import { toast } from 'react-toastify'
import moment from 'moment'
import { useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

const Commission = () => {
  const [editCommission, setEditCommission] = useState(null)
  const [commissionValue, setCommissionValue] = useState(null)

  const { data: { getAllCommissions: commissions } = [], loading } = useQuery(GET_COMMISSIONS, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    }
  })

  const [updateCommission] = useMutation(UPDATE_USER_ROLE, {
    onError: (err) => {
      toast.dismiss()
      console.error(err)
      toast.error('Error updating commission')
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('Commission updated')
      setCommissionValue(null)
      setEditCommission(null)
    },
    refetchQueries: [{ query: GET_COMMISSIONS }]
  })

  const handleSaveEdit = () => {
    updateCommission({ variables: { id: editCommission.id, commission: parseFloat(commissionValue) } })
  }
  const handleCancelEdit = () => {
    setCommissionValue(null)
    setEditCommission(null)
  }

  const handleEdit = (commission) => {
    setCommissionValue(commission.commission)
    setEditCommission(commission)
  }

  const columns = [
    {
      key: 'user_id',
      title: 'User Id',
      width: 125,
      render: (commission) => {
        return <Typography.Body type="2">{commission.user_id}</Typography.Body>
      }
    },
    {
      key: 'lonewolf_agentnum',
      title: 'LoneWolf Id',
      width: 125,
      render: (commission) => {
        return <Typography.Body type="2">{commission.lonewolf_agentnum}</Typography.Body>
      }
    },
    {
      key: 'name',
      title: 'Name',
      width: 400,
      render: (commission) => {
        return <Typography.Body type="2">{commission.name}</Typography.Body>
      }
    },
    {
      key: 'commission',
      title: 'Fee',
      width: 300,
      render: (commission) => {
        return editCommission?.id === commission.id ? (
          <Input value={commissionValue} onChange={(e) => setCommissionValue(e.target.value)} />
        ) : (
          <Typography.Body type="2">{formatCurrency(commission.commission)}</Typography.Body>
        )
      }
    },
    {
      key: 'created_at',
      title: 'Created At',
      width: 300,
      render: (commission) => {
        return <Typography.Body type="2">{moment(commission.created_at).format('YYYY-MM-DD')}</Typography.Body>
      }
    },
    {
      key: 'action',
      title: '',
      width: 100,
      render: (commission) => {
        if (editCommission?.id !== commission.id) return null
        return (
          <Row>
            <CheckIcon onClick={handleSaveEdit} style={{ color: '#4bb543', marginRight: 8 }} />
            <CloseIcon onClick={handleCancelEdit} style={{ color: '#e90b0b' }} />
          </Row>
        )
      }
    }
  ]

  return (
    <Col gap={24}>
      <Typography.Heading type="5">Commission</Typography.Heading>

      {loading ? <Loading fullpage /> : <Table columns={columns} data={commissions} rowOnClick={handleEdit} />}
    </Col>
  )
}

export default Commission

const GET_COMMISSIONS = gql`
  query getAllCommissions {
    getAllCommissions {
      id
      commission
      lonewolf_agentnum
      name
      user_id
      created_at
    }
  }
`

const UPDATE_USER_ROLE = gql`
  mutation updateCommission($id: Int!, $commission: Float!) {
    updateCommission(id: $id, commission: $commission)
  }
`
