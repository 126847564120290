import React, { useContext, useState } from 'react'
import { UserContext } from 'context'
import RecoLicensePopup from '../settings/RecoLicensePopup'
import RecoExpiry from 'components/nav/RecoExpiry'
import styles from './settings.module.scss'

const RecoLicense = () => {
  const { user } = useContext(UserContext)
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const openPopup = () => {
    setIsPopupOpen(true)
  }

  const closePopup = () => {
    setIsPopupOpen(false)
  }

  const onSave = () => {}

  return (
    <div className={styles.RecoExpiry}>
      <RecoExpiry onEditClick={openPopup} expiryDate={user.reco_expiry} showLabel={true} />
      {isPopupOpen && <RecoLicensePopup user={user} onClose={closePopup} onSave={onSave} />}
    </div>
  )
}

export default RecoLicense
