import { Col, Typography, Row, Button } from 'components'
import { gql, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { useState } from 'react'

const LaunchCloud = () => {
  const [sent, setSent] = useState(false)

  const [triggerLaunchInvitations, { loading }] = useMutation(TRIGGER_LAUNCH_INVITATIONS, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      setSent(true)
      toast.success('Emails sent.')
    }
  })

  return (
    <Col gap={24} style={{ height: '100vh' }}>
      <Typography.Heading type="5">RARE Cloud Launch</Typography.Heading>
      <Typography.Body type="3" color="secondary">
        Note: This will send a password reset link to every RARE user
      </Typography.Body>
      <Row>
        <Button
          loading={loading}
          onClick={() => {
            if (sent) return
            triggerLaunchInvitations()
          }}>
          {sent ? 'Sent' : 'Send Invitations'}
        </Button>
      </Row>
    </Col>
  )
}

const TRIGGER_LAUNCH_INVITATIONS = gql`
  mutation triggerLaunchInvitations {
    triggerLaunchInvitations
  }
`

export default LaunchCloud
