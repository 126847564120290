import { gql, useMutation } from '@apollo/client'
import { Col, Row, Textarea, Typography, Select, Checkbox } from 'components'
import { GET_LISTING } from 'helpers/gql'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import dropdownOption from 'helpers/dropdownOptions'
import { IconButton, createTheme, useMediaQuery } from '@mui/material'
import downloadAllIcon from './../../assets/images/downloadAllIcon.png'
import { downloadLibraryZip } from 'helpers/utils'
import vars from '../../vars.scss'
import { STATUS_TYPES } from 'helpers/constants'
import ListingFooter from './ListingFooter'
import styles from '../deals/deals.module.scss'
import AdditionalDocuments from 'components/upload/AdditionalDocuments'
import downloadingGif from '../../assets/images/downloading.gif'

const ListingAdmin = ({ listing, loading = false, listingStatus, isAdmin = false, isEdit = false, onListingSave }) => {
  const [downloading, setDownloading] = useState(false)

  const params = useParams()
  const { handleSubmit, control } = useForm({
    defaultValues: {
      adminDocuments: listing?.adminDocuments?.length
        ? listing?.adminDocuments
        : [{ fileName: '', key: null, size: null, type: '' }]
    }
  })
  const [statusChanged, setStatusChanged] = useState()
  const { id } = params

  const [adminUpdateListing, { loading: adminSaveLoading }] = useMutation(ADMIN_UPDATE_LISTING, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      if (statusChanged) {
        toast.success('Listing status auto updated from submitted to in-review.')
      } else {
        toast.success('Listing updated.')
      }
      setStatusChanged(false)
    },
    refetchQueries: [{ query: GET_LISTING, variables: { id } }]
  })

  const [generatePreSignedUrls] = useMutation(GENERATE_PRESIGNED_URLS, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
      setDownloading(false)
    },
    onCompleted: async (data) => {
      const filesUrl = []
      data?.generatePreSignedUrlsForDealsAndListing?.map((doc) => filesUrl.push(doc))
      await downloadLibraryZip(filesUrl, `${listing?.address}.zip`)
      setDownloading(false)
    }
  })

  const onSubmit = (values) => {
    console.log('data', values)
    adminUpdateListing({
      variables: {
        data: {
          id,
          admin_notes: values.admin_notes,
          status: values.status.value,
          adminDocuments: values.adminDocuments ?? [],
          checkListTasks: values?.checklist
        }
      }
    })
  }

  const handleDownloadAll = () => {
    setDownloading(true)
    const listingDocUrls = []
    listing?.documents?.map((doc) => listingDocUrls.push(doc.path))
    listing?.additionalDocuments?.map((doc) => listingDocUrls.push(doc.key))
    listing?.adminDocuments?.map((doc) => listingDocUrls.push(doc.key))

    generatePreSignedUrls({
      variables: {
        data: {
          s3ObjectKeys: listingDocUrls,
          type: 'listings'
        }
      }
    })
  }

  const updatedListingStatus = useMemo(() => {
    return listing?.status === STATUS_TYPES.submitted
      ? dropdownOption.ADMIN_STATUS_OPTIONS.find((d) => d.value === STATUS_TYPES.review)
      : dropdownOption.ADMIN_STATUS_OPTIONS.find((d) => d.value === listing?.status)
  }, [listing?.status])

  useEffect(() => {
    if (listing?.status === STATUS_TYPES.submitted && isAdmin) {
      adminUpdateListing({
        variables: {
          data: {
            id,
            status: STATUS_TYPES.review
          }
        }
      })
      setStatusChanged(true)
    }
  }, [adminUpdateListing, listing?.status, id, isAdmin])

  return (
    <div className={`${styles.responsive} dealStepSubTitle`}>
      <Col style={{ marginTop: 32 }}>
        <Typography.Heading type="5" style={{ marginBottom: 16 }}>
          Admin Section
        </Typography.Heading>
        <Row style={{ alignItems: 'center' }}>
          {downloading ? (
            <IconButton sx={{ color: vars['doc-font-color'], mr: 1 }} size="large">
              <img src={downloadingGif} alt="downloadAllIcon" height={40} width={40} />
            </IconButton>
          ) : (
            <IconButton onClick={handleDownloadAll} sx={{ color: vars['doc-font-color'], mr: 1 }} size="large">
              <img src={downloadAllIcon} alt="downloadAllIcon" height={40} width={40} />
            </IconButton>
          )}
          <Typography.Body type={2} className="dealStepSubTitle">
            Download All
          </Typography.Body>
        </Row>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AdditionalDocuments isAdminSection control={control} />

          <div>
            {listing?.checkListTasks.length > 0 && (
              <Typography.Heading type="5" style={{ marginBottom: 10, marginTop: 16 }}>
                Checklist
              </Typography.Heading>
            )}
            {listing?.checkListTasks.map((checklist, index) => {
              return (
                <div>
                  <Row>
                    <Controller
                      name={`checklist.${index}`}
                      control={control}
                      defaultValue={{ id: checklist?.id, name: checklist?.name, isChecked: checklist?.isChecked }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Checkbox
                          id={checklist?.id}
                          label={checklist?.name}
                          name={checklist?.name}
                          style={{ zoom: 1.3 }}
                          error={error?.message}
                          onChange={(e) => {
                            const data = { id: checklist?.id, name: e.target.name, isChecked: e.target.checked }
                            onChange(data)
                          }}
                          checked={value?.isChecked}
                        />
                      )}
                    />
                  </Row>
                </div>
              )
            })}
          </div>
          <Col style={{ marginTop: 20 }}>
            <Controller
              name="status"
              control={control}
              rules={{
                required: 'Listing status is required'
              }}
              defaultValue={updatedListingStatus}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Select
                  type={5}
                  options={dropdownOption.ADMIN_STATUS_OPTIONS.filter((val) => val.value !== STATUS_TYPES.settled)}
                  onChange={onChange}
                  value={value}
                  error={error?.message}
                  label="Listing status"
                  placeholder="Select listing status"
                  required
                  name="status"
                />
              )}
            />
          </Col>
          <Col style={{ marginTop: 20 }}>
            <Controller
              name="admin_notes"
              control={control}
              defaultValue={listing.admin_notes || undefined}
              render={({ field: { onChange, value } }) => (
                <Textarea
                  type={5}
                  value={value}
                  onChange={onChange}
                  label="Admin Notes"
                  name="admin_notes"
                  style={{ width: '100%', marginBottom: 16 }}
                />
              )}
            />
          </Col>
        </form>
      </Col>
      <ListingFooter
        isAdmin
        listingStatus={listingStatus}
        isEdit={isEdit}
        loading={loading}
        adminSaveLoading={adminSaveLoading}
        onListingSave={onListingSave}
        onAdminSave={handleSubmit(onSubmit)}
      />
    </div>
  )
}

const ADMIN_UPDATE_LISTING = gql`
  mutation adminUpdateListing($data: UpdateListingInput!) {
    adminUpdateListing(data: $data)
  }
`

const GENERATE_PRESIGNED_URLS = gql`
  mutation generatePreSignedUrlsForDealsAndListing($data: PreSignedInput) {
    generatePreSignedUrlsForDealsAndListing(data: $data) {
      fileName
      preSignedUrl
    }
  }
`
export default ListingAdmin
