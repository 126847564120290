import React, { useContext, useState } from 'react'
import { Avatar, Typography } from 'components'
import { UserContext } from 'context'
import style from './Header.module.scss'
import { getFileUrl } from 'helpers/utils'
import { FORM_DEAL_TYPES, HEADERS, USER_ROLES } from 'helpers/constants'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import vars from '../../vars.scss'
import { Box, Grid, IconButton, createTheme, useMediaQuery } from '@mui/material'
import GlobalSearch from 'pages/home/GlobalSearch'
import { KeyboardBackspace } from '@mui/icons-material'
import moment from 'moment'
import RecoLicense from 'pages/settings/RecoLicense'
import RecoExpiry from './RecoExpiry'

const Header = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const theme = createTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { type } = useParams()
  const { user } = useContext(UserContext)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const path = location.pathname.substring(location.pathname.lastIndexOf('/') + 1) ?? ''
  const isAnnouncements = location.pathname.includes('announcements')
  const isPrecon = path === FORM_DEAL_TYPES.PRE_CON || type === FORM_DEAL_TYPES.PRE_CON
  const agent = user.role === USER_ROLES.agent

  if (location.pathname.includes('chat')) return null
  const recoExpiry = user.reco_expiry && moment(user.reco_expiry).isBefore(moment().add(60, 'days'))

  const openPopup = () => {
    setIsPopupOpen(true)
  }

  const closePopup = () => {
    setIsPopupOpen(false)
  }

  return (
    <>
      <div style={{ marginBottom: 18 }}>
        {recoExpiry && agent && <RecoLicense open={isPopupOpen} hideOpen={closePopup} />}
      </div>
      <div className={style.headerMain}>
        <Grid container columnSpacing={5} columns={12}>
          <Grid container item xs={10}>
            <Grid item xs={9}>
              {isAnnouncements ? (
                <div style={{ display: 'flex' }}>
                  {isMobile && location.pathname !== '/announcements' && (
                    <IconButton onClick={() => navigate('/announcements')}>
                      <KeyboardBackspace fontSize="large" />
                    </IconButton>
                  )}
                  <Typography.Heading type={2} className={style.headerText}>
                    {location.pathname === '/announcements' ? HEADERS['announcements'] : HEADERS['announcement']}
                  </Typography.Heading>
                </div>
              ) : location.pathname.includes('library') ? (
                <Typography.Heading type={2} className={style.headerText}>
                  {HEADERS['library']}
                </Typography.Heading>
              ) : (
                <Typography.Heading type={2} className={style.headerText}>
                  {HEADERS[type ?? path]}
                </Typography.Heading>
              )}

              {path === '' && !location.pathname.includes('library') && (
                <>
                  <Typography.Body style={{ fontSize: '32px', fontFamily: ' Source Serif' }}>
                    Hi {user.name}.
                  </Typography.Body>
                  <Typography.Body
                    style={{
                      fontSize: '20px',
                      fontFamily: ' Source Serif',
                      marginTop: 7
                    }}>
                    Welcome to your dashboard. Here's what's happening today
                  </Typography.Body>
                </>
              )}
              {isPrecon && (
                <Typography.Heading
                  type={5}
                  style={{ fontWeight: 500, marginTop: 8, color: vars.requiredIcon }}
                  className={style.preconHeaderSubText}>
                  **ONLY SUBMIT ONCE FIRM (AFTER 10 DAY COOLING OFF PERIOD)**
                </Typography.Heading>
              )}
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: { xs: 2, md: 0 }, display: { xs: 'none', md: 'flex' } }}>
              {path === '' && !location.pathname.includes('library') && <GlobalSearch />}
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <div style={{ float: 'right', display: 'flex' }}>
              {path === 'revenue_share' && (
                <Typography.Body
                  onClick={() => navigate('/calculator')}
                  style={{ marginTop: 8, cursor: 'pointer', marginRight: 25, fontWeight: 600 }}>
                  Calculator
                </Typography.Body>
              )}
              <Avatar name={user.name} src={getFileUrl(user.thumbnail)} className={style.profileAvatar} />
            </div>
          </Grid>
        </Grid>
        <Box sx={{ mt: 2, display: { md: 'none', xs: 'flex' } }}>
          {path === '' && !location.pathname.includes('library') && <GlobalSearch />}
        </Box>
      </div>
    </>
  )
}

export default Header
