import { Typography, Textarea, Radio, RadioGroup, Row } from 'components'
import { Grid } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { DatePicker } from 'components'
import { useCallback, useState } from 'react'
import styles from '../listings.module.scss'

const ListingDetails = () => {
  const { control, getValues, setValue } = useFormContext()
  const type = getValues().type
  const listingType = type === 'sale_listing' ? 'sale' : 'lease'
  const listingTypeText = type === 'sale_listing' ? 'Sale' : 'Lease'
  const [isSaleSignNeeded, setIsSaleSignNeeded] = useState(getValues().need_sale_sign ?? '')
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)

  const handleChange = useCallback(
    (value) => {
      setIsSaleSignNeeded(value)
      setValue('sign_installation_date', null)
      setValue('is_rider_needed', '')
    },
    [setValue]
  )

  return (
    <div style={{ marginTop: 20 }}>
      <Row>
        <Typography.Heading className={styles.resolutionSecondaryAgent} type={5}>
          For {listingTypeText} Signage
        </Typography.Heading>
        <Typography.Body type={1} style={{ color: '#949494', marginTop: 1 }} className={'resolutionOptionalText'}>
          (Optional)
        </Typography.Body>
      </Row>
      <Grid container style={{ marginBottom: 10 }}>
        <Grid container columnSpacing={7} sx={{ mt: 2 }}>
          <Grid xs={12} sm={6} item>
            <Controller
              name="need_sale_sign"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <RadioGroup
                  type={5}
                  label={`Will you require a  ${listingType} sign?`}
                  onChange={(e) => {
                    onChange(e)
                    handleChange(e.target.value)
                  }}
                  value={value}
                  error={error?.message}>
                  <Radio
                    lableStyle={{ marginRight: 50 }}
                    name="need_sale_sign_yes"
                    label="Yes"
                    value="yes"
                    checked={value === 'yes'}
                  />
                  <Radio name="need_sale_sign_no" label="No" value="no" checked={value === 'no'} />
                </RadioGroup>
              )}
            />
          </Grid>
        </Grid>
        {isSaleSignNeeded === 'yes' && (
          <>
            <Grid container columnSpacing={7} sx={{ marginTop: 2 }}>
              <Grid xs={12} md={6} item>
                <Controller
                  name="is_rider_needed"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <RadioGroup
                      type={5}
                      label="Do you have a name rider?"
                      onChange={onChange}
                      value={value}
                      error={error?.message}>
                      <Radio
                        lableStyle={{ marginRight: 50 }}
                        name="yes"
                        label="Yes"
                        value="yes"
                        checked={value === 'yes'}
                      />
                      <Radio name="no" label="No" value="no" checked={value === 'no'} />
                    </RadioGroup>
                  )}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={7} sx={{ marginTop: 2 }}>
              <Grid xs={12} sm={3} item>
                <Controller
                  name="sign_installation_date"
                  control={control}
                  defaultValue={null}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <DatePicker
                      type={5}
                      error={error?.message}
                      value={value}
                      onChange={onChange}
                      sublabel="Date for which you need the sign board"
                      label="Select Date"
                      required
                      name="sign_installation_date"
                      style={{ width: '100%' }}
                      minDate={tomorrow}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid container columnSpacing={7} sx={{ marginTop: 3 }}>
          <Grid xs={12} md={6} item>
            <Controller
              name="additional_copy_note"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Textarea
                  type={5}
                  preventLineBreaks={true}
                  value={value}
                  onChange={onChange}
                  label="Additional Information"
                  sublabel="Please include any special notes or instructions related to the listing."
                  name="additional_copy_note"
                  style={{ width: '100%' }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default ListingDetails
