import MUIDataTable from 'mui-datatables'
import React from 'react'
import { BasicTableTheme } from './BasicTableTheme'
import { ThemeProvider } from '@mui/material'

const BasicTable = (props) => {
  const {
    tableData,
    tableColumns,
    tableOptions,
    handleOnTableInit,
    tableHeight = 'auto',
    hanldeSelectedRowsData,
    displayRowCheckbox,
    checkedRows,
    handleChangeRowsPerPage,
    rowsPerPage,
    handleRowClick
  } = props

  const options = {
    print: false,
    pagination: true,
    elevation: 0,
    search: false,
    download: false,
    viewColumns: false,
    sort: true,
    filter: false,
    tableBodyHeight: tableHeight,
    onTableInit: handleOnTableInit,
    displayRowCheckbox: displayRowCheckbox,
    selectableRows: displayRowCheckbox ? 'multiple' : 'none',
    rowsPerPage: rowsPerPage ?? 10,
    rowsPerPageOptions: [10, 25, 50, 100],
    onRowSelectionChange: hanldeSelectedRowsData,
    selectableRowsHeader: false,
    rowsSelected: checkedRows,
    responsive: 'standard',
    tableBodyMaxHeight: '100%',
    onChangeRowsPerPage: handleChangeRowsPerPage,
    onRowClick: (rowData, RowMeta) => {
      if (handleRowClick) {
        const selectRowData = tableData[RowMeta.dataIndex]
        handleRowClick(selectRowData)
      }
    }
  }

  return (
    <ThemeProvider theme={BasicTableTheme()}>
      <MUIDataTable data={tableData} columns={tableColumns} options={tableOptions ? tableOptions : options} />
    </ThemeProvider>
  )
}

export default BasicTable
