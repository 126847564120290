import React from 'react'
import { PopUp, Typography } from 'components'
import { Tooltip } from '@mui/material'
import { PREVIEWABLE_DOC_TYPES, PREVIEWABLE_TYPES, PREVIEWABLE_VIDEO_TYPES } from 'helpers/constants'
import vars from '../../vars.scss'
import styles from './announcements.module.scss'

const AttachmentViewer = ({ attachment, onCancel, open }) => {
  const fileExtension = attachment?.file_name?.split('.').pop()?.toLowerCase()
  const isAndroidDevice = /Android/i.test(navigator.userAgent)

  return (
    <PopUp
      title={
        <>
          <Typography.Heading type={6}>Preview</Typography.Heading>
          <Tooltip placement="bottom-start" title={attachment?.file_name.length > 30 ? attachment?.file_name : ''}>
            <div>
              <Typography.Body style={{ color: vars['table-cell-color'] }} type={2}>
                {attachment?.file_name.length > 30 ? `${attachment?.file_name.slice(0, 30)}...` : attachment?.file_name}
              </Typography.Body>
            </div>
          </Tooltip>
        </>
      }
      open={open}
      maxWidth="lg"
      canShowCancelButton={false}
      onCancel={onCancel}
      canShowConfirmButton={false}>
      <div className={styles.attachmentPreview}>
        {PREVIEWABLE_DOC_TYPES.includes(fileExtension) ? (
          isAndroidDevice ? (
            <div className={styles.inValidFileType}>
              <Typography.Body style={{ color: vars['table-cell-color'] }} type={2}>
                Android does not allow preview of PDF or Word documents. Please download the document or use a desktop
                to preview.
              </Typography.Body>
            </div>
          ) : (
            <iframe title={attachment?.file_name} src={attachment?.signedUrl} className={styles.docs} />
          )
        ) : PREVIEWABLE_TYPES.includes(fileExtension) ? (
          <img className={styles.image} src={attachment?.signedUrl} alt="img" />
        ) : PREVIEWABLE_VIDEO_TYPES.includes(fileExtension) ? (
          <video controls className={styles.video}>
            <source src={attachment?.signedUrl} type={`video/${fileExtension}`} />
            Your browser does not support the video tag.
          </video>
        ) : (
          <div className={styles.inValidFileType}>
            <Typography.Body style={{ color: vars['table-cell-color'] }} type={2}>
              This file type is not supported
            </Typography.Body>
          </div>
        )}
      </div>
    </PopUp>
  )
}

export default AttachmentViewer
