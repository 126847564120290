import { downloadLibraryZip } from 'helpers/utils'
import { Col, Row, Textarea, Typography, Select, Checkbox } from 'components'
import { Controller, useForm } from 'react-hook-form'
import dropdownOption from 'helpers/dropdownOptions'
import styles from './deals.module.scss'
import { IconButton, createTheme, useMediaQuery } from '@mui/material'
import vars from '../../vars.scss'
import downloadAllIcon from '../../assets/images/downloadAllIcon.png'
import downloadingGif from '../../assets/images/downloading.gif'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { gql, useMutation } from '@apollo/client'
import { GET_DEAL } from 'helpers/gql'
import { useParams } from 'react-router'
import DealFooter from './DealFooter'
import { useContext } from 'react'
import { DealContext } from 'context'
import AdditionalDocuments from 'components/upload/AdditionalDocuments'
import { STATUS, STATUS_TYPES } from 'helpers/constants'
import { convertUTCToLocal } from 'helpers/utils'

const DealAdmin = ({ isAdmin = false, onEditDeal, loading }) => {
  const params = useParams()
  const { id } = params
  const { deal } = useContext(DealContext)
  const [downloading, setDownloading] = useState(false)
  const [statusChanged, setStatusChanged] = useState(false)

  const { handleSubmit, control } = useForm({
    defaultValues: {
      adminDocuments: deal?.adminDocuments?.length
        ? deal?.adminDocuments
        : [{ fileName: '', key: null, size: null, type: '' }]
    }
  })

  const [adminUpdateDeal, { loading: adminSaveLoading }] = useMutation(ADMIN_UPDATE_DEAL, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
      setDownloading(false)
    },
    onCompleted: () => {
      toast.dismiss()
      if (statusChanged) {
        toast.success('Deal status auto updated from submitted to In Review.')
      } else {
        toast.success('Deal updated.')
      }
      setStatusChanged(false)
    },
    refetchQueries: [{ query: GET_DEAL, variables: { id } }]
  })

  const [generatePreSignedUrls] = useMutation(GENERATE_PRESIGNED_URLS, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
      setDownloading(false)
    },
    onCompleted: async (data) => {
      const filesUrl = []
      data?.generatePreSignedUrlsForDealsAndListing?.map((doc) => filesUrl.push(doc))
      await downloadLibraryZip(filesUrl, `${deal?.address}.zip`)
      setDownloading(false)
    }
  })

  const onSubmit = (values) => {
    console.log('data', values)
    adminUpdateDeal({
      variables: {
        data: {
          id,
          admin_notes: values.admin_notes,
          status: values.status.value,
          adminDocuments: values.adminDocuments ?? [],
          checkListTasks: values?.checklist
        }
      }
    })
  }

  const handleDownloadAll = () => {
    setDownloading(true)
    const dealDocUrls = []
    deal?.documents?.map((doc) => dealDocUrls.push(doc?.path))
    deal?.additionalDocuments?.map((doc) => dealDocUrls.push(doc?.key))
    deal?.adminDocuments?.map((doc) => dealDocUrls.push(doc?.key))
    generatePreSignedUrls({
      variables: {
        data: {
          s3ObjectKeys: dealDocUrls,
          type: 'deals'
        }
      }
    })
  }

  const getActionOptions = (deal) => {
    const closeDate = convertUTCToLocal(deal.close_date)
    const currentDate = convertUTCToLocal(new Date())
    const status = deal.status === STATUS_TYPES.closed
    const settle = deal.status !== STATUS_TYPES.settled

    const adminStatusOption = [
      settle && { value: STATUS_TYPES.review, label: STATUS.review },
      settle && { value: STATUS_TYPES.attention, label: STATUS.attention },
      settle && { value: STATUS_TYPES.approved, label: STATUS.approved },
      closeDate <= currentDate && { value: STATUS_TYPES.closed, label: STATUS.closed },
      (deal.status === STATUS_TYPES.settled || status) && { value: STATUS_TYPES.settled, label: STATUS.settled }
    ]
    return adminStatusOption.filter(Boolean)
  }
  const actionOptions = getActionOptions(deal)

  return (
    <div className={styles.responsive}>
      <form>
        <Col style={{ marginTop: 32 }}>
          <Typography.Heading type="5" style={{ marginBottom: 16 }}>
            Admin Section
          </Typography.Heading>
          <Row style={{ alignItems: 'center' }}>
            {downloading ? (
              <IconButton sx={{ color: vars['doc-font-color'], mr: 1 }} size="large">
                <img src={downloadingGif} alt="downloadAllIcon" height={40} width={40} />
              </IconButton>
            ) : (
              <IconButton onClick={handleDownloadAll} sx={{ color: vars['doc-font-color'], mr: 1 }} size="large">
                <img src={downloadAllIcon} alt="downloadAllIcon" height={40} width={40} />
              </IconButton>
            )}
            <Typography.Body type={2}>Download All</Typography.Body>
          </Row>

          <AdditionalDocuments isAdminSection control={control} />

          <div>
            {deal?.checkListTasks.length > 0 && (
              <Typography.Heading type="5" style={{ marginBottom: 10, marginTop: 16 }}>
                Checklist
              </Typography.Heading>
            )}
            {deal?.checkListTasks.map((checklist, index) => {
              return (
                <div>
                  <Row>
                    <Controller
                      name={`checklist.${index}`}
                      control={control}
                      defaultValue={{ id: checklist?.id, name: checklist?.name, isChecked: checklist?.isChecked }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Checkbox
                          id={checklist?.id}
                          label={checklist?.name}
                          name={checklist?.name}
                          style={{ zoom: 1.3 }}
                          error={error?.message}
                          onChange={(e) => {
                            const data = { id: checklist?.id, name: e.target.name, isChecked: e.target.checked }
                            onChange(data)
                          }}
                          checked={value?.isChecked}
                        />
                      )}
                    />
                  </Row>
                </div>
              )
            })}
          </div>
          <Col style={{ marginTop: 20 }}>
            <Controller
              name="status"
              control={control}
              rules={{
                required: 'Deal status is required'
              }}
              defaultValue={dropdownOption.ADMIN_STATUS_OPTIONS.find((d) => d.value === deal.status)}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Select
                  type={5}
                  options={actionOptions}
                  onChange={onChange}
                  value={value}
                  error={error?.message}
                  label="Deal status"
                  placeholder="Select deal status"
                  required
                  name="status"
                />
              )}
            />
          </Col>
          <Col style={{ marginTop: 20 }}>
            <Controller
              name="admin_notes"
              control={control}
              defaultValue={deal?.admin_notes || undefined}
              render={({ field: { onChange, value } }) => (
                <Textarea
                  type={5}
                  value={value}
                  onChange={onChange}
                  label="Admin Notes"
                  name="admin_notes"
                  style={{ width: '100%', marginBottom: 16 }}
                />
              )}
            />
          </Col>
        </Col>
      </form>
      <DealFooter
        isAdmin={isAdmin}
        loading={loading}
        onSave={handleSubmit(onSubmit)}
        adminSaveLoading={adminSaveLoading}
        onEditDeal={onEditDeal}
      />
    </div>
  )
}

const ADMIN_UPDATE_DEAL = gql`
  mutation adminUpdateDeal($data: UpdateDealInput!) {
    adminUpdateDeal(data: $data)
  }
`
const GENERATE_PRESIGNED_URLS = gql`
  mutation generatePreSignedUrlsForDealsAndListing($data: PreSignedInput) {
    generatePreSignedUrlsForDealsAndListing(data: $data) {
      fileName
      preSignedUrl
    }
  }
`

export default DealAdmin
