import { Avatar, Typography } from 'components'
import moment from 'moment'
import React from 'react'
import vars from '../../vars.scss'
import { getFileUrl } from 'helpers/utils'
import { ThemeProvider } from '@emotion/react'
import MUIDataTable from 'mui-datatables'
import { LogsTableTheme } from 'components/table/BasicTableTheme'
import { Tooltip } from '@mui/material'

const MyFileLogs = ({ downloadLog }) => {
  const columns = [
    {
      name: 'file_name',
      label: 'Name',
      options: {
        customBodyRender: (value) => {
          return (
            <Typography.Body style={{ color: vars['table-cell-color'], width: 260 }}>
              <Tooltip placement="right-start" title={value.length > 28 ? value : ''}>
                <div>{value.length > 28 ? `${value.slice(0, 28)}...` : value}</div>
              </Tooltip>
            </Typography.Body>
          )
        }
      }
    },
    {
      name: 'user',
      label: 'Last Modified By',
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              {value && (
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar name={value.name} src={getFileUrl(value.thumbnail)} />
                    <div style={{ marginLeft: '10px' }}>
                      <Typography.Body>{value.name}</Typography.Body>
                      <Typography.Body style={{ color: vars['loading-inner-color-secondary'] }}>
                        {`Rare Agent #${value.id}`}
                      </Typography.Body>
                    </div>
                  </div>
                </>
              )}
            </div>
          )
        }
      }
    },
    {
      name: 'activity_time',
      label: 'Date Uploaded',
      options: {
        customBodyRender: (value) => {
          return (
            <Typography.Body style={{ color: vars['table-cell-color'] }}>
              {moment(value).format('MMM DD, yyyy hh:mm A')}
            </Typography.Body>
          )
        }
      }
    },
    {
      name: 'file_size',
      label: 'File Size',
      options: {
        customBodyRender: (value) => {
          return (
            <Typography.Body style={{ color: vars['table-cell-color'] }}>
              {(value / 1024 ** 2).toFixed(2)} MB
            </Typography.Body>
          )
        }
      }
    },

    {
      name: 'activity',
      label: 'Action',
      options: {
        customBodyRender: (value) => {
          return <Typography.Body style={{ color: vars['table-cell-color'] }}>{value}</Typography.Body>
        }
      }
    }
  ]

  const options = {
    print: false,
    pagination: true,
    elevation: 0,
    search: false,
    download: false,
    viewColumns: false,
    sort: false,
    filter: false,
    displayRowCheckbox: false,
    rowsPerPage: 10,
    selectableRows: 'none',
    rowsPerPageOptions: [10, 25, 50, 100],
    selectableRowsHeader: false,
    responsive: 'standard',
    tableBodyMaxHeight: '100%'
  }

  return (
    <>
      <Typography.Heading style={{ fontSize: '22px', marginBotton: 14, marginLeft: 12 }}>My Files</Typography.Heading>
      <div style={{ marginLeft: 12 }}>
        <ThemeProvider theme={LogsTableTheme()}>
          <MUIDataTable data={downloadLog} columns={columns} options={options} />
        </ThemeProvider>
      </div>
    </>
  )
}

export default MyFileLogs
