import { Row, Typography } from 'components'
import styles from '../deals.module.scss'
import LeaseDocuments from './lease/LeaseDocuments'
import SaleDocuments from './sale/SaleDocuments'
import PreConDocuments from './pre_con/PreConDocuments'
import { DealContext, UserContext } from 'context'
import { useContext, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FORM_DEAL_TYPES, USER_ROLES } from 'helpers/constants'
import { downloadLibraryZip } from 'helpers/utils'
import { IconButton } from '@mui/material'
import vars from '../../../vars.scss'
import downloadAllIcon from '../../../assets/images/downloadAllIcon.png'
import downloadingGif from '../../../assets/images/downloading.gif'
import { gql, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

const Documents = () => {
  const { getValues } = useFormContext()
  const { deal, isEdit, isView, isClosedAndAgent } = useContext(DealContext)
  const type = getValues().type || deal?.type
  const { user } = useContext(UserContext)
  const isAgent = user.role === USER_ROLES.agent
  const [downloading, setDownloading] = useState(false)

  const [generatePreSignedUrls] = useMutation(GENERATE_PRESIGNED_URLS, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
      setDownloading(false)
    },
    onCompleted: async (data) => {
      const filesUrl = []
      data?.generatePreSignedUrlsForDealsAndListing?.map((doc) => filesUrl.push(doc))
      await downloadLibraryZip(filesUrl, `${deal?.address}.zip`)
      setDownloading(false)
    }
  })

  const handleDownloadAll = () => {
    setDownloading(true)
    const dealDocUrls = []
    deal?.documents?.map((doc) => dealDocUrls.push(doc?.path))
    deal?.additionalDocuments?.map((doc) => dealDocUrls.push(doc?.key))
    generatePreSignedUrls({
      variables: {
        data: {
          s3ObjectKeys: dealDocUrls,
          type: 'deals'
        }
      }
    })
  }

  return (
    <div className={styles.documentMain}>
      <Typography.Heading type={5} className={styles.headerLabel}>
        Agreement and Supporting Documents
      </Typography.Heading>
      <Typography.Label type={1} className={styles.documentsRequiredInfo}>
        The following documents are required to process this deal
      </Typography.Label>
      {isAgent && (isEdit || isView) && (
        <Row style={{ alignItems: 'center' }}>
          {downloading ? (
            <IconButton sx={{ color: vars['doc-font-color'], mr: 1 }} size="large">
              <img src={downloadingGif} alt="downloadingGif" height={40} width={40} />
            </IconButton>
          ) : (
            <IconButton onClick={handleDownloadAll} sx={{ color: vars['doc-font-color'], mr: 1 }} size="large">
              <img src={downloadAllIcon} alt="downloadAllIcon" height={40} width={40} />
            </IconButton>
          )}
          <Typography.Body type={2}>Download All</Typography.Body>
        </Row>
      )}
      <Typography.Heading type={6} className={styles.headerLabel}>
        Attachments
      </Typography.Heading>

      {type === FORM_DEAL_TYPES.SALE && <SaleDocuments isAgent={isAgent} />}
      {type === FORM_DEAL_TYPES.PRE_CON && <PreConDocuments isAgent={isAgent} />}
      {type === FORM_DEAL_TYPES.LEASE && <LeaseDocuments isAgent={isAgent} />}
    </div>
  )
}

const GENERATE_PRESIGNED_URLS = gql`
  mutation generatePreSignedUrlsForDealsAndListing($data: PreSignedInput) {
    generatePreSignedUrlsForDealsAndListing(data: $data) {
      fileName
      preSignedUrl
    }
  }
`

export default Documents
