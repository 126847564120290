import React from 'react'
import AnnouncementForm from './AnnouncementForm'
import { useNavigate } from 'react-router-dom'
import { GET_ANNOUNCEMENTS } from 'helpers/gql'
import { toast } from 'react-toastify'
import { gql, useMutation } from '@apollo/client'
import { Helmet } from 'react-helmet-async'
import { Row, Typography } from 'components'
import { ANNONCEMENT_TYPES } from 'helpers/constants'
import { useState } from 'react'
import { convertLocalToUTC } from 'helpers/utils'

const CreateAnnouncement = () => {
  const navigate = useNavigate()
  const [successMesaage, setSuccessMesaage] = useState()
  const [loadingSection, setLoadingSection] = useState()

  const [createAnnouncement, { loading }] = useMutation(CREATE_ANNOUNCEMENT, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
      setLoadingSection(null)
    },
    onCompleted: () => {
      toast.dismiss()
      if (successMesaage) {
        toast.success(successMesaage)
      }
      navigate('/announcements')
      setLoadingSection(null)
    },
    refetchQueries: [{ query: GET_ANNOUNCEMENTS, variables: { filters: { status: ANNONCEMENT_TYPES.INBOX } } }]
  })

  const handleSaveDraft = async (values) => {
    setLoadingSection(ANNONCEMENT_TYPES.DRAFT)
    setSuccessMesaage('Announcement saved as draft')
    await createAnnouncement({
      variables: {
        data: {
          ...values,
          start_date: convertLocalToUTC(values.start_date),
          end_date: convertLocalToUTC(values.end_date),
          status: ANNONCEMENT_TYPES.DRAFT,
          sender_id: values.sender_id?.value
        }
      }
    })
  }

  const handleSave = async (data) => {
    setSuccessMesaage('Announcement created.')
    setLoadingSection('post')
    await createAnnouncement({
      variables: {
        data: {
          ...data,
          start_date: convertLocalToUTC(data.start_date),
          end_date: convertLocalToUTC(data.end_date),
          status: ANNONCEMENT_TYPES.INBOX,
          sender_id: data.sender_id?.value
        }
      }
    })
  }

  const handleSchedulePost = async (data) => {
    setSuccessMesaage('Announcement scheduled.')
    setLoadingSection(ANNONCEMENT_TYPES.SCHEDULED)
    await createAnnouncement({
      variables: {
        data: { ...data, status: ANNONCEMENT_TYPES.SCHEDULED, sender_id: data.sender_id?.value }
      }
    })
  }

  return (
    <>
      <Helmet title="Announcement | RARE" />
      <section id="announcement">
        <Row marginBottom={10}>
          <Typography.Heading type={5}>New Announcement</Typography.Heading>
        </Row>
        <AnnouncementForm
          loading={loading}
          loadingSection={loadingSection}
          onSave={handleSave}
          onDraftSave={handleSaveDraft}
          onSchedulePost={handleSchedulePost}
        />
      </section>
    </>
  )
}

const CREATE_ANNOUNCEMENT = gql`
  mutation createAnnouncement($data: AnnouncementInput!) {
    createAnnouncement(data: $data)
  }
`

export default CreateAnnouncement
