import { Controller, useFormContext } from 'react-hook-form'
import { BasicUpload } from 'components'
import { useMemo, useContext, useCallback, useState } from 'react'
import { LISTING_COMMON_DOCUMENTS } from 'helpers/constants'
import { DealContext, DocumentContext } from 'context'
import { v4 as uuidv4 } from 'uuid'

const DocumentBase = ({ name, title, required }) => {
  const {
    documentsByListing,
    handleDeleteDefaultListingDocuments,
    disabled,
    isView,
    isEdit,
    deal,
    isClosedAndAgent,
    isApprovedAndAgent
  } = useContext(DealContext)
  const { getValues, control, setValue } = useFormContext()
  const { handleDeleteFile } = useContext(DocumentContext)
  const defaultValue = getValues()[name] ?? null
  const [checked, setChecked] = useState()

  //create deal
  const docDefaultValueByListing = useMemo(() => {
    const dealDocuments = documentsByListing || []
    const doc = dealDocuments.find((doc) => {
      return doc.checklist_document_type === LISTING_COMMON_DOCUMENTS[name]
    })
    if (!doc) return null
    return {
      id: doc.id,
      documentId: doc.document_id,
      key: doc.path,
      fileName: doc.name,
      type: doc.type,
      size: doc.size
    }
  }, [documentsByListing, name])

  const handleDeleteDefaultDocs = (value) => {
    if (value && value.id) {
      handleDeleteDefaultListingDocuments(value.id)
    }
  }

  if (docDefaultValueByListing && !isEdit) {
    setValue(name, docDefaultValueByListing)
  }

  const initialValue = useMemo(() => {
    if (defaultValue) {
      return defaultValue
    } else {
      const matchedDocument = deal?.documents?.find((document) => document.checklist_document_type === name)
      if (matchedDocument) {
        const { id, document_id, path, name: fileName } = matchedDocument
        return { id, documentId: document_id, key: path, fileName }
      }
    }
  }, [defaultValue, deal?.documents, name])

  const { checkedDocuments } = getValues()
  const isChecked = useMemo(() => {
    const filtered = checkedDocuments?.filter((doc) => doc?.key === name)
    return filtered?.[0]?.isChecked ?? false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedDocuments, name, checked])

  const handleCheckChange = useCallback(() => {
    const documents = getValues()?.checkedDocuments ?? []
    const existingObjectIndex = documents?.findIndex((doc) => doc.key === name)
    if (existingObjectIndex !== -1) {
      documents[existingObjectIndex].isChecked = documents[existingObjectIndex].isChecked === true ? false : true
    } else {
      const newObject = { key: name, isChecked: true }
      documents.push(newObject)
    }
    setValue('checkedDocuments', documents)
    setChecked(checked === true ? false : true)
  }, [checked, getValues, name, setValue])

  return (
    <Controller
      name={name}
      control={control}
      key={uuidv4()}
      defaultValue={isEdit || isView ? initialValue : defaultValue}
      required
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <BasicUpload
          onChange={onChange}
          title={title}
          defaultValue={value}
          error={error?.message}
          required={false}
          disabled={disabled}
          isChecked={isChecked}
          isAllDocument={title.includes('All')}
          onCheckChange={handleCheckChange}
          handleDeleteFile={isEdit ? handleDeleteFile : handleDeleteDefaultDocs}
          isClosedAndAgent={isClosedAndAgent}
          isApprovedAndAgent={isApprovedAndAgent}
        />
      )}
    />
  )
}

export default DocumentBase
