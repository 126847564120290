import { Button, Col, Divider, Input, Row, Typography } from 'components'
import { UserContext } from 'context'
import { passwordStrength } from 'check-password-strength'
import { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material'
import styles from './settings.module.scss'
import { gql, useMutation } from '@apollo/client'
import { GET_USER } from 'context/UserProvider'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

const UpdateEmailForm = () => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  const validationSchema = Yup.object().shape({
    email: Yup.string().matches(emailRegex, 'Email address must be a valid email').required('Email Address is required')
  })

  const { user } = useContext(UserContext)
  const { handleSubmit, control, reset } = useForm({ resolver: yupResolver(validationSchema) })

  const [updateEmail, { loading }] = useMutation(UPDATE_EMAIL, {
    onError: (err) => {
      toast.dismiss()
      console.error(err)
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      reset()
      toast.success('Email updated.')
    },
    refetchQueries: [{ query: GET_USER }]
  })

  const onSubmit = ({ email }) => {
    updateEmail({
      variables: { email }
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Col style={{ width: '100%' }} gap={24}>
        <Typography.Heading type="6">Update email address</Typography.Heading>

        <Col>
          <Typography.Body type="3">Current email address</Typography.Body>
          <Typography.Subheading type="3">{user.email}</Typography.Subheading>
        </Col>

        <Row>
          <Col style={{ width: 560 }}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: 'Email address is required'
              }}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="New email address"
                  required
                  name="email"
                  style={{ width: '100%' }}
                />
              )}
            />
          </Col>
        </Row>

        <Button form block loading={loading} style={{ width: 195, marginTop: 24 }}>
          Update email address
        </Button>
      </Col>
    </form>
  )
}

const ValidationIcon = ({ valid = false }) => {
  return valid ? <CheckIcon className={styles.checkIcon} /> : <CloseIcon className={styles.closeIcon} />
}

const UpdatePasswordForm = () => {
  const [newPassword, setNewpassword] = useState()
  const [confirmPassword, setconfirmpassword] = useState()

  const [passwordValidation, setPasswordValidation] = useState({
    passwordsMatch: false,
    eightChars: false,
    lowerCase: false,
    upperCase: false,
    number: false,
    specialChars: false
  })

  const disabled =
    !passwordValidation.eightChars ||
    !passwordValidation.lowerCase ||
    !passwordValidation.upperCase ||
    !passwordValidation.number ||
    !passwordValidation.specialChars ||
    newPassword !== confirmPassword

  const { handleSubmit, control, watch, reset } = useForm()
  const watchFields = watch(['newPassword'])

  const [updatePassword, { loading }] = useMutation(UPDATE_PASSWORD, {
    onError: (err) => {
      toast.dismiss()
      console.error(err)
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      reset()
      setPasswordValidation({
        passwordsMatch: false,
        eightChars: false,
        lowerCase: false,
        upperCase: false,
        number: false,
        specialChars: false
      })
      toast.success('Password updated.')
    }
  })

  const onSubmit = (variables) => {
    updatePassword({
      variables
    })
    setNewpassword(null)
    setconfirmpassword(null)
  }

  const checkValidation = (value) => {
    const rule = passwordStrength(value)
    const eightChars = rule.length >= 8
    const lowerCase = rule.contains.indexOf('lowercase') !== -1
    const upperCase = rule.contains.indexOf('uppercase') !== -1
    const number = rule.contains.indexOf('number') !== -1
    const specialChars = rule.contains.indexOf('symbol') !== -1

    setPasswordValidation({
      ...passwordValidation,
      eightChars,
      lowerCase,
      upperCase,
      number,
      specialChars
    })

    return eightChars && lowerCase && upperCase && number && specialChars
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Col style={{ width: '100%' }} gap={24}>
        <Typography.Heading type="6">Update password</Typography.Heading>

        <Row>
          <Col style={{ width: 560 }}>
            <Controller
              name="currentPassword"
              control={control}
              rules={{
                required: 'Please enter your current password'
              }}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="password"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Current password"
                  required
                  name="currentPassword"
                  style={{ width: '100%' }}
                />
              )}
            />
          </Col>
        </Row>

        <Col>
          <Typography.Subheading type="3">New password must contain:</Typography.Subheading>
          <ul className={styles.passwordRequirements}>
            <li>
              <Row>
                <Typography.Body type="3">At least 8 characters</Typography.Body>
                <ValidationIcon valid={passwordValidation.eightChars} />
              </Row>
            </li>
            <li>
              <Row>
                <Typography.Body type="3">At least 1 lowercase character</Typography.Body>
                <ValidationIcon valid={passwordValidation.lowerCase} />
              </Row>
            </li>
            <li>
              <Row>
                <Typography.Body type="3">At least 1 uppercase character</Typography.Body>
                <ValidationIcon valid={passwordValidation.upperCase} />
              </Row>
            </li>
            <li>
              <Row>
                <Typography.Body type="3">At least 1 number</Typography.Body>
                <ValidationIcon valid={passwordValidation.number} />
              </Row>
            </li>
            <li>
              <Row>
                <Typography.Body type="3">At least 1 special character</Typography.Body>
                <ValidationIcon valid={passwordValidation.specialChars} />
              </Row>
            </li>
            <li>
              <Row>
                <Typography.Body type="3">Confirm password should be same as new password</Typography.Body>
                <ValidationIcon
                  valid={newPassword === confirmPassword && newPassword != null && confirmPassword != null}
                />
              </Row>
            </li>
          </ul>
        </Col>

        <Row>
          <Col style={{ width: 560 }}>
            <Controller
              name="newPassword"
              control={control}
              rules={{
                required: 'Please enter your new password',
                validate: (value) => checkValidation(value) || 'Password is not strong enough'
              }}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="password"
                  error={error?.message}
                  value={value}
                  onChange={(e) => {
                    onChange(e)
                    checkValidation(e.target.value)
                    setNewpassword(e.target.value)
                  }}
                  label="New password"
                  required
                  name="newPassword"
                  style={{ width: '100%' }}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 560 }}>
            <Controller
              name="confirmPassword"
              control={control}
              rules={{
                required: 'Please confirm your new password',
                validate: (value) => value === watchFields[0] || 'Passwords do not match'
              }}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="password"
                  error={error?.message}
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value)
                    checkValidation(e.target.value)
                    setconfirmpassword(e.target.value)
                  }}
                  label="Confirm new password"
                  required
                  name="confirmPassword"
                  style={{ width: '100%' }}
                />
              )}
            />
          </Col>
        </Row>

        <Button form block loading={loading} disabled={disabled} style={{ width: 265, marginTop: 24 }}>
          Update password
        </Button>
      </Col>
    </form>
  )
}

const LoginInfo = () => {
  const { user } = useContext(UserContext)
  return (
    <Col style={{ width: '100%' }}>
      <Typography.Heading type="5" style={{ marginBottom: 24 }}>
        Login information
      </Typography.Heading>
      {user.role === 'super_admin' && (
        <>
          <UpdateEmailForm />
          <Divider />
        </>
      )}

      <UpdatePasswordForm />
    </Col>
  )
}

const UPDATE_EMAIL = gql`
  mutation updateEmail($email: String!) {
    updateEmail(email: $email)
  }
`

const UPDATE_PASSWORD = gql`
  mutation updatePassword($currentPassword: String!, $newPassword: String!) {
    updatePassword(currentPassword: $currentPassword, newPassword: $newPassword)
  }
`

export default LoginInfo
