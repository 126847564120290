import { Button, Row } from 'components'
import { STATUS_TYPES } from 'helpers/constants'
import React from 'react'
import { useNavigate } from 'react-router'
import styles from './listings.module.scss'

const ListingFooter = (props) => {
  const navigate = useNavigate()
  const {
    status = '',
    listingStatus,
    isAdmin = false,
    isEdit = false,
    loading = false,
    adminSaveLoading = false,
    onListingSave = console.log,
    onAdminSave = console.log,
    onSubmitDraft = console.log
  } = props

  return (
    <Row gap={20} className={styles.listingFooter}>
      {isAdmin ? (
        <>
          <Button form onClick={() => navigate('/listings')}>
            Back to Dashboard
          </Button>
          {isEdit && (
            <Button
              form
              className={styles.listingFooterBotton}
              style={{ width: 110 }}
              loading={loading}
              onClick={onListingSave}>
              Save Listing
            </Button>
          )}
          <Button form style={{ width: 150 }} loading={adminSaveLoading} onClick={onAdminSave}>
            Save Changes (Admin)
          </Button>
        </>
      ) : (
        <>
          <Button form onClick={() => navigate('/listings')}>
            Back to Dashboard
          </Button>
          {isEdit && (
            <>
              <Button
                form
                loading={loading && listingStatus === STATUS_TYPES.draft}
                style={{ width: 110 }}
                onClick={onListingSave}>
                Save Listing
              </Button>
              {status === STATUS_TYPES.draft && (
                <Button
                  form
                  style={{ width: 110 }}
                  loading={loading && listingStatus === STATUS_TYPES.submitted}
                  onClick={onSubmitDraft}>
                  Submit Draft
                </Button>
              )}
            </>
          )}
        </>
      )}
    </Row>
  )
}

export default ListingFooter
