import { Grid } from '@mui/material'
import { Tag, Typography } from 'components'
import { AgentSelect } from 'components'
import { UserContext } from 'context'
import React, { useContext, useState, useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styles from '../deals.module.scss'
import { DealContext } from 'context'
import { FORM_DEAL_TYPES, FORM_BROKERAGE_SIDES, REPRESENTATIVE_TYPE, USER_ROLES } from 'helpers/constants'

const DealFormHeader = ({ setSecondaryAgent, setPrimaryAgent }) => {
  const { user } = useContext(UserContext)
  const { control, setValue, getValues } = useFormContext()
  const { agentsByListing, deal, disabled, isView, isEdit, isClosedAndAgent } = useContext(DealContext)
  const [selectedAgents, setSelectedAgents] = useState(deal?.agents ?? [])
  const { type, brokerage_side, representative_type, listing_id } = getValues()
  const isAdmin = user.role === USER_ROLES.super_admin || user.role === USER_ROLES.admin

  const primaryAgent = useMemo(() => deal?.agents?.find((agent) => agent.id === deal?.creator_id), [])

  const filteredAgents = useMemo(() => {
    const agent = selectedAgents.filter((agent) => agent.id !== primaryAgent?.id)
    if (isEdit) {
      setPrimaryAgent(primaryAgent)
      setSecondaryAgent(agent)
    }
    return agent
  }, [selectedAgents, isEdit, primaryAgent, setPrimaryAgent, setSecondaryAgent])

  useEffect(() => {
    if (!isView && !isEdit) {
      setSelectedAgents(agentsByListing)
    }
  }, [agentsByListing, deal, getValues, isEdit, isView])

  useEffect(() => {
    if (selectedAgents) {
      setValue(
        'agents',
        selectedAgents.map((agent) => agent.id)
      )
    }
  }, [filteredAgents, selectedAgents, setValue])

  const isDisabled =
    brokerage_side !== FORM_BROKERAGE_SIDES.CO_OP &&
    representative_type !== REPRESENTATIVE_TYPE.BUYER &&
    type !== FORM_DEAL_TYPES.PRE_CON

  return (
    <Grid container item rowSpacing={3} columnSpacing={9} columns={12} className={styles.dealFormHeaderMain}>
      <Grid item xs={12} md={6}>
        <Typography.Body type={1} className={styles.mandatoryFieldText}>
          <span className={styles.requiredIcon}>*</span>
          Mandatory Fields
        </Typography.Body>
        <div className={styles.agentNameLable}>
          <Typography.Heading type={6} style={{ fontWight: 700 }}>
            RARE Agent # {isView || isEdit ? primaryAgent.id : user.id} :{' '}
            {isView || isEdit ? primaryAgent.name : user.name}
          </Typography.Heading>
          <Tag text="Primary Agent" type={2} className={styles.primaryAgentLabel} />
        </div>
        <div style={{ marginTop: 16, marginBottom: 30 }}>
          {filteredAgents?.map((agent, index) => {
            return (
              <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginTop: 8 }}>
                <Typography.Body type={1}>
                  RARE Agent # {agent.id} : {agent.name}
                </Typography.Body>
              </div>
            )
          })}
        </div>
      </Grid>

      {!disabled && ((isEdit && (user.id === primaryAgent?.id || isAdmin)) || (!isEdit && true)) && (
        <Grid className={styles.addAgentDropdown} item xs={12} md={6}>
          <Controller
            name="agents"
            control={control}
            render={({ field: { onChange } }) => (
              <AgentSelect
                onChange={(values) => {
                  setSelectedAgents(values)
                  onChange(values.map((agent) => agent.id))
                }}
                selectedAgents={selectedAgents}
                isClosedAndAgent={isClosedAndAgent}
                creatorId={deal?.creator_id}
                disabled={isDisabled ? !listing_id : false}
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default DealFormHeader
