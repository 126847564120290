import { AddCircleOutline, Delete } from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'
import { Input } from 'components'
import { DatePicker } from 'components'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

const AdditionalConditional = ({ isView = false }) => {
  const { control } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'conditions'
  })

  return (
    <>
      {fields?.map(({ id }, index) => (
        <Grid container columnSpacing={7} mt={2} key={id}>
          <Grid xs={9} sm={5} md={3} item>
            <Controller
              name={`conditions.${index}.conditional_upon`}
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  name={`conditions.${index}.conditional_upon`}
                  value={value}
                  label={`Condition ${index + 1}`}
                  type="text"
                  error={error?.message}
                  style={{ width: '100%' }}
                  onChange={onChange}
                  disabled={isView}
                  required
                />
              )}
            />
          </Grid>
          <Grid xs={9} sm={5} md={3} item>
            <Controller
              name={`conditions.${index}.conditional_until`}
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <DatePicker
                  name={`conditions.${index}.conditional_until`}
                  value={value}
                  label="Expiry Date"
                  type={5}
                  error={error?.message}
                  style={{ width: '100%' }}
                  onChange={onChange}
                  required
                  disabled={isView}
                />
              )}
            />
          </Grid>
          {!isView && (
            <Grid>
              {fields?.length !== 1 && (
                <IconButton onClick={() => remove(index)} sx={{ mt: 4 }} size="small">
                  <Delete />
                </IconButton>
              )}
              {fields?.length - 1 === index && (
                <IconButton
                  onClick={() => {
                    append({ conditional_upon: '', conditional_until: null })
                  }}
                  sx={{ mt: 4 }}
                  size="small">
                  <AddCircleOutline />
                </IconButton>
              )}
            </Grid>
          )}
        </Grid>
      ))}
    </>
  )
}

export default AdditionalConditional
