import { Button, Row } from 'components'
import { DealContext } from 'context'
import { STATUS_TYPES } from 'helpers/constants'
import React from 'react'
import { useContext } from 'react'
import { useNavigate } from 'react-router'
import styles from './deals.module.scss'

const ViewDealFooter = (props) => {
  const navigate = useNavigate()
  const {
    dealStatus,
    loading = false,
    isAdmin = false,
    onSave = console.log,
    onEditDeal = console.log,
    onSubmitDraft = console.log,
    adminSaveLoading = false
  } = props
  const { isEdit, deal } = useContext(DealContext)

  return (
    <Row gap={20} className={styles.dealFooter}>
      {isAdmin ? (
        <>
          <Button form onClick={() => navigate('/deals')}>
            Back to Dashboard
          </Button>
          {isEdit && (
            <Button className={styles.dealFooterButton} form loading={loading} onClick={onEditDeal}>
              Save Deal
            </Button>
          )}
          <Button style={{ width: 150 }} form loading={adminSaveLoading} onClick={onSave}>
            Save Changes (Admin)
          </Button>
        </>
      ) : (
        <>
          <Button form onClick={() => navigate('/deals')}>
            Back to Dashboard
          </Button>
          {isEdit && (
            <>
              <Button
                form
                style={{ width: 110 }}
                loading={loading && dealStatus === STATUS_TYPES.draft}
                onClick={onEditDeal}>
                Save Deal
              </Button>
              {deal?.status === STATUS_TYPES.draft && (
                <Button
                  form
                  style={{ width: 110 }}
                  loading={loading && dealStatus === STATUS_TYPES.submitted}
                  onClick={onSubmitDraft}>
                  Submit Draft
                </Button>
              )}
            </>
          )}
        </>
      )}
    </Row>
  )
}

export default ViewDealFooter
