import { Autocomplete, IconButton, TextField } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import vars from '../vars.scss'
import { Typography } from 'components'
import styles from '../components/select/select.module.scss'
import debounce from 'lodash.debounce'
import { ArrowForwardIos } from '@mui/icons-material'

const AddressAutoComplete = ({
  value,
  error,
  label = 'Property Address',
  name,
  required,
  disabled = false,
  placeholder = '',
  onChange
}) => {
  const val = value?.indexOf(',')
  const [textValue, descriptionValue] = [value?.slice(0, val), value?.slice(val + 1)]
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [selectedValue, setSelectedValue] = useState(value ? { Text: textValue, Description: descriptionValue } : null)

  useEffect(() => {
    if (value) {
      setSelectedValue({ Text: textValue, Description: descriptionValue })
    }
  }, [descriptionValue, textValue, value])

  const handleChange = useCallback(
    (e, newValue) => {
      if (newValue?.Next !== 'Find') {
        setSelectedValue(newValue)
        onChange(`${newValue?.Text}, ${newValue.Description}`)
        setOpen(false)
      } else if (newValue?.SubChild) {
        setSelectedValue(newValue.SubChild)
        onChange(`${newValue?.SubChild?.Text}, ${newValue?.SubChild?.Description}`)
        setOpen(false)
      } else {
      }
    },
    [onChange]
  )

  const handleAddressSearch = (searchText, lastId) => {
    if (searchText) {
      var url = process.env.REACT_APP_ADDRESS_AUTOCOMPLETE
      var params = ''
      params += '&Key=' + encodeURIComponent(process.env.REACT_APP_ADDRESS_AUTOCOMPLETE_KEY_ID)
      params += '&SearchTerm=' + encodeURIComponent(searchText)
      params += '&LastId=' + encodeURIComponent(lastId)

      var http = new XMLHttpRequest()
      http.open('POST', url, true)
      http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
      http.onreadystatechange = function () {
        if (http.readyState === 4 && http.status === 200) {
          var response = JSON.parse(http.responseText)
          setOptions(response.Items ?? [])
        }
      }
      http.send(params)
    }
  }

  const handleSearch = useCallback(
    debounce(async (e, val) => {
      if (val === '') {
        setOptions([])
      }
      handleAddressSearch(e?.target.value)
    }, 300),
    []
  )

  return (
    <>
      {label && (
        <Typography.Body type={2} color="primary" htmlFor={name} className={styles.label}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </Typography.Body>
      )}
      <Autocomplete
        value={selectedValue}
        disablePortal
        sx={{
          borderColor: vars['btn-secondary-bg-color'],
          '& .Mui-disabled ': {
            WebkitTextFillColor: 'black',
            background: vars['dot-color']
          },
          '& .MuiOutlinedInput-root': {
            fontSize: '14px',
            borderRadius: 0,
            padding: '0px',
            '&:hover fieldset': {
              borderColor: vars['font-color-primary'],
              borderWidth: '1px'
            },
            '&.Mui-focused fieldset': {
              borderColor: vars['font-color-primary'],
              borderWidth: '1px'
            },
            '& .Mui-disabled ': {
              WebkitTextFillColor: '#0d0d0dc7'
            }
          },
          '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
            backgroundColor: 'transparent !important;'
          }
        }}
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        options={options}
        loading={false}
        disabled={disabled}
        getOptionLabel={(option) => `${option.Text || ''}, ${option.Description || ''}`}
        getOptionSelected={(option, value) =>
          (option.id === value.id && option.Next === value.Next) || (option.SubChild && option.SubChild.id === value.id)
        }
        filterOptions={(options, state) => options}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onInputChange={handleSearch}
        onChange={handleChange}
        renderOption={(props, option) => (
          <li
            key={option.id}
            {...props}
            style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}
            onClick={(e) => {
              if (option.Next === 'Find') {
                handleAddressSearch(option.Text, option.Id)
              } else {
                handleChange(e, option)
              }
            }}>
            <Typography.Body>
              {option?.Text || ''}, {option?.Description || ''}
            </Typography.Body>
            {option?.Next === 'Find' && (
              <IconButton onClick={(e) => handleAddressSearch(option?.Text, option.Id)}>
                <ArrowForwardIos sx={{ fontSize: '16px' }} />
              </IconButton>
            )}
          </li>
        )}
        renderInput={(params) => <TextField placeholder={placeholder} {...params} />}
      />

      {error && (
        <Typography.Body type="3" color="error" className={styles.error}>
          {error}
        </Typography.Body>
      )}
    </>
  )
}

export default AddressAutoComplete
