import styles from './typography.module.scss'
import { Link as RouterLink } from 'react-router-dom'

const COLORS = ['primary', 'secondary', 'white', 'error', 'success']

const Typography = ({
  TComponent,
  TClass,
  className,
  color,
  style,
  onClick,
  children,
  htmlFor,
  form,
  type,
  to,
  href
}) => {
  const TType = `${TClass}_${type}`
  const TColor = (() => {
    if (color && COLORS.includes(color)) return color
    return 'primary'
  })()

  if (TClass === 'link') {
    if (onClick) {
      return (
        <a
          onClick={onClick}
          className={`${styles[TType]} ${styles[TColor]}${className ? ` ${className}` : ''}`}
          style={style}>
          {children}
        </a>
      )
    }
    if (href) {
      return (
        <a
          href={href}
          className={`${styles[TType]} ${styles[TColor]}${className ? ` ${className}` : ''}`}
          style={style}
          target="_blank"
          rel="noopener noreferrer">
          {children}
        </a>
      )
    }
    return (
      <RouterLink
        to={to}
        className={`${styles[TType]} ${styles[TColor]}${className ? ` ${className}` : ''}`}
        style={style}>
        {children}
      </RouterLink>
    )
  }

  return (
    <TComponent
      className={`${styles[TType]} ${styles[TColor]}${className ? ` ${className}` : ''}`}
      style={style}
      onClick={onClick}
      htmlFor={htmlFor || null}
      form={form || null}>
      {children}
    </TComponent>
  )
}

// Type: 1, 2, 3, 4, 5, 6
const Heading = ({ type = 1, className, color, style, onClick, children }) => {
  if (type < 1 || type > 6) type = 1
  const TComponent = `h${type}`
  return (
    <Typography
      TClass="heading"
      TComponent={TComponent}
      type={type}
      className={className}
      color={color}
      style={style}
      onClick={onClick}>
      {children}
    </Typography>
  )
}

// Type: 1, 2, 3
const Subheading = ({ type = 1, className, color, style, onClick, children }) => {
  if (type < 1 || type > 3) type = 1

  return (
    <Typography
      TClass="subheading"
      TComponent="p"
      type={type}
      className={className}
      color={color}
      style={style}
      onClick={onClick}>
      {children}
    </Typography>
  )
}

// Type: 1, 2, 3, 4
const Body = ({ type = 1, className, color, style, onClick, children }) => {
  if (type < 1 || type > 5) type = 1
  return (
    <Typography
      TClass="body"
      TComponent="p"
      type={type}
      className={className}
      color={color}
      style={style}
      onClick={onClick}>
      {children}
    </Typography>
  )
}

// Type: 1
const Caption = ({ style, className, color, onClick, children }) => {
  return (
    <Typography
      TClass="caption"
      TComponent="p"
      type={1}
      className={className}
      color={color}
      style={style}
      onClick={onClick}>
      {children}
    </Typography>
  )
}

// Type: 1
const Overline = ({ style, className, color, onClick, children }) => {
  return (
    <Typography
      TClass="overline"
      TComponent="p"
      type={1}
      className={className}
      color={color}
      style={style}
      onClick={onClick}>
      {children}
    </Typography>
  )
}

// Type: 1, 2
const Label = ({ type = 1, className, color, style, onClick, htmlFor, form, children }) => {
  if (type < 1 || type > 2) type = 1
  return (
    <Typography
      TClass="label"
      TComponent="label"
      className={className}
      color={color}
      type={type}
      style={style}
      htmlFor={htmlFor}
      form={form}
      onClick={onClick}>
      {children}
    </Typography>
  )
}

// Type: 1
const Link = ({ color, style, to, href, onClick, children, className }) => {
  return (
    <Typography
      TClass="link"
      type={1}
      color={color}
      style={style}
      to={to}
      href={href}
      onClick={onClick}
      className={className}>
      {children}
    </Typography>
  )
}

Typography.Heading = Heading
Typography.Subheading = Subheading
Typography.Body = Body
Typography.Caption = Caption
Typography.Overline = Overline
Typography.Label = Label
Typography.Link = Link
export default Typography
