import React, { useCallback, useContext } from 'react'
import vars from '../vars.scss'
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material'
import { Box, Checkbox, Divider, IconButton } from '@mui/material'
import { toast } from 'react-toastify'
import { GET_AGENTS } from 'helpers/gql'
import { useQuery } from '@apollo/client'
import { Typography, Row } from 'components'
import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import { UserContext } from 'context'
import Autocomplete from '@mui/material/Autocomplete'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import InputBase from '@mui/material/InputBase'
import { USER_ROLES } from 'helpers/constants'

const StyledAutocompletePopper = styled('div')(({ theme }) => ({}))
function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props
  return <StyledAutocompletePopper {...other} />
}

PopperComponent.propTypes = {
  anchorEl: PropTypes.any,
  disablePortal: PropTypes.bool,
  open: PropTypes.bool.isRequired
}

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: '100%',
  '& input': {
    borderRadius: 4,
    padding: 8,
    border: `1px solid #eaecef`,
    fontSize: 14
  }
}))

function AgentSelect({ onChange, selectedAgents, disabled = false, creatorId = '', isClosedAndAgent }) {
  const { user } = useContext(UserContext)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const { data: { getAgents: agents } = [], loading } = useQuery(GET_AGENTS, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    }
  })
  const handleChange = useCallback(
    (event, values) => {
      if (event.key !== 'Backspace') {
        onChange(values)
      }
    },
    [onChange]
  )

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'github-label' : undefined
  const filteredAgents = agents?.filter(
    (agent) => agent.id !== user.id && agent?.role === USER_ROLES.agent && agent.id !== creatorId
  )
  const value = selectedAgents?.filter((val) => val.id !== creatorId)

  return (
    <>
      <Row gap={10} marginBottom={10}>
        <IconButton
          disabled={disabled || isClosedAndAgent}
          onClick={handleClick}
          sx={{ color: vars['doc-font-color'], p: 0 }}>
          <AddCircleOutline className="reolutionAddIcon" />
        </IconButton>
        <Typography.Heading
          type={6}
          style={{ fontFamily: vars['fontFamilySourceSansPro'] }}
          className={'resolutionSecondaryAgent'}>
          Add Secondary Agent
        </Typography.Heading>
        <Typography.Body type={1} style={{ color: '#949494', marginTop: 1 }} className={'resolutionOptionalText'}>
          (Optional)
        </Typography.Body>
      </Row>
      {disabled && (
        <Typography.Body
          type={3}
          style={{
            color: vars['font-color-error'],
            marginTop: '-8px',
            marginBottom: vars.margin4
          }}>
          Please select listing first
        </Typography.Body>
      )}

      <Popper
        sx={{ border: '1px solid #e1e4e8', width: 350, backgroundColor: 'white', mt: '20px !important' }}
        style={{ marginTop: '16px !important' }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Typography.Body type={1} style={{ margin: '10px 0px 10px 10px' }}>
              RARE Agents
            </Typography.Body>
            <Divider />
            <Autocomplete
              open
              multiple
              value={value}
              loading={loading}
              disableCloseOnSelect
              isOptionEqualToValue={(option, value) => option.id === value.id}
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText="No Agents"
              options={filteredAgents ?? []}
              getOptionLabel={(option) => option.name}
              onChange={handleChange}
              onClose={(event, reason) => {
                if (reason === 'escape') {
                  handleClose()
                }
              }}
              renderOption={(props, option, { selected }) => (
                <Box
                  component="li"
                  {...props}
                  key={option.id}
                  style={{ display: 'flex', justifyContent: 'space-between ', alignItems: 'center' }}>
                  <Row style={{ alignItems: 'center' }}>
                    <Checkbox
                      size="small"
                      icon={<CheckBoxOutlineBlank />}
                      checkedIcon={<CheckBox />}
                      checked={selected}
                      sx={{
                        '&.Mui-checked': {
                          color: '#1f1616'
                        }
                      }}
                    />
                    <Typography.Body type={2}>{option.name}</Typography.Body>
                  </Row>
                  <Typography.Body type={5} style={{ color: '#A5A5A5', fontSize: '13px' }}>
                    RARE Agent #{option.id}
                  </Typography.Body>
                </Box>
              )}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder="Search..."
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  )
}
export default AgentSelect
