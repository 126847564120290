import React, { useEffect, useState } from 'react'
import { PopUp, Col, Typography, Input, DatePicker } from 'components'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import { gql, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { GET_USER } from 'context/UserProvider'

const RecoLicensePopup = ({ user, onClose, onSave }) => {
  const { handleSubmit, control, setValue } = useForm()
  const [isDisplay, setIsDisplay] = useState(false)

  const [updateRecoLicense, { loading }] = useMutation(UPDATE_RECO_LICENSE, {
    onError: (err) => {
      toast.dismiss()
      console.error(err)
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('Reco license updated.')
    },
    refetchQueries: [{ query: GET_USER }]
  })

  useEffect(() => {
    setValue('reco_expiry', moment(user.reco_expiry).toDate())
  }, [user.reco_expiry, setValue])

  const onSubmit = (data) => {
    updateRecoLicense({
      variables: { data },
      onCompleted: () => {
        toast.dismiss()
        toast.success('Reco license updated.')
        onClose()
        onSave()
      }
    })
  }

  return (
    <PopUp
      title="Update RECO Expiry Date"
      open={true}
      loading={loading}
      confirmBtnLabel="Update"
      onCancel={onClose}
      canShowConfirmButton={isDisplay ? true : false}
      style={{ border: '2px solid red' }}
      onConfirm={handleSubmit(onSubmit)}>
      <Col style={{ width: '100%' }}>
        <Typography.Heading type="5">Reco License</Typography.Heading>
        <form style={{ marginTop: 24, maxWidth: 420 }}>
          <Controller
            name="reco_number"
            control={control}
            defaultValue={user.reco_number}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type="text"
                error={error?.message}
                value={value}
                onChange={onChange}
                label="RECO number"
                name="reco_number"
                disabled
                style={{ width: '100%', marginBottom: 24 }}
              />
            )}
          />
          <Controller
            name="reco_expiry"
            control={control}
            rules={{ required: 'Reco expiry date is required' }}
            defaultValue={moment(user.reco_expiry).toDate()}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <DatePicker
                error={error?.message}
                value={value}
                onChange={(date) => {
                  setIsDisplay(true)
                  if (moment(date).isBefore(moment(user.reco_expiry))) {
                    date = moment(user.reco_expiry).toDate()
                  }
                  onChange(date)
                  setValue('reco_expiry', date)
                }}
                label="Expiration date"
                required
                name="reco_expiry"
                style={{ width: '100%' }}
                minDate={moment(user.reco_expiry).toDate()}
              />
            )}
          />
        </form>
      </Col>
    </PopUp>
  )
}

const UPDATE_RECO_LICENSE = gql`
  mutation updateRecoLicense($data: UpdateRecoLicenseInput!) {
    updateRecoLicense(data: $data)
  }
`

export default RecoLicensePopup
