import { Search } from '@mui/icons-material'
import { TextField, ThemeProvider, createTheme } from '@mui/material'
import React from 'react'
import vars from '../../vars.scss'

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
          borderColor: vars['btn-secondary-bg-color'],
          '& .MuiOutlinedInput-root': {
            borderRadius: '0px',
            '&:hover fieldset': {
              borderColor: vars['font-color-primary'],
              borderWidth: '1px'
            },
            '&.Mui-focused fieldset': {
              borderColor: vars['font-color-primary'],
              borderWidth: '1px'
            }
          },
          '@media (max-width: 992px)': {
            width: '100%'
          }
        }
      }
    }
  }
})

const InputSearch = ({ name, onChange, value, placeholder, size = 'small' }) => {
  return (
    <ThemeProvider theme={theme}>
      <TextField
        name={name}
        className="inputSearch"
        size={size}
        placeholder={placeholder ? placeholder : 'Search...'}
        InputProps={{
          startAdornment: <Search sx={{ fontSize: '30px', color: vars['btn-secondary-bg-color'] }} />
        }}
        onChange={onChange}
      />
    </ThemeProvider>
  )
}

export default InputSearch
