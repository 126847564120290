import * as React from 'react'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { Typography } from 'components'
import styles from './datepicker.module.scss'
import { CalendarMonthOutlined, Clear } from '@mui/icons-material'
import { IconButton, InputAdornment, ThemeProvider, createTheme } from '@mui/material'
import './YearPicker.scss'
import moment from 'moment'
import vars from '../../vars.scss'
import { usePopup } from 'hooks/usePopup'

export const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
          borderColor: vars['btn-secondary-bg-color'],
          '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            fontSize: 14,
            height: 35,
            '&:hover fieldset': {
              borderColor: vars['font-color-primary'],
              borderWidth: '1px'
            },
            '&.Mui-focused fieldset': {
              borderColor: vars['font-color-primary'],
              borderWidth: '1px'
            },
            '&.Mui-disabled': {
              backgroundColor: vars['disabled-feild-color']
            }
          }
        }
      }
    }
  }
})

const YearPicker = (props) => {
  const {
    label,
    required,
    name,
    error,
    value,
    onChange,
    type = 3,
    placeholder = '',
    disabled = false,
    maxDate = null,
    minDate = null
  } = props
  const [open, show, hide] = usePopup()
  const selectedValue = value ? moment(value) : null

  return (
    <div className="yearPickerMain">
      {label && (
        <Typography.Body type={2} htmlFor={name} style={{ marginBottom: 6 }} className={styles.label}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </Typography.Body>
      )}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <DatePicker
            defaultValue={moment()}
            value={selectedValue}
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
            maxDate={maxDate}
            minDate={minDate}
            open={open}
            onClose={hide}
            views={['year']}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      {(!disabled || !selectedValue) && (
                        <IconButton onClick={() => onChange(null)}>
                          <Clear />
                        </IconButton>
                      )}
                      <IconButton disabled={disabled} edge="end" onClick={show}>
                        <CalendarMonthOutlined />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              }
            }}
          />
        </ThemeProvider>
      </LocalizationProvider>
      {error && (
        <Typography.Body type={3} color="error" className={styles.error}>
          {error}
        </Typography.Body>
      )}
    </div>
  )
}

export default YearPicker
