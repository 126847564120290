import { STATUS } from 'helpers/constants'
import moment from 'moment'
import { formatCurrency } from './utils'

const ListingTableFilterRowsBySearch = (rows, searchedVal) => {
  return rows.filter((row) => {
    return (
      row.address.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
      row.id.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
      row.type.toLowerCase().includes(searchedVal.toLowerCase()) ||
      moment(row.created)
        .format('MMM DD, yyyy hh:mm A')
        ?.toString()
        .toLowerCase()
        .includes(searchedVal.toString().toLowerCase()) ||
      STATUS[row.status].toLowerCase().includes(searchedVal.toLowerCase()) ||
      row.agents
        .filter((a) => a.id === row.creator_id)[0]
        ?.name.toLowerCase()
        .includes(searchedVal.toLowerCase()) ||
      row.option?.toLowerCase().includes(searchedVal.toLowerCase())
    )
  })
}

const DealsTableFilterRowsBySearch = (rows, searchedVal) => {
  return rows.filter((row) => {
    return (
      row.address.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
      row.id.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
      row.type.toLowerCase().includes(searchedVal.toLowerCase()) ||
      row?.price?.toLowerCase().includes(searchedVal.split(',').join('').toLowerCase()) ||
      row.agents
        .filter((a) => a.id === row.creator_id)[0]
        ?.name.toLowerCase()
        .includes(searchedVal.toLowerCase()) ||
      STATUS[row.status].toLowerCase().includes(searchedVal.toLowerCase()) ||
      row.agents
        .filter((a) => a.id === row.creator_id)[0]
        ?.id.toString()
        .toLowerCase()
        .includes(searchedVal.toString().toLowerCase()) ||
      formatCurrency(row.sale_price)?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
      moment(row.created)
        .format('MMM DD, yyyy hh:mm A')
        ?.toString()
        .toLowerCase()
        .includes(searchedVal.toString().toLowerCase()) ||
      moment(row?.close_date)
        .format('MMM DD, yyyy hh:mm A')
        ?.toString()
        .toLowerCase()
        .includes(searchedVal.toString().toLowerCase())
    )
  })
}

const ActivityLogTableFilterRowsBySearch = (rows, searchedVal) => {
  return rows.filter((row) => {
    return (
      row.activity.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
      JSON.stringify(row.user).toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
      row.url?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
      row.description?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
      moment(row.activity_time)
        .format('MMM DD, yyyy hh:mm A')
        ?.toString()
        .toLowerCase()
        .includes(searchedVal.toString().toLowerCase())
    )
  })
}
const AdminPanelFilterRowsBySearch = (rows, searchedVal) => {
  return rows.filter((row) => {
    const active = 'Active'
    const Terminated = 'Terminated'
    return (
      row.id.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
      row.name.toLowerCase().includes(searchedVal.toLowerCase()) ||
      (row.deleted_at
        ? Terminated.toLowerCase().includes(searchedVal.toLowerCase())
        : active.toLowerCase().includes(searchedVal.toLowerCase()))
    )
  })
}
const UserManagementFilterRowsBySearch = (rows, searchedVal) => {
  return rows.filter((row) => {
    const active = 'Active'
    const InActive = 'InActive'
    return (
      row.id.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
      row.name.toLowerCase().includes(searchedVal.toLowerCase()) ||
      row.role.toLowerCase().includes(searchedVal.toLowerCase()) ||
      (row.deleted_at
        ? InActive.toLowerCase().includes(searchedVal.toLowerCase())
        : active.toLowerCase().includes(searchedVal.toLowerCase()))
    )
  })
}

const search = {
  ListingTableFilterRowsBySearch,
  DealsTableFilterRowsBySearch,
  ActivityLogTableFilterRowsBySearch,
  AdminPanelFilterRowsBySearch,
  UserManagementFilterRowsBySearch
}

export default search
