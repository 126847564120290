import { Article, Login, Person } from '@mui/icons-material'
import { Divider, Filter, Typography } from 'components'
import { useState, useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'
import { UserContext } from 'context'
import LoginInfo from './LoginInfo'
import Notifications from './Notifications'
import Profile from './Profile'
import RecoLicense from './RecoLicense'

const Settings = () => {
  const navigate = useNavigate()

  const { user } = useContext(UserContext)
  const params = useParams()
  const { section } = params

  const [tab, setTab] = useState(section || 'profile')

  const handleTabChange = (section) => {
    setTab(section)
    navigate(`/settings/${section}`)
  }

  const settingsOptions =
    user.role === 'super_admin'
      ? [
          {
            value: 'profile',
            label: 'Profile',
            icon: Person
          },
          // {
          //   value: 'notifications',
          //   label: 'Notifications',
          //   icon: NotificationsIcon
          // },
          {
            value: 'login_info',
            label: 'Login information',
            icon: Login
          }
          // {
          //   value: 'reco_license',
          //   label: 'Reco License',
          //   icon: Article
          // }
        ]
      : [
          {
            value: 'login_info',
            label: 'Login information',
            icon: Login
          },
          {
            value: 'reco_license',
            label: 'Reco License',
            icon: Article
          }
        ]

  return (
    <>
      <Helmet title="Settings | RARE" />
      <section id="settings">
        <Typography.Heading type="3">Settings</Typography.Heading>
        <Divider />

        <div className="table-layout-page" style={{ display: 'flex', gap: 40 }}>
          <Filter options={settingsOptions} defaultValue={tab} onChange={handleTabChange} />
          <div style={{ display: 'flex', flexDirection: 'column', gap: 24, width: '100%' }}>
            {tab === 'profile' && <Profile />}
            {tab === 'login_info' && <LoginInfo />}
            {tab === 'notifications' && <Notifications />}
            {tab === 'reco_license' && <RecoLicense />}
          </div>
        </div>
      </section>
    </>
  )
}

export default Settings
