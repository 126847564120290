import styles from './pagination.module.scss'
import { KeyboardArrowLeft, KeyboardArrowRight, MoreHoriz } from '@mui/icons-material'
import { useState } from 'react'
import Typography from 'components/typography/Typography'

const Pagination = ({ total = 40, pageSize = 10, defaultCurrentPage = 1, onChange }) => {
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage)
  const numPages = Math.ceil(total / pageSize)

  const handleNext = () => {
    setCurrentPage((currentPage) => {
      const page = Math.min(numPages, currentPage + 1)
      if (currentPage === page) return page
      if (onChange) onChange(page)
      return page
    })
  }
  const handlePrev = () => {
    setCurrentPage((currentPage) => {
      const page = Math.max(1, currentPage - 1)
      if (currentPage === page) return page
      if (onChange) onChange(page)
      return page
    })
  }

  const handlePageChange = (page) => {
    if (currentPage === page) return page
    if (onChange) onChange(page)
    setCurrentPage(page)
  }

  const pageOptions = (() => {
    if (numPages <= 6) return Array.from({ length: numPages }, (_, i) => i + 1)
    else if (currentPage < 4) return [1, 2, 3, 4, null, numPages]
    else if (currentPage > numPages - 3) return [1, null, numPages - 3, numPages - 2, numPages - 1, numPages]
    return [1, null, currentPage - 1, currentPage, currentPage + 1, null, numPages]
  })()

  return (
    <div className={styles.pagination}>
      <div className={`${styles.paginationItem} ${currentPage === 1 ? styles.disabled : ''}`} onClick={handlePrev}>
        <KeyboardArrowLeft />
      </div>
      {pageOptions.map((page, i) => {
        const selected = currentPage === page ? styles.selected : ''
        if (page === null) {
          return (
            <div key={i} className={`${styles.paginationDots}`}>
              <MoreHoriz />
            </div>
          )
        }
        return (
          <div
            key={i}
            className={`${styles.paginationItem} ${selected}`}
            onClick={() => {
              handlePageChange(page)
            }}>
            <Typography.Body type={3}>{page}</Typography.Body>
          </div>
        )
      })}
      <div className={`${styles.paginationItem} ${currentPage === numPages ? styles.disabled : ''}`} onClick={handleNext}>
        <KeyboardArrowRight />
      </div>
    </div>
  )
}

export default Pagination
