import { gql, useMutation } from '@apollo/client'
import { PopUp, Typography } from 'components'
import { GET_ALL_USERS } from 'helpers/gql'
import { useCallback } from 'react'
import { toast } from 'react-toastify'

const ActivateUserPopup = ({ user, open, hidePopup }) => {
  const [deactivateUser] = useMutation(DEACTIVATE_USER, {
    onError: (err) => {
      toast.dismiss()
      console.error(err)
      toast.error('Error Deactivating user')
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('User Deactivated')
      hidePopup()
    },
    refetchQueries: [{ query: GET_ALL_USERS, variables: {
      excludeDeleted: false
    }}]
  })

  const [activateUser] = useMutation(ACTIVATE_USER, {
    onError: (err) => {
      toast.dismiss()
      console.error(err)
      toast.error('Error Activating user')
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('User Activated')
      hidePopup()
    },
    refetchQueries: [{ query: GET_ALL_USERS, variables: {
      excludeDeleted: false
    }}]
  })

  const handleConfirm = useCallback(() => {
    if (user?.value) {
      activateUser({ variables: { id: user.id } })
    } else {
      deactivateUser({ variables: { id: user.id } })
    }
  }, [activateUser, deactivateUser, user])

  return (
    <PopUp
      title={user?.value ? 'Activate user' : 'Deactivate user'}
      open={open}
      confirmBtnLabel={'Confirm'}
      cancelBtnLabel={'Cancel'}
      onCancel={hidePopup}
      onConfirm={handleConfirm}>
      <div style={{ width: 350, height: 40, display: 'flex', alignItems: 'center' }}>
        <Typography.Body> Are you sure you want to {user?.value ? 'Activate' : 'Deactivate'}?</Typography.Body>
      </div>
    </PopUp>
  )
}
const DEACTIVATE_USER = gql`
  mutation deactivateUser($id: Int!) {
    deactivateUser(id: $id)
  }
`
const ACTIVATE_USER = gql`
  mutation activateUser($id: Int!) {
    activateUser(id: $id)
  }
`
export default ActivateUserPopup
