import { Typography } from 'components'
import styles from './textarea.module.scss'

const Textarea = ({
  placeholder,
  name,
  error,
  value,
  onChange,
  style,
  label,
  sublabel,
  disabled,
  //   onBlur,
  required,
  rows = 6,
  maxLength,
  preventLineBreaks = false
}) => {
  const handleKeyDown = (e) => {
    if (preventLineBreaks && e.key === 'Enter') {
      e.preventDefault()
    }
  }
  return (
    <>
      {label && (
        <Typography.Body type={2} color="primary" htmlFor={name} className={styles.label}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </Typography.Body>
      )}

      {sublabel && (
        <Typography.Body type={3} color="secondary" htmlFor={name} className={styles.sublabel}>
          {sublabel}
        </Typography.Body>
      )}

      <textarea
        rows={rows}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        style={style}
        disabled={disabled}
        className={styles.textarea}
        onKeyDown={handleKeyDown}
      />

      {error && (
        <Typography.Body type="3" color="error" className={styles.error}>
          {error}
        </Typography.Body>
      )}
    </>
  )
}

export default Textarea
