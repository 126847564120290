import { useRef, useState } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { downloadS3Url, generateSignedUrl } from 'helpers/utils'
import { DownloadForOffline, Clear, AddCircleOutline, Visibility } from '@mui/icons-material'
import { Checkbox, IconButton, LinearProgress, Tooltip, createTheme, useMediaQuery } from '@mui/material'
import vars from '../../vars.scss'
import { toast } from 'react-toastify'
import uploadIcon from '../../assets/images/upload icon.png'
import { usePopup } from 'hooks/usePopup'
import { Col, Row, Typography, WarningPopup } from 'components'
import styles from './upload.module.scss'
import AttachmentViewer from 'pages/announcements/AttachmentViewer'

const BasicUpload = ({
  index = '',
  title,
  defaultValue,
  error,
  append,
  remove,
  fields,
  isClosedAndAgent = false,
  isApprovedAndAgent = false,
  isChecked = false,
  required = false,
  disabled = false,
  canShowAdditionalDoc = false,
  isAllDocument = false,
  onCheckChange = console.log,
  handleDeleteFile = console.log,
  onChange = console.log
}) => {
  const fileSelector = useRef(null)
  const client = useApolloClient()
  const [value, setValue] = useState(defaultValue?.fileName ? defaultValue : null)
  const [loading, setLoading] = useState(false)
  const [confirmDelete, showConfirmDelete, hideConfirmDelete] = usePopup()
  const [attachmentPreview, showAttachmentPreview, hideAttachmentPreview] = usePopup()
  const [attachment, setAttachment] = useState()
  const [uploadProgress, setUploadProgress] = useState(defaultValue?.fileName ? 100 : 0)
  const HW = isAllDocument ? 40 : 30
  const themes = createTheme()
  const isMobile = useMediaQuery(themes.breakpoints.down('sm'))
  const fileNameLength = isMobile ? 12 : 40
  const supportedFileTypes = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/jpeg',
    'image/png'
  ]
  const uploadFile = async (file) => {
    toast.dismiss()
    const { type, size } = file
    if (!supportedFileTypes.includes(file.type)) {
      toast.error('Unsupported file type. Please upload a PDF, Word document, JPG, or PNG.')
      return
    }
    setLoading(true)
    return new Promise(async (resolve, reject) => {
      const { data: { getSignedUrl: { signedUrl, key, fileName, newFilename } } = {} } = await client.query({
        fetchPolicy: 'no-cache',
        query: GET_SIGNED_URL,
        variables: { fileName: file.name, fileType: file.type }
      })

      const xhr = new XMLHttpRequest()
      xhr.open('PUT', signedUrl)

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded * 100) / event.total)
          setUploadProgress(progress)
        }
      }

      xhr.onload = () => {
        setLoading(false)
        if (xhr.status === 200) {
          resolve({ key, fileName, type, size, newFilename })
        } else {
          toast.error('File upload failed')
          setUploadProgress(0)
          reject(xhr.statusText)
          fileSelector.current.value = null
          setValue(null)
        }
      }

      xhr.onerror = (error) => {
        setLoading(false)
        setUploadProgress(0)
        toast.error('File upload failed')
        reject(error)
        fileSelector.current.value = null
        setValue(null)
      }
      xhr.send(file)
    })
  }

  const handleFileChange = async (e) => {
    if (!e.target.files) return
    const uploadedFiles = await uploadFile(e.target.files[0])
    setValue({ ...uploadedFiles, path: uploadedFiles.key })
    setValue(uploadedFiles)
    onChange(uploadedFiles)
  }

  const handleUpload = () => {
    fileSelector.current.click()
  }

  const handleAdditionalRemove = () => {
    remove(index)
    if (fields?.length === 1) {
      append({ fileName: '', key: null, size: null, type: '' })
    }
  }

  const handleRemove = async () => {
    if (canShowAdditionalDoc) {
      handleAdditionalRemove()
      handleDeleteFile(value)
      fileSelector.current.value = null
      setUploadProgress(0)
      hideConfirmDelete()
    } else {
      handleDeleteFile(value)
      fileSelector.current.value = null
      setValue(null)
      onChange(null)
      setUploadProgress(0)
      hideConfirmDelete()
    }
  }
  const handleAttachmentPreview = async (attachment) => {
    try {
      const signedUrl = await generateSignedUrl(attachment.key)
      setAttachment({ file_name: attachment.fileName, signedUrl, key: attachment.key })
    } catch (error) {
      console.error('Error generating signed URL:', error)
    }
    showAttachmentPreview()
  }

  return (
    <>
      <Row className={styles.upload}>
        <input type="file" ref={fileSelector} onChange={handleFileChange} hidden />
        {!disabled && (
          <IconButton
            onClick={handleUpload}
            disabled={(value && !isClosedAndAgent) || (isClosedAndAgent && value) || loading}
            disableRipple
            className={styles.uploadIcon}
            sx={{
              opacity: value || loading ? 0.5 : 1,
              pr: canShowAdditionalDoc && fields?.length - 1 !== index ? '36px' : 0
            }}
            size="large">
            <img src={uploadIcon} alt="uploadIcon" height={HW} width={HW} />
          </IconButton>
        )}
        {!isAllDocument && !canShowAdditionalDoc && (
          <Checkbox
            sx={{
              '&.Mui-checked': {
                color: '#1f1616',
                marginLeft: '-5px'
              }
            }}
            disabled={disabled || (isClosedAndAgent && value) || (isApprovedAndAgent && value)}
            size="small"
            checked={value?.fileName ?? isChecked}
            onChange={onCheckChange}
          />
        )}
        {canShowAdditionalDoc && !disabled && fields?.length - 1 === index && (
          <IconButton
            disabled={!value}
            onClick={() => {
              append({ fileName: '', key: null, size: null, type: '' })
            }}>
            <AddCircleOutline className={styles.buttons} />
          </IconButton>
        )}
        <div
          style={{
            width: ' 100%',
            marginLeft: disabled && (canShowAdditionalDoc || isAllDocument) ? 38 : 0
          }}>
          <Row>
            <Col style={{ width: '75%' }}>
              <Typography.Body type={2} style={{ color: vars['doc-font-color'] }}>
                {title}
                {required && title && <span style={{ color: '#FF0000' }}>*</span>}
              </Typography.Body>
            </Col>
            {uploadProgress > 0 && (
              <Col className={styles.uploadProgressCol}>
                <Typography.Body type={2} className={styles.uploadProgress}>
                  {uploadProgress}%
                </Typography.Body>
              </Col>
            )}
          </Row>
          {uploadProgress > 0 && (
            <Row>
              <Col className={styles.linearProgressCol}>
                <LinearProgress
                  variant="determinate"
                  value={uploadProgress}
                  size="large"
                  className={styles.linearProgress}
                  sx={{
                    '& .MuiLinearProgress-bar': { backgroundColor: vars['font-color-primary'] }
                  }}
                />
                {value && (
                  <Tooltip
                    placement="right-start"
                    title={value.fileName.length > fileNameLength ? value?.fileName : ''}>
                    <div className={styles.fileName}>
                      {value?.fileName.length > fileNameLength
                        ? `${value?.fileName.slice(0, fileNameLength)}...`
                        : value?.fileName}
                    </div>
                  </Tooltip>
                )}
              </Col>
              <Col className={styles.iconButtons}>
                {!disabled && (
                  <IconButton
                    disabled={!value || (isClosedAndAgent && value?.id) || (isApprovedAndAgent && value?.id)}
                    onClick={showConfirmDelete}>
                    <Clear className={styles.buttons} />
                  </IconButton>
                )}
                <IconButton
                  disabled={!value}
                  onClick={() => {
                    handleAttachmentPreview(value)
                  }}>
                  <Visibility className={styles.buttons} />
                </IconButton>
                <IconButton
                  disabled={!value}
                  onClick={() => {
                    downloadS3Url(value.key, value.fileName)
                  }}>
                  <DownloadForOffline className={styles.buttons} />
                </IconButton>
              </Col>
            </Row>
          )}
          {error && !value && (
            <Typography.Body type="3" color="error" style={{ marginTop: 8 }}>
              {error}
            </Typography.Body>
          )}
        </div>
      </Row>
      <WarningPopup
        openModel={confirmDelete}
        confirmBtnLabel={'Delete'}
        onCancle={hideConfirmDelete}
        onConfirm={handleRemove}>
        <Typography.Body type={1}>Are you sure you want to delete this document ?</Typography.Body>
      </WarningPopup>
      <AttachmentViewer open={attachmentPreview} onCancel={hideAttachmentPreview} attachment={attachment} />
    </>
  )
}

const GET_SIGNED_URL = gql`
  query getSignedUrl($fileName: String, $fileType: String) {
    getSignedUrl(fileName: $fileName, fileType: $fileType) {
      signedUrl
      fileName
      key
      newFilename
    }
  }
`
export default BasicUpload
