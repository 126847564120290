import { Row, Typography } from 'components'
import Col from 'components/layout/Col'
import styles from './radio.module.scss'

const DIRECTIONS = ['horizontal', 'vertical']

const RadioGroup = ({
  onChange,
  value,
  gap = 24,
  error,
  children,
  direction = 'horizontal',
  label,
  required = false,
  name
}) => {
  if (!DIRECTIONS.includes(direction)) direction = 'horizontal'

  return (
    <>
      {label && (
        <Typography.Body type={2} htmlFor={name} className={styles.groupLabel}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </Typography.Body>
      )}

      {direction === 'horizontal' && (
        <Row onChange={onChange} value={value} gap={gap}>
          {children}
        </Row>
      )}
      {direction === 'vertical' && (
        <Col onChange={onChange} value={value} gap={gap}>
          {children}
        </Col>
      )}

      {error && (
        <Typography.Body type="3" color="error" className={styles.error}>
          {error}
        </Typography.Body>
      )}
    </>
  )
}

export default RadioGroup
