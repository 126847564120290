import { Grid, Typography } from '@mui/material'
import { CardHeader } from './CardHeader'
import { Card } from 'components'
import React, { useEffect, useState } from 'react'
import styles from './home.module.scss'
import { formatCurrency } from 'helpers/utils'
import moment from 'moment'
import { gql, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

const GCIEarnings = () => {
  const style = {
    borderRight: ' 3px solid #BBBBBB',
    backgroundColor: 'white !important',
    '& .MuiCardContent-root:last-child': {
      pb: 1
    },
    '& .MuiCardHeader-root': {
      pb: 0,
      fontWeight: 280
    }
  }
  const [selectedYear, setSelectedYear] = useState(moment().year())
  const [yearOptions, setYearOptions] = useState([])

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value)
  }

  const { data: { getGrossCommissionIncome: grossCommissionIncome } = [], refetch: grossCommissionIncomeRefetch } =
    useQuery(GET_GROSS_COMMISSION_iNCOME, {
      onError: (err) => {
        toast.dismiss()
        if (!err?.message?.includes('Session timeout')) {
          toast.error(err.message)
        }
      },
      variables: {
        year: selectedYear
      }
    })

  useEffect(() => {
    const fetchYears = async () => {
      try {
        const currentYear = new Date().getFullYear()
        const startingYear = 2023
        const lastYears = Array.from({ length: currentYear - startingYear + 1 }, (_, index) => currentYear - index)
        setYearOptions(lastYears)
      } catch (error) {
        console.error('Error fetching years:', error)
      }
    }

    fetchYears()
  }, [])

  useEffect(() => {
    grossCommissionIncomeRefetch()
  }, [grossCommissionIncomeRefetch])

  return (
    <>
      <Card
        title={
          <CardHeader
            title="Gross Commission Income"
            showYearDropdown
            selectedYear={selectedYear}
            handleYearChange={handleYearChange}
            yearOptions={yearOptions}
          />
        }>
        <Grid container columnSpacing={7}>
          <Grid item xs={12} md={4}>
            <Card style={style} title={'Firm GCI'}>
              <Typography sx={{ fontSize: '22px', fontWeight: 500 }}>
                {formatCurrency(grossCommissionIncome?.firmGCIAmount || 0.0)}
              </Typography>
              <Typography className={styles.cgiTime}>{grossCommissionIncome?.GCIMessage || `........`}</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} mt={{ xs: 2, md: 0 }}>
            <Card style={style} title={'Closed GCI'}>
              <Typography sx={{ fontSize: '22px', fontWeight: 500 }}>
                {formatCurrency(grossCommissionIncome?.closedGCIAmount || 0.0)}
              </Typography>
              <Typography className={styles.cgiTime}>{grossCommissionIncome?.GCIMessage || `........`}</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} mt={{ xs: 2, md: 0 }}>
            <Card style={style} title={'Settled GCI'}>
              <Typography sx={{ fontSize: '22px', fontWeight: 500 }}>
                {formatCurrency(grossCommissionIncome?.settledGCIAmount || 0.0)}
              </Typography>
              <Typography className={styles.cgiTime}>{grossCommissionIncome?.GCIMessage || `........`}</Typography>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </>
  )
}

const GET_GROSS_COMMISSION_iNCOME = gql`
  query getGrossCommissionIncome($year: Int!) {
    getGrossCommissionIncome(year: $year) {
      firmGCIAmount
      closedGCIAmount
      settledGCIAmount
      GCIMessage
    }
  }
`

export default GCIEarnings
