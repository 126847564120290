import { Checkbox, Tooltip, createTheme, useMediaQuery } from '@mui/material'
import folderIcon from '../../assets/images/folder.png'
import epsIcon from '../../assets/images/epsIcon.png'
import zipFolderIcon from '../../assets/images/zipFolderIcon.png'
import pdfIcon from '../../assets/images/Pdf.png'
import textFileIcon from '../../assets/images/textfileIcon.png'
import videoIcon from '../../assets/images/videoIcon.png'
import msWord from '../../assets/images/Word_96x96.png'
import msExcel from '../../assets/images/Excel_96x96.png'
import msPowerPoint from '../../assets/images/PowerPoint_96x96.png'
import msOneNote from '../../assets/images/OneNote_96x96.png'
import msOutLook from '../../assets/images/Outlook_96x96.png'
import googleDocs from '../../assets/images/google-docs-96.png'
import googleSheet from '../../assets/images/google-sheets-96.png'
import googleSlides from '../../assets/images/google-slides-96.png'
import PicIcon from '../../assets/images/PicIcon.png'
import {
  downloadLibraryZip,
  downloadURI,
  generateLibrarySignedUrl,
  generateSignedUrl,
  getFileLibraryUrl,
  getFileUrl,
  getLibraryFileUrl
} from 'helpers/utils'
import { MoreVert } from '@mui/icons-material'
import styles from './library.module.scss'
import React, { useMemo, useState, useCallback } from 'react'
import gql from 'graphql-tag'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'
import { GET_DOWNLOADS_LOGS } from 'helpers/gql'
import { CONTENT_TYPE, LIBRARY_ACTIONS } from 'helpers/constants'
import { usePopup } from 'hooks/usePopup'
import dropdownOption from 'helpers/dropdownOptions'
import { MUIActionButton } from 'components'
import RenameFilePopup from './RenameFilePopup'
import AttachmentViewer from 'pages/announcements/AttachmentViewer'

const LibraryList = (props) => {
  const {
    isAdmin,
    option,
    selectedTab,
    refetch,
    handleInnerTabChange,
    selectedToDelete,
    handleBulkDelete,
    setSetshowCheckbox,
    showCheckbox,
    setSelectedToDelete,
    showConfirmDelete
  } = props
  const [rename, showRename, hideRename] = usePopup()
  const [preview, showPreview, hidePreview] = usePopup()
  const [attachment, setAttachment] = useState()
  const theme = createTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const heightWidth = isMobile ? 30 : 60
  const inValidChar = ['#', '+', '%']

  const key =
    option?.key.split('/').reverse()[0] !== ''
      ? option?.key.split('/').reverse()[0]
      : option?.key.split('/').reverse()[1]

  const [createDownloadLog] = useMutation(CREATE_DOWNLOAD_LOG, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    refetchQueries: [{ query: GET_DOWNLOADS_LOGS }]
  })

  const [getURLS] = useMutation(GENERATE_URLS, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: (data) => {
      if (option?.type === 'File') {
        downloadURI(getLibraryFileUrl(data?.generateUrls[0]?.preSignedUrl), key)
      } else {
        const filesUrl = []
        data?.generateUrls?.map((doc) => filesUrl.push(doc))
        if (filesUrl?.length > 0) {
          downloadLibraryZip(filesUrl, key)
        } else {
          toast.dismiss()
          toast.success('Folder is empty')
        }
      }
    },
    refetchQueries: [{ query: GET_DOWNLOADS_LOGS }]
  })

  const handleDownload = useCallback(async () => {
    toast.success(
      `${key.length > 20 ? key.slice(0, 20) : key} downloading started, Kindly expect delay in downloading Large files`
    )
    await getURLS({ variables: { data: { key: option?.key } } })
    if (selectedTab !== 'downloads') {
      createDownloadLog({ variables: { data: [option] } })
    }
  }, [createDownloadLog, getURLS, key, option, selectedTab])

  const handleSingleClick = () => {
    if (showCheckbox) {
      setSetshowCheckbox(false)
      setSelectedToDelete([])
    } else {
      setSetshowCheckbox(true)
    }
  }

  const fileIcon = useMemo(() => {
    const parts = option?.key.toString().toLowerCase().split('.')
    const extension = parts.length > 1 ? parts.pop() : null

    switch (extension) {
      case 'pdf':
        return pdfIcon
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'bmp':
        return inValidChar.some((char) => option?.key.includes(char))
          ? PicIcon
          : option.key.includes('user_library')
            ? getFileUrl(option.key)
            : getFileLibraryUrl(option?.key)
      case 'eps':
        return epsIcon
      case 'zip':
        return zipFolderIcon
      case 'txt':
        return textFileIcon
      case 'mp4':
      case 'avi':
      case 'mkv':
      case 'mov':
      case 'wmv':
        return videoIcon
      case 'docx':
      case 'doc':
        return msWord
      case 'xlsx':
      case 'xls':
      case 'csv':
        return msExcel
      case 'pptx':
      case 'ppt':
      case 'ppsx':
        return msPowerPoint
      case 'pst':
        return msOutLook
      case 'one':
        return msOneNote
      case 'gdoc':
        return googleDocs
      case 'gslides':
        return googleSlides
      case 'gsheet':
        return googleSheet
      default:
        return pdfIcon
    }
  }, [inValidChar, option.key])

  const actionOptions = useMemo(() => {
    let actions = []
    if (option.type === CONTENT_TYPE.FOLDER) {
      actions = dropdownOption.LIBRARY_HOVER_OPTIONS.filter((opt) => opt?.id !== LIBRARY_ACTIONS?.PREVIEW)
    } else {
      actions = dropdownOption.LIBRARY_HOVER_OPTIONS
    }
    if (!isAdmin) {
      actions = actions.filter((opt) => opt?.id === LIBRARY_ACTIONS?.DOWNLOAD || opt?.id === LIBRARY_ACTIONS?.PREVIEW)
    }
    return actions
  }, [isAdmin, option.type])

  const handleFilePreview = useCallback(async () => {
    try {
      let signedUrl
      if (option?.key.includes('user_library')) {
        signedUrl = await generateSignedUrl(option?.key)
      } else {
        signedUrl = await generateLibrarySignedUrl(option?.key)
      }
      setAttachment({ file_name: key, signedUrl, key: option.key })
    } catch (error) {
      console.error('Error generating signed URL:', error)
    }
    showPreview()
  }, [key, option, showPreview])

  const handleLibraryActions = useCallback(
    (action) => {
      if (action === LIBRARY_ACTIONS.DOWNLOAD) {
        handleDownload()
      } else if (action === LIBRARY_ACTIONS.PREVIEW) {
        handleFilePreview()
      } else if (action === LIBRARY_ACTIONS.RENAME) {
        showRename()
      } else if (action === LIBRARY_ACTIONS.DELETE) {
        setSelectedToDelete([option])
        showConfirmDelete()
      }
    },
    [handleDownload, handleFilePreview, option, setSelectedToDelete, showConfirmDelete, showRename]
  )

  return (
    <>
      <figure className={styles.fileIcon}>
        {(isAdmin || selectedTab === 'downloads') && showCheckbox ? (
          <Checkbox
            className={styles.deleteCheckbox}
            sx={{
              padding: '4px 4px 0px 0px ',
              '& .MuiSvgIcon-root': { fontSize: 20 },
              '&.Mui-checked': {
                color: '#1f1616'
              }
            }}
            checked={selectedToDelete.includes(option)}
            onChange={() => handleBulkDelete(option)}
          />
        ) : (
          <div className={styles.download}>
            <MUIActionButton
              actionIcon={<MoreVert fontSize="small" />}
              options={actionOptions}
              handleOnClickActionButton={(menu) => handleLibraryActions(menu)}
            />
          </div>
        )}
        {option?.type === 'Folder' ? (
          <img
            src={folderIcon}
            height={heightWidth}
            width={heightWidth}
            alt="folderIcon"
            onClick={handleSingleClick}
            style={{ cursor: 'pointer' }}
            onDoubleClick={(e) => handleInnerTabChange(option, e)}
          />
        ) : (
          fileIcon && (
            <img src={fileIcon} height={heightWidth} width={heightWidth} alt="imgIcon" onClick={handleSingleClick} />
          )
        )}
        <figcaption style={{ textAlign: 'center', cursor: 'pointer' }}>
          {
            <Tooltip
              placement="right-start"
              title={selectedTab === 'downloads' ? option?.key : key?.length > 10 ? key : ''}>
              <div>{key.length > 10 ? `${key.slice(0, 10)}...` : key}</div>
            </Tooltip>
          }
        </figcaption>
      </figure>
      {rename && (
        <RenameFilePopup rename={rename} folderName={key} option={option} refetch={refetch} hideRename={hideRename} />
      )}
      {preview && <AttachmentViewer open={preview} fileName={key} attachment={attachment} onCancel={hidePreview} />}
    </>
  )
}

const CREATE_DOWNLOAD_LOG = gql`
  mutation createDownloadLog($data: [LibraryFileInput!]!) {
    createDownloadLog(data: $data)
  }
`
const GENERATE_URLS = gql`
  mutation generateUrls($data: PreSignedUrlInput) {
    generateUrls(data: $data) {
      fileName
      preSignedUrl
    }
  }
`

export default LibraryList
