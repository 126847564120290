import { createTheme } from '@mui/material'
import vars from '../../vars.scss'

export const BasicTableTheme = () =>
  createTheme({
    components: {
      MUIDataTable: {
        styleOverrides: {
          root: {
            border: `2px solid ${vars['table-border-color']}`
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: vars['table-border-color'],
            fontWeight: '600',
            fontFamily: vars['fontFamilySourceSansPro'],
            fontSize: vars['fontSize22'],
            color: vars['font-color-primary']
          },
          contentWrapper: {
            justifyContent: 'space-evenly'
          },
          sortActive: {
            backgroundColor: vars['table-border-color'],
            fontWeight: '600',
            fontFamily: vars['fontFamilySourceSansPro'],
            fontSize: vars['fontSize22'],
            color: vars['font-color-primary'],
            lineHeight: '1.5rem'
          },
          sortAction: {
            backgroundColor: vars['table-border-color'],
            fontWeight: '600',
            fontFamily: vars['fontFamilySourceSansPro'],
            fontSize: vars['fontSize22'],
            color: vars['font-color-primary'],
            lineHeight: '1.5rem'
          }
        }
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            fontWeight: '500',
            fontFamily: vars['fontFamilySourceSansPro'],
            fontSize: vars['fontSize20'],
            color: vars['font-color-primary']
          }
        }
      },

      MuiTablePagination: {
        styleOverrides: {
          root: {
            fontFamily: vars['fontFamilySourceSansPro'],
            fontSize: vars['fontSize20']
          },
          selectLabel: {
            fontSize: vars['fontSize20'],
            fontFamily: vars['fontFamilySourceSansPro']
          },
          select: {
            fontSize: vars['fontSize20'],
            fontFamily: vars['fontFamilySourceSansPro']
          },
          menuItem: {
            fontSize: vars['fontSize20'],
            fontFamily: vars['fontFamilySourceSansPro']
          },
          displayedRows: {
            fontSize: vars['fontSize20'],
            fontFamily: vars['fontFamilySourceSansPro']
          },
          actions: {
            '& .MuiButtonBase-root': {
              '& .MuiSvgIcon-root': {
                fontSize: vars['fontSize20']
              }
            }
          }
        }
      },
      MUIDataTableSelectCell: {
        styleOverrides: {
          headerCell: {
            backgroundColor: vars['table-border-color']
          }
        }
      },
      MUIDataTableToolbarSelect: {
        styleOverrides: {
          root: {
            display: 'none'
          }
        }
      }
    }
  })

export const LogsTableTheme = () =>
  createTheme({
    components: {
      MUIDataTable: {
        styleOverrides: {
          root: {
            border: `2px solid ${vars['table-border-color']}`
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: '#EBEBEB',
            fontWeight: '400',
            fontFamily: vars['fontFamilySourceSansPro'],
            fontSize: vars['fontSize20'],
            color: '#808080'
          },
          contentWrapper: {
            justifyContent: 'space-evenly'
          }
        }
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            fontWeight: '500',
            fontFamily: vars['fontFamilySourceSansPro'],
            fontSize: vars['fontSize20'],
            color: vars['font-color-primary']
          }
        }
      },

      MuiTablePagination: {
        styleOverrides: {
          root: {
            fontFamily: vars['fontFamilySourceSansPro'],
            fontSize: vars['fontSize20']
          },
          selectLabel: {
            fontSize: vars['fontSize20'],
            fontFamily: vars['fontFamilySourceSansPro']
          },
          select: {
            fontSize: vars['fontSize20'],
            fontFamily: vars['fontFamilySourceSansPro']
          },
          menuItem: {
            fontSize: vars['fontSize20'],
            fontFamily: vars['fontFamilySourceSansPro']
          },
          displayedRows: {
            fontSize: vars['fontSize20'],
            fontFamily: vars['fontFamilySourceSansPro']
          },
          actions: {
            '& .MuiButtonBase-root': {
              '& .MuiSvgIcon-root': {
                fontSize: vars['fontSize20']
              }
            }
          }
        }
      },
      MUIDataTableSelectCell: {
        styleOverrides: {
          headerCell: {
            backgroundColor: vars['table-border-color']
          }
        }
      },
      MUIDataTableToolbarSelect: {
        styleOverrides: {
          root: {
            display: 'none'
          }
        }
      }
    }
  })

export const RevShareTableTheme = () =>
  createTheme({
    components: {
      MUIDataTable: {
        styleOverrides: {
          root: {
            width: '100%',
            boxShadow: 'none'
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: vars['table-border-color'],
            fontWeight: '600',
            fontFamily: vars['fontFamilySourceSansPro'],
            fontSize: '18px !important',
            color: '#494949'
          },
          contentWrapper: {
            justifyContent: 'space-evenly'
          }
        }
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            fontWeight: '500',
            fontFamily: vars['fontFamilySourceSansPro'],
            fontSize: vars['fontSize20'],
            color: vars['font-color-primary']
          }
        }
      }
    }
  })
