import React, { useCallback, useState } from 'react'
import { ClickAwayListener, Collapse, IconButton, List, ListItemButton, ListItemText, Popper } from '@mui/material'
import { ExpandLess, ExpandMore, Menu } from '@mui/icons-material'

const MobileLibraryList = (props) => {
  const { libraryOptions, onMyDownloads, onMyFiles, onAllFiles, onTabChange } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(true)
  const openPopper = Boolean(anchorEl)
  const id = openPopper ? 'github-label' : undefined

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleAllFiles = useCallback(() => {
    onAllFiles()
    setOpen(!open)
  }, [onAllFiles, open])

  const handleTabChange = useCallback(
    (option) => {
      onTabChange(option)
      handleClose()
    },
    [onTabChange, handleClose]
  )

  return (
    <>
      <IconButton onClick={handleClick}>
        <Menu />
      </IconButton>
      <Popper
        sx={{ border: '1px solid #e1e4e8', width: 300 }}
        id={id}
        open={openPopper}
        anchorEl={anchorEl}
        placement="bottom-start"
        onMouseLeave={handleClose}>
        <ClickAwayListener onClickAway={handleClose}>
          <List sx={{ bgcolor: 'background.paper' }}>
            <ListItemButton onClick={handleAllFiles}>
              <ListItemText primary="All Files" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit sx={{ height: 350, overflowY: 'auto' }}>
              {libraryOptions?.map((option, i) => {
                return (
                  <ListItemButton sx={{ pl: 4 }} key={i} onClick={() => handleTabChange(option)}>
                    <ListItemText primary={option.length > 25 ? `${option.slice(0, 25)}...` : option} />
                  </ListItemButton>
                )
              })}
            </Collapse>
            <ListItemButton onClick={onMyFiles}>
              <ListItemText primary="My Files" />
            </ListItemButton>
            <ListItemButton onClick={onMyDownloads}>
              <ListItemText primary="Downloads" />
            </ListItemButton>
          </List>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export default MobileLibraryList
