import { Typography, Col, Textarea, Input, Row } from 'components'
import { Controller, useFormContext } from 'react-hook-form'
import Referrals from './Referrals'
import style from '../deals.module.scss'
import PreConCommission from './pre_con/PreConCommission'
import { Grid } from '@mui/material'
import { useCallback, useContext, useEffect, useState } from 'react'
import { DealContext, UserContext } from 'context'
import { FORM_DEAL_TYPES, STATUS_TYPES } from 'helpers/constants'
import ViewCommission from '../view_deal/ViewCommission'

const Commission = ({
  secondaryAgent,
  primaryAgent,
  price,
  agents,
  setAgentCommissions,
  setSecondaryCommission,
  setTotalCommissions,
  priceFlag
}) => {
  const { control, getValues, setValue } = useFormContext()
  const { deal, disabled = false, isEdit, isView, isClosedAndAgent } = useContext(DealContext)
  const { type } = deal || getValues()
  const referrals = [FORM_DEAL_TYPES.SALE, FORM_DEAL_TYPES.PRE_CON].includes(type)
  const { user } = useContext(UserContext)
  const canDisabledCommission = user.role === 'agent' && isEdit && deal?.status !== STATUS_TYPES.draft
  const isAdmin = ['admin', 'super_admin'].includes(user.role)
  const [commissionError, setCommissionError] = useState(false)
  const [commission, setCommission] = useState()
  const [primaryAgentCommission, setPrimaryAgentCommission] = useState()
  const [secondaryAgentCommissions, setSecondaryAgentCommissions] = useState([])
  const [warningMsg, setWarningMsg] = useState(false)

  const calculateTotalPercentage = useCallback(() => {
    const primaryAgentPercentage = parseFloat(getValues('primary_Agent_Percentage')) || 0
    const secondaryAgentPercentages =
      secondaryAgent?.map((agent) => {
        return parseFloat(getValues(`secondaryAgent_percentage_${agent.id}`)) || 0
      }) || []

    const totalPercentage =
      primaryAgentPercentage + secondaryAgentPercentages.reduce((acc, percentage) => acc + percentage, 0)
    return totalPercentage
  }, [getValues, secondaryAgent])

  const calculateCommission = useCallback(
    (e) => {
      if (e !== undefined) {
        const totalCommission = e / 100
        const commission = totalCommission * price
        setCommission(commission.toFixed(2))
        setTotalCommissions(parseFloat(commission).toFixed(2))
        setPrimaryAgentCommission(0)
        setValue('primary_Agent_Percentage', 0)
        secondaryAgent?.map((agent) => {
          return setValue(`secondaryAgent_percentage_${agent.id}`, 0)
        })
        const initialCommissions = secondaryAgent.map(() => 0)
        setSecondaryAgentCommissions(initialCommissions)
      } else {
        const totalCommissions = parseFloat(getValues('deal_commission_amount'))
        setTotalCommissions(parseFloat(totalCommissions).toFixed(2))
      }
    },
    [price, primaryAgentCommission, secondaryAgentCommissions, setTotalCommissions]
  )

  const calculateCommissionForAgent = useCallback(
    (percent, agentType, index) => {
      const totalCommission = percent / 100
      let agentCommission

      if (commission === undefined) {
        agentCommission = totalCommission * deal?.deal_commission_amount
      } else {
        agentCommission = totalCommission * commission
      }

      if (agentType === 'primary') {
        setPrimaryAgentCommission(parseFloat(agentCommission).toFixed(2))
        setSecondaryCommission(agentCommission)
      } else if (agentType === 'secondary' && typeof index === 'number') {
        setSecondaryAgentCommissions((prev) => {
          const updatedCommissions = [...prev]
          updatedCommissions[index] = parseFloat(agentCommission).toFixed(2)
          return updatedCommissions
        })

        setAgentCommissions((prevCommissions) => {
          const updatedCommissions = [...prevCommissions]
          updatedCommissions[index] = parseFloat(agentCommission).toFixed(2)
          return updatedCommissions
        })
      }
    },
    [commission, deal?.deal_commission_amount, setSecondaryCommission, setAgentCommissions, secondaryAgentCommissions]
  )

  const calculateCommissionForPrice = useCallback(() => {
    setWarningMsg(true)
    setValue('deal_commission_percentage', '0')
    setValue('primary_Agent_Percentage', '0')
    secondaryAgent?.map((agent) => {
      return setValue(`secondaryAgent_percentage_${agent.id}`, '0')
    })
    setCommission('0')
    const initialCommissions = secondaryAgent.map(() => 0)
    setSecondaryAgentCommissions(initialCommissions)
  }, [setCommission, secondaryAgent, setValue, setCommission])

  useEffect(() => {
    if (primaryAgent) {
      const initialCommissions = 0
      setPrimaryAgentCommission(initialCommissions)
    }

    if (primaryAgent) {
      const existingPercentage = parseFloat(getValues(`primary_Agent_Percentage`))
      if (!isNaN(existingPercentage)) {
        calculateCommissionForAgent(existingPercentage, 'primary', 'index')
      }
    }

    if (secondaryAgent) {
      const initialCommissions = secondaryAgent.map(() => 0)
      setSecondaryAgentCommissions(initialCommissions)
    }

    if (secondaryAgent) {
      secondaryAgent.forEach((agent, index) => {
        const existingPercentage = parseFloat(getValues(`secondaryAgent_percentage_${agent.id}`))
        if (!isNaN(existingPercentage)) {
          calculateCommissionForAgent(existingPercentage, 'secondary', index)
        }
      })
    }

    if (isEdit) {
      calculateCommission()
    }

    if (price && priceFlag) {
      calculateCommissionForPrice()
    }
  }, [secondaryAgent, getValues, primaryAgent, isEdit, price])

  useEffect(() => {
    const totalPercentage = calculateTotalPercentage()

    if (totalPercentage !== 100) {
      setCommissionError(true)
    } else {
      setCommissionError(false)
    }
  }, [calculateCommission, calculateTotalPercentage])

  return (
    <>
      {(isEdit || isView) && (
        <div>
          {type !== FORM_DEAL_TYPES.PRE_CON && (
            <>
              <Col style={{ width: '100%', marginTop: 20 }}>
                <Typography.Heading
                  type={5}
                  style={{ marginTop: 22, fontFamily: 'Source Sans Pro' }}
                  gap={50}
                  className="dealStepTitle">
                  Commission Breakdown
                </Typography.Heading>
              </Col>

              <Col style={{ width: '100%', marginTop: 10 }}>
                <Controller
                  name="annual_personal_deal"
                  control={control}
                  defaultValue={deal ? (deal.annual_personal_deal ? 'yes' : 'no') : undefined}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div className={style.annual_personal_deal_checkbox}>
                      <label htmlFor="annual_personal_deal_checkbox" className="annualDealSublabel">
                        This deal is for my personal property:
                      </label>
                      <div>
                        <input
                          id="annual_personal_deal_checkbox"
                          type="checkbox"
                          value="yes"
                          disabled={disabled || canDisabledCommission}
                          checked={value === 'yes'}
                          onChange={(e) => {
                            onChange(e.target.checked ? 'yes' : 'no')
                          }}
                        />
                        {error && <span>{error.message}</span>}
                      </div>
                    </div>
                  )}
                />
              </Col>
              <Grid container columnSpacing={7}>
                <Grid xs={12} md={6} item>
                  <Grid container columnSpacing={7}>
                    <Grid xs={12} md={6} item>
                      <Controller
                        name="deal_commission_percentage"
                        control={control}
                        defaultValue={deal?.deal_commission_percentage}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <Input
                            type="text"
                            error={error?.message}
                            value={value}
                            onChange={(e) => {
                              setWarningMsg(false)
                              calculateCommission(e.target.value)
                              onChange(e)
                            }}
                            label="Percentage"
                            name="deal_commission_percentage"
                            placeholder={'%'}
                            disabled={isAdmin && !isView ? false : true}
                            style={{ width: '100%' }}
                          />
                        )}
                      />
                      {warningMsg && (
                        <Typography.Body type={1} style={{ color: 'red', marginTop: 10 }}>
                          since you have updated Price, please enter the percentage{' '}
                        </Typography.Body>
                      )}
                    </Grid>
                    <Grid xs={12} md={6} item>
                      <Controller
                        name="deal_commission_amount"
                        control={control}
                        defaultValue={deal?.deal_commission_amount}
                        render={({ field: { onChange }, fieldState: { error } }) => (
                          <Input
                            type="number"
                            error={error?.message}
                            value={commission || deal?.deal_commission_amount}
                            onChange={onChange}
                            label="Total commission"
                            name="deal_commission_amount"
                            placeholder={'$'}
                            disabled={true}
                            style={{ width: '100%' }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container items spacing={2} sx={{ marginTop: 1 }}>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                      <Typography.Body type={1} style={{ marginTop: 10, fontSize: '16px' }}>
                        Percentage
                      </Typography.Body>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography.Body type={1} style={{ marginTop: 10, fontSize: '16px' }}>
                        Amount
                      </Typography.Body>
                    </Grid>
                  </Grid>
                  {isEdit && (
                    <Grid container items spacing={2}>
                      <Grid item xs={4}>
                        <Typography.Heading type={6} style={{ fontWight: 700 }}>
                          {primaryAgent?.name}
                        </Typography.Heading>
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={'primary_Agent_Percentage'}
                          control={control}
                          defaultValue={
                            deal?.agents_commission_breakdown?.primary_agent_commission?.commission_percentage
                          }
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                              type="text"
                              error={error?.message}
                              value={value}
                              onChange={(e) => {
                                calculateCommissionForAgent(e.target.value, 'primary')
                                onChange(e)
                              }}
                              placeholder={'Percentage'}
                              name={'primary_Agent_Percentage'}
                              disabled={isAdmin ? false : true}
                              style={{ width: '100%' }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={'primary_Agent_Total'}
                          control={control}
                          defaultValue={deal?.agents_commission_breakdown?.primary_agent_commission?.commission_amount}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                              type="number"
                              error={error?.message}
                              value={primaryAgentCommission}
                              onChange={onChange}
                              placeholder={'Total'}
                              disabled={true}
                              style={{ width: '100%' }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {secondaryAgent?.map((agent, index) => {
                    const defaultValuePercentage =
                      deal?.agents_commission_breakdown?.secondary_agents_commission.filter(
                        (ag) => agent.id === ag.id
                      )[0]?.commission_percentage ?? 0

                    const defaultValueTotal =
                      deal?.agents_commission_breakdown?.secondary_agents_commission.filter(
                        (ag) => agent.id === ag.id
                      )[0]?.commission_amount ?? 0

                    return (
                      <div key={agent.id}>
                        <Grid container items spacing={2} sx={{ marginTop: 1 }}>
                          <Grid item xs={4}>
                            <Typography.Body type={1} style={{ marginTop: 10 }}>
                              {agent.name}
                            </Typography.Body>
                          </Grid>
                          <Grid item xs={4}>
                            <Controller
                              name={'secondaryAgent_percentage_' + agent.id}
                              control={control}
                              defaultValue={defaultValuePercentage}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Input
                                  type="number"
                                  error={error?.message}
                                  value={value}
                                  onChange={(e) => {
                                    calculateCommissionForAgent(e.target.value, 'secondary', index)
                                    onChange(e)
                                  }}
                                  placeholder={'Percentage'}
                                  name={agent.name}
                                  disabled={isAdmin ? false : true}
                                  style={{ width: '100%' }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Controller
                              name={'secondaryAgent_total_' + agent.id}
                              control={control}
                              defaultValue={defaultValueTotal}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Input
                                  type="number"
                                  error={error?.message}
                                  value={secondaryAgentCommissions[index]}
                                  onChange={onChange}
                                  placeholder={'Total'}
                                  disabled={true}
                                  name="totalCommission"
                                  style={{ width: '100%' }}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                  {isView && <ViewCommission agents={agents} deal={deal} />}
                </Grid>
              </Grid>
            </>
          )}
        </div>
      )}
      {commissionError && isEdit && isAdmin && type !== FORM_DEAL_TYPES.PRE_CON && (
        <Typography.Body type={1} style={{ color: 'red', marginTop: 10 }}>
          Total percentage should be equal to 100%.
        </Typography.Body>
      )}
      {/* {type === FORM_DEAL_TYPES.PRE_CON ? (
        <PreConCommission canDisabledCommission={disabled || canDisabledCommission} />
      ) : (
        <SaleCommission canDisabledCommission={disabled || canDisabledCommission} />
      )} */}

      {type === FORM_DEAL_TYPES.PRE_CON && (
        <PreConCommission canDisabledCommission={disabled || canDisabledCommission} />
      )}

      {(isAdmin || (user.role === 'agent' && isView)) && referrals && (
        <Referrals control={control} deal={deal} disabled={disabled} />
      )}

      <Grid container columnSpacing={7} sx={{ marginTop: 2 }}>
        <Grid xs={12} sm={6} item>
          <Typography.Heading type={5} style={{ fontFamily: 'Source Sans Pro' }} className="dealStepTitle">
            Notes and Instructions
          </Typography.Heading>
        </Grid>
      </Grid>
      <Grid container columnSpacing={7}>
        <Grid xs={12} md={6} item>
          <Controller
            name="notes"
            control={control}
            defaultValue={deal?.notes || ''}
            render={({ field: { onChange, value } }) => (
              <Textarea
                value={value}
                onChange={onChange}
                type={5}
                sublabel="Please include any special notes or instructions related to the deal"
                name="notes"
                style={{ width: '100%' }}
                rows={5}
                disabled={disabled || isClosedAndAgent}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Commission
