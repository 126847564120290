import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { Search } from '@mui/icons-material'
import vars from '../../vars.scss'
import { GET_GLOBAL_SEARCH } from 'helpers/gql'
import { useQuery } from '@apollo/client'
import { Tooltip, Typography } from '@mui/material'

export default function Asynchronous() {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [searchText, setSearchText] = useState('')

  const {
    data: { getGlobalSearch: searchResult } = [],
    loading,
    refetch
  } = useQuery(GET_GLOBAL_SEARCH, {
    fetchPolicy: 'no-cache',
    variables: { filters: { text: searchText } },
    skip: !searchText
  })

  useEffect(() => {
    if (searchResult) {
      setOptions([...searchResult?.deals, ...searchResult?.listings] ?? [])
    } else {
      setOptions([])
    }
  }, [searchResult])

  useEffect(() => {
    refetch()
  }, [refetch, searchText, searchResult])

  const handleNavigate = (option) => {
    if (option) {
      if (option.isColumn === 'listing') {
        navigate(`/listings/view/${option.listing_option}/${option.public_id}`)
      } else if (option.isColumn === 'deals') {
        navigate(`/deals/view/${option.deal_type_name}/${option.public_id}`)
      }
    }
  }

  return (
    <Autocomplete
      disablePortal
      id="asynchronous-demo"
      sx={{
        width: { xs: '100%', md: 300 },
        borderColor: vars['btn-secondary-bg-color'],
        '& .MuiOutlinedInput-root': {
          padding: '0px',
          '&:hover fieldset': {
            borderColor: vars['font-color-primary'],
            borderWidth: '1px'
          },
          '&.Mui-focused fieldset': {
            borderColor: vars['font-color-primary'],
            borderWidth: '1px'
          }
        }
      }}
      open={open}
      onOpen={() => {
        if (searchText) setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      onInputChange={(event, newInputValue) => {
        setSearchText(newInputValue)
      }}
      onChange={(event, newValue) => {
        handleNavigate(newValue)
      }}
      getOptionLabel={(option) => option.isColumn}
      options={searchText ? options : []}
      filterOptions={(options, state) => options}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      clearOnBlur={false}
      loading={loading && options.length === 0}
      renderOption={(props, option) => {
        const address = option.property_address.slice(0, 22)
        return (
          <li
            key={option.id}
            {...props}
            style={{ display: 'flex', justifyContent: 'space-between ', marginTop: 5 }}
            onClick={() => handleNavigate(option)}>
            <Tooltip
              placement="bottom-start"
              title={option.property_address.length >= 22 ? option.property_address : ''}>
              <Typography>
                {address}
                {option.property_address.length >= 22 ? '...' : ''}
              </Typography>
            </Tooltip>
            <Typography sx={{ fontSize: '14px' }}>{option.isColumn === 'deals' ? 'Deal' : 'Listing'}</Typography>
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          placeholder="Search..."
          onChange={(e) => setSearchText(e.target.value)}
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: <Search sx={{ fontSize: '30px', ml: 1, color: vars['btn-secondary-bg-color'] }} />
          }}
        />
      )}
    />
  )
}
