import { useRef, useEffect, useCallback } from 'react'
import { Typography, Input, Col } from 'components'
import { Controller, useForm } from 'react-hook-form'
import JoditEditor from 'jodit-react'
import { usePopup } from 'hooks/usePopup'
import { ANNONCEMENT_TYPES } from 'helpers/constants'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import AnnouncementFileUpload from './AnnouncementFileUpload'
import SchedulePostPopup from './SchedulePostPopup'
import AnnouncementPopup from 'pages/home/AnnouncementPopup'
import moment from 'moment'
import { convertUTCToLocal } from 'helpers/utils'
import AnnouncementSender from './AnnouncementSender'

const editorButtons = [
  'undo',
  'redo',
  '|',
  'bold',
  'strikethrough',
  'underline',
  'italic',
  '|',
  'align',
  '|',
  'ul',
  'ol',
  'outdent',
  'indent',
  '|',
  'font',
  'fontsize',
  'brush',
  'paragraph',
  '|',
  'image',
  'link',
  'table',
  '|',
  'hr',
  '|',
  'print',
  '|',
  'spellcheck'
]

const AnnouncementForm = (props) => {
  const { announcement, isEdit = false, onSave, onDraftSave, onSchedulePost, loading, loadingSection } = props
  const joditEditorRef = useRef(null)
  const [previewOpen, showPreview, hidePreview] = usePopup()
  const [schedule, showSchedule, hideSchedule] = usePopup()

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(250, 'Title  must be atmost 250 Characters').required('Title is required'),
    start_date: Yup.date()
      .nullable()
      .when('title', {
        is: () => announcement?.status === ANNONCEMENT_TYPES.SCHEDULED || schedule,
        then: () =>
          Yup.date()
            .required('Date and time is required')
            .test('validateDateTime', 'Invalid date and time.', (value, ctx) => {
              const currTime = moment()
              if (value && moment(value).diff(currTime, 'minutes') <= 2) {
                return false
              }
              return true
            })
      }),
    end_date: Yup.date()
      .nullable()
      .typeError('Invalid date and time')
      .when('title', {
        is: () => announcement?.status === ANNONCEMENT_TYPES.SCHEDULED || schedule,
        then: () =>
          Yup.date()
            .nullable()
            .min(Yup.ref('start_date'), 'End date should be greater than start date')
            .typeError('Invalid date and time')
      }),
    content: Yup.string().required('Content is required'),
    sender_id: Yup.mixed().required('Sender name is required.')
  })

  const { handleSubmit, control, setValue, register, getValues, trigger, resetField } = useForm({
    defaultValues: {
      title: announcement?.title ?? '',
      start_date: convertUTCToLocal(announcement?.start_date) ?? null,
      end_date: convertUTCToLocal(announcement?.end_date) ?? null,
      content: announcement?.content ?? '',
      attachments: announcement?.attachments ?? [],
      sender_id: announcement?.sender_id ? { value: announcement?.sender_id, label: announcement?.sender } : null
    },
    resolver: yupResolver(validationSchema)
  })
  const { title, preview, content, sender_id } = getValues()

  useEffect(() => {
    register('content')
  })

  const handlePostSchedule = useCallback(async () => {
    const isValid = await trigger()
    if (isValid) {
      showSchedule()
    }
  }, [showSchedule, trigger])

  const handleClose = useCallback(() => {
    hideSchedule()
    resetField('start_date')
    resetField('end_date')
  }, [hideSchedule, resetField])

  return (
    <>
      <form>
        <Controller
          name="title"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              name="title"
              type="text"
              error={error?.message}
              value={value}
              placeholder="Enter Title"
              onChange={onChange}
              style={{ width: '100%' }}
            />
          )}
        />
        <Col style={{ marginTop: 14 }}>
          <Controller
            name="sender_id"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <AnnouncementSender onChange={onChange} value={value} error={error} />
            )}
          />
        </Col>
        <div style={{ marginTop: 16 }}>
          <Controller
            name="content"
            control={control}
            render={({ field: { value }, fieldState: { error } }) => (
              <>
                <div style={{ overflowY: 'auto' }}>
                  <JoditEditor
                    ref={joditEditorRef}
                    value={value}
                    config={{
                      height: 380,
                      buttons: editorButtons,
                      spellcheck: true,
                      askBeforePasteFromWord: false,
                      askBeforePasteHTML: false,
                      uploader: {
                        insertImageAsBase64URI: true
                      },
                      addNewLine: false
                    }}
                    tabIndex={1}
                    onBlur={(content) => setValue('content', content)}
                  />
                </div>
                {error && (
                  <Typography.Body type="3" color="error" style={{ marginTop: 4 }}>
                    {error.message}
                  </Typography.Body>
                )}
              </>
            )}
          />
        </div>
        <Controller
          name="attachments"
          control={control}
          render={({ field: { onChange, value } }) => (
            <AnnouncementFileUpload
              isEdit={isEdit}
              loading={loading}
              loadingSection={loadingSection}
              value={value}
              status={announcement?.status}
              onChange={onChange}
              handlePreview={showPreview}
              onSubmit={handleSubmit(onSave)}
              onDraftSave={handleSubmit(onDraftSave)}
              onScheduledSend={handlePostSchedule}
            />
          )}
        />
        <SchedulePostPopup
          schedule={schedule}
          control={control}
          hideSchedule={handleClose}
          loading={loading}
          loadingSection={loadingSection}
          onSchedulePost={handleSubmit(onSchedulePost)}
        />
      </form>
      <AnnouncementPopup
        announcement={{ preview, content, title, sender: sender_id?.label }}
        viewAnnouncement={previewOpen}
        onCancel={hidePreview}
      />
    </>
  )
}

export default AnnouncementForm
