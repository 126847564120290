import { createContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { Loading } from 'components'
import { CometChat } from '@cometchat-pro/chat'
import { toast } from 'react-toastify'

const UserContext = createContext(null)

const UserProvider = ({ children }) => {
  const { data: { getUser: user } = {}, loading } = useQuery(GET_USER, {
    onCompleted: (data) => {
      // Rare Dev auth key - TODO: move to env file + set up environment variables
      const uid = data.getUser?.public_id

      if (uid) {
        CometChat.login(uid, process.env.REACT_APP_AUTH_KEY).then((error) => {
          //intentional comment
          // console.log('Login failed with exception')
        })
      }
    },

    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    }
  })

  if (loading) return <Loading fullpage />
  return <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
}

export { UserContext, UserProvider }

export const GET_USER = gql`
  query getUser {
    getUser {
      id
      public_id
      role
      name
      email
      first_name
      last_name
      title
      thumbnail
      reco_number
      reco_expiry
      godmode
      created
    }
  }
`
