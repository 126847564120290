import { Typography, Radio, RadioGroup, AddressAutoComplete } from 'components'
import { Grid } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import styles from './listings.module.scss'

const PropertyInfo = ({
  listing,
  isEdit = false,
  isView = false,
  onChangeListingType = console.log,
  onChangeListingOption = console.log
}) => {
  const { control, getValues } = useFormContext()

  return (
    <div className={styles.listingsStepOne}>
      <Typography.Heading type={5} className={styles.listingTypeTitle}>
        Listing Type
        <span className={styles.requiredIcon}>*</span>
      </Typography.Heading>

      <Grid container columns={12} ml={0}>
        <Grid container mt={{ xs: 2, md: 0 }}>
          <Grid item xs={12} md={2}>
            <Typography.Body type={5} style={{ marginBottom: 8, marginTop: 8 }}>
              Select Listing
            </Typography.Body>
          </Grid>
          {isView ? (
            <Grid xs={8} md={4} item>
              <Typography.Heading type="6" style={{ marginBottom: 8 }}>
                {getValues().option === 'mls' ? 'MLS' : 'Exclusive'}
              </Typography.Heading>
            </Grid>
          ) : (
            <Grid xs={8} md={4} item>
              <Controller
                name="option"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <RadioGroup
                    onChange={(e) => {
                      onChange(e)
                      onChangeListingOption(e.target.value)
                    }}
                    value={value}
                    error={error?.message}>
                    <Grid item xs={12} md={6}>
                      <Radio
                        name="exclusive"
                        label="Exclusive"
                        value="exclusive"
                        checked={value === 'exclusive'}
                        disabled={isView || isEdit}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Radio name="mls" label="MLS" value="mls" checked={value === 'mls'} disabled={isView || isEdit} />
                    </Grid>
                  </RadioGroup>
                )}
              />
            </Grid>
          )}
        </Grid>
        <Grid container mt={{ xs: 2, md: 0 }}>
          <Grid item xs={12} md={2}>
            <Typography.Body type="5" style={{ marginBottom: 8 }}>
              This is a
            </Typography.Body>
          </Grid>
          {isView ? (
            <Grid xs={8} md={4} item>
              <Typography.Heading type="6" style={{ marginBottom: 8 }}>
                {getValues().type === 'sale_listing' ? 'Sale Listing' : 'Lease Listing'}
              </Typography.Heading>
            </Grid>
          ) : (
            <Grid xs={8} md={4} item>
              <Controller
                name="type"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <RadioGroup
                    onChange={(e) => {
                      onChange(e)
                      onChangeListingType(e.target.value)
                    }}
                    value={value}
                    error={error?.message}>
                    <Grid item xs={12} md={6}>
                      <Radio
                        name="lease_listing"
                        label="Lease Listing"
                        value="lease_listing"
                        checked={value === 'lease_listing'}
                        disabled={isView || isEdit}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Radio
                        name="sale_listing"
                        label="Sale Listing"
                        value="sale_listing"
                        checked={value === 'sale_listing'}
                        disabled={isView || isEdit}
                      />
                    </Grid>
                  </RadioGroup>
                )}
              />
            </Grid>
          )}
        </Grid>
        <Grid container columnSpacing={7} mt="20px">
          <Grid xs={12} md={6} item>
            <Controller
              name="address"
              control={control}
              defaultValue={listing?.address ?? ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <AddressAutoComplete
                  value={value}
                  required
                  name="address"
                  isEdit={isEdit}
                  isView={isView}
                  onChange={onChange}
                  error={error?.message}
                  disabled={isView || isEdit}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default PropertyInfo
