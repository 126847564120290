import { gql, useMutation } from '@apollo/client'
import styles from './toolbox.module.scss'
import { Typography, Button, Input, Alert, Divider, Table, Col } from 'components'
import { Controller, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { openNewTab } from 'helpers/utils'
import { Helmet } from 'react-helmet-async'
import { Article } from '@mui/icons-material'
import { toast } from 'react-toastify'
import docTestPath from 'assets/docs/test.pdf'
import docTestPath2 from 'assets/docs/test.docx'

// TEMP: Need designs for this

const Toolbox = () => {
  const { handleSubmit, control, reset: resetForm } = useForm()

  // TEMP Until we build a Toast component
  const [showAlert, setShowAlert] = useState(false)
  useEffect(() => {
    const timeOut = setTimeout(() => {
      setShowAlert(false)
    }, 3000)

    return () => clearTimeout(timeOut)
  }, [showAlert])

  const [sendReferral, { loading }] = useMutation(SEND_REFERRAL, {
    onError: (err) => {
      toast.dismiss()
      console.error(err)
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      setShowAlert(true)
      resetForm()
      toast.success('Referral sent')
    }
  })

  const onSubmit = async ({ email }) => {
    sendReferral({
      variables: { email }
    })
  }

  const columns = [
    {
      key: 'name',
      title: 'Name',
      width: '60%',
      render: (object) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <Article fontSize="20px" />
          <Typography.Body type={2}>{object.name}</Typography.Body>
        </div>
      )
    },
    {
      key: 'type',
      title: 'Type',
      width: '20%',
      render: (object) => <Typography.Body type={2}>{object.__typename}</Typography.Body>
    },
    {
      key: 'last_modified',
      title: 'Last Modified',
      width: '20%',
      render: (object) => <Typography.Body type={2}>Apr 04, 2022</Typography.Body>
    }
  ]

  const handleRowClick = (object) => {
    openNewTab(object.path)
  }

  const docs = [
    { path: docTestPath, name: 'Test.pdf', __typename: 'PDF' },
    { path: docTestPath, name: 'Hello world.pdf', __typename: 'PDF' },
    { path: docTestPath, name: '1233121312312.pdf', __typename: 'PDF' },
    { path: docTestPath2, name: 'Test.docx', __typename: 'DOC' }
  ]

  return (
    <>
      <Helmet title="Toolbox | RARE" />

      <section id="toolbox">
        <Typography.Heading type="3">Toolbox</Typography.Heading>
        <Divider />

        <div className={styles.items}>
          <Col className={styles.item}>
            <Typography.Heading type={5}>Refer an Agent</Typography.Heading>

            <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 16 }}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: 'Email is required'
                  // validate: (value) => EmailValidator.validate(value) || '* Enter a valid email'
                }}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    type="text"
                    placeholder="Email"
                    error={error?.message}
                    value={value}
                    onChange={onChange}
                    label="Email address"
                    required
                    name="email"
                    style={{ width: '100%' }}
                    size="large"
                  />
                )}
              />

              {showAlert && <Alert type="success"> Referral Sent! </Alert>}

              <Button form block loading={loading} style={{ marginTop: 16 }}>
                Send Referral
              </Button>
            </form>
          </Col>

          <Col className={styles.item}>
            <Typography.Heading type={5}>Revenue Share Calculator</Typography.Heading>

            <Button
              block
              style={{ marginTop: 16 }}
              onClick={() => {
                openNewTab('https://digitalplatform.rarerealestate.ca/#/calculator')
              }}>
              Open Calculator
            </Button>
          </Col>

          <Col className={`${styles.item} ${styles.docs}`}>
            <Typography.Heading type={5} style={{ marginBottom: 16 }}>
              Important Documents
            </Typography.Heading>

            <Table data={docs} columns={columns} rowOnClick={handleRowClick} />
          </Col>
        </div>
      </section>
    </>
  )
}

const SEND_REFERRAL = gql`
  mutation sendReferral($email: String!) {
    sendReferral(email: $email)
  }
`

export default Toolbox
