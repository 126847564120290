const Col = ({
  gap = null,
  justifyContent = 'flex-start',
  alignItems = 'stretch',
  className,
  children,
  style,
  onChange,
  onClick
}) => {
  return (
    <div
      onChange={onChange}
      onClick={onClick}
      className={className}
      style={{ display: 'flex', flexDirection: 'column', gap, justifyContent, alignItems, ...style }}>
      {children}
    </div>
  )
}

export default Col
