import { Col, Typography, Button, Table, Agent } from 'components'
import { gql, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { useRef, useState } from 'react'

const LoneWolfUpload = () => {
  const [agents, setAgents] = useState(null)
  const hiddenFileInput = useRef(null)

  const handleFileClick = () => {
    hiddenFileInput.current.click()
  }

  const handleFileChange = async (event) => {
    uploadLibraryFile({ variables: { file: event.target.files[0] } })
  }

  const handleSmsClick = () => {
    smsLoneWolf({ variables: { agents } })
  }

  const [uploadLibraryFile, { loading: uploadFileLoading }] = useMutation(UPLOAD_LONE_WOLF, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: ({ uploadLoneWolf }) => {
      toast.dismiss()
      toast.success('File uploaded.')
      setAgents(
        uploadLoneWolf.map((a) => {
          return {
            id: a.id,
            name: a.name,
            phone_number: a.phone_number
          }
        })
      )
    }
  })

  const [smsLoneWolf, { loading: sendSMSLoading }] = useMutation(SMS_LONE_WOLF, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('SMS sent to all agents.')
    }
  })

  const columns = [
    {
      key: 'agent',
      title: 'Agents',
      width: 560,
      render: (agent) => {
        return <Agent agent={agent} />
      }
    },
    {
      key: 'phone_number',
      title: 'Phone number',
      width: 560,
      render: (agent) => {
        return <Typography.Body type="2">{agent.phone_number}</Typography.Body>
      }
    }
  ]

  return (
    <Col gap={24}>
      <Typography.Heading type="5">LoneWolf Upload</Typography.Heading>
      <Button onClick={handleFileClick} loading={uploadFileLoading}>
        Upload File
      </Button>
      <input type="file" ref={hiddenFileInput} onChange={handleFileChange} style={{ display: 'none' }} />

      <Typography.Heading type="5">Send SMS to Agents</Typography.Heading>
      {agents ? (
        <>
          <Table columns={columns} data={agents} />
          <Button onClick={handleSmsClick} loading={sendSMSLoading}>
            Send SMS
          </Button>
        </>
      ) : (
        <Typography.Body>Upload a csv file before sending an SMS.</Typography.Body>
      )}
    </Col>
  )
}

export default LoneWolfUpload

const UPLOAD_LONE_WOLF = gql`
  mutation uploadLoneWolf($file: Upload!) {
    uploadLoneWolf(file: $file) {
      id
      name
      phone_number
    }
  }
`

const SMS_LONE_WOLF = gql`
  mutation smsLoneWolf($agents: [LoneWolfSms]) {
    smsLoneWolf(agents: $agents)
  }
`
