import { Controller, useFormContext } from 'react-hook-form'
import { useMemo, useContext } from 'react'
import { BasicUpload, Row } from 'components'
import { DocumentContext, UserContext } from 'context'
import { useCallback } from 'react'
import { useState } from 'react'
import { STATUS_TYPES, USER_ROLES } from 'helpers/constants'

const ListingDocumentBase = (props) => {
  const { name, title, required, listing, disabled = false } = props
  const { control, getValues, setValue } = useFormContext()
  const { user } = useContext(UserContext)
  const { handleDeleteFile } = useContext(DocumentContext)
  const [checked, setChecked] = useState()
  const isClosedAndAgent = user.role === USER_ROLES.agent && listing?.status === STATUS_TYPES.closed

  const defaultValue = getValues()[name] ?? null

  const initialValue = useMemo(() => {
    if (defaultValue) {
      return defaultValue
    } else {
      const matchedDocument = listing?.documents?.find((document) => document.checklist_document_type === name)
      if (matchedDocument) {
        const { id, document_id, path, name: fileName } = matchedDocument
        return { id, documentId: document_id, key: path, fileName }
      }
    }
  }, [defaultValue, listing?.documents, name])

  const { checkedDocuments } = getValues()
  const isChecked = useMemo(() => {
    const filtered = checkedDocuments?.filter((doc) => doc?.key === name)
    return filtered?.[0]?.isChecked ?? false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedDocuments, name, checked])

  const handleCheckChange = useCallback(() => {
    const documents = getValues()?.checkedDocuments ?? []
    const existingObjectIndex = documents?.findIndex((doc) => doc.key === name)
    if (existingObjectIndex !== -1) {
      documents[existingObjectIndex].isChecked = documents[existingObjectIndex].isChecked === true ? false : true
    } else {
      const newObject = { key: name, isChecked: true }
      documents.push(newObject)
    }
    setValue('checkedDocuments', documents)
    setChecked(checked === true ? false : true)
  }, [checked, getValues, name, setValue])

  return (
    <Row>
      <Controller
        name={name}
        control={control}
        defaultValue={initialValue}
        required
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <BasicUpload
            onChange={onChange}
            title={title}
            defaultValue={value}
            error={error?.message}
            required={false}
            isChecked={isChecked}
            isAllDocument={title.includes('All')}
            onCheckChange={handleCheckChange}
            handleDeleteFile={handleDeleteFile}
            disabled={disabled}
            isClosedAndAgent={isClosedAndAgent}
          />
        )}
      />
    </Row>
  )
}

export default ListingDocumentBase
