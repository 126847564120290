import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Typography, Row, Col, Button } from 'components'
import { CometChat } from '@cometchat-pro/chat'
import TagIcon from '@mui/icons-material/Tag'
import PersonIcon from '@mui/icons-material/Person'
import SettingsIcon from '@mui/icons-material/Settings'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import styles from './header.module.scss'
import { useQuery, useMutation, gql } from '@apollo/client'
import { toast } from 'react-toastify'

const Header = ({ setSettingsOpen }) => {
  const params = useParams()
  const location = useLocation()
  const [channel, setChannel] = useState(null)
  const [user, setUser] = useState(null)

  const isChannel = location.pathname.includes('channels')
  const id = isChannel ? params.gid : params.uid

  const getChannel = (id) => {
    if (user) setUser(null)

    if (location.pathname.includes('add')) return

    CometChat.getGroup(id)
      .then((group) => setChannel(group))
      .catch((error) => {
        console.log('Group details fetching failed with exception:', error)
      })
  }

  const getUser = (id) => {
    if (channel) setChannel(null)

    CometChat.getUser(id)
      .then((user) => setUser(user))
      .catch((error) => {
        console.log('User details fetching failed with exception:', error)
      })
  }

  const { data } = useQuery(
    GET_CHAT_MUTE,
    { variables: { mute: id ?? '' } },
    {
      onError: (err) => {
        toast.dismiss()
        if (!err?.message?.includes('Session timeout')) {
          toast.error(err.message)
        }
      }
    }
  )

  const [updateChatMute] = useMutation(UPDATE_CHAT_MUTE, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
      toast.error(err.message)
    },
    onCompleted: (err) => {
      toast.dismiss()
      toast.success('Updated SMS mute settings')
    },
    refetchQueries: [{ query: GET_CHAT_MUTE, variables: { mute: id } }]
  })

  useEffect(() => {
    setChannel(null)
    setUser(null)
    setSettingsOpen(false)

    if (isChannel) {
      getChannel(id)
    } else {
      getUser(id)
    }
  }, [id, location.pathname])

  useEffect(() => {
    return () => {
      setChannel(null)
      setUser(null)
    }
  }, [])

  const handleSettingsClick = () => {
    setSettingsOpen((prev) => !prev)
  }

  const handleMuteChange = () => {
    updateChatMute({ variables: { mute: id } })
  }

  if (!channel && !user) return null

  return (
    <>
      <div className={styles.header}>
        <Row alignItems="center">
          <Col>{channel ? <TagIcon className={styles.icon} /> : <PersonIcon className={styles.icon} />}</Col>
          <Col>
            <Typography.Subheading>{channel?.name || user?.name}</Typography.Subheading>
          </Col>
        </Row>
        <Row>
          <Button
            prefixIcon={data?.getChatMute?.mute ? <VolumeOffIcon /> : <VolumeUpIcon />}
            onClick={handleMuteChange}>
            {data?.getChatMute?.mute ? 'Unmute' : 'Mute'} SMS
          </Button>
          {isChannel && (
            <Button prefixIcon={<SettingsIcon />} onClick={handleSettingsClick}>
              Settings
            </Button>
          )}
        </Row>
      </div>
    </>
  )
}

export default Header

const GET_CHAT_MUTE = gql`
  query getChatMute($mute: String!) {
    getChatMute(mute: $mute) {
      id
      agent_id
      mute
      created_at
    }
  }
`

const UPDATE_CHAT_MUTE = gql`
  mutation updateChatMute($mute: String!) {
    updateChatMute(mute: $mute)
  }
`
