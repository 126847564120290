import { useFormContext } from 'react-hook-form'
import { useMemo, useContext } from 'react'
import { BasicUpload, Row } from 'components'
import { UserContext } from 'context'
import { STATUS_TYPES, USER_ROLES } from 'helpers/constants'

const ListingDocumentInDealBase = (props) => {
  const { name, title, listing, disabled = false } = props
  const {  getValues } = useFormContext()
  const { user } = useContext(UserContext)
  const isClosedAndAgent = user.role === USER_ROLES.agent && listing?.status === STATUS_TYPES.closed

  const defaultValue = getValues()[name] ?? null

  const initialValue = useMemo(() => {
    if (defaultValue) {
      return defaultValue
    } else {
      const matchedDocument = listing?.documents?.find((document) => document.checklist_document_type === name)
      if (matchedDocument) {
        const { id, document_id, path, name: fileName } = matchedDocument
        return { id, documentId: document_id, key: path, fileName }
      }
    }
  }, [defaultValue, listing?.documents, name])

  return (
    <Row>
          <BasicUpload
            title={title}
            defaultValue={initialValue}
            required={false}
            canShowAdditionalDoc
            isAllDocument={title.includes('All')}
            disabled={disabled}
            isClosedAndAgent={isClosedAndAgent}
          />
    </Row>
  )
}

export default ListingDocumentInDealBase
