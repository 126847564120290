import React from 'react'
import { AddressAutoComplete, Row, Tag, Textarea, Typography } from 'components'
import { Controller, useFormContext } from 'react-hook-form'
import { Grid } from '@mui/material'
import { useContext } from 'react'
import { DealContext } from 'context'

const ViewPropertyInfo = () => {
  const { deal, disabled = false, isView = false } = useContext(DealContext)
  const { control } = useFormContext()

  return (
    <Grid container ml="0px" mt={3}>
      <Grid container sx={{ mt: { xs: 2, sm: 0, md: 0 } }}>
        <Grid xs={12} md={6} item>
          <Typography.Heading
            type={5}
            style={{ marginTop: 22, fontFamily: 'Source Sans Pro' }}
            gap={50}
            className="dealStepTitle">
            RARE is the
          </Typography.Heading>
          <Row gap={14}>
            {deal.brokerage_side !== 'co-op' && <Tag text="Listing" background="#0072C340" />}
            {deal.brokerage_side !== 'listing' && <Tag text="Cooperating" background="#BA4E0040" />}
          </Row>
        </Grid>
      </Grid>
      <Grid container columnSpacing={7} sx={{ mt: 3 }}>
        <Grid xs={12} md={6} item>
          <Controller
            name="property_address"
            control={control}
            defaultValue={deal?.address || null}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <AddressAutoComplete
                value={value}
                required
                name="property_address"
                isView={isView}
                onChange={onChange}
                error={error?.message}
                disabled={isView}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
export default ViewPropertyInfo
