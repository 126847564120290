import ReactDatePicker from 'react-date-picker'
import { Typography } from 'components'
import styles from './datepicker.module.scss'
import moment from 'moment-timezone'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'

const DatePicker = (props) => {
  let {
    label,
    sublabel,
    required,
    name,
    error,
    style,
    value,
    onChange,
    type = 3,
    minDate = null,
    disabled = false
  } = props
  value = value ? moment(value).toDate() : null

  const datePickerClassName = disabled ? `${styles.datePicker} ${styles.disabled}` : styles.datePicker

  return (
    <>
      {label && (
        <Typography.Body type={2} htmlFor={name} className={styles.label}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </Typography.Body>
      )}

      {sublabel && (
        <Typography.Body type={3} color="secondary" htmlFor={name} className={styles.sublabel}>
          {sublabel}
        </Typography.Body>
      )}

      <div style={{ ...style }} className={`${styles.datePickerOuterDiv} customDatepickerDiv`}>
        <ReactDatePicker
          {...props}
          value={value}
          calendarIcon={<CalendarMonthOutlinedIcon />}
          dayPlaceholder="DD"
          monthPlaceholder="MM"
          yearPlaceholder="YYYY"
          disabled={disabled}
          onChange={(e) => {
            const dateString = e ? moment(e).format('YYYY-MM-DD') : null
            onChange(dateString)
          }}
          className={`${datePickerClassName} customDatepicker`}
          minDate={minDate}
        />
      </div>

      {error && (
        <Typography.Body type="3" color="error" className={styles.error}>
          {error}
        </Typography.Body>
      )}
    </>
  )
}

export default DatePicker
