import { Col, Input, PhoneInput, Row, Typography } from 'components'
import { Controller, useFormContext } from 'react-hook-form'
import styles from '../deals.module.scss'
import { DealContext } from 'context'
import { useContext } from 'react'
import { FORM_DEAL_TYPES } from 'helpers/constants'

const LawyerInfo = () => {
  const { control, getValues } = useFormContext()
  const { deal, disabled = false } = useContext(DealContext)
  const canShowSellerLawwyer = getValues().type === FORM_DEAL_TYPES.PRE_CON || deal?.type === FORM_DEAL_TYPES.PRE_CON

  return (
    <div className={styles.responsive}>
      {!canShowSellerLawwyer && (
        <>
          <Typography.Heading
            type={5}
            style={{ marginTop: 22, fontFamily: 'Source Sans Pro' }}
            gap={50}
            className="dealStepTitle">
            Lawyer Information - Seller
          </Typography.Heading>
          <Typography.Label type={1} style={{ marginTop: 18, marginBottom: 30, fontFamily: 'Source Sans Pro' }}>
            Please provide the following details for the seller's Lawyer
          </Typography.Label>
          <Col style={{ width: '100%', marginTop: 20 }}>
            <Controller
              name="seller_lawyer_name"
              control={control}
              defaultValue={deal?.seller_lawyer?.name || ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Full Name"
                  name="seller_lawyer_name"
                  disabled={disabled}
                  style={{ width: '100%' }}
                />
              )}
            />
          </Col>
          <Col style={{ width: '100%', marginTop: 20 }}>
            <Controller
              name="seller_lawyer_address"
              control={control}
              defaultValue={deal?.seller_lawyer?.address || ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Address"
                  disabled={disabled}
                  name="seller_lawyer_address"
                  style={{ width: '100%' }}
                />
              )}
            />
          </Col>
          <Row gap={50} style={{ width: '100%', marginTop: 20 }}>
            <Col style={{ width: '100%' }}>
              <Controller
                name="seller_lawyer_phone"
                control={control}
                defaultValue={deal?.seller_lawyer?.phone_number || null}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <PhoneInput
                    label="Phone Number"
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                    disabled={disabled}
                    style={{ width: '100%' }}
                  />
                )}
              />
            </Col>
            <Col style={{ width: '100%' }}>
              <Controller
                name="seller_lawyer_email"
                control={control}
                defaultValue={deal?.seller_lawyer?.email || ''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    type="text"
                    error={error?.message}
                    value={value}
                    onChange={onChange}
                    label="Email Address"
                    name="seller_lawyer_email"
                    style={{ width: '100%' }}
                    disabled={disabled}
                  />
                )}
              />
            </Col>
          </Row>
        </>
      )}
      <Typography.Heading
        type={5}
        style={{ marginTop: 22, fontFamily: 'Source Sans Pro' }}
        gap={50}
        className="dealStepTitle">
        Lawyer Information - Buyer
      </Typography.Heading>
      <Typography.Label type={1} style={{ marginTop: 18, marginBottom: 30 }}>
        Please provide the following details for the seller's Lawyer
      </Typography.Label>
      <Col style={{ width: '100%', marginTop: 20 }}>
        <Controller
          name="buyer_lawyer_name"
          control={control}
          defaultValue={deal?.buyer_lawyer?.name || ''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type="text"
              error={error?.message}
              value={value}
              onChange={onChange}
              label="Full Name"
              disabled={disabled}
              name="buyer_lawyer_name"
              style={{ width: '100%' }}
            />
          )}
        />
      </Col>
      <Col style={{ width: '100%', marginTop: 20 }}>
        <Controller
          name="buyer_lawyer_address"
          control={control}
          defaultValue={deal?.buyer_lawyer?.address || ''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type="text"
              error={error?.message}
              value={value}
              onChange={onChange}
              label="Address"
              name="buyer_lawyer_address"
              style={{ width: '100%' }}
              disabled={disabled}
            />
          )}
        />
      </Col>
      <Row gap={50} style={{ width: '100%', marginTop: 20 }}>
        <Col style={{ width: '100%' }}>
          <Controller
            name="buyer_lawyer_phone"
            control={control}
            defaultValue={deal?.buyer_lawyer?.phone_number || null}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <PhoneInput
                label="Phone Number"
                error={error?.message}
                value={value}
                style={{ width: '100%' }}
                onChange={onChange}
                disabled={disabled}
              />
            )}
          />
        </Col>
        <Col style={{ width: '100%' }}>
          <Controller
            name="buyer_lawyer_email"
            control={control}
            defaultValue={deal?.buyer_lawyer?.email || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type="text"
                error={error?.message}
                value={value}
                onChange={onChange}
                label="Email Address"
                name="buyer_lawyer_email"
                style={{ width: '100%' }}
                disabled={disabled}
              />
            )}
          />
        </Col>
      </Row>
    </div>
  )
}

export default LawyerInfo
