import * as React from 'react'
import { LocalizationProvider, DateTimePicker as DatePicker, renderTimeViewClock } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { Typography } from 'components'
import styles from './datepicker.module.scss'
import { ThemeProvider } from '@mui/material'
import './YearPicker.scss'
import moment from 'moment'
import { theme } from './YearPicker'

const DateTimePicker = (props) => {
  const {
    label,
    required,
    name,
    error,
    value,
    onChange,
    type = 3,
    style,
    placeholder = '',
    disabled = false,
    maxDateTime = null,
    minDateTime = null
  } = props
  const selectedValue = value ? moment(value) : null

  return (
    <div className="yearPickerMain">
      {label && (
        <Typography.Body type={type} htmlFor={name} style={{ marginBottom: 6 }} className={styles.label}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </Typography.Body>
      )}

      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <DatePicker
            defaultValue={moment()}
            value={selectedValue}
            onChange={onChange}
            disabled={disabled}
            style={style}
            placeholder={placeholder}
            maxDateTime={maxDateTime}
            minDateTime={minDateTime}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock
            }}
          />
        </ThemeProvider>
      </LocalizationProvider>

      {error && (
        <Typography.Body type={3} color="error" className={styles.error}>
          {error}
        </Typography.Body>
      )}
    </div>
  )
}

export default DateTimePicker
