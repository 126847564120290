import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Tab, Tabs, ThemeProvider, createTheme } from '@mui/material'
import { InputSearch, Loading } from 'components'
import { toast } from 'react-toastify'
import dropdownOption from 'helpers/dropdownOptions'
import { GET_ANNOUNCEMENTS } from 'helpers/gql'
import AnnouncementsList from './AnnouncementsList'
import { ANNONCEMENT_TYPES } from 'helpers/constants'
import { Helmet } from 'react-helmet-async'
import debounce from 'lodash.debounce'

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 18,
          fontWeight: 400,
          alignItems: 'baseline',
          '&.Mui-selected': {
            color: '#C12121 !important',
            fontSize: 18,
            fontWeight: 600
          }
        }
      }
    }
  }
})

const MobileAnnouncements = () => {
  const [page, setPage] = useState(10)
  const [value, setValue] = useState(0)
  const [announcements, setAnnouncements] = useState()
  const [selectedTab, setSelectedTab] = useState(ANNONCEMENT_TYPES.INBOX)
  const [searchText, setSearchText] = useState(null)

  const {
    data: { getAnnouncements: data } = [],
    loading,
    refetch
  } = useQuery(GET_ANNOUNCEMENTS, {
    variables: {
      filters: { status: selectedTab, perPage: page, searchString: searchText }
    },
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: (data) => {
      setAnnouncements(data?.getAnnouncements?.data)
    }
  })

  const handleTabChange = async (option) => {
    await setSearchText(null)
    await setPage(10)
    await setSelectedTab(option.value)
    refetch()
  }

  const tabOptions = useMemo(() => {
    return dropdownOption.ANNOUNCEMENT_OPTIONS.filter(
      (opt) => opt.value !== ANNONCEMENT_TYPES.DRAFT && opt.value !== ANNONCEMENT_TYPES.SCHEDULED
    )
  }, [])

  const handleChange = (event, newValue) => {
    setPage(10)
    setValue(newValue)
  }

  useEffect(() => {
    refetch()
  }, [refetch, searchText])

  const handleShowMore = useCallback(async () => {
    await setPage(page + 10)
    refetch()
  }, [page, refetch])

  const handleSearch = useCallback(
    debounce((e) => {
      const text = e.target.value?.trimStart()
      if (text?.length > 2) {
        setSearchText(text)
      } else {
        setSearchText(null)
      }
    }, 300),
    []
  )

  return (
    <>
      <Helmet title="Announcement | RARE" />
      <ThemeProvider theme={theme}>
        <Tabs
          value={value}
          onChange={handleChange}
          orientation="horizontal"
          variant="scrollable"
          TabIndicatorProps={{
            style: { display: 'none' }
          }}
          style={{ alignSelf: 'left', marginBottom: '10px' }}>
          {tabOptions.map((option, index) => {
            return (
              <Tab
                label={<div style={{ display: 'flex' }}>{option.label}</div>}
                key={index}
                sx={{ textTransform: 'none' }}
                onClick={() => handleTabChange(option)}
              />
            )
          })}
        </Tabs>
      </ThemeProvider>
      <div style={{ marginBottom: 10 }}>
        <InputSearch name="search" onChange={handleSearch} />
      </div>
      {loading ? (
        <Loading fullpage />
      ) : (
        <AnnouncementsList
          data={data}
          loading={loading}
          searchText={searchText}
          announcements={announcements}
          onShowMore={handleShowMore}
        />
      )}
    </>
  )
}

export default MobileAnnouncements
