import { Typography, Textarea, Radio, RadioGroup, Row } from 'components'
import { Grid } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { DatePicker } from 'components'
import { useCallback, useContext, useState } from 'react'
import AdditionalConditional from './AdditionalConditional'
import { STATUS_TYPES, USER_ROLES } from 'helpers/constants'
import { UserContext } from 'context'

const ListingDetails = ({ listing, listingType, listingOption, isView = false, isEdit = false }) => {
  const { control, setValue } = useFormContext()
  const listingTypeLabel = listingType === 'sale_listing' ? 'sale' : 'lease'
  const listingTypeText = listingType === 'sale_listing' ? 'Sale' : 'Lease'
  const [isSaleSignNeeded, setIsSaleSignNeeded] = useState(
    listing.need_sale_sign ? 'need_sale_sign_yes' : 'need_sale_sign_no' ?? ''
  )
  const [isConditional, setIsConditional] = useState(listing?.conditional ? 'yes' : 'no')
  const { user } = useContext(UserContext)
  const isClosedAndAgent = user.role === USER_ROLES.agent && listing?.status === STATUS_TYPES.closed
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)

  const handleChange = useCallback(
    (value) => {
      setIsSaleSignNeeded(value)
      setValue('sign_installation_date', null)
      setValue('is_rider_needed', '')
    },
    [setValue]
  )

  return (
    <div style={{ marginTop: 20 }}>
      <Row>
        <Typography.Heading type={5}>For {listingTypeText} Signage </Typography.Heading>
        <Typography.Body type={1} style={{ color: '#C0C0C0', marginTop: 4 }}>
          (optional)
        </Typography.Body>
      </Row>
      <Grid container style={{ marginBottom: 20 }}>
        <Grid container sx={{ mt: 2 }}>
          <Grid xs={12} md={6} item>
            <Controller
              name="need_sale_sign"
              control={control}
              defaultValue={listing.need_sale_sign ? 'need_sale_sign_yes' : 'need_sale_sign_no'}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <RadioGroup
                  type={5}
                  label={`Will you require a  ${listingTypeLabel} sign?`}
                  onChange={(e) => {
                    onChange(e)
                    handleChange(e.target.value)
                  }}
                  value={value}
                  error={error?.message}>
                  <Radio
                    lableStyle={{ marginRight: 50 }}
                    name="need_sale_sign_yes"
                    label="Yes"
                    value="need_sale_sign_yes"
                    checked={value === 'need_sale_sign_yes'}
                    disabled={isView || isEdit || isClosedAndAgent}
                  />
                  <Radio
                    name="need_sale_sign_no"
                    label="No"
                    value="need_sale_sign_no"
                    checked={value === 'need_sale_sign_no'}
                    disabled={isView || isEdit || isClosedAndAgent}
                  />
                </RadioGroup>
              )}
            />
          </Grid>
        </Grid>
        {isSaleSignNeeded === 'need_sale_sign_yes' && (
          <>
            <Grid container sx={{ mt: 2 }}>
              <Grid xs={12} md={6} item>
                <Controller
                  name="is_rider_needed"
                  control={control}
                  defaultValue={listing.is_rider_needed === 'yes' ? 'yes' : 'no' ?? null}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <RadioGroup
                      type={5}
                      label="Do you have a name rider?"
                      onChange={onChange}
                      value={value}
                      error={error?.message}>
                      <Radio
                        lableStyle={{ marginRight: 50 }}
                        name="yes"
                        label="Yes"
                        value="yes"
                        checked={value === 'yes'}
                        disabled={isView || isEdit || isClosedAndAgent}
                      />
                      <Radio name="no" label="No" value="no" checked={value === 'no'} disabled={isView || isEdit} />
                    </RadioGroup>
                  )}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 2 }}>
              <Grid xs={12} md={3} item>
                <Controller
                  name="sign_installation_date"
                  control={control}
                  defaultValue={listing.sign_installation_date ?? null}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <DatePicker
                      type={5}
                      error={error?.message}
                      value={value}
                      onChange={onChange}
                      sublabel="Date for which you need the sign board"
                      label="Select Date"
                      required
                      disabled={isView || isClosedAndAgent}
                      name="sign_installation_date"
                      style={{ width: '100%' }}
                      minDate={tomorrow}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid container sx={{ marginTop: 3 }} columnSpacing={7}>
          <Grid xs={12} md={6} item>
            <Controller
              name="additional_copy_note"
              control={control}
              defaultValue={listing.additional_copy_note ?? null}
              render={({ field: { onChange, value } }) => (
                <Textarea
                  type={5}
                  value={value}
                  onChange={onChange}
                  label="Additional Information"
                  sublabel="Please include any special notes or instructions related to the listing."
                  name="additional_copy_note"
                  style={{ width: '100%' }}
                  disabled={isView || isClosedAndAgent}
                />
              )}
            />
          </Grid>
        </Grid>
        {listingOption === 'mls' && (
          <>
            <Grid container sx={{ marginTop: 2, ml: '0px' }}>
              <Grid xs={12} md={6} item>
                <Controller
                  name="conditional"
                  control={control}
                  defaultValue={listing.conditional ? 'yes' : 'no'}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <RadioGroup
                      type={5}
                      label={listingType === 'sale_listing' ? 'Sold Conditional ?' : 'Leased Conditional ?'}
                      required
                      onChange={(e) => {
                        onChange(e)
                        setIsConditional(e.target.value)
                      }}
                      value={value}
                      error={error?.message}>
                      <Radio
                        name="conditional_yes"
                        label="Yes"
                        value="yes"
                        checked={value === 'yes'}
                        lableStyle={{ marginRight: 70 }}
                        disabled={isView || isClosedAndAgent}
                      />
                      <Radio
                        name="conditional_no"
                        label="No"
                        value="no"
                        checked={value === 'no'}
                        disabled={isView || isClosedAndAgent}
                      />
                    </RadioGroup>
                  )}
                />
              </Grid>
            </Grid>
            {isConditional === 'yes' && <AdditionalConditional listing={listing} isView={isView || isClosedAndAgent} />}
            <Grid container sx={{ marginTop: 2, ml: '0px' }}>
              <Grid xs={12} md={6} item>
                <Controller
                  name="upload_conditional"
                  control={control}
                  defaultValue={listing?.upload_conditional ? 'yes' : 'no'}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <RadioGroup
                      type={5}
                      label="Did you upload conditional deal ?"
                      required
                      onChange={onChange}
                      value={value}
                      error={error?.message}>
                      <Radio
                        name="upload_conditional_yes"
                        label="Yes"
                        value="yes"
                        checked={value === 'yes'}
                        lableStyle={{ marginRight: 70 }}
                        disabled={isView || isClosedAndAgent}
                      />
                      <Radio
                        name="upload_conditional_no"
                        label="No"
                        value="no"
                        checked={value === 'no'}
                        disabled={isView || isClosedAndAgent}
                      />
                    </RadioGroup>
                  )}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  )
}

export default ListingDetails
