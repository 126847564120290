import { Helmet } from 'react-helmet-async'
import { Typography } from 'components'
import styles from './signin.module.scss'
import loginLogo from '../../assets/Login-page-logo.png'
import { createTheme, useMediaQuery } from '@mui/material'

const SuccessRequestPassword = ({ email = process.env.REACT_APP_SUCCESS_REQUEST_PASSWORD, setEmail }) => {
  const theme = createTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const heightWidth = isMobile ? 200 : 350

  return (
    <>
      <Helmet title="Check email | RARE" />
      <div className={styles.signin} justifyContent="center">
        <div className={styles.logoContainer}>
          <img src={loginLogo} alt="RARE Logo" height={heightWidth} width={heightWidth} />
        </div>
        <div className={styles.centerContent}>
          <Typography.Heading type="2" color="white" style={{ marginTop: 36, textAlign: 'center' }}>
            Check your email
          </Typography.Heading>
          <Typography.Body color="white" style={{ marginTop: 8, textAlign: 'center', maxWidth: '480px' }}>
            We just sent an email to {email} with instructions on how to reset your password. Please check your spam
            folder if the email doesn’t appear within a few minutes.
          </Typography.Body>
          <Typography.Body color="white" style={{ marginTop: 72, textAlign: 'center' }}>
            Didn’t receive an email?
            <Typography.Link onClick={() => setEmail(null)} style={{ marginLeft: 8 }}>
              Resend email
            </Typography.Link>
          </Typography.Body>
          <Typography.Body color="white" style={{ marginTop: 16, textAlign: 'center' }}>
            No longer need to reset password?
            <Typography.Link to="/login" style={{ marginLeft: 8 }}>
              Sign in instead
            </Typography.Link>
          </Typography.Body>
        </div>
      </div>
    </>
  )
}

export default SuccessRequestPassword
