import { East } from '@mui/icons-material'
import { Checkbox, IconButton, Tooltip } from '@mui/material'
import { Row, Typography } from 'components'
import React, { useMemo } from 'react'
import folderIcon from '../../assets/images/folder.png'
import epsIcon from '../../assets/images/epsIcon.png'
import zipFolderIcon from '../../assets/images/zipFolderIcon.png'
import pdfIcon from '../../assets/images/Pdf.png'
import textFileIcon from '../../assets/images/textfileIcon.png'
import videoIcon from '../../assets/images/videoIcon.png'
import msWord from '../../assets/images/Word_96x96.png'
import msExcel from '../../assets/images/Excel_96x96.png'
import msPowerPoint from '../../assets/images/PowerPoint_96x96.png'
import msOneNote from '../../assets/images/OneNote_96x96.png'
import msOutLook from '../../assets/images/Outlook_96x96.png'
import googleDocs from '../../assets/images/google-docs-96.png'
import googleSheet from '../../assets/images/google-sheets-96.png'
import googleSlides from '../../assets/images/google-slides-96.png'
import PicIcon from '../../assets/images/PicIcon.png'
import { getFileLibraryUrl, getFileUrl } from 'helpers/utils'

const AttchmentsList = ({ attchement, selectdAttachments, onFolderClick, onAttachmentSelect }) => {
  const inValidChar = ['#', '+', '%']

  const isChecked = useMemo(
    () => selectdAttachments.find((doc) => doc?.path === attchement?.key || doc?.source_path === attchement?.key),
    [attchement, selectdAttachments]
  )
  const attachmentName = useMemo(
    () =>
      attchement?.key.split('/').reverse()[0] !== ''
        ? attchement?.key.split('/').reverse()[0]
        : attchement?.key.split('/').reverse()[1],
    [attchement?.key]
  )

  const attchementIcon = useMemo(() => {
    const parts = attchement?.key.toString().toLowerCase().split('.')
    const extension = parts.length > 1 ? parts.pop() : null

    switch (extension) {
      case 'pdf':
        return pdfIcon
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'bmp':
        return inValidChar.some((char) => attchement?.key.includes(char))
          ? PicIcon
          : attchement.key.includes('user_library')
          ? getFileUrl(attchement.key)
          : getFileLibraryUrl(attchement?.key)
      case 'eps':
        return epsIcon
      case 'zip':
        return zipFolderIcon
      case 'txt':
        return textFileIcon
      case 'mp4':
      case 'avi':
      case 'mkv':
      case 'mov':
      case 'wmv':
        return videoIcon
      case 'docx':
      case 'doc':
        return msWord
      case 'xlsx':
      case 'xls':
      case 'csv':
        return msExcel
      case 'pptx':
      case 'ppt':
      case 'ppsx':
        return msPowerPoint
      case 'pst':
        return msOutLook
      case 'one':
        return msOneNote
      case 'gdoc':
        return googleDocs
      case 'gslides':
        return googleSlides
      case 'gsheet':
        return googleSheet
      default:
        return pdfIcon
    }
  }, [attchement.key, inValidChar])

  return (
    <>
      {attchement.type === 'File' ? (
        <Row
          marginTop={8}
          style={{
            alignItems: 'center',
            backgroundColor: isChecked ? 'aliceblue' : ''
          }}>
          <Checkbox
            size="small"
            sx={{
              '&.Mui-checked': {
                color: '#1f1616'
              }
            }}
            checked={isChecked}
            onClick={() => onAttachmentSelect(attchement)}
          />
          <img src={attchementIcon} height={30} width={30} alt="imgIcon" />
          <Tooltip placement="right-start" title={attachmentName?.length > 30 ? attachmentName : ''}>
            <div>
              <Typography.Body style={{ marginLeft: 6 }}>
                {attachmentName?.length > 30 ? `${attachmentName?.slice(0, 30)}...` : attachmentName}
              </Typography.Body>
            </div>
          </Tooltip>
        </Row>
      ) : (
        <Row marginTop={8} style={{ alignItems: 'center' }}>
          <IconButton disabled>
            <East />
          </IconButton>
          <img
            src={folderIcon}
            height={30}
            width={30}
            alt="folderIcon"
            onClick={() => onFolderClick(attchement)}
            style={{ cursor: 'pointer' }}
          />
          <Typography.Body style={{ marginLeft: 6 }}>{attachmentName}</Typography.Body>
        </Row>
      )}
    </>
  )
}

export default AttchmentsList
