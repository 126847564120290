import {ReactComponent as LogoSVG} from '../../assets/rare-logo-white.svg'
import {ReactComponent as LogoMobileSVG} from '../../assets/rare-logo-white-mobile.svg'
import { NavLink } from 'react-router-dom';
import './nav.scss'

const LogoItem = (LogoImage, id) => ({style}) => {
  return (
    // 0 lineHeight is necessary otherwise the NavLink will be taller than the contained svg
    <NavLink id={id} to="/" style={{...style, lineHeight: 0}}>
      <LogoImage style={{lineHeight: 0}}/>
    </NavLink>
  )
}

export const LogoDesktop = LogoItem(LogoSVG, 'desktop-logo');
export const LogoMobile = LogoItem(LogoMobileSVG, 'mobile-logo');
