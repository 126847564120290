import { useQuery } from '@apollo/client'
import { InputSearch, Loading, PopUp, Row, Typography } from 'components'
import { GET_LIBRARY } from 'helpers/gql'
import React, { useState, useCallback, useEffect } from 'react'
import { toast } from 'react-toastify'
import AttchmentsList from './AttchmentsList'
import vars from '../../vars.scss'
import { debounce } from 'lodash'
import { ArrowCircleLeft } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import styles from './announcements.module.scss'

const AnnouncementAttchementPopup = ({ attachFiles, uploadedFiles = [], hideAttachFiles, onAddAttachments }) => {
  const [librarySearch, setLibrarySearch] = useState(null)
  const [folderPath, setFolderPath] = useState(null)
  const [selectdAttachments, setSelectdAttachments] = useState(uploadedFiles)

  useEffect(() => {
    setSelectdAttachments(uploadedFiles)
  }, [uploadedFiles])

  const {
    data: { getLibrary: libraryList } = [],
    loading,
    refetch
  } = useQuery(
    GET_LIBRARY,
    {
      variables: {
        filters: { type: librarySearch, prefix: librarySearch ? null : folderPath }
      }
    },
    {
      onError: (err) => {
        toast.dismiss()
        if (!err?.message?.includes('Session timeout')) {
          toast.error(err.message)
        }
      }
    }
  )

  const handleFolderClick = useCallback(
    async (attachment) => {
      setLibrarySearch(null)
      await setFolderPath(attachment.key)
      refetch()
    },
    [refetch]
  )

  const handleSearch = useCallback(
    debounce(async (searchedVal) => {
      setLibrarySearch(searchedVal)
    }, 400),
    []
  )

  const handleCancel = useCallback(() => {
    setFolderPath(null)
    setSelectdAttachments(uploadedFiles)
    setLibrarySearch(null)
    hideAttachFiles()
  }, [hideAttachFiles, uploadedFiles])

  const handleAttachmentSelect = useCallback(
    (attchement) => {
      const existingObjectIndex = selectdAttachments?.findIndex(
        (doc) => doc?.source_path === attchement?.key || doc?.path === attchement?.key
      )
      if (existingObjectIndex !== -1) {
        const filter = selectdAttachments.filter(
          (val) => val.source_path !== attchement?.key && val?.path !== attchement?.key
        )
        setSelectdAttachments(filter)
      } else {
        const file_name =
          attchement?.key?.split('/').reverse()[0] !== ''
            ? attchement?.key?.split('/').reverse()[0]
            : attchement?.key?.split('/').reverse()[1]
        setSelectdAttachments([
          ...selectdAttachments,
          {
            path: attchement?.key,
            file_name,
            file_size: attchement?.size,
            bucket_name: process.env.REACT_APP_LIBRARY_BUCKET
          }
        ])
      }
    },
    [selectdAttachments]
  )

  const handleAddAttachments = useCallback(() => {
    onAddAttachments(selectdAttachments)
    hideAttachFiles()
  }, [hideAttachFiles, onAddAttachments, selectdAttachments])

  const handleBack = useCallback(async () => {
    const splitedPath = folderPath?.split('/')
    if (splitedPath.length === 2) {
      await setFolderPath(null)
    } else {
      splitedPath?.splice(-2, 1)
      const path = splitedPath?.join('/')
      await setFolderPath(path)
    }
    await refetch()
  }, [folderPath, refetch])

  return (
    <div>
      <PopUp
        title={'Add Attachments'}
        open={attachFiles}
        confirmBtnLabel={'Add Attachments'}
        cancelBtnLabel={'Cancel'}
        onCancel={handleCancel}
        onConfirm={handleAddAttachments}>
        <div className={styles.attachmentPopup}>
          <InputSearch name="search" value={librarySearch} onChange={(e) => handleSearch(e.target.value.trimStart())} />
          <Row style={{ marginTop: 10, alignItems: 'center' }}>
            <IconButton sx={{ color: vars['font-color-primary'] }} onClick={handleBack} disabled={!folderPath}>
              <ArrowCircleLeft />
            </IconButton>
            Back
            <Typography.Body style={{ marginLeft: 20 }}>{folderPath}</Typography.Body>
          </Row>
          {loading ? (
            <Loading fullpage />
          ) : libraryList?.length > 0 ? (
            <div className={styles.attachmentListContainer}>
              {libraryList?.map((attchement, index) => {
                return (
                  <div key={index} className={styles.attachmentList}>
                    <AttchmentsList
                      attchement={attchement}
                      selectdAttachments={selectdAttachments}
                      onFolderClick={handleFolderClick}
                      onAttachmentSelect={handleAttachmentSelect}
                    />
                  </div>
                )
              })}
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography.Body style={{ color: vars['table-cell-color'], paddingTop: 150 }} type={1}>
                This folder is empty
              </Typography.Body>
            </div>
          )}
        </div>
      </PopUp>
    </div>
  )
}

export default AnnouncementAttchementPopup
