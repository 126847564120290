import React from 'react'
import styles from './input.module.scss'
import { Typography } from 'components'

const SIZES = ['small', 'medium', 'large']
const COLORS = ['light', 'dark']

const PhoneInput = ({
  name,
  error,
  value,
  defaultValue,
  style,
  label,
  labelStyle,
  sublabel,
  disabled = false,
  required,
  color = 'light',
  size = 'medium',
  onChange
}) => {
  const TColor = (() => {
    if (color && COLORS.includes(color)) return color
    return 'light'
  })()

  const TSize = (() => {
    if (size && SIZES.includes(size)) return size
    return 'medium'
  })()

  const phoneNumberAutoFormat = (phoneNumber) => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, '')
    if (number.length < 3) return number
    if (number.length < 6) return number.replace(/(\d{3})(\d{1})/, '$1-$2')
    if (number.length < 10) return number.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3')
    return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  }

  const handleChange = (e) => {
    const targetValue = phoneNumberAutoFormat(e.target.value)
    onChange(targetValue ?? null)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  return (
    <>
      {label && (
        <Typography.Body
          type={2}
          color={color === 'light' ? 'primary' : 'white'}
          htmlFor={name}
          className={styles.label}
          style={labelStyle}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </Typography.Body>
      )}
      {sublabel && (
        <Typography.Body type="3" color="secondary" htmlFor={name} className={styles.sublabel}>
          {sublabel}
        </Typography.Body>
      )}
      <input
        type="tel"
        placeholder="555-555-5555"
        name={name}
        maxLength={12}
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        style={style}
        disabled={disabled}
        className={`customInput ${styles.input} ${styles[TColor]} ${styles[TSize]}
      `}
      />
      {error && (
        <Typography.Body type="3" color="error" className={styles.error}>
          {error}
        </Typography.Body>
      )}
    </>
  )
}

export default PhoneInput
