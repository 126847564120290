import Typography from 'components/typography/Typography'
import styles from './dashboard.module.scss'

const DashboardItem = ({ title = 'Title', value = 123, date, change, border = false }) => {
  return (
    <div className={`${styles.dashboardItem} ${border && styles.border}`}>
      <Typography.Label type={2}>{title}</Typography.Label>
      <Typography.Heading type={4}>{value}</Typography.Heading>
      <div>
        {date && <Typography.Body type={3}>{date}</Typography.Body>}
        {change && <Typography.Body type={3}>{change} from last month </Typography.Body>}
      </div>
    </div>
  )
}

export default DashboardItem
