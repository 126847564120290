import ReactSelect from 'react-select'
import { Typography } from 'components'
import styles from './select.module.scss'
import vars from '../../vars.scss'

const Select = (props) => {
  const { error, required, label, name, placeholder = '', disabled = false } = props

  return (
    <>
      {label && (
        <Typography.Body type={2} color="primary" htmlFor={name} className={styles.label}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </Typography.Body>
      )}
      <ReactSelect
        {...props}
        placeholder={placeholder}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: vars['select-hover-color'],
            // primary50: vars['select-hover-color'],
            primary: vars['select-primary-color']
          }
        })}
        classNamePrefix={'customSelectPlaceholder'}
        isDisabled={disabled}
      />
      {error && (
        <Typography.Body type="3" color="error" className={styles.error}>
          {error}
        </Typography.Body>
      )}
    </>
  )
}

export default Select
