import { Button } from 'components'
import ListingComments from '../ListingComments'
import ListingFormHeader from '../ListingFormHeader'
import { FormProvider, useForm } from 'react-hook-form'
import styles from '../listings.module.scss'
import ListingDocuments from '../ListingDocuments'
import { UserContext } from 'context'
import { useContext } from 'react'
import { useNavigate } from 'react-router'
import EditListingDetails from '../ListingDetails'
import ListingAdmin from '../ListingAdmin'
import PropertyInfo from '../PropertyInfo'
import { convertUTCToLocal } from 'helpers/utils'

const ViewListingForm = ({ listing }) => {
  const navigate = useNavigate()
  const { user } = useContext(UserContext)
  const isAdmin = ['admin', 'super_admin'].includes(user.role)

  const formRef = useForm({
    defaultValues: {
      type: listing?.type,
      option: listing?.option,
      address: listing?.address,
      mls_pictures_link: listing?.mls_pictures_link,
      conditions: listing?.conditions.length
        ? listing?.conditions
        : [{ conditional_upon: '', conditional_until: null }],
      additionalDocuments: listing?.additionalDocuments?.length
        ? listing?.additionalDocuments
        : [{ fileName: '', key: null, size: null, type: '' }],
      checkedDocuments: listing?.checkedDocuments,
      sign_installation_date: convertUTCToLocal(listing?.sign_installation_date)
    }
  })

  return (
    <FormProvider {...formRef}>
      <ListingFormHeader listing={listing} isView />
      <form>
        <div className={styles.flexContainer}>
          <div className={styles.leftSide}>
            <div className={styles.form}>
              <PropertyInfo listing={listing} isView />
              <ListingDocuments listing={listing} isView />
              <EditListingDetails
                listing={listing}
                listingType={listing?.type}
                listingOption={listing?.option}
                isView
              />
              <div className={styles.mobileListingComment}>
                <ListingComments listing={listing} />
              </div>
              {isAdmin && <ListingAdmin listing={listing} isAdmin={isAdmin} />}
              {!isAdmin && (
                <Button form onClick={() => navigate('/listings')}>
                  Back to Dashboard
                </Button>
              )}
            </div>
          </div>
          <div className={styles.rightSide}>
            <ListingComments listing={listing} />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

export default ViewListingForm
