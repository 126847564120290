import DealAdmin from '../DealAdmin'
import { DealProvider, UserContext } from 'context'
import { useContext } from 'react'
import DealFooter from '../DealFooter'
import { FormProvider, useForm } from 'react-hook-form'
import OfferDetails from '../create_deal/OfferDetails'
import DealDocuments from '../create_deal/DealDocuments'
import OutsideBrokerage from '../create_deal/OutsideBrokerage'
import LawyerInfo from '../create_deal/LawyerInfo'
import Commission from '../create_deal/Commission'
import DealFormHeader from '../create_deal/DealFormHeader'
import styles from '../deals.module.scss'
import ViewPropertyInfo from './ViewPropertyInfo'
import { FORM_DEAL_TYPES, STATUS_TYPES } from 'helpers/constants'
import DealComments from '../DealComments'
import { convertUTCToLocal } from 'helpers/utils'
import ListingDocumentsInDeal from '../ListingDocumentsInDeal'
import { Row, Typography } from 'components'

const ViewDealForm = ({ deal }) => {
  const { user } = useContext(UserContext)
  const isAdmin = ['admin', 'super_admin'].includes(user.role)
  const isView = true

  const formRef = useForm({
    defaultValues: {
      type: deal?.type,
      brokerage_side: deal?.brokerage_side,
      property_address: deal?.address,
      offer_date: convertUTCToLocal(deal?.offer_date),
      acceptance_date: convertUTCToLocal(deal?.acceptance_date),
      close_date: convertUTCToLocal(deal?.close_date),
      settled_date: convertUTCToLocal(deal?.settled_date),
      sale_price: deal?.sale_price,
      lease_price: deal?.lease_price,
      deposit_amount: deal?.deposit_amount,
      second_deposit_amount: deal?.second_deposit_amount,
      brokerage: deal?.brokerage,
      brokerage_phone_number: deal?.brokerage_phone_number,
      brokerage_email: deal?.brokerage_email,
      external_agents: [
        {
          name: deal?.external_agents?.[0]?.name,
          email: deal?.external_agents?.[0]?.email,
          phone: deal?.external_agents?.[0]?.phone
        }
      ],
      seller_lawyer: [
        {
          name: deal?.seller_lawyer?.name,
          address: deal?.seller_lawyer?.address,
          phone: deal?.seller_lawyer?.phone_number,
          email: deal?.seller_lawyer?.email,
          fax: deal?.seller_lawyer?.fax_number
        }
      ],
      buyer_lawyer: [
        {
          name: deal?.buyer_lawyer?.name,
          address: deal?.buyer_lawyer?.address,
          phone: deal?.buyer_lawyer?.phone_number,
          email: deal?.buyer_lawyer?.email,
          fax: deal?.buyer_lawyer?.fax_number
        }
      ],
      annual_personal_deal_checkbox: deal?.annual_personal_deal_checkbox,
      first_installment: deal?.first_installment,
      second_installment: deal?.second_installment,
      third_installment: deal?.third_installment,
      final_installment: deal?.final_installment,
      total_commission: deal?.total_commission,
      listing_brokerage_commission: deal?.listing_brokerage_commission,
      cooperating_brokerage_commission: deal?.cooperating_brokerage_commission,
      total: deal?.total,
      notes: deal?.notes,
      referral: [
        {
          referral_first_name: deal?.referral?.first_name,
          referral_last_name: deal?.referral?.last_name,
          referral_amount: deal?.referral?.referral_amount,
          referral_percentage: deal?.referral?.referral_percentage,
          referral_office_name: deal?.referral?.office_name,
          referral_office_address: deal?.referral?.office_address,
          referral_office_phone: deal?.referral?.office_phone,
          referral_office_fax: deal?.referral?.office_fax,
          referral_office_email: deal?.referral?.office_email
        }
      ],
      additionalDocuments: deal?.additionalDocuments?.length
        ? deal?.additionalDocuments
        : [{ fileName: '', key: null, size: null, type: '' }],
      checkedDocuments: deal?.checkedDocuments
    }
  })
  const canShowBrokerage = deal?.brokerage_side !== 'both' && deal?.type !== FORM_DEAL_TYPES.PRE_CON
  const canShowLawyer = deal?.type !== FORM_DEAL_TYPES.LEASE
  return (
    <>
      <DealProvider deal={deal} disabled isView>
        <FormProvider {...formRef}>
          <form id="createDeal">
            <div className={styles.flexContainer}>
              <div className={styles.leftSide}>
                <div className={styles.form}>
                  <DealFormHeader />
                  <ViewPropertyInfo />
                  {<OfferDetails isView={isView} />}
                  {canShowBrokerage && <OutsideBrokerage />}
                  <DealDocuments />
                  {!deal?.listing && (<Typography.Heading type={6} className={styles.headerLabel} style={{ marginTop: 20 }}>
                    No linked listing
                  </Typography.Heading>)}
                  {(deal?.listing && (deal?.listing?.documents?.length !== 0 || deal?.listing?.additionalDocuments?.length !== 0) && (<ListingDocumentsInDeal listing={deal?.listing} isView={true} listingType={deal?.listing?.type} />))}
                  {canShowLawyer && <LawyerInfo />}
                  <Commission isAgent={isAdmin} agents={deal.agents} />
                  <div className={styles.mobileDealComment}>
                    <DealComments deal={deal} />
                  </div>
                  {isAdmin && deal.status !== STATUS_TYPES.submitted ? <DealAdmin isAdmin={isAdmin} /> : <DealFooter />}
                </div>
              </div>
              <div className={styles.rightSide}>
                <DealComments deal={deal} />
              </div>
            </div>
          </form>
        </FormProvider>
      </DealProvider>
    </>
  )
}

export default ViewDealForm
