import { Divider, Filter, Typography } from 'components'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'
import Godmode from './Godmode'
import { Group, VpnKey, FileUpload, Paid } from '@mui/icons-material'
import UserManagement from './UserManagement'
import LaunchCloud from './LaunchCloud'
import LoneWolfUpload from './LoneWolfUpload'
import Commission from './Commission'
import ActivityLog from '../activity_log/ActivityLog'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'

const adminOptions = [
  {
    value: 'supermode',
    label: 'Super Mode',
    icon: VpnKey
  },
  {
    value: 'user-management',
    label: 'User Management',
    icon: Group
  },
  // {
  //   value: 'lone-wolf',
  //   label: 'LoneWolf',
  //   icon: FileUpload
  // },
  // {
  //   value: 'commission',
  //   label: 'Commission',
  //   icon: Paid
  // },
  {
    value: 'activity-log',
    label: 'Activity Log',
    icon: ReceiptLongIcon
  }
]

const Admin = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { section } = params

  const [tab, setTab] = useState(section || 'supermode')

  const handleTabChange = (section) => {
    setTab(section)
    navigate(`/admin/${section}`)
  }

  return (
    <>
      <Helmet title="Admin Panel | RARE" />
      <section id="admin">
        <Divider />
        <div className="table-layout-page" style={{ display: 'flex', gap: 40 }}>
          <Filter options={adminOptions} defaultValue={tab} onChange={handleTabChange} />
          <div style={{ display: 'flex', flexDirection: 'column', gap: 24, width: '100%' }}>
            {tab === 'supermode' && <Godmode />}
            {tab === 'user-management' && <UserManagement />}
            {tab === 'launch' && <LaunchCloud />}
            {tab === 'lone-wolf' && <LoneWolfUpload />}
            {tab === 'commission' && <Commission />}
            {tab === 'activity-log' && <ActivityLog />}
          </div>
        </div>
      </section>
    </>
  )
}

export default Admin
