import { gql } from '@apollo/client'
import React, { useState } from 'react'
import { Delete } from '@mui/icons-material'
import client from 'helpers/client'
import { Button, MUIMenuButton, Row, Typography } from 'components'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { ANNONCEMENT_TYPES, LIBRARY_OPTIONS } from 'helpers/constants'
import { IconButton, Tooltip } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import dropdownOption from 'helpers/dropdownOptions'
import { useCallback } from 'react'
import AnnouncementAttchementPopup from './AnnouncementAttchementPopup'
import { usePopup } from 'hooks/usePopup'
import styles from './announcements.module.scss'

function AnnouncementFileUpload({
  loading = false,
  loadingSection,
  isEdit,
  value,
  status,
  onChange,
  handlePreview,
  onSubmit,
  onDraftSave,
  onScheduledSend
}) {
  const navigate = useNavigate()
  const [uploadedFiles, setUploadedFiles] = useState(value ?? [])
  const [docLoading, setDocLoading] = useState(false)
  const [attachFiles, showAttachFiles, hideAttachFiles] = usePopup()

  const handleAttachment = async (file) => {
    const { type, size } = file
    return new Promise(async (resolve, reject) => {
      setDocLoading(true)
      const { data: { getSignedUrl: { signedUrl, key, fileName, newFilename } } = {} } = await client.query({
        query: GET_SIGNED_URL,
        variables: { fileName: file.name, fileType: file.type }
      })
      fetch(signedUrl, {
        method: 'PUT',
        body: file
      })
        .then(() => {
          resolve({ path: key, file_name: fileName, type, file_size: size, newFilename })
          setDocLoading(false)
        })
        .catch((e) => {
          setDocLoading(false)
          toast.error(e)
          reject(e)
        })
    })
  }

  const handleFileUpload = async (files) => {
    const uploadedFile = await Promise.all(Array.from(files).map(handleAttachment))
    setUploadedFiles([...uploadedFiles, ...uploadedFile])
  }

  const handleDeleteFile = (index) => {
    const newUploadedFiles = uploadedFiles?.filter((_, i) => i !== index)
    setUploadedFiles(newUploadedFiles)
  }

  useEffect(() => {
    onChange(uploadedFiles)
  }, [onChange, uploadedFiles])

  const handleAddAttachments = useCallback(
    (option) => {
      if (option === LIBRARY_OPTIONS.ATTACH_FILE) {
        showAttachFiles()
      } else {
        document.getElementById('attachment-input').click()
      }
    },
    [showAttachFiles]
  )

  const handleAttachmentsChange = useCallback((attchements) => {
    setUploadedFiles([...attchements])
  }, [])

  return (
    <>
      <input
        type="file"
        id="attachment-input"
        style={{ display: 'none' }}
        multiple
        onChange={(e) => handleFileUpload(e.target.files)}
      />
      {uploadedFiles?.map((file, index) => (
        <Row key={index} style={{ alignItems: 'center', width: '350px' }}>
          <Tooltip placement="right-start" title={file?.file_name?.length > 40 ? file?.file_name : ''}>
            <div>
              <Typography.Body type={3}>
                {file?.file_name?.length > 40 ? `${file?.file_name.slice(0, 40)}...` : file?.file_name}
              </Typography.Body>
            </div>
          </Tooltip>
          <IconButton onClick={() => handleDeleteFile(index)} style={{ marginLeft: 'auto' }}>
            <Delete color="error" />
          </IconButton>
        </Row>
      ))}
      <Row
        gap={15}
        style={{ marginTop: 30, justifyContent: 'space-between' }}
        className={styles.announcementFormFooter}>
        <MUIMenuButton
          loading={docLoading}
          onClick={handleAddAttachments}
          width={180}
          label={'Add Attachments'}
          options={dropdownOption.ATTACHMENT_UPLOAD_OPTIONS}
        />
        <Row gap={15} style={{ justifyContent: 'flex-end' }} className={styles.announcementFormFooter}>
          <Button style={{ width: 100 }} onClick={() => navigate('/announcements')}>
            Cancel
          </Button>
          {(status === ANNONCEMENT_TYPES.SCHEDULED || status === ANNONCEMENT_TYPES.DRAFT || !isEdit) && (
            <Button style={{ width: 120 }} onClick={onScheduledSend}>
              Schedule Send
            </Button>
          )}
          <Button style={{ width: 100 }} onClick={handlePreview}>
            Preview
          </Button>
          {(status === ANNONCEMENT_TYPES.DRAFT || !isEdit) && (
            <Button
              style={{ width: 100 }}
              onClick={onDraftSave}
              loading={loadingSection === ANNONCEMENT_TYPES.DRAFT && loading}>
              Save Draft
            </Button>
          )}
          {status !== ANNONCEMENT_TYPES.SCHEDULED && (
            <Button style={{ width: 100 }} onClick={onSubmit} loading={loadingSection === 'post' && loading}>
              Post
            </Button>
          )}
        </Row>
      </Row>
      <AnnouncementAttchementPopup
        attachFiles={attachFiles}
        hideAttachFiles={hideAttachFiles}
        uploadedFiles={uploadedFiles}
        onAddAttachments={handleAttachmentsChange}
      />
    </>
  )
}

const GET_SIGNED_URL = gql`
  query getSignedUrl($fileName: String, $fileType: String) {
    getSignedUrl(fileName: $fileName, fileType: $fileType) {
      signedUrl
      fileName
      key
      newFilename
    }
  }
`

export default AnnouncementFileUpload
