import { Col, Typography } from 'components'

const Notifications = () => {
  return (
    <Col style={{ width: '100%' }}>
      <Typography.Heading type="5">Notifications</Typography.Heading>
    </Col>
  )
}

export default Notifications
