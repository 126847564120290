import React, { useMemo } from 'react'
import { Divider, Row, Typography } from 'components'
import moment from 'moment'
import styles from './announcements.module.scss'
import schedule from '../../assets/images/schedule.png'
import AnnouncementAttachments from './AnnouncementAttachments'

const AnnouncementPreview = ({ isAdmin = false, preview, isMobile = false }) => {
  const isScheduleDate = useMemo(() => {
    if ((preview?.start_date || preview?.end_date) && isAdmin) {
      return true
    } else if (!isAdmin && preview?.end_date) {
      return true
    } else {
      return false
    }
  }, [isAdmin, preview])
  return (
    <div style={{ margin: 16 }}>
      <Typography.Heading type={6} className={styles.title}>
        {preview?.title}
      </Typography.Heading>
      <Row justifyContent="space-between">
        {/* <Typography.Body type={2} className={styles.preview}>
          {preview?.preview ?? ''}
        </Typography.Body> */}
        {preview?.sender && 
        <Typography.Body type={2} className={styles.preview}>
        From {preview?.sender ?? ''}
      </Typography.Body>}
        <Typography.Body type={2} className={styles.created}>
          {isAdmin
            ? moment(preview?.created).format('LL')
            : preview.start_date
            ? moment(preview?.start_date).format('LL')
            : moment(preview?.created).format('LL')}
        </Typography.Body>
      </Row>
      {isScheduleDate && (
        <Row justifyContent="flex-end">
          <div style={{ display: 'flex' }}>
            <Typography.Body type={2} className={styles.created}>
              <img src={schedule} alt="cancelImg" color="black" height={14} width={14} style={{ marginTop: '5px' }} />
            </Typography.Body>
            <Typography.Body type={2} className={styles.created} style={{ marginLeft: '5px' }}>
              {preview?.end_date
                ? `${moment(preview?.start_date).format('MMM DD YYYY hh:mm A')} to ${moment(preview?.end_date).format(
                    'MMM DD YYYY hh:mm A'
                  )}`
                : `${moment(preview?.start_date).format('MMM DD YYYY hh:mm A')}`}
            </Typography.Body>
          </div>
        </Row>
      )}
      <Divider style={{ margin: '10px 0px', color: '#888787' }} />
      <div className={styles.previewContent}>
        {!isMobile && <AnnouncementAttachments preview={preview} />}
        <div dangerouslySetInnerHTML={{ __html: preview?.content }} />
        {isMobile && <AnnouncementAttachments preview={preview} />}
      </div>
      {isAdmin && (
        <Typography.Body style={{ paddingTop: 10 }} type={2} className={styles.created}>
          Total Views: {preview?.view_count?.length ?? 0}
        </Typography.Body>
      )}
    </div>
  )
}

export default AnnouncementPreview
