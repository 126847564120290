import { Typography, BasicTable, Loading, Button, Row, Agent, Input, MUIActionButton, InputSearch } from 'components'
import { gql, useMutation, useQuery } from '@apollo/client'
import { GET_AGENTS, GET_ALL_USERS, GET_ROLES, GET_USER_PROFILE_BY_ID } from 'helpers/gql'
import { toast } from 'react-toastify'
import { useCallback, useState } from 'react'
import { Add } from '@mui/icons-material'
import CreateUser from './CreateUser'
import CreateAdmin from './CreateAdmin'
import EditRevShare from './EditRevShare'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { USER_MANAGEMENT_ACTIONS } from 'helpers/constants'
import dropdownOption from 'helpers/dropdownOptions'
import { usePopup } from 'hooks/usePopup'
import ActivateUserPopup from './ActivateUserPopup'
import EditUser from './EditUser'
import { Grid } from '@mui/material'
import vars from '../../vars.scss'
import debounce from 'lodash.debounce'
import search from 'helpers/search'

const AgentNum = ({ agentId, children }) => {
  const [value, setValue] = useState(children)
  const [edit, setEdit] = useState(false)

  const [updateAgentNum] = useMutation(UPDATE_AGENT_NUM, {
    onError: (err) => {
      toast.dismiss()
      console.error(err)
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: () => {
      toast.dismiss()
      toast.success('Lonewolf number updated')
      setEdit(false)
    },
    refetchQueries: [{ query: GET_AGENTS }]
  })

  const handleSaveEdit = () => {
    updateAgentNum({ variables: { userId: agentId, agentnum: +value } })
  }

  const handleCancelEdit = () => {
    setValue(children)
    setEdit(false)
  }

  return (
    <>
      {edit ? (
        <Row alignItems="center">
          <Input
            type="number"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }}
          />
          <CheckIcon onClick={handleSaveEdit} style={{ color: '#4bb543', margin: '0px 8px' }} />
          <CloseIcon onClick={handleCancelEdit} style={{ color: '#e90b0b' }} />
        </Row>
      ) : (
        <>
          <Typography.Body type="2" onClick={() => setEdit(true)}>
            {children || '-'}
          </Typography.Body>
        </>
      )}
    </>
  )
}

const UserManagement = () => {
  const [createAdminOpen, setCreateAdminOpen] = useState(false)
  const [editRevShare, setEditRevShare] = useState(null)
  const [selectedUser, setSelectedUser] = useState()
  const [open, showPopup, hidePopup] = usePopup()
  const [create, showCreate, hideCreate] = usePopup()
  const [edit, showEdit, hideEdit] = usePopup()
  const [rows, setRows] = useState()
  const [rowsPerPage, setrowsPerPage] = useState(10)

  const {
    data: { getUsersV2: allUsers } = [],
    refetch,
    loading
  } = useQuery(GET_ALL_USERS, {
    variables: {
      excludeDeleted: false
    },
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: (data) => {
      setRows(data.getUsersV2)
    }
  })

  const [refetchUserProfile] = useMutation(GET_USER_PROFILE_BY_ID, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setSelectedUser(data.getUserById)
    },
    onError: (err) => {
      toast.dismiss()
      toast.error(err.message)
    }
  })

  const { data: { getRoles: roles } = [] } = useQuery(GET_ROLES, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    }
  })

  const getActionOptions = (deleted_at) => {
    return dropdownOption.USER_MANAGEMENT_OPTIONS.filter((option) => {
      if (deleted_at) {
        return option.id !== USER_MANAGEMENT_ACTIONS.DEACTIVATE
      } else {
        return option.id !== USER_MANAGEMENT_ACTIONS.ACTIVATE
      }
    })
  }

  const handleOnClickActionButton = async (menu, user) => {
    if (USER_MANAGEMENT_ACTIONS.EDIT === menu) {
      refetchUserProfile({ variables: { userId: user.id } })
      showEdit()
    } else {
      setSelectedUser(user)
      showPopup()
    }
  }
  const handleSearch = useCallback(
    debounce(async (searchedVal) => {
      const filteredRows = await search.UserManagementFilterRowsBySearch(allUsers, searchedVal)
      setRows(filteredRows ?? [])
    }, 400),
    [allUsers]
  )
  const handleChangeRows = (perPageRows) => {
    setrowsPerPage(perPageRows)
  }
  const columns = [
    {
      name: 'public_id',
      label: '',
      options: {
        display: false
      }
    },
    {
      name: 'id',
      label: '',
      options: {
        display: false
      }
    },
    {
      name: 'thumbnail',
      label: '',
      options: {
        display: false
      }
    },

    {
      name: 'name',
      label: 'Agents',
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const [public_id, id, thumbnail] = tableMeta.rowData
          return <Agent agent={{ name: value, id, thumbnail }} />
        }
      }
    },
    {
      name: 'role',
      label: 'Role',
      options: {
        sort: false,
        customBodyRender: (value) => {
          const role = roles?.find((d) => d.name === value)
          return <Typography.Body style={{ color: vars['table-cell-color'] }}>{role?.pretty_name}</Typography.Body>
        }
      }
    },
    {
      name: 'deleted_at',
      label: 'Status',
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography.Body style={{ color: vars['table-cell-color'] }}>
              {value ? 'Inactive' : 'Active'}
            </Typography.Body>
          )
        }
      }
    },
    {
      name: 'deleted_at',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const [public_id, id] = tableMeta.rowData
          const actionOptions = getActionOptions(value)

          return (
            <MUIActionButton
              options={actionOptions}
              handleOnClickActionButton={(menu) => handleOnClickActionButton(menu, { id, value })}
            />
          )
        }
      }
    }

    // {
    //   key: 'agentnum',
    //   title: 'Lonewolf #',
    //   width: 160,
    //   render: (agent) => {
    //     return <AgentNum agentId={agent.id}>{agent.agentnum}</AgentNum>
    //   }
    // },
    // {
    //   key: 'revshare',
    //   title: 'Rev Share',
    //   width: 160,
    //   render: (agent) => {
    //     return <Button onClick={() => setEditRevShare(agent)}>Edit</Button>
    //   }
    // },
  ]

  return (
    <>
      <Grid container item xs={12}>
        <Typography.Heading type="5">User Management</Typography.Heading>
      </Grid>
      <Grid container item>
        <Grid item xs={6} md={3}>
          <InputSearch
            name="search"
            onChange={(e) => {
              handleSearch(e.target.value.trimStart())
            }}
          />
        </Grid>
        <Grid item xs={6} md={9} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button prefixIcon={<Add />} onClick={showCreate}>
            Create user
          </Button>
        </Grid>
      </Grid>
      {loading ? (
        <Loading fullpage />
      ) : (
        <BasicTable
          tableData={rows}
          tableColumns={columns}
          handleChangeRowsPerPage={handleChangeRows}
          rowsPerPage={rowsPerPage}
        />
      )}
      <ActivateUserPopup open={open} user={selectedUser} hidePopup={hidePopup} />
      <CreateUser create={create} hideCreate={hideCreate} roles={roles} />
      <CreateAdmin open={createAdminOpen} setOpen={setCreateAdminOpen} />
      <EditRevShare
        open={editRevShare}
        setOpen={setEditRevShare}
        agents={allUsers}
        allUsers={allUsers}
      />
      <EditUser open={edit} users={selectedUser} hidePopup={hideEdit} refetch={refetch} roles={roles} />
    </>
  )
}

const UPDATE_AGENT_NUM = gql`
  mutation updateAgentNum($userId: Int!, $agentnum: Int!) {
    updateAgentNum(userId: $userId, agentnum: $agentnum)
  }
`

export default UserManagement
