import { useRef, useEffect } from 'react'

function createRootElement(id) {
  const rootContainer = document.createElement('div')
  rootContainer.setAttribute('id', id)
  return rootContainer
}

function addRootElement(rootElem) {
  document.body.insertBefore(rootElem, document.body.lastElementChild.nextElementSibling)
}

// Remove open if want to render <div id={id} /> always in DOM
function usePortal(id, open) {
  const rootElemRef = useRef(null)

  useEffect(
    function setupElement() {
      const existingParent = document.querySelector(`#${id}`)

      const parentElem = existingParent || createRootElement(id)

      if (!existingParent && open) {
        addRootElement(parentElem)
      }

      parentElem.appendChild(rootElemRef.current)

      return function removeElement() {
        rootElemRef.current.remove()
        if (!parentElem.childElementCount) {
          parentElem.remove()
        }
      }
    },
    [id, open]
  )

  function getRootElem() {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div')
    }
    return rootElemRef.current
  }

  return getRootElem()
}

export default usePortal
