import { Row, Typography } from 'components'
import styles from './revshare.module.scss'
import { useContext } from 'react'
import { UserContext } from 'context'
import vars from '../../vars.scss'
import moment from 'moment'
import { formatCurrency } from 'helpers/utils'

const RevShareHeader = ({ revShareAgent, referrals = 6, yearFilter }) => {
  const { user } = useContext(UserContext)

  const eligibleEarningamount = revShareAgent?.eligibleEarning > 0.0
  const renderMessage = () => {
    if (!revShareAgent.isEligibility) {
      return <Typography.Body style={{ color: '#DD0000' }}>{revShareAgent?.headerMessage}</Typography.Body>
    } else if (revShareAgent?.isEligibility && revShareAgent?.totalChildren === 0) {
      return <Typography.Body style={{ color: '#DD0000' }}>{revShareAgent?.headerMessage}</Typography.Body>
    } else {
      return (
        <>
          <Typography.Body className={styles.revShareHeading}>{revShareAgent?.headerMessage}</Typography.Body>
          {eligibleEarningamount && (
            <Typography.Body className={styles.revShareSubHeading}>
              For Inspiring and leading at RARE, you received :
            </Typography.Body>
          )}
        </>
      )
    }
  }

  return (
    <>
      <Typography.Heading style={{ marginBottom: 10 }} type={5}>
        Hello {user.name}!
      </Typography.Heading>
      {renderMessage()}

      <div style={{ marginTop: 10 }}>{yearFilter}</div>

      {revShareAgent.isEligibility &&
      revShareAgent?.eligibleEarning >= 0 &&
      revShareAgent?.totalEligibleEarning >= 0 ? (
        <Row gap={30}>
          <div
            className={styles.earningCard}
            style={{ border: '2px solid #4131FE', color: '#4131FE', marginBottom: 10 }}>
            <span style={{ alignItems: 'center' }}>
              {moment()
                .month(revShareAgent?.month - 1)
                .format('MMMM')}{' '}
              Earnings
            </span>

            <br></br>
            <span className={styles.revshareCardAmount} style={{ color: vars['black'] }}>
              {formatCurrency(revShareAgent?.eligibleEarning)}
            </span>
            <span style={{ color: vars['black'] }}> (+HST)</span>
          </div>

          <div
            className={styles.earningCard}
            style={{ border: '2px solid #4131FE', color: '#4131FE', marginBottom: 10 }}>
            <span style={{ alignItems: 'center' }}>YTD Earnings</span>

            <br></br>
            <span className={styles.revshareCardAmount} style={{ color: vars['black'] }}>
              {formatCurrency(revShareAgent?.totalEligibleEarning)}
            </span>
            <span style={{ color: vars['black'] }}> (+HST)</span>
          </div>
        </Row>
      ) : revShareAgent.isEligibility ? (
        ''
      ) : (
        <Row gap={30}>
          <div
            className={styles.earningCard}
            style={{ border: '2px solid #dd0000', color: '#dd0000', marginBottom: 10 }}>
            <span style={{ alignItems: 'center' }}>
              {moment()
                .month(revShareAgent?.month - 1)
                .format('MMMM')}{' '}
              Earnings
            </span>
            <br></br>
            {revShareAgent?.potentialEarning === 'N/A' ? (
              <span className={styles.revshareCardAmount} style={{ color: vars['#dd0000'] }}>
                {revShareAgent?.potentialEarning}
              </span>
            ) : (
              <>
                <span className={styles.revshareCardAmount} style={{ color: vars['#dd0000'] }}>
                  {formatCurrency(revShareAgent?.potentialEarning)}
                </span>
                <span style={{ color: vars['#dd0000'] }}> (+HST)</span>
              </>
            )}
          </div>
          <div
            className={styles.earningCard}
            style={{ border: '2px solid #dd0000', color: '#dd0000', marginBottom: 10 }}>
            <span style={{ alignItems: 'center' }}>YTD Earnings</span>

            <br></br>
            {revShareAgent?.totalPotentialEarning === 'N/A' ? (
              <span className={styles.revshareCardAmount} style={{ color: vars['#dd0000'] }}>
                {revShareAgent?.totalPotentialEarning}
              </span>
            ) : (
              <>
                <span className={styles.revshareCardAmount} style={{ color: vars['#dd0000'] }}>
                  {formatCurrency(revShareAgent?.totalPotentialEarning)}
                </span>
                <span style={{ color: vars['#dd0000'] }}> (+HST)</span>
              </>
            )}
          </div>
        </Row>
      )}
    </>
  )
}

export default RevShareHeader
