import { useQuery } from '@apollo/client'
import { Loading } from 'components'
import { GET_DEAL } from 'helpers/gql'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { DealProvider, DocumentProvider } from 'context'
import EditDealForm from './EditDealForm'
import { toast } from 'react-toastify'
import { useEffect } from 'react'

// NOTE: Not adding deal type and brokerage side here as it messes up the downstream form inputs
const EditDeal = () => {
  const params = useParams()
  const { id } = params

  const {
    data: { getDeal: deal } = [],
    loading,
    refetch
  } = useQuery(GET_DEAL, {
    variables: {
      id
    },
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    }
  })
  useEffect(() => {
    refetch()
  })

  return (
    <>
      <Helmet title="Deal | RARE" />
      <section id="deal">
        {loading || !deal ? (
          <Loading fullpage />
        ) : deal ? (
          <DocumentProvider>
            <DealProvider deal={deal} isEdit>
              <EditDealForm />
            </DealProvider>
          </DocumentProvider>
        ) : (
          <Loading fullpage />
        )}
      </section>
    </>
  )
}

export default EditDeal
