import { gql, useMutation, useQuery } from '@apollo/client'
import {
  Avatar,
  BasicTable,
  Button,
  Col,
  CustomTabs,
  InputSearch,
  Loading,
  Row,
  Typography,
  WarningPopup,
  MUIMenuButton,
  Tag,
  MUIActionButton,
  PopUp
} from 'components'
import { convertUTCToLocal, formatCurrency, getFileUrl, isAdmin } from 'helpers/utils'
import { Helmet } from 'react-helmet-async'
import {
  DEAL_TYPES,
  STATUS,
  STATUS_COLORS,
  STATUS_TYPES,
  ACTION_OPTIONS,
  USER_ROLES,
  AGENT_COLORS
} from 'helpers/constants'
import dropdownOption from 'helpers/dropdownOptions'
import search from 'helpers/search'
import { GET_DEALS } from 'helpers/gql'
import { useCallback, useContext, useState } from 'react'
import { UserContext } from 'context'
import { useNavigate } from 'react-router-dom'
import { usePopup } from 'hooks/usePopup'
import { toast } from 'react-toastify'
import styles from './deals.module.scss'
import vars from '../../vars.scss'
import { useMemo } from 'react'
import debounce from 'lodash.debounce'

const Deals = () => {
  const navigate = useNavigate()
  const { user } = useContext(UserContext)
  const [filters, setFilters] = useState({})
  const [rows, setRows] = useState()
  const [selectedRows, setSelectedRows] = useState([])
  const [confirmDelete, showConfirmDelete, hideConfirmDelete] = usePopup()
  const [confirmSettled, showConfirmSettled, hideConfirmSettled] = usePopup()
  const [checkedRows, setCheckedRows] = useState()
  const admin = user.role === USER_ROLES.super_admin || user.role === USER_ROLES.admin
  const [rowsPerPage, setrowsPerPage] = useState(10)
  const {
    data: { getDeals: deals } = [],
    loading,
    refetch
  } = useQuery(GET_DEALS, {
    fetchPolicy: 'no-cache',
    variables: {
      filters
    },
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: (data) => {
      setRows(data.getDeals)
    }
  })

  const [deleteDeal, { loading: deleteLoading }] = useMutation(DELETE_DEAL, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
      hideConfirmDelete()
    },
    onCompleted: () => {
      toast.dismiss()
      setCheckedRows([])
      setSelectedRows([])
      toast.success('Deal(s) deleted.')
      refetch()
      hideConfirmDelete()
    }
  })

  const [settledDeal, { loading: settledDealLoading }] = useMutation(CHANGE_DEALS_STATUS_TO_SETTLE, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
      hideConfirmSettled()
    },
    onCompleted: () => {
      toast.dismiss()
      setCheckedRows([])
      setSelectedRows([])
      toast.success('Deal(s) settled.')
      refetch()
      hideConfirmSettled()
    }
  })

  const getActionOptions = (status) => {
    const canShowDeleteMenu =
      (status === STATUS_TYPES.draft && user.role === USER_ROLES.agent) ||
      user.role === USER_ROLES.admin ||
      user.role === USER_ROLES.super_admin

    const canShowSettledMenu = user.role === USER_ROLES.admin || user.role === USER_ROLES.super_admin

    const options = [
      { id: ACTION_OPTIONS.view, text: ACTION_OPTIONS.view },
      status !== STATUS_TYPES.review && { id: ACTION_OPTIONS.edit, text: ACTION_OPTIONS.edit },
      canShowDeleteMenu && { id: ACTION_OPTIONS.delete, text: ACTION_OPTIONS.delete },
      canShowSettledMenu &&
        status === STATUS_TYPES.closed && { id: ACTION_OPTIONS.settled, text: ACTION_OPTIONS.settled }
    ]
    return options.filter(Boolean)
  }

  const handleOnClickActionButton = (menu, publicId, type) => {
    if (ACTION_OPTIONS.view === menu) {
      navigate(`/deals/view/${type}/${publicId}`)
    } else if (ACTION_OPTIONS.edit === menu) {
      navigate(`/deals/edit/${type}/${publicId}`)
    } else if (ACTION_OPTIONS.delete === menu) {
      setSelectedRows([publicId])
      showConfirmDelete()
    } else if (ACTION_OPTIONS.settled === menu) {
      setSelectedRows([publicId])
      showConfirmSettled()
    }
  }

  const canDelete = useMemo(() => {
    return (user.role === USER_ROLES.agent && filters.status === STATUS_TYPES.draft) ||
      user.role === USER_ROLES.admin ||
      user.role === USER_ROLES.super_admin
      ? true
      : false
  }, [filters.status, user.role])

  const tabOptions = useMemo(() => {
    return user.role === USER_ROLES.admin || user.role === USER_ROLES.super_admin
      ? dropdownOption.dealsFilterOptions.filter((opt) => opt.value !== STATUS_TYPES.draft)
      : dropdownOption.dealsFilterOptions
  }, [user.role])

  const columns = [
    {
      name: 'public_id',
      label: '',
      options: {
        display: false
      }
    },
    {
      name: 'id',
      label: '',
      options: {
        display: false
      }
    },
    {
      name: 'creator_id',
      label: '',
      options: {
        display: false
      }
    },
    {
      name: 'agents',
      label: 'Agent',
      options: {
        sortCompare: (order) => (rowA, rowB) => {
          const [public_id1, id1, creator_id1] = rowA.rowData
          const dealAgentA = rowA?.data?.filter((a) => a.id === (isAdmin(user) ? creator_id1 : user.id))[0]

          const [public_id2, id2, creator_id2] = rowB.rowData
          const dealAgentB = rowB?.data?.filter((a) => a.id === (isAdmin(user) ? creator_id2 : user.id))[0]

          const agentNameA = dealAgentA?.name.toUpperCase()
          const agentNameB = dealAgentB?.name.toUpperCase()
          if (agentNameA < agentNameB) {
            return order === 'asc' ? -1 : 1
          }
          if (agentNameA > agentNameB) {
            return order === 'asc' ? 1 : -1
          }
          return 0
        },
        customBodyRender: (value, tableMeta) => {
          const [public_id, id, creator_id] = tableMeta.rowData
          const dealAgent = value.filter((a) => a.id === (isAdmin(user) ? creator_id : user.id))[0]
          return (
            <div className={styles.agentColumn}>
              <Avatar name={dealAgent.name} src={getFileUrl(dealAgent.thumbnail)} />
              <div>
                <Typography.Body className={styles.agentName}>{dealAgent.name}</Typography.Body>
                <Typography.Body style={{ color: vars['loading-inner-color-secondary'] }}>
                  {dealAgent.id === creator_id ? (
                    <Tag text={`Primary Rare Agent #${dealAgent.id}`} background={`${AGENT_COLORS['primary']}`} />
                  ) : (
                    <Tag text={`Secondary Rare Agent #${dealAgent.id}`} background={`${AGENT_COLORS['secondary']}`} />
                  )}
                </Typography.Body>
              </div>
            </div>
          )
        }
      }
    },
    {
      name: 'price',
      label: 'Price',
      options: {
        sort: true,
        display: admin,
        customBodyRender: (value) => {
          return <Typography.Body style={{ color: vars['table-cell-color'] }}>{formatCurrency(value)}</Typography.Body>
        }
      }
    },
    {
      name: 'type',
      label: 'Deals',
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const [public_id, id] = tableMeta.rowData
          const fontColor = tableMeta.rowData[13] === false ? 'red' : 'initial'
          return (
            <>
              <Typography.Body style={{ color: fontColor }}>{DEAL_TYPES[value]}</Typography.Body>
              <Typography.Body style={{ color: fontColor }}>#{id}</Typography.Body>
            </>
          )
        }
      }
    },
    {
      name: 'status',
      label: '',
      options: {
        display: false
      }
    },
    {
      name: 'status_pretty_name',
      label: 'Status',
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const [id, public_id, creator_id, agents, price, type, status] = tableMeta.rowData
          return <Tag className={styles.statusColumn} text={value} background={`${STATUS_COLORS[status]}`} />
        }
      }
    },
    {
      name: 'address',
      label: 'Listing',
      options: {
        sort: true,
        customBodyRender: (value) => {
          return <Typography.Body style={{ color: vars['table-cell-color'] }}>{value}</Typography.Body>
        }
      }
    },
    {
      name: 'created',
      label: 'Created Date',
      options: {
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography.Body style={{ color: vars['table-cell-color'] }}>
              {value ? convertUTCToLocal(value, 'MMM DD, yyyy hh:mm A') : null}
            </Typography.Body>
          )
        }
      }
    },
    {
      name: 'close_date',
      label: 'Closed Date',
      options: {
        sort: true,
        display: admin,
        customBodyRender: (value, tableMeta) => {
          const dealType = tableMeta.rowData[5]
          return (
            <Typography.Body style={{ color: vars['table-cell-color'] }}>
              {dealType === 'pre-con' ? (
                value ? (
                  convertUTCToLocal(value, 'yyyy')
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span>-</span>
                  </div>
                )
              ) : value ? (
                convertUTCToLocal(value, 'MMM DD, yyyy hh:mm A')
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <span>-</span>
                </div>
              )}
            </Typography.Body>
          )
        }
      }
    },
    {
      name: 'settled_date',
      label: 'Settled Date',
      options: {
        sort: true,
        display:
          admin &&
          filters.status !== STATUS_TYPES.submitted &&
          filters.status !== STATUS_TYPES.review &&
          filters.status !== STATUS_TYPES.attention &&
          filters.status !== STATUS_TYPES.approved &&
          filters.status !== STATUS_TYPES.closed,
        customBodyRender: (value) => {
          return (
            <Typography.Body style={{ color: vars['table-cell-color'] }}>
              {value ? convertUTCToLocal(value, 'MMM DD, yyyy hh:mm A') : <span>-</span>}
            </Typography.Body>
          )
        }
      }
    },
    {
      name: 'status',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const [public_id, id, creator_id, agents, sale_price, type] = tableMeta.rowData
          const actionOptions = getActionOptions(value)
          return (
            <MUIActionButton
              options={actionOptions}
              handleOnClickActionButton={(menu) => handleOnClickActionButton(menu, public_id, type)}
            />
          )
        }
      }
    },
    {
      name: 'checklist_tasks_completed',
      label: '',
      options: {
        display: false,
        sort: false
      }
    }
  ]

  const handleStatusFilterChange = async (status) => {
    setrowsPerPage(10)
    setCheckedRows([])
    setSelectedRows([])
    if (status === 'all') return setFilters({})
    setFilters({
      status
    })
  }

  const handleSearch = useCallback(
    debounce(async (searchedVal) => {
      const filteredRows = await search.DealsTableFilterRowsBySearch(deals, searchedVal)
      setRows(filteredRows ?? [])
    }, 400),
    [deals]
  )
  const handleRowSelect = useCallback(
    (currentRowsSelected, allRowsSelected, selectedRows) => {
      const deletedRows = []
      allRowsSelected.map((row) => {
        const selectedRows = rows.at(row.index)
        return deletedRows.push(selectedRows.public_id)
      })
      setSelectedRows(deletedRows)
      setCheckedRows(selectedRows)
    },
    [rows]
  )

  const handleMultipleDelete = useCallback(() => {
    deleteDeal({
      variables: { id: selectedRows }
    })
  }, [deleteDeal, selectedRows])

  const handleMultipleSettledDeal = useCallback(() => {
    settledDeal({
      variables: { ids: selectedRows }
    })
  }, [settledDeal, selectedRows])

  const handleOnCreateDealMenuClick = (value) => {
    navigate('/deals/create/' + value)
  }
  const handleConfirmDelete = () => {
    hideConfirmDelete()
    setCheckedRows([])
    setSelectedRows([])
  }
  const handleConfirmSettled = () => {
    hideConfirmSettled()
    setCheckedRows([])
    setSelectedRows([])
  }
  const handleChangeRows = (perPageRows) => {
    setrowsPerPage(perPageRows)
  }

  return (
    <div className={styles.dealsTableMain}>
      <Helmet title="Deals | RARE" />
      <section id="deals">
        <Row alignItems="center" marginBottom="40px" justifyContent="space-between" className={styles.dealsDivMain}>
          <Col className={styles.dealsInnerDiv}>
            <InputSearch
              name="search"
              onChange={(e) => {
                handleSearch(e.target.value.trimStart())
              }}
            />
          </Col>
          <Col>
            <div style={{ display: 'flex' }}>
              {user.role === USER_ROLES.agent && (
                <MUIMenuButton
                  onClick={handleOnCreateDealMenuClick}
                  label={'New Deal'}
                  options={dropdownOption.dealOptions}
                />
              )}
              {filters.status === STATUS_TYPES.closed
                ? (user.role === USER_ROLES.admin || user.role === USER_ROLES.super_admin) && (
                    <Button
                      onClick={showConfirmSettled}
                      disabled={selectedRows?.length > 0 ? false : true}
                      style={{ marginLeft: '25px' }}>
                      Settled
                    </Button>
                  )
                : null}
              {canDelete && (
                <Button
                  onClick={showConfirmDelete}
                  disabled={selectedRows?.length > 0 ? false : true}
                  style={{ marginLeft: '25px' }}>
                  Delete
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <CustomTabs options={tabOptions} onChange={handleStatusFilterChange}>
          {loading ? (
            <Loading fullpage />
          ) : (
            <BasicTable
              tableData={rows}
              tableColumns={columns}
              hanldeSelectedRowsData={handleRowSelect}
              displayRowCheckbox={canDelete}
              checkedRows={checkedRows}
              handleChangeRowsPerPage={handleChangeRows}
              rowsPerPage={rowsPerPage}
            />
          )}
        </CustomTabs>
      </section>
      <WarningPopup
        openModel={confirmDelete}
        loading={deleteLoading}
        confirmBtnLabel={'Delete'}
        onCancle={handleConfirmDelete}
        onConfirm={handleMultipleDelete}>
        <Typography.Body type={1}>Do you really want to delete this deal(s)?</Typography.Body>
        {selectedRows.length > 1 && (
          <Typography.Body type={1} style={{ textAlign: 'center' }}>
            {selectedRows.length} deals selected
          </Typography.Body>
        )}
      </WarningPopup>
      <PopUp
        title={'Settle Deal'}
        open={confirmSettled}
        loading={settledDealLoading}
        confirmBtnLabel={'Settle'}
        cancelBtnLabel={'Cancel'}
        onCancel={handleConfirmSettled}
        onConfirm={handleMultipleSettledDeal}>
        <div style={{ padding: 30 }}>
          {selectedRows.length > 1 ? (
            <>
              <Typography.Body type={1} style={{ textAlign: 'center' }}>
                You have selected multiple deals to settle
              </Typography.Body>
              <Typography.Body type={1} style={{ textAlign: 'center' }}>
                Please confirm that you want to settle all deals selected.
              </Typography.Body>
              <Typography.Body type={1} style={{ textAlign: 'center' }}>
                {selectedRows.length} deals selected
              </Typography.Body>
            </>
          ) : (
            <>
              <Typography.Body type={1} style={{ textAlign: 'center' }}>
                You have selected deal to settle
              </Typography.Body>
              <Typography.Body type={1} style={{ textAlign: 'center' }}>
                Please confirm that you want to settle selected deal.
              </Typography.Body>
            </>
          )}
        </div>
      </PopUp>
    </div>
  )
}

const DELETE_DEAL = gql`
  mutation deleteDeal($id: [String]!) {
    deleteDeal(ids: $id)
  }
`
const CHANGE_DEALS_STATUS_TO_SETTLE = gql`
  mutation ChangeDealsStatusToSettle($ids: [String]!) {
    changeDealsStatusToSettle(ids: $ids)
  }
`
export default Deals
