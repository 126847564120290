import { Typography } from 'components'
import styles from './checkbox.module.scss'

const Checkbox = ({ name, error, onChange, style, label, labelStyle, disabled, checked }) => {
  return (
    <>
      <input
        id={name}
        type="checkbox"
        name={name}
        onChange={onChange}
        style={style}
        disabled={disabled}
        className={styles.checkbox}
        checked={checked}
      />

      {label && (
        <Typography.Label htmlFor={name} className={styles.label} style={labelStyle}>
          {label}
        </Typography.Label>
      )}

      {error && (
        <Typography.Body type="3" color="error" className={styles.error}>
          {error}
        </Typography.Body>
      )}
    </>
  )
}

export default Checkbox
